import { SNAPSHOT_APP_SUB_SCHEMA } from '../generated/snapshot.schema-registry.contracts';
import { COLLECTION_NAME } from '@oms/frontend-schemas';
import type { SubSchemaRegistry } from '@oms/frontend-schemas';

export const CUSTOM_SUB_SCHEMA_CLI = {
  ACCEPT_INVESTOR_ORDER: 'ACCEPT_INVESTOR_ORDER',
  BUMP_PRICE: 'BUMP_PRICE',
  CANCEL_INVESTOR_ORDER: 'CANCEL_INVESTOR_ORDER',
  CREATE_INVESTOR_ORDER: 'CREATE_INVESTOR_ORDER',
  CREATE_UNBOUND_TO: 'CREATE_UNBOUND_TO',
  DONE_FOR_DAY: 'DONE_FOR_DAY',
  FLEX_LAYOUT: 'FLEX_LAYOUT',
  MODIFY_INVESTOR_ORDER: 'MODIFY_INVESTOR_ORDER',
  REJECT_INVESTOR_ORDER: 'REJECT_INVESTOR_ORDER',
  ROUTE_ORDER: 'ROUTE_ORDER',
  SNAPSHOT: 'SNAPSHOT',
  VIEW_INVESTOR_ORDER: 'VIEW_INVESTOR_ORDER'
} as const;

const appPath = `apps/oms-app/src/app`;

export const CUSTOM_SUB_SCHEMA_REGISTRY_CLI: SubSchemaRegistry = {
  [CUSTOM_SUB_SCHEMA_CLI.ACCEPT_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-monitor/commands/accept-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.BUMP_PRICE]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/montage/commands/bump-price/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.CANCEL_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-monitor/commands/cancel-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.CREATE_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [SNAPSHOT_APP_SUB_SCHEMA.INVESTOR_ORDER_ENTRY_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-entry/commands/create-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.CREATE_UNBOUND_TO]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [SNAPSHOT_APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/commands/create-unbound-trading-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.DONE_FOR_DAY]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-monitor/commands/done-for-day/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.FLEX_LAYOUT]: {
    subSchemaFolderPath: `${appPath}/app-config/schemas/flex-layout`,
    type: 'other',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.MODIFY_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [SNAPSHOT_APP_SUB_SCHEMA.INVESTOR_ORDER_ENTRY_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-entry/commands/modify-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.REJECT_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-monitor/commands/reject-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.ROUTE_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    dependencies: [SNAPSHOT_APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES],
    subSchemaFolderPath: `${appPath}/widgets/trading/route-order/commands/route-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.SNAPSHOT]: {
    collection: COLLECTION_NAME.SNAPSHOTS,
    subSchemaFolderPath: `${appPath}/app-config/schemas/snapshots`,
    type: 'other',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  },
  [CUSTOM_SUB_SCHEMA_CLI.VIEW_INVESTOR_ORDER]: {
    collection: COLLECTION_NAME.ACTIONS,
    subSchemaFolderPath: `${appPath}/widgets/trading/investor-order-view/commands/view-investor-order/schema`,
    type: 'action',
    versionedSchemas: [] // Keep empty (to prevent code-gen cli issues & update here: apps/oms-app/src/app/schemas/sub-schema.registry.ts)
  }
};

//apps\oms-app\src\app\widgets\trading\investor-order-monitor\grid-actions\accept.investor-order.action.ts
