import Keycloak from 'keycloak-js';
import { container } from 'tsyringe';
import { getEnvVar } from '@oms/shared/util';

const publicKeycloakUrlReplaceRegEx = /^(ui|uinew)(-?[0-9a-z]+m?)?\./;

const deriveAuthUrlFromBrowerLocation = (): string => {
  if (window.location.host.match(publicKeycloakUrlReplaceRegEx)) {
    return (
      window.location.protocol +
      '//' +
      window.location.host.replace(publicKeycloakUrlReplaceRegEx, 'id.') +
      '/auth/'
    );
  } else {
    // for privatelink ui access, ui ingress covers keycloak via /auth/ path
    return `${window.location.protocol}//${window.location.host}/auth/`;
  }
};

const getKeycloakClientAuthServerUrl = (): string =>
  getEnvVar('NX_CUSTOM_VAR_KEYCLOAK_CLIENT_AUTH_SERVER_URL') || deriveAuthUrlFromBrowerLocation();

export const keycloakSetup: Keycloak.KeycloakConfig = {
  realm: getEnvVar('NX_CUSTOM_VAR_KEYCLOAK_CLIENT_REALM'),
  url: getKeycloakClientAuthServerUrl(),
  clientId: getEnvVar('NX_CUSTOM_VAR_KEYCLOAK_FRONTEND_CLIENT_ID')
};

/**
 * Global Keycloak auth client instance
 */
export const keycloakInstance = new Keycloak(keycloakSetup);

container.register(Keycloak, { useValue: keycloakInstance });

export const getKeyCloakInstance = () => keycloakInstance;

declare global {
  interface Window {
    keycloak: Keycloak;
    getKeycloak: () => Keycloak;
  }
}

window['keycloak'] = keycloakInstance;
window['getKeycloak'] = getKeyCloakInstance;
