import type { AnyRecord } from '@valstro/workspace';
import type { IAllFields } from './all-fields.component';
import type { IAdditionalFields } from './additional-fields.component';
import { FieldDefinition } from '@oms/frontend-foundation';
import type { AnyFieldDefinition, Field, IAnyField } from '@oms/frontend-foundation';
import type { IEnrichedField } from '../enriched-field/enriched-field.component';

export const createAdditionalAllFields = <_TOutputContract extends AnyRecord = AnyRecord>(
  allFieldsOptions: Pick<IAllFields, 'groups' | 'fields' | 'fixedFields' | 'sx' | 'columns'>,
  renderBehaviour: IAllFields['renderBehaviour'] = 'all'
) => {
  const allFieldsDef: IAllFields = {
    formId: '',
    formType: '',
    component: 'all-fields',
    renderStrategy: 'main-form',
    renderBehaviour,
    name: 'all-fields',
    columns: 3,
    ...allFieldsOptions
  };

  const additionalFieldsDef: IAdditionalFields = {
    name: 'additional-fields',
    component: 'additional-fields',
    renderBehaviour,
    fields: [allFieldsDef]
  };
  return {
    component: 'section',
    name: 'additional-fields-section',
    sx: {
      backgroundColor: 'layout.level1',
      marginTop: 1,
      pt: 1,
      pb: 4,
      px: 0,
      ...(allFieldsOptions.sx && allFieldsOptions.sx)
    },
    style: {
      // paddingLeft: 0,
      // paddingRight: 0
    },
    fields: [[additionalFieldsDef as unknown as Field]]
  } as IAnyField;
};

export const createEnrichedField = <T extends AnyFieldDefinition | ReturnType<AnyFieldDefinition['build']>>(
  field: T,
  options: Pick<IEnrichedField, 'groupName' | 'colSpan' | 'condition'> = {}
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const fieldDef = field instanceof FieldDefinition ? field.build() : field;
  const enrichedField: IEnrichedField = {
    component: 'enriched-field',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    name: `${fieldDef.name}-field`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: fieldDef,
    ...options
  };
  return enrichedField;
};
