import { openCancelExecutions } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { TableServerMappedExecutions } from '@oms/generated/frontend';

function isVisible(rowData: TableServerMappedExecutions[]): boolean {
  const isOnlyOneRowSelected = rowData.length === 1;
  const data = rowData[0];

  return (
    isOnlyOneRowSelected && !!data && !!data.investorOrderId && !rowData.some((e) => !e.executedQuantity)
  );
}

export const cancelExecutionsOnChange = async (
  ctx: ActionContext<TableServerMappedExecutions, ActionComponentConfig<TableServerMappedExecutions>>
) => {
  const { lifecycle, data } = ctx;
  const investorOrderId = data[0]?.investorOrderId;
  const executionIds = data.map((e) => e.id);
  const isDisabled = !isVisible(data);

  ctx.notify({ isDisabled });

  if (lifecycle === 'change') {
    if (isDisabled) {
      return;
    }

    await openCancelExecutions(PROCESS_ID.LEADER, {
      title: `${t('app.orders.orderMonitor.cancelExecutions')}: ${executionIds.length}`,
      form: {
        formId: `cancel-executions-${investorOrderId}`,
        input: {
          orderId: investorOrderId,
          executionIds
        }
      }
    });
  }
};

export const cancelExecutionsAction: ActionDefFactory<TableServerMappedExecutions> = (builder) =>
  builder
    .name('cancel_executions')
    .toolbar((t) =>
      t.component('action-button').id('cancel_executions_button').location('UserToolbar').props({
        isDisabled: true,
        content: 'Cancel'
      })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.TradeManage]);
    })
    .customMenu((m) =>
      m
        .name('Cancel')
        .visible(({ rowData }) => isVisible(rowData))
        .tabName(t('app.common.grids.contextMenuTabs.action'))
    )
    .onChange<ActionComponentConfig<TableServerMappedExecutions>>(cancelExecutionsOnChange);
