import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type { ActionDefFactory, SplitButtonActionComponentConfig } from '@oms/frontend-vgrid';
import { ModificationStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { acceptPendingModificationOnChange } from './accept.pending-modification.action';
import { rejectPendingModificationOnChange } from './reject.pending-modification.action';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

export const doesRowHaveAcceptingStatus = (row: VisibleModificationInfoFragment) =>
  row &&
  'status' in row &&
  row?.status === ModificationStatus.Accepting &&
  row?.__typename === 'VisibleModification';

export const inlinePendingModificationsActions: ActionDefFactory<VisibleModificationInfoFragment> = (
  builder
) =>
  builder
    .name('inline_pending_modification_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(110)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            variant: 'secondary'
          })
        )
    )
    .lifecycles('change', 'init')
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .onChange<SplitButtonActionComponentConfig<VisibleModificationInfoFragment>>(async (ctx) => {
      const { state, lifecycle, appContainer } = ctx;

      const selectedId = state.selectedId;
      const selectedRow = ctx.data[0];

      const authService = appContainer.resolve(AuthService);
      const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);

      switch (lifecycle) {
        case 'init': {
          ctx.notify({
            actionsConfig:
              hasEntitlement && !doesRowHaveAcceptingStatus(selectedRow)
                ? [
                    {
                      id: 'accept',
                      title: 'Accept'
                    },
                    {
                      id: 'reject',
                      title: 'Reject'
                    }
                  ]
                : []
          });
          break;
        }

        case 'change': {
          switch (selectedId) {
            case 'accept':
              return await acceptPendingModificationOnChange<VisibleModificationInfoFragment>(ctx);
            case 'reject':
              return await rejectPendingModificationOnChange<VisibleModificationInfoFragment>(ctx);
            default:
              throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
          }
        }
      }
    });
