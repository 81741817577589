import { Lifecycle, scoped, inject } from 'tsyringe';
import type { Subscription } from 'rxjs';
import { GridApi } from '@ag-grid-community/core';
import { AnyRecord } from '@oms/frontend-foundation';
import type { EventHandler, EventSource, GridEventType } from '@oms/frontend-vgrid';
import { NotificationVisiblitySignal } from '@app/notifications/notifications.signals';
import { NOTIFICATION_VISIBILITY } from '@app/notifications/notifications.contracts';
import type { NotificationSignal } from '@app/notifications/notifications.contracts';
import {
  VIS_KEY,
  VIS_VALUE
} from '@app/widgets/trading/investor-order-monitor/grid-services/visibility.filters';

export const createAlertsEventHandler = <TData extends AnyRecord>(resolveVisibilityKey: keyof TData) => {
  @scoped(Lifecycle.ContainerScoped)
  class AlertsEventHandler implements EventHandler<TData> {
    public name = 'notification-visibility-alerts';
    public subscriptions: Subscription[] = [];

    private api: GridApi | undefined;

    constructor(@inject(NotificationVisiblitySignal) public visibilitySignal: NotificationVisiblitySignal) {}

    public addEvents(eventSource: EventSource<GridEventType, TData>): void {
      eventSource.add('onGridReady', (e) => {
        this.api = e.api;

        this.subscriptions.push(
          this.visibilitySignal.signal.$.subscribe((signal: NotificationSignal) => {
            const currentFilters = this.api?.getFilterModel();
            this.api?.setFilterModel({
              ...currentFilters,
              [resolveVisibilityKey]:
                // Map NOTIFICATION_VISIBILITY to VIS_VALUE
                signal === NOTIFICATION_VISIBILITY.MINE
                  ? {
                      type: 'set',
                      values: VIS_VALUE[VIS_KEY.MINE]
                    }
                  : signal === NOTIFICATION_VISIBILITY.TEAM
                    ? {
                        type: 'set',
                        values: VIS_VALUE[VIS_KEY.COVERAGE_TEAM]
                      }
                    : undefined
            });
          })
        );
      });
    }

    public removeEvents(): void {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  return AlertsEventHandler;
};
