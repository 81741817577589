import type { ComponentType } from 'react';
import { Switch } from '../actions/components/switch';
import { ActionButton } from '../actions/components/action.button';
import { ActionInput } from '../actions/components/action.input';
import { ActionDropdown, type ActionDropdownProps } from '../actions/components/action.dropdown';
import { ActionText } from '../actions/components/action.text';
import {
  SplitButtonAction,
  type SplitButtonActionComponentConfig
} from '../actions/components/split.button.action';
import type { AnyRecord } from '@oms/frontend-foundation';
import { Divider, type DividerProps } from './divider';
import { IconCounterButton } from '../actions/components/iconcounter.button';
import { IconCounterProps } from '@oms/shared-frontend/ui-design-system';
import { ActionComponentConfig } from '../../models/actions.model';
import { ActionIconButton } from '../actions/components/action.icon-button';

export const COMPONENT_TYPE = {
  ACTION_BUTTON: 'action-button',
  ACTION_ICON_BUTTON: 'action-icon-button',
  ACTION_INPUT: 'action-input',
  ACTION_DROPDOWN: 'action-dropdown',
  ACTION_TEXT: 'action-text',
  SPLIT_BUTTON_ACTION: 'split-button-action',
  ACTION_SWITCH: 'action-switch',
  DIVIDER: 'divider',
  ICON_COUNTER: 'icon-counter-button'
} as const;

export const COMPONENT_REGISTRY = new Map<DefaultComponentTypes, ComponentType<any>>([
  [COMPONENT_TYPE.ACTION_BUTTON, ActionButton],
  [COMPONENT_TYPE.ACTION_ICON_BUTTON, ActionIconButton],
  [COMPONENT_TYPE.ACTION_INPUT, ActionInput],
  [COMPONENT_TYPE.ACTION_DROPDOWN, ActionDropdown],
  [COMPONENT_TYPE.ACTION_TEXT, ActionText],
  [COMPONENT_TYPE.SPLIT_BUTTON_ACTION, SplitButtonAction],
  [COMPONENT_TYPE.ACTION_SWITCH, Switch],
  [COMPONENT_TYPE.DIVIDER, Divider],
  [COMPONENT_TYPE.ICON_COUNTER, IconCounterButton]
]);

export type ComponentRegistryProps<TData extends AnyRecord> = {
  [COMPONENT_TYPE.ACTION_BUTTON]: ActionComponentConfig<TData>;
  [COMPONENT_TYPE.ACTION_ICON_BUTTON]: ActionComponentConfig<TData>;
  [COMPONENT_TYPE.ACTION_INPUT]: ActionComponentConfig<TData>;
  [COMPONENT_TYPE.ACTION_DROPDOWN]: ActionDropdownProps<TData>;
  [COMPONENT_TYPE.ACTION_TEXT]: ActionComponentConfig<TData>;
  [COMPONENT_TYPE.SPLIT_BUTTON_ACTION]: SplitButtonActionComponentConfig<TData>;
  [COMPONENT_TYPE.ACTION_SWITCH]: ActionComponentConfig<TData>;
  [COMPONENT_TYPE.DIVIDER]: DividerProps;
  [COMPONENT_TYPE.ICON_COUNTER]: ActionComponentConfig<TData> & IconCounterProps;
};

export type DefaultComponentTypes = keyof ComponentRegistryProps<AnyRecord>;
