import { useService } from '@oms/frontend-foundation';
import { useEffect } from 'react';
import { debounceTime } from 'rxjs';
import type {
  InstrumentTrackingEvent,
  TrackingSourceType
} from '@app/common/grids/grid-tracking/grid-tracking.types';
import { GridTrackingSignal } from '@app/common/grids/grid-tracking/grid-tracking.signal';

export const useInstrumentTracking = (
  callback: (event: InstrumentTrackingEvent) => void,
  sourceTypes?: TrackingSourceType[]
) => {
  const signal = useService(GridTrackingSignal);

  useEffect(() => {
    const subscription = signal.instrument$({ sourceTypes }).pipe(debounceTime(250)).subscribe(callback);

    return () => {
      subscription.unsubscribe();
    };
  }, [signal, callback, sourceTypes]);
};
