import { useEffect, useMemo, useRef, useState } from 'react';
import { delay } from 'tsyringe';
import { useGridContainer } from '@oms/frontend-vgrid';
import { GridApi } from '@ag-grid-community/core';

export function useGridApi<TData = any>() {
  const gridContainer = useGridContainer();
  const gridApi = gridContainer.resolve(delay(() => GridApi<TData>));

  const gridApiLoadedRef = useRef(false);
  const [gridApiLoaded, setGridApiLoaded] = useState(false);

  useEffect(() => {
    if (gridApiLoadedRef.current) return;
    if (gridApi) {
      gridApiLoadedRef.current = true;
      setGridApiLoaded(true);
    }
  }, [gridApi]);

  return useMemo(() => gridApi, [gridApiLoaded]);
}
