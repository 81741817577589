import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { convertAlert } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';
import type { ActionContext } from '@oms/frontend-vgrid';
import type { ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import { FormValidationFeedbackLevel, InvestorOrderStatus } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { compact } from 'lodash';

type ReactivateInvestorOrderActionArgs = {
  id: string;
  status: string;
  parentTradingOrderId: string;
};

async function openConfirmationAndListen(
  orderIds: string[],
  errors: FeedbackWrapper[],
  orderService: InvestorOrdersService,
  workspace: FoundationWorkspace,
  numRows: number
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
    title: `Reactivate ${numRows} investor order${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to reactivate ${numRows} investor order${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;

  if (event.type !== DIALOG_EVENT_TYPE.OK) return;

  const response = await orderService.reactivateInvestorOrders(orderIds, false);

  if (response.isSuccess() === true) return;

  // The mutation failed. Invoke another confirmation.

  const feedbackWrappers: FeedbackWrapper[] = response.errors as unknown as FeedbackWrapper[];
  await openConfirmationAndListen(orderIds, feedbackWrappers, orderService, workspace, numRows);
}

export const reactivateInvestorOrderOnChange = async <T extends ReactivateInvestorOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;

  const activeRows = data.filter((selectedRow) => canBeReactivated(selectedRow)).length;

  ctx.notify({ isDisabled: activeRows === 0 });

  if (lifecycle !== 'change') return;

  const orderService = ctx.appContainer.resolve(InvestorOrdersService);

  const [_, api] = await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
    title: `Reactivate ${activeRows} investor order${activeRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      message: `Are you sure you want to reactivate ${activeRows} investor order${
        activeRows > 1 ? 's' : ''
      }?`,
      confirmButtonText: t('app.common.yes'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  if (event.type !== DIALOG_EVENT_TYPE.OK) return;

  const orderIds = data.filter((selectedRow) => canBeReactivated(selectedRow)).map((row) => row.id);

  const response = await orderService.reactivateInvestorOrders(orderIds, false);

  const feedbackWrappers: FeedbackWrapper[] = [];
  if (response.isSuccess()) {
    const feedback = compact(response.value.data?.reactivateInvestorOrders?.feedback);
    feedbackWrappers.push(...feedback);
  }
  if (response.isFailure()) {
    const feedback: FeedbackWrapper[] = response.errors.map((error) => ({
      code: 'Order Reactivation',
      level: FormValidationFeedbackLevel.Error,
      message: error.message
    }));
    feedbackWrappers.push(...feedback);
  }

  if (feedbackWrappers.length > 0)
    await openConfirmationAndListen(orderIds, feedbackWrappers, orderService, ctx.workspace, orderIds.length);
};

export const reactivateInvestorOrderAction =
  <T extends ReactivateInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('reactivate_investor_order')
      .customMenu((m) =>
        m
          .name('Reactivate')
          .visible(({ rowData }) => {
            const activeRows = rowData.filter((selectedRow) => canBeReactivated(selectedRow)).length;
            return activeRows > 0;
          })
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(reactivateInvestorOrderOnChange);

const canBeReactivated = (investorOrder: ReactivateInvestorOrderActionArgs): boolean => {
  const investorOrderStatus = investorOrder.status;

  if (investorOrderStatus !== InvestorOrderStatus.ExecutionCancelled) return false;
  if (investorOrder.parentTradingOrderId) return false;

  return true;
};
