import { tradeStatusMapping } from './order-enums';
import type { TradeStatus } from '@oms/generated/frontend';

export const mapTradeStatus = <T extends string | undefined | null>(
  value?: T,
  defaultVal = value || ''
): string => {
  return value && tradeStatusMapping[value as TradeStatus]
    ? tradeStatusMapping[value as TradeStatus]
    : defaultVal;
};
