import { FC, useCallback, useRef, useState } from 'react';
import { useSize } from '@oms/shared-frontend/ui-design-system';

export const useFlexibleContent = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenRef = useRef<HTMLSpanElement>(null);

  const containerEl = useSize(containerRef, { debounceTime: 1000, throttleTime: 1000 });
  const containerWidth = containerEl ? containerEl.width : 200;

  const [displayState, setDisplayState] = useState<{ content: string; tooltip?: string }>({
    content: ''
  });

  const checkTextWidthAndSetContent = useCallback(
    (value: string, minimalText?: string, tooltip?: string) => {
      if (!hiddenRef.current || containerWidth === 0) {
        return true;
      }

      // Render the text in the hidden span for measurement
      hiddenRef.current.textContent = value;
      const textWidth = hiddenRef.current.offsetWidth;

      if (textWidth <= containerWidth) {
        setDisplayState({ content: value });
        return true;
      }

      if (minimalText) {
        setDisplayState({ content: minimalText, tooltip });
      }
    },
    [containerWidth, setDisplayState]
  );

  return {
    containerRef,
    hiddenRef,
    containerWidth,
    setDisplayState,
    displayState,
    checkTextWidthAndSetContent
  };
};

type FlexibleContentRendererProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  hiddenElementRef: React.RefObject<HTMLSpanElement>;
  content: string;
  tooltip?: string;
};

export const FlexibleContentRenderer: FC<FlexibleContentRendererProps> = ({
  containerRef,
  hiddenElementRef,
  tooltip,
  content
}) => {
  return (
    <div
      ref={containerRef}
      className="container"
      style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
    >
      {/* Hidden span used for measurement */}
      <span
        ref={hiddenElementRef}
        style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap' }}
      />
      <span title={tooltip} data-testid="content">
        {content}
      </span>
    </div>
  );
};
