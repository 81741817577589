import type { EnrichedColumnDef } from '@app/common/grids/table-server/table-server.types';
import { MarketDataCellRenderer } from '@app/data-access/services/marketdata/components/market-data.cell.renderer';
import { CellBadgeClassEnum, positivePriceTypes } from '@oms/frontend-vgrid';
import {
  OrderEntryType,
  OrderSettleType,
  OrderSide,
  OrderSideType,
  OrderType,
  TimeInForce,
  TradingOrderCapacity,
  TradingOrderCategory,
  TradingOrderStatus,
  InvestorOrderStatus,
  VisibilityReason,
  OrderCapacity,
  TradeStatus
} from '@oms/generated/frontend';
import omit from 'lodash/omit';

// ************** Generic *************/

export const defaultCol: EnrichedColumnDef = {
  resizable: true,
  sortable: true,
  filter: true,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  floatingFilter: false
};

export const text: EnrichedColumnDef = {
  resizable: true,
  sortable: true,
  width: 110,
  filter: 'agTextColumnFilter'
};

export const currency: EnrichedColumnDef = {
  filter: 'agTextColumnFilter',
  width: 110
};

export const dateTime: EnrichedColumnDef = {
  filter: 'dateFilter',
  floatingFilterComponent: 'dateFilter',
  cellRenderer: 'FlexibleDatetimeCellRenderer',
  format: 'datetime',
  width: 180
};

export const dateTimeFixed: EnrichedColumnDef = {
  filter: 'dateFilter',
  floatingFilterComponent: 'dateFilter',
  format: 'datetime',
  width: 180
};

export const date: EnrichedColumnDef = {
  filter: 'dateFilter',
  floatingFilterComponent: 'dateFilter',
  format: 'date',
  width: 110
};

export const timestamp: EnrichedColumnDef = {
  filter: 'dateFilter',
  floatingFilterComponent: 'dateFilter',
  format: 'time12',
  width: 200,
  minWidth: 90
};

export const numeric: EnrichedColumnDef = {
  type: 'numericColumn',
  filter: 'agNumberColumnFilter',
  width: 110,
  maxWidth: 160,
  resizable: true,
  sortable: true,
  format: 'number'
};

// ************** Orders *************/

export const id: EnrichedColumnDef = {
  filter: 'agTextColumnFilter',
  hide: true
};

export const instrumentDisplayCode: EnrichedColumnDef = {
  width: 110,
  minWidth: 100,
  filter: 'agTextColumnFilter'
};

export const instrumentLongName: EnrichedColumnDef = {
  minWidth: 100,
  hide: true
};

export const instrumentCusip: EnrichedColumnDef = {
  width: 110,
  minWidth: 90,
  hide: true
};

export const instrumentId: EnrichedColumnDef = {
  width: 110,
  minWidth: 90,
  hide: true
};

export const instrumentExchCode: EnrichedColumnDef = {
  width: 200,
  maxWidth: 200,
  hide: true
};

export const price: EnrichedColumnDef = {
  extends: 'generic.numeric',
  format: 'price'
};

export const limitPrice: EnrichedColumnDef = {
  ...omit(numeric, 'format'),
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'limitPrice' }
};

export const side: EnrichedColumnDef = {
  width: 82,
  maxWidth: 500,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [OrderSide.Buy, OrderSide.Sell],
    valueFormatter: 'side'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'side' },
  cellClassRules: {
    'price-positive': (value) => positivePriceTypes.includes(value.value as OrderSideType),
    'price-negative': (value) => !positivePriceTypes.includes(value.value as OrderSideType)
  }
};

export const sideType: EnrichedColumnDef = {
  width: 82,
  maxWidth: 500,
  minWidth: 75,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [
      OrderSideType.Buy,
      OrderSideType.Sell,
      OrderSideType.Short,
      OrderSideType.Exempt,
      OrderSideType.Btc
    ],
    valueFormatter: 'sideType'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'sideType' },
  cellClassRules: {
    'price-positive': (value) => positivePriceTypes.includes(value.value as OrderSideType),
    'price-negative': (value) => !positivePriceTypes.includes(value.value as OrderSideType)
  }
};

export const lastTradeCondition: EnrichedColumnDef = {
  minWidth: 80,
  width: 160,
  maxWidth: 260,
  cellRenderer: MarketDataCellRenderer
};
export const tickDirection: EnrichedColumnDef = {
  width: 80,
  maxWidth: 100,
  minWidth: 60,
  cellRenderer: MarketDataCellRenderer
};

export const quantity: EnrichedColumnDef = {
  format: 'quantity',
  filter: 'agNumberColumnFilter',
  width: 95,
  maxWidth: 180
};

export const settleType: EnrichedColumnDef = {
  width: 110,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(OrderSettleType),
    valueFormatter: 'orderSettleType'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'settleType' }
};

export const investorAccount: EnrichedColumnDef = {
  extends: 'generic.text',
  minWidth: 90,
  width: 210
};

export const timeInForce: EnrichedColumnDef = {
  width: 180,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(TimeInForce)
  },
  valueFormatter: 'timeInForce',
  cellClass: CellBadgeClassEnum.Capital
};

export const user: EnrichedColumnDef = {
  extends: 'generic.text',
  width: 180
};

export const orderStatus: EnrichedColumnDef = {
  width: 110,
  maxWidth: 220,
  minWidth: 100
};

export const owner: EnrichedColumnDef = {
  extends: 'generic.text',
  width: 180,
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'owner' }
};

export const ownerId: EnrichedColumnDef = {
  extends: 'orders.id',
  width: 110,
  filter: 'agTextColumnFilter',
  filterParams: { filterOptions: ['equals'] }
};

export const orderType: EnrichedColumnDef = {
  width: 110,
  maxWidth: 220,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(OrderType),
    valueFormatter: 'orderType'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'orderType' }
};

export const orderEntryType: EnrichedColumnDef = {
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(OrderEntryType),
    valueFormatter: 'orderEntryType'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'orderEntryType' }
};

export const todayExecutedQuantity: EnrichedColumnDef = {
  extends: 'orders.quantity',
  minWidth: 120,
  width: 140,
  maxWidth: 220
};

export const todayAveragePrice: EnrichedColumnDef = {
  extends: 'orders.price',
  width: 130,
  maxWidth: 190
};

export const visibilityReason: EnrichedColumnDef = {
  width: 130,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(VisibilityReason),
    valueFormatter: 'visibilityReason'
  },
  valueFormatter: 'visibilityReason',
  cellClass: CellBadgeClassEnum.Capital
};

export const orderCapacity: EnrichedColumnDef = {
  extends: 'generic.text',
  width: 95,
  maxWidth: 180,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [OrderCapacity.Agency, OrderCapacity.Principal],
    valueFormatter: 'capacity'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'capacity' }
};

export const tradingOrderCapacity: EnrichedColumnDef = {
  extends: 'generic.text',
  width: 95,
  maxWidth: 180,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [TradingOrderCapacity.Agency, TradingOrderCapacity.Principal],
    valueFormatter: 'capacity'
  },
  cellRenderer: 'FlexibleContentCellRenderer',
  cellRendererParams: { formatterKey: 'capacity' }
};

export const tradingOrderStatus: EnrichedColumnDef = {
  extends: 'orders.status',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(TradingOrderStatus),
    valueFormatter: 'tradingOrderStatus'
  },
  valueFormatter: 'tradingOrderStatus',
  cellClass: CellBadgeClassEnum.Capital
};

export const tradingOrderCategory: EnrichedColumnDef = {
  extends: 'orders.status',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(TradingOrderCategory),
    valueFormatter: 'tradingOrderCategory'
  },
  valueFormatter: 'tradingOrderCategory',
  cellClass: CellBadgeClassEnum.Capital
};

export const investorOrderStatus: EnrichedColumnDef = {
  extends: 'orders.status',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(InvestorOrderStatus),
    valueFormatter: 'investorOrderStatus'
  },
  valueFormatter: 'investorOrderStatus',
  cellClass: CellBadgeClassEnum.Capital,
  hide: true
};

export const tradeStatus: EnrichedColumnDef = {
  extends: 'orders.status',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(TradeStatus),
    valueFormatter: 'tradeStatus'
  },
  valueFormatter: 'tradeStatus',
  cellClass: CellBadgeClassEnum.Capital
};

export const investorOrderIds: EnrichedColumnDef = {
  width: 110,
  filter: 'agArrayColumnFilter',
  hide: true
};
