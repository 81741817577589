import React, { useEffect } from 'react';
import type { FORM_COMPONENT_TYPE } from '../../../../forms/form-builder/common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '@oms/frontend-foundation';
import { DestinationType, FeedbackWrapper, OrderType } from '@oms/generated/frontend';
import type { OrderRouteCreateInput, StrategyInput } from '@oms/generated/frontend';
import { BulkRouteOrderFormValues } from '../bulk-route-order.form-contract';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import {
  useEnhancedFormApi,
  useFormBuilderTemplate,
  useService,
  useWorkspaceContainer
} from '@oms/frontend-foundation';
import { TableServerService } from '@app/data-access/services/system/table-server/table-server.service';
import { buildBulkRouteOrderColumnDefs } from './bulk-route-order-grid.columns';
import { TsInvestorOrdersWithChargesDocument } from '../../investor-order-monitor/investor-order-monitor.contracts';
import { BulkRouteOrderGridEventHander } from './bulk-route-order-grid.event-handler';
import { BulkRouteIOHazard } from '../../utils/row-state-rule-utils';
import {
  agFilterModelToTableServerFilterStr,
  agSortModelToTableServerSortStr
} from '@app/data-access/services/system/table-server/filters/ag-grid.table-server.transformer';
import * as _ from 'lodash';
import { BulkRouteOrderRow, BulkRouteOrderRowWithValidation } from './bulk-route-order-grid.columns';
import { joinFeedbackWrapperWithRowData, reduceDataToBulkRouteOrderRow } from './bulk-route-order-grid.utils';
import { createStrategyInput } from '../../route-order/fixatdl-strategy-field/fixatdl-strategy-field.util';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';

export interface IBulkRouteOrderGrid<TValidator = Validator>
  extends ICommonTextField<
    typeof FORM_COMPONENT_TYPE.BULK_ROUTE_ORDER,
    BulkRouteOrderRowWithValidation[],
    TValidator
  > {}

// Bulk Route Order grid for use in the Bulk Route Order form.
export const BulkRouteOrderGrid: React.FC<FieldProps<IBulkRouteOrderGrid>> = () => {
  const { formId } = useFormBuilderTemplate();
  const formApi = useEnhancedFormApi<BulkRouteOrderFormValues>(); // use this to access the parent form

  // We have 3 useStates to:
  // 1. keep track of IO data, as returned from the table server.
  // 2. keep track of feedback data, as returned from dry run validation.
  // 3. combined data of the above, to be the data source of our grid.
  const [ioData, setIoData] = React.useState<BulkRouteOrderRow[]>([]);
  const [feedbackData, setFeedbackData] = React.useState<Map<string, FeedbackWrapper[]>>(
    new Map<string, FeedbackWrapper[]>()
  );
  const [combinedData, setCombinedData] = React.useState<BulkRouteOrderRowWithValidation[]>([]); // TESTX

  // Because feedbackData is a Map, use an updater to properly set state.
  const updateFeedbackMap = (orderId: string, feedback: FeedbackWrapper[]) => {
    setFeedbackData(new Map(feedbackData.set(orderId, feedback || [])));
  };

  // We also keep track of the current form values for venue and strategy.
  const [venue, setVenue] = React.useState<string>(formApi?.initialValues?.venue?.id || '');
  const initialStrategy = formApi?.initialValues?.strategy?.value
    ? createStrategyInput(formApi.initialValues.strategy.value)
    : { name: '', uirep: '', parameters: {}, controls: {} };
  const [strategy, setStrategy] = React.useState<StrategyInput>(initialStrategy as StrategyInput);

  // Lastly keep track of the initial values for IO IDs. (Which can change when resetting the form.)
  const [initialIoIds, setInitialIoIds] = React.useState<string[]>(
    (formApi?.initialValues?.ioIds as string[]) || []
  );

  const workspaceContainer = useWorkspaceContainer();

  // Based on changes in the IO Data, venue, and strategy, do a dry run validation for each order.
  useEffect(() => {
    const routeOrderService = workspaceContainer.resolve(RouteOrderService);

    ioData.forEach((order) => {
      if (order.id && order.orderType) {
        // If we have no ID nor orderType something went wrong.
        // Only try a dry run if we also have a venue and a strategy.
        if (venue && strategy) {
          const orci: OrderRouteCreateInput = {
            destinationId: venue,
            destinationType:
              venue === 'primary-trader'
                ? DestinationType.PrimaryTrader
                : venue === 'trader'
                  ? DestinationType.Trader
                  : DestinationType.Venue,
            limitPrice: order.orderType === OrderType.Limit ? Number(order?.limitPrice) : undefined,
            orderId: order.id,
            orderType: order.orderType as OrderType,
            // In theory the quantity should be percentage (set at 100% now) times the openQuantity.
            quantity: Number(order.openQuantity),
            strategy: strategy
          };

          try {
            routeOrderService
              .routeInvestorOrder(orci, { dryRun: true })
              .then((result) => {
                if (result.value && 'data' in result.value) {
                  const newFeedback = result.value.data?.requestOrderRoute?.feedback;
                  const oldFeedback = feedbackData.get(order.id);
                  if (!_.isEqual(oldFeedback, newFeedback)) {
                    updateFeedbackMap(order.id, newFeedback || []);
                  }
                }
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          } catch (error) {
            console.error('Error:', error);
          }
        }
      } else {
        console.error('Could not route order:', order);
      }
    });
  }, [ioData, venue, strategy]);

  // Based on changes in the IO Data and feedback data, we combine the two into a single data source for the grid.
  useEffect(() => {
    setCombinedData(joinFeedbackWrapperWithRowData(ioData, feedbackData));
  }, [ioData, feedbackData]);

  // Subscribe to the parent form api to keep track of the current venue, strategy, and initial IO IDs.
  formApi
    .get$({
      fields: [],
      values: true,
      modified: true,
      active: true
    })
    .subscribe(({ values, modified, active }) => {
      if (values?.venue?.id) {
        if (!_.isEqual(values.venue.id, venue)) {
          if (modified?.venue) {
            setVenue(values.venue.id);
          }
        }
      }

      if (values?.strategy?.value) {
        const newStrategyInput = createStrategyInput(values.strategy.value);
        if (!_.isEqual(newStrategyInput, strategy)) {
          if (modified?.strategy && active === 'strategy') {
            setStrategy(newStrategyInput || {});
          }
        }
      }

      if (values?.ioIds) {
        if (!_.isEqual(values.ioIds, initialIoIds)) {
          setInitialIoIds(values.ioIds);
        }
      }
    });

  // Set up the IO Table Server query service.
  const queryService = useService(TableServerService);

  useEffect(() => {
    // Specify the filter with the IO IDs that the parent form has been invoked (or reset) with.
    const ioQueryObservable = queryService.query$({
      query: TsInvestorOrdersWithChargesDocument,
      variables: {
        filterBy: agFilterModelToTableServerFilterStr({
          id: {
            filterType: 'set',
            values: initialIoIds
          }
        }),
        limit: 100,
        offset: 0,
        sortBy: agSortModelToTableServerSortStr([])
      },
      getData: (r) => r.tsInvestorOrdersWithFilter
    });

    // Subscribe to the observable, and once we have results, set the IO data.
    // Out of the entire IO row data, reduce the data to only fields we need.
    const querySubscription = ioQueryObservable.subscribe((data) => {
      const reducedData = reduceDataToBulkRouteOrderRow(data?.rows || []);
      if (!_.isEqual(reducedData, ioData)) {
        setIoData(reducedData);
      }
    });

    return () => {
      querySubscription.unsubscribe();
    };
  }, [formApi, initialIoIds]);

  const gridProps = useVGrid<BulkRouteOrderRowWithValidation>(
    'bulk-route-order',
    (builder) =>
      builder
        .context({ formApiContext: formApi, formId }) // Set the context to access the parent form
        .tableServerColumnLibrary(buildBulkRouteOrderColumnDefs())
        .datasource((d) =>
          d
            .source(combinedData)
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .injectEvents([BulkRouteOrderGridEventHander])
        .rowStateRules({
          hazard: (params) => BulkRouteIOHazard(params.data?.feedback || [])
        }),
    [combinedData]
  );

  return <VGrid {...gridProps} />;
};
