import {
  useCurrentWindow,
  useRootActor,
  useWorkspace,
  useCurrentWidget,
  useCurrentWidgetActorProps,
  useClosestFlexLayoutActor,
  useClosestFlexLayoutActorProps
} from '@valstro/workspace-react';
import type {
  AppFlexLayoutActorSchema,
  AppWidgetActorSchema,
  AppWindowActorSchema,
  AppWorkspace
} from '@app/app-config/workspace.config';
import { isTauri } from './workspace.constants';
import type { Actor, AnyRecord } from '@valstro/workspace';

export function useAppWorkspace() {
  return useWorkspace<AppWorkspace>();
}

export function useCurrentAppWindow(otherTypes?: string[]) {
  return useCurrentWindow<AppWindowActorSchema>(otherTypes);
}

export function useCurrentAppWidget() {
  return useCurrentWidget() as Actor<AppWidgetActorSchema>;
}

export function useClosestAppFlexLayoutActor<T extends AnyRecord = AnyRecord>() {
  return useClosestFlexLayoutActor() as Actor<AppFlexLayoutActorSchema<T>> | null;
}

export function useClosestAppFlexLayoutActorProps<T extends AnyRecord = AnyRecord>() {
  return useClosestFlexLayoutActorProps<T>();
}

export function useCurrentAppWidgetActorProps<TProps extends AnyRecord = AnyRecord>() {
  return useCurrentWidgetActorProps<TProps>();
}

export const useLeaderOrTabId = () => {
  const rootWindow = useRootActor();
  const workspace = useWorkspace();
  return isTauri() ? workspace.getLeaderProcessId() : rootWindow.id;
};
