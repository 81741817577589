import { Command } from './cmdk';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type Subscription } from 'rxjs';
import { ComboBoxItem } from './combo-box-items.component';
import { ComboBoxContext } from './combo-box.context';
import {
  type ComboBoxItem as IComboBoxItem,
  type ComboBoxItemUnion,
  type ComboBoxProps,
  type ComboBoxTabItem
} from './combo-box.types';
import { uniqBy } from 'lodash';
import { CloseIcon } from '../../icons/icons';
import { Button } from '../button/button';
import { Tabs, TabsList, TabTrigger } from '../tabs/tabs';

/**
 * ComboBox Component
 * Controlled externally by the parent component for maximum flexibility
 */
const ComboBoxBase: React.FC<ComboBoxProps> = ({
  inputValue = '',
  items = [],
  selectedItems = [],
  placeholder = 'Start typing...',
  shouldAutoFilter = true,
  customFilter,
  setInputValue,
  setSelectedItems,
  removeOnBackspace = true,
  setItems,
  enableSubmit = false,
  onSubmit: _onSubmit,
  onItemClick,
  submitReady = false,
  submitInstantly = false,
  strategy = 'default',
  setIsOpen,
  isOpen,
  hiddenInput = false,
  hiddenSelectedItems = false,
  keydown$,
  isLoading = false,
  isInvalidated = false,
  errorMessage = '',
  onHighlightedItemChange,
  allowAnyValue = false,
  onInputEscape,
  tabThroughTabs = false,
  allSwitchable = false
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>();
  const listRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const buttonRef = useRef<HTMLButtonElement>();
  const prevItemsRef = useRef(items);
  const prevIsOpenRef = useRef(isOpen);
  const highlightedItemRef = useRef<IComboBoxItem<any> | null>(null);
  prevItemsRef.current = items;
  const prevTabIndexBeforeAllSwitchable = useRef(tabIndex);

  // Remove drilldown items from selected items, as these are just used for navigation/nesting
  const onSubmit = useCallback(
    (selectedItems: IComboBoxItem<any>[]) => {
      if (_onSubmit) {
        _onSubmit((selectedItems || []).filter((i) => !i.isDrilldown));
      }
    },
    [_onSubmit]
  );

  useEffect(() => {
    if (prevIsOpenRef.current !== isOpen) {
      prevIsOpenRef.current = isOpen;
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (submitReady) {
      buttonRef.current?.focus();
    } else {
      inputRef.current?.focus();
    }
  }, [submitReady]);

  const getItemById = useCallback((id: string) => {
    function findItemById(items: ComboBoxItemUnion<any>[]): IComboBoxItem<any> | null {
      for (const item of items) {
        if (item.type === 'item' && item.id === id) {
          return item;
        }

        if (item.type === 'tab' || item.type === 'group') {
          return findItemById(item.items);
        }
      }

      return null;
    }

    return findItemById(prevItemsRef.current);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutationList: MutationRecord[], _observer: MutationObserver) => {
      for (const mutation of mutationList) {
        if (mutation.attributeName === 'data-selected') {
          const el = mutation.target as HTMLDivElement;
          const isSelected = el.getAttribute('data-selected');
          const id = el.getAttribute('data-id');
          if (isSelected && id && onHighlightedItemChange) {
            const item = getItemById(id);
            if (item) {
              highlightedItemRef.current = item;
              onHighlightedItemChange(item);
              break;
            }
          }
        }
      }

      if (highlightedItemRef.current && onHighlightedItemChange) {
        const anySelected = wrapperRef.current?.querySelector('[data-selected="true"]');
        if (!anySelected) {
          highlightedItemRef.current = null;
          onHighlightedItemChange(null);
        }
      }
    });

    // Start observing the target node for configured mutations
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, { attributes: true, childList: true, subtree: true });
    }

    let sub: Subscription | undefined;

    if (keydown$) {
      sub = keydown$.subscribe((e) => {
        if (e !== 'Other') {
          inputRef.current?.dispatchEvent(
            new KeyboardEvent('keydown', {
              key: e,
              code: e,
              bubbles: true
            })
          );
        }
      });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }

      observer.disconnect();
    };
  }, [keydown$, onHighlightedItemChange, getItemById]);

  const tabs = useMemo(() => {
    const allTab: ComboBoxTabItem<any> = {
      type: 'tab',
      label: 'All',
      items: uniqBy(extractFlatItems(items), 'id')
    };
    return [
      ...items
        .filter((i) => i.type === 'tab')
        // If allSwitchable is on, ensure the "All" tab is not included because we add it below
        .filter((i) => !allSwitchable || (allSwitchable && i.label !== 'All')),
      ...(allSwitchable ? [allTab] : [])
    ];
  }, [items, allSwitchable]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape' && onInputEscape) {
        onInputEscape();
      }

      if (e.key === 'Escape' && setIsOpen) {
        setIsOpen(false);
      }

      // If tab (go forward a tab)
      if (tabThroughTabs && e.key === 'Tab' && !e.shiftKey && tabs.length) {
        e.preventDefault();
        const isAlreadyOnLastTab = tabIndex === tabs.length - 1;
        const nextTabIndex = isAlreadyOnLastTab ? 0 : tabIndex + 1;
        setTabIndex(nextTabIndex);
        prevTabIndexBeforeAllSwitchable.current = nextTabIndex;
      }

      // If shift Tab (go back a tab)
      if (tabThroughTabs && e.key === 'Tab' && e.shiftKey && tabs.length) {
        e.preventDefault();
        const isAlreadyOnFirstTab = tabIndex === 0;
        const nextTabIndex = isAlreadyOnFirstTab ? tabs.length - 1 : tabIndex - 1;
        setTabIndex(nextTabIndex);
        prevTabIndexBeforeAllSwitchable.current = nextTabIndex;
      }

      if (removeOnBackspace && setSelectedItems && e.key === 'Backspace' && inputValue === '') {
        const cloned = [...(selectedItems || [])];
        const lastItem = cloned[cloned.length - 1];
        cloned.pop();
        setSelectedItems(cloned);

        // With a nested strategy, we need to set the items to the previous items
        if (strategy === 'nested-commands' && setItems && lastItem?.prevItems?.length) {
          prevItemsRef.current = lastItem.prevItems;
          setItems(lastItem.prevItems);
        }
      }

      if (e.key === 'Enter' && enableSubmit && submitReady) {
        e.preventDefault();
        onSubmit(selectedItems);
      }

      if (allSwitchable) {
        const allTabIndex = tabs.length - 1;

        // Switch to all tab if key is a letter or number
        if (isAlphanumericKey(e.key) && tabIndex !== allTabIndex) {
          setTabIndex(allTabIndex);
        }

        // Switch back to previous tab if key is backspace and input value is empty
        if (
          e.key === 'Backspace' &&
          inputValue.length === 1 &&
          prevTabIndexBeforeAllSwitchable.current !== allTabIndex
        ) {
          setTabIndex(prevTabIndexBeforeAllSwitchable.current);
        }
      }
    },
    [
      enableSubmit,
      inputValue,
      onSubmit,
      selectedItems,
      setIsOpen,
      setItems,
      setSelectedItems,
      removeOnBackspace,
      strategy,
      submitReady,
      onInputEscape,
      tabs,
      tabIndex,
      tabThroughTabs,
      allSwitchable
    ]
  );

  const comboBoxContext = useMemo(
    () => ({
      strategy,
      setItems,
      setInputValue,
      setSelectedItems,
      selectedItems,
      prevItemsRef,
      submitInstantly,
      onSubmit,
      onItemClick
    }),
    [
      strategy,
      setItems,
      setInputValue,
      setSelectedItems,
      selectedItems,
      prevItemsRef,
      submitInstantly,
      onSubmit,
      onItemClick
    ]
  );

  const isTabs = tabs.length > 0;
  const currentItems = useMemo(
    () => (isTabs ? tabs[tabIndex]?.items || [] : items),
    [isTabs, tabs, tabIndex, items]
  );

  if (!setItems && strategy === 'nested-commands') {
    return <div>setItems is required when using the nested-commands strategy</div>;
  }

  // Note, items only represents the FULL list of items being passed in. It does not represent filtered items if shouldAutoFilter is true
  const isInitiallyEmpty = !items || (Array.isArray(items) && items.length === 0);

  const isLoadingMessage = isLoading && isInitiallyEmpty;
  const isNoResultsMessage = !isInvalidated && !isLoading && !allowAnyValue;
  const isNoResultsAnyValueMessage = !isInvalidated && !isLoading && allowAnyValue;
  const isErrorMessage = !isLoading && isInvalidated;

  return (
    <ComboBoxContext.Provider value={comboBoxContext}>
      <Command
        ref={wrapperRef as React.Ref<HTMLDivElement>}
        label="Combo Box"
        shouldFilter={shouldAutoFilter}
        filter={customFilter}
        onKeyDown={onKeyDown}
      >
        {selectedItems && !hiddenSelectedItems && (
          <div>
            {selectedItems.map((p, i) => (
              <div key={p.id} cmdk-valstro-badge="">
                {p.label}
                {/* If this a nested strategy, only show the close icon for last item in list */}
                {(strategy === 'default' || i === selectedItems.length - 1) && (
                  <CloseIcon
                    style={{ marginLeft: 3, cursor: 'pointer' }}
                    onClick={() => {
                      if (setSelectedItems) {
                        const itemBeingRemoved = selectedItems.find((v) => v.id === p.id);
                        const newItems = (selectedItems || []).filter((v) => v.id !== p.id);
                        setSelectedItems(newItems);

                        // With a nested strategy, we need to set the items to the previous items
                        if (
                          strategy === 'nested-commands' &&
                          setItems &&
                          itemBeingRemoved?.prevItems?.length
                        ) {
                          prevItemsRef.current = itemBeingRemoved.prevItems;
                          setItems(itemBeingRemoved.prevItems);
                        }
                      }
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        <div cmdk-input-wrapper="" style={{ display: hiddenInput ? 'none' : 'block' }}>
          <Command.Input
            ref={inputRef as React.Ref<HTMLInputElement>}
            autoFocus={!hiddenInput}
            placeholder={placeholder}
            value={inputValue}
            onValueChange={(value) => {
              if (!hiddenInput && setInputValue) {
                setInputValue(value);
              }
            }}
          />
          {enableSubmit && (
            <Button
              ref={buttonRef as React.LegacyRef<HTMLButtonElement>}
              size="sm"
              cmdk-submit=""
              isDisabled={!submitReady}
              onClick={() => {
                if (onSubmit) {
                  onSubmit(selectedItems);
                }
              }}
            >
              Submit
            </Button>
          )}
        </div>

        {isTabs && (
          <Tabs value={`tab-${tabIndex}`} onValueChange={(v) => setTabIndex(parseInt(v.split('-')[1]))}>
            <TabsList style={{ height: 36, display: 'flex', flexShrink: 1 }}>
              {tabs.map((tab, i) => (
                <TabTrigger
                  value={`tab-${i}`}
                  key={`tab-${i}`}
                  onClick={() => {
                    prevTabIndexBeforeAllSwitchable.current = i;
                  }}
                >
                  {tab.label}
                </TabTrigger>
              ))}
            </TabsList>
          </Tabs>
        )}

        <Command.List ref={listRef as React.Ref<HTMLDivElement>}>
          <Command.Loading>{isLoadingMessage && <div>Loading…</div>}</Command.Loading>
          <Command.Empty>
            {isNoResultsMessage && <div>No results found</div>}
            {isNoResultsAnyValueMessage && <div>No results. Tab to create "{inputValue}"</div>}
            {isErrorMessage && <div>{errorMessage ? errorMessage : 'Error. Something went wrong'}</div>}
          </Command.Empty>

          {currentItems.map((item, i) => (
            <ComboBoxItem
              isLastItem={i === currentItems.length - 1}
              item={item}
              key={item.type === 'item' ? item.id : i}
            />
          ))}
        </Command.List>
      </Command>
    </ComboBoxContext.Provider>
  );
};

export const ComboBox = memo(ComboBoxBase);

function extractFlatItems(items: ComboBoxItemUnion[]): IComboBoxItem[] {
  return items.flatMap((item) => {
    if (item.type === 'tab' || item.type === 'group') {
      return extractFlatItems(item.items);
    }
    if (item.type === 'item') {
      return [item];
    }
    return [];
  });
}

export function isAlphanumericKey(key: string) {
  return key.match(/[a-zA-Z0-9]/) && key.length === 1;
}
