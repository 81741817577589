import { useRxMarketDataSubscription } from '@app/data-access/services/marketdata/components/market-data.hook';
import type { Level1Data } from '@app/data-access/services/marketdata/marketdata.common';
import { OrderSide, TableServerJoinedInvestorOrder } from '@oms/generated/frontend';
import { Flex, Text } from '@oms/shared-frontend/ui-design-system';
import { format } from '@oms/shared-frontend/ui-design-system';
import { Sprinkles } from '@oms/shared-frontend/ui-design-system/css';
import { ReactNode } from 'react';
import { getADV, getFormattedVwap, getSuffix } from './investor-order-monitor.stats-header.utils';

const SummaryItem = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <Flex direction="column">
      <Text type="gridTiny" sx={{ color: 'text.minor' }}>
        {label}
      </Text>
      <Text type="gridBase" sx={{ color: 'text.primary' }}>
        {children}
      </Text>
    </Flex>
  );
};

const VWAP = ({
  averagePrice,
  side,
  vwap,
  midPrice
}: {
  side: TableServerJoinedInvestorOrder['side'];
  averagePrice: TableServerJoinedInvestorOrder['averagePrice'];
  vwap: Level1Data['vwap'];
  midPrice: Level1Data['midPrice'];
}): ReactNode => {
  const formattedVwap = getFormattedVwap({ midPrice, vwap });
  const suffix = getSuffix({ averagePrice, side, vwap });

  let textColor: Sprinkles['color'] = 'text.secondary';

  if (suffix) {
    if (side === OrderSide.Buy) {
      textColor = suffix.startsWith('+') ? 'text.green' : 'text.error';
    }

    if (side === OrderSide.Sell) {
      textColor = suffix.startsWith('-') ? 'text.green' : 'text.error';
    }
  }

  return (
    <>
      {formattedVwap} <Text sx={{ color: textColor }}>{suffix}</Text>
    </>
  );
};

export const InvestorOrderTradingOrdersStatsGroupHeader = ({
  instrumentDisplayCode,
  totalQuantity,
  averageFillSize,
  side,
  averagePrice,
  lastExecutionTime
}: TableServerJoinedInvestorOrder): React.ReactElement => {
  const marketData = useRxMarketDataSubscription(instrumentDisplayCode || '');

  const level1 = marketData?.level1 || {};

  const adv = getADV({ quantity: totalQuantity, adv30day: level1.adv30day });

  const formattedExecutionTime = lastExecutionTime ? format('datetime', String(lastExecutionTime)) : '-';

  return (
    <Flex
      style={{
        minHeight: 48,
        paddingRight: 'calc(var(--ag-cell-horizontal-padding) * 2 - 1px)',
        paddingLeft:
          'calc((var(--ag-cell-horizontal-padding) - 1px * 2) + (var(--ag-cell-widget-spacing) + 16px))'
      }}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        bgColor: 'layout.level2',
        width: 'full',
        gap: 'large'
      }}
      direction="row"
    >
      {/* Hardcoding '-' for now as per request from Product */}
      <SummaryItem label="Order VWAP">-</SummaryItem>
      <SummaryItem label="VWAP">
        <VWAP side={side} averagePrice={averagePrice} vwap={level1.vwap} midPrice={level1.midPrice} />
      </SummaryItem>
      <SummaryItem label="ADV %">{adv}</SummaryItem>
      <SummaryItem label="Last Execution Time">{formattedExecutionTime}</SummaryItem>
      <SummaryItem label="Avg Fill Size">{averageFillSize}</SummaryItem>
    </Flex>
  );
};
