import { inject, singleton } from 'tsyringe';
import type { Dispatch, SetStateAction } from 'react';
import { TableServerService } from '../../system/table-server/table-server.service';
import { TableServerRowSubscriptionVariables } from '../../system/table-server/table-server.datasource.contracts';
import { agFilterModelToTableServerFilterStr } from '../../system/table-server/filters/ag-grid.table-server.transformer';
import { testScoped } from '@app/workspace.registry';
import {
  ExecutionRow,
  TsExecutionsWithFilterDocument,
  TsExecutionsWithFilterSubscription
} from '@oms/generated/frontend';

@testScoped
@singleton()
export class ExecutionsSubscriptionService {
  constructor(@inject(TableServerService) private tableServerService: TableServerService) {}

  public track(
    id: string,
    setOrder: Dispatch<SetStateAction<ExecutionRow | undefined>>,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ) {
    const variables: TableServerRowSubscriptionVariables = {
      filterBy: agFilterModelToTableServerFilterStr<keyof ExecutionRow>({
        id: {
          filterType: 'text',
          type: 'contains',
          filter: id
        }
      }),
      limit: 1,
      offset: 0,
      sortBy: ''
    };

    const subscription = this.tableServerService
      .query$<ExecutionRow, TsExecutionsWithFilterSubscription>({
        query: TsExecutionsWithFilterDocument,
        getData: (r) => r.tsExecutionsWithFilter,
        variables
      })
      .subscribe(({ errors, rows }) => {
        if (errors?.length) {
          setError(new Error('Error getting execution'));
          console.error(errors);
        }
        if (!errors && rows?.length === 0) {
          setError(new Error('Execution not found'));
        }

        setOrder(rows?.[0]);
      });

    return () => {
      subscription.unsubscribe();
    };
  }

  public dispose() {}
}
