import { ViewTabsList } from '@app/widgets/common/components/view.tabs-list';
import type { TabsComponentProps } from '@app/widgets/common/components/view.tabs-list';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import { ExecutionViewInvestorOrderGrid } from './execution-view.investor-orders.grid';

const getTabsListProps = (props: ExecutionViewLayoutComponentProps): TabsComponentProps['tabs'] => [
  {
    name: 'Investor Order',
    value: 'investorOrder',
    tabContent: <ExecutionViewInvestorOrderGrid {...props} />
  },
  { name: 'Market charges', value: 'marketCharges', tabContent: <div>Market charges...</div> }
];

export const ExecutionViewTabbedDetailsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<ExecutionViewLayoutComponentProps>();

  if (!props) {
    throw new Error('props must be an object');
  }

  return <ViewTabsList defaultValue="investorOrder" tabs={getTabsListProps(props)} />;
};

export default ExecutionViewTabbedDetailsWidget;
