import omit from 'lodash/omit';
import { FORM_EVENT_TYPE, FORM_RENDERER_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { Actor } from '@valstro/workspace';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import type {
  CustomNotificationEntryInput,
  CustomNotificationEntryOutput
} from './custom-notification-entry.form-common';
import type {
  CustomNotificationEntryContractType,
  CustomNotificationEntryValues
} from './custom-notification-entry.form-contract';
import { customNotificationEntryContract } from './custom-notification-entry.form-contract';
import { FeedbackWrapper } from '@oms/frontend-foundation';

export const customNotificationEntryBuilder = FormBuilder.create<
  CustomNotificationEntryInput,
  CustomNotificationEntryOutput
>('custom-notification-entry-form')
  .contract<CustomNotificationEntryContractType>(customNotificationEntryContract)
  .type('custom-notification-entry')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(input, ctx) {
        let initialFormValues: Partial<CustomNotificationEntryValues> = {
          id: input.notificationId
        };

        switch (input.entryType) {
          case 'create': {
            initialFormValues = {
              ...initialFormValues,
              isPopup: false,
              isSound: false,
              isShown: false
            };
            break;
          }
          case 'update': {
            if (!input.notificationId) throw new Error('Setting ID is required');

            const service = ctx.container.resolve(NotificationSettingsService);
            const response = await service.getCustomSettingById(input.notificationId);

            if (!response) {
              console.error(response);
            } else {
              initialFormValues = {
                ...initialFormValues,
                notificationName: response.notificationName,
                description: response.description,
                matchedAccounts:
                  response.matchedAccounts && response.matchedAccounts.length
                    ? response.matchedAccounts.map((accountId) => ({ id: accountId || '' }))
                    : undefined,
                isPopup: response.isPopup,
                isSound: response.isSound,
                isShown: response.isShown
              };
            }
          }
        }

        return {
          hiddenFormInfo: {
            entryType: input.entryType || 'create'
          },
          ...initialFormValues
        };
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        if (
          !formValues.notificationName ||
          formValues.isPopup === undefined ||
          formValues.isSound === undefined ||
          formValues.isShown === undefined ||
          formValues.hiddenFormInfo === undefined
        ) {
          return;
        }

        const output: CustomNotificationEntryOutput = {
          ...(formValues.id ? { id: formValues.id } : {}),
          notificationName: formValues.notificationName,
          matchedAccounts: formValues.matchedAccounts
            ? formValues.matchedAccounts.map((account) => account.id)
            : [],
          description: formValues.description || '',
          isPopup: formValues.isPopup,
          isSound: formValues.isSound,
          isShown: formValues.isShown,
          hiddenFormInfo: formValues.hiddenFormInfo
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        const service = ctx.container.resolve(NotificationSettingsService);
        const formDiff = event.payload.formValuesDiff;
        const formValues = event.payload.formValues;

        if (formDiff.notificationName && formValues.hiddenFormInfo?.entryType === 'create') {
          const factoryDefaultSettings = await service.getFactoryDefaults(formDiff.notificationName);

          if (!factoryDefaultSettings) {
            console.error(factoryDefaultSettings);
            return;
          }

          ctx.notify({
            type: FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES,
            payload: {
              fieldValues: {
                isPopup: false, // disable all pop-ups for all custom notification types
                isSound: factoryDefaultSettings.isSound,
                isShown: factoryDefaultSettings.isShown
              }
            }
          });
        }
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(NotificationSettingsService);
        const formOutput = event.payload.output;
        const upsertSetting = omit(formOutput, 'hiddenFormInfo');
        const upsertResponse = await service.upsertCustomSettings(upsertSetting);

        if (upsertResponse.isSuccess()) {
          service.triggerFetch();
          ctx.notify({
            type: FORM_RENDERER_EVENT_TYPE.RESET
          });

          Actor.get<AppWindowActorSchema>(event.meta.windowId)
            .then((actor) => {
              actor.operations.close().catch(console.error);
            })
            .catch(console.error);
        }

        if (upsertResponse.isFailure()) {
          ctx.notify({
            type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
            payload: {
              feedback: upsertResponse.errors.map(
                (e: Error) =>
                  ({
                    code: e.name,
                    level: 'Error',
                    message: e.message
                  }) as FeedbackWrapper
              )
            }
          });
        }
        break;
      }
    }
  });

export type CustomNotificationEntryBuilderType = typeof customNotificationEntryBuilder;

export default customNotificationEntryBuilder;
