import type { StandardActorEvent } from '@valstro/workspace';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { useLayoutEffect, useRef } from 'react';
import type {
  ComboBoxDefaultValue,
  ComboBoxItem,
  ComboBoxKeyboardEvent
} from '@oms/shared-frontend/ui-design-system';

/**
 * Custom event contracts for the autocomplete popover onItemClick event
 */
export const AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT = 'popover/custom/autocomplete-popover-item-click';
export interface AutocompetePopoverItemClickEvent<T extends ComboBoxDefaultValue = ComboBoxDefaultValue>
  extends StandardActorEvent {
  type: typeof AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT;
  payload: {
    item: ComboBoxItem<T>;
  };
}

/**
 * Creates a subject that emits keyboard events for the autocomplete popover
 *
 * @param popoverId - popover id
 * @returns a subject that emits keyboard events
 */
function createAutoCompleteKeyboardSubject(popoverId: string) {
  return new BroadcastSubject<ComboBoxKeyboardEvent>(`popover-keydown-${popoverId}`);
}

/**
 * Returns a subject that emits keyboard events for the autocomplete popover
 * and ensures that the subject is recreated when the popover id changes
 * and the previous subject is completed
 *
 * @param popoverId - popover id
 * @returns  a subject that emits keyboard events
 */
export function useAutocompleteKeyboardSubject(popoverId: string) {
  const prevIdRef = useRef(popoverId);
  const subjectRef = useRef(createAutoCompleteKeyboardSubject(popoverId));

  useLayoutEffect(() => {
    if (prevIdRef.current !== popoverId) {
      subjectRef.current.complete(); // complete the previous subject
      subjectRef.current = createAutoCompleteKeyboardSubject(popoverId);
      prevIdRef.current = popoverId;
    }
  }, [popoverId]);

  return subjectRef.current;
}
