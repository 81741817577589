import type { AuthEventAction } from '@app/common/auth/auth.contracts';
import { DEFAULT_AUTH_STATE } from '@app/common/auth/auth.contracts';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from './signal';

/**
 * Signal for AuthClientState
 *
 * Used to broadcast the current state of the AuthClient
 * and to listen for changes to the AuthClientState
 *
 * Used primarily at the framework level to broadcast the current state of the AuthClient
 * @see app.stream.ts
 * @see plugin-auth
 *
 * @usage
 * ```ts
 * const authSignal = container.resolve(AuthSignal);
 * const subscription = authSignal.$.subscribe((state) => {
 *  console.log('Auth state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(AuthSignal) private authSignal: AuthSignal) {
 *  const subscription = this.authSignal.$.subscribe((state) => {
 *    console.log('Auth state changed', state);
 *  });
 * }
 */
@testScoped
@singleton()
export class AuthSignal extends AbstractSignal<AuthClientState> {
  public action$: BroadcastSubject<AuthEventAction>;

  constructor(@inject(ProcessState) processState: ProcessState, broadcast?: boolean) {
    super(
      processState,
      'auth',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false,
        broadcast
      },
      DEFAULT_AUTH_STATE
    );
    this.action$ = new BroadcastSubject<AuthEventAction>(`${this.channelName}-action`);
  }
}
