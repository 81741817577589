import type {
  AccountType,
  AnyAccount,
  AnyGroupingAccount,
  AnyInvestorAccount,
  AnySimpleAccount,
  SimpleGroupingAccount,
  SimpleInvestorAccount
} from './types';
import type { InvestorAccountFragment } from '@oms/generated/frontend';
import { createLogger } from '@oms/shared/util';
import type { Optional } from '@oms/shared/util-types';
import { isLabeled } from '@oms/shared/util-types';

const l = createLogger({ name: 'simplifyAccount' });

export const simplifyAccountOrThrow = (account: AnyAccount): AnySimpleAccount => {
  if (isLabeled(account)) {
    return account;
  }
  const { __typename, id, name: label, description } = account;
  const type: AccountType = (() => {
    switch (__typename) {
      case 'InvestorAccount':
        return 'investor';
      case 'GroupingAccount':
        return 'parent';
      default: {
        const { accountType, accountSubType, accumulation } = account as AnyInvestorAccount;
        if (accountType || accountSubType || typeof accumulation === 'boolean') return 'investor';
        const { accounts, children } = account as AnyGroupingAccount;
        if (Array.isArray(accounts) || Array.isArray(children)) return 'parent';
        return 'parent';
      }
    }
  })();
  const hasNo = (what: string): string => `Passed investor account has no ${what}!`;
  if (!id) throw new TypeError(hasNo('ID'));
  if (!label) throw new TypeError(hasNo('label'));
  const simpleAccount: AnySimpleAccount = { id, label, type };
  if (description) simpleAccount.description = description;
  const { parent } = account as AnyGroupingAccount | InvestorAccountFragment;
  if (parent) {
    try {
      const simplified = simplifyAccountOrThrow(parent);
      simpleAccount.parent = simplified;
    } catch (_) {
      // Can ignore... we just don't set this property if simplification fails
    }
  }
  return simpleAccount;
};

export const simplifyAccount = (account: AnyAccount): Optional<AnySimpleAccount> => {
  try {
    return simplifyAccountOrThrow(account);
  } catch (e) {
    l.error(e);
    return undefined;
  }
};

export const isAnySimpleAccount = (account: unknown): account is AnySimpleAccount => {
  if (typeof account !== 'object') return false;
  const { id, label, type } = account as AnySimpleAccount;
  return typeof id === 'string' && typeof label === 'string' && (type === 'investor' || type === 'parent');
};

export const isSimpleInvestorAccount = (account?: AnySimpleAccount): account is SimpleInvestorAccount =>
  !!(account && account.type === 'investor');

export const isSimpleGroupingAccount = (account?: AnySimpleAccount): account is SimpleGroupingAccount =>
  !!(account && account.type === 'parent');
