import { t } from '@oms/codegen/translations';
import type { ActionDefFactory, ActionComponentConfig, ActionContext } from '@oms/frontend-vgrid';

const onChangeFn = (ctx: ActionContext<any, ActionComponentConfig<any>>) => {
  if (ctx.lifecycle === 'change') {
    ctx.api.refreshServerSide({
      purge: true
    });
  }
};

export const refreshServersideCustomMenuAction: ActionDefFactory<any> = (builder) =>
  builder
    .name('refresh_serverside')
    .onChange<ActionComponentConfig<any>>(onChangeFn)
    .customMenu((m) =>
      m.name('Refresh Data').priority(1_100).tabName(t('app.common.grids.contextMenuTabs.action'))
    );
