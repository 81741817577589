import { Lifecycle, scoped } from 'tsyringe';
import type {
  RowEditingStoppedEvent,
  RowDataUpdatedEvent,
  FirstDataRenderedEvent
} from '@ag-grid-community/core';
import type { EventHandler, EventSource, GridEvent } from '@oms/frontend-vgrid';
import { BulkRouteOrderRow } from './bulk-route-order-grid.columns';

@scoped(Lifecycle.ContainerScoped)
export class BulkRouteOrderGridEventHander implements EventHandler {
  public name = 'bulk-route-orders-grid-events';

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    eventSource
      .add('onFirstDataRendered', (e: FirstDataRenderedEvent) => {
        this.onRowDataChange(e).catch((error) => {
          console.error('onFirstDataRendered validation failed', error);
        });
      })
      .add('onRowDataUpdated', (e: RowDataUpdatedEvent) => {
        this.onRowDataChange(e).catch((error) => {
          console.error('onRowDataUpdated validation failed', error);
        });
      });
  }

  private async onRowDataChange(
    e:
      | RowDataUpdatedEvent
      | (RowEditingStoppedEvent & { context: { formApiContext: { investorOrders: BulkRouteOrderRow[] } } })
  ) {
    let rowData: BulkRouteOrderRow[] = [];
    e.api.forEachNode((rowNode, _index) => {
      rowData.push(rowNode.data);
    });

    // Put the investorOrders into the parent.
    e.context.formApiContext.change('investorOrders', rowData);
  }

  public removeEvents(): void {}
}
