import {
  FactsetOrderVWAPRequest,
  FactsetOrderVWAPResponse
} from '@app/data-access/services/marketdata/factset/factset.types';
import { useEffect, useState } from 'react';
import { getEndTime, getStartTime } from '../order-vwap.widget.utils';
import { FactsetClient } from '@app/data-access/services/marketdata/factset/factset.client';
import { useWorkspaceContainer } from '@oms/frontend-foundation';

export type OrderVwapHookParams = {
  investorOrderId: string;
  tickerSymbol: string;
  startTime: string | Date | number | undefined;
  endTime?: string;
};
export type VolumeWeightedAveragePrice = number | null;

/**
 * Hook to fetch Volume Weighted Average Price (VWAP) data from Factset for a given ticker symbol
 * over a specified time range.
 *
 * @returns {{ vwap: VolumeWeightedAveragePrice }} An object containing:
 * - vwap: The volume weighted average price value, or null if unavailable
 */
export const useGetOrderVwapFromFactset = ({
  investorOrderId,
  tickerSymbol,
  startTime,
  endTime
}: OrderVwapHookParams): { vwap: VolumeWeightedAveragePrice } => {
  const [vwap, setVwap] = useState<VolumeWeightedAveragePrice>(null);

  const factsetClient = useWorkspaceContainer().resolve(FactsetClient);

  const start = getStartTime(startTime);
  const end = endTime || getEndTime();

  useEffect(() => {
    if (!tickerSymbol || !start || !end) {
      return;
    }

    const fetchVwapData = async () => {
      try {
        await factsetClient
          .requestEndpoint<FactsetOrderVWAPRequest, FactsetOrderVWAPResponse>({
            endpoint: '/prices/timeSeries/intraday/subsample/list',
            method: 'POST',
            payload: {
              data: {
                identifier: {
                  value: tickerSymbol,
                  type: 'tickerRegion'
                },
                type: 'trade',
                quality: 'BST',
                range: {
                  start,
                  end
                },
                granularity: '10s'
              },
              meta: {
                attributes: ['range', 'volumeWeightedAveragePrice'],
                pagination: {
                  limit: 5000
                }
              }
            }
          })
          .subscribe((data) => {
            setVwap(data.data.volumeWeightedAveragePrice ?? null);
          });
      } catch (error) {
        console.error('[useOrderVwap] Error fetching VWAP data:', error);
      }
    };

    void fetchVwapData();

    return () => {
      factsetClient.disconnect().catch(console.error);
    };
  }, [tickerSymbol, start, end, factsetClient.connected, factsetClient, investorOrderId]);

  return { vwap };
};
