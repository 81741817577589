export const PROCESS_ID = {
  LEADER: 'main'
} as const;

export const URL_PARAM = {
  ACTOR_DEFINITION_JSON: 'actorDef',
  ACTOR_DEFINITION_ID: 'actorDefId',
  WORKSPACE_DEACTIVATED: 'workspaceDeactivated'
} as const;

export const STORAGE_KEY = {
  ACTOR_DEFINITION_JSON: 'ACTOR_DEFINITION'
} as const;
