import { inject, Lifecycle, scoped } from 'tsyringe';
import { GridColumnHeadersService } from '../services/grid-column-header.service';
import { EventHandler } from './event.handler';
import { EventSource, GridEventType } from './event.source';

@scoped(Lifecycle.ContainerScoped)
export class GridColumnHeadersEventHandler implements EventHandler<any> {
  private _service: GridColumnHeadersService;

  public name = 'grid-column-headers-event-handler';

  constructor(@inject(GridColumnHeadersService) service: GridColumnHeadersService) {
    this._service = service;
  }

  public addEvents(eventSource: EventSource<GridEventType, any>) {
    eventSource
      .add('onGridReady', (e) => {
        this._service.init(e.api);
      })
      .add('onGridPreDestroyed', () => {
        this._service.dispose();
      })
      .add('onGridColumnsChanged', (e) => {
        this._service.observeColumns(e.api.getAllDisplayedColumns());
      })
      .add('onVirtualColumnsChanged', (e) => {
        this._service.observeColumns(e.api.getAllDisplayedColumns());
      });
  }

  public removeEvents() {}
}
