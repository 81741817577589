import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type {
  ActionComponentConfig,
  ActionContext,
  ActionDefFactory,
  SplitButtonActionComponentConfig,
  SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import { openConfirmation } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { t } from '@oms/codegen/translations';
import { RepairCategory } from '@oms/generated/frontend';
import type { NotificationRow } from '@oms/generated/frontend';
import { SharedNotification, SharedNotificationTargets } from '@oms/shared/oms-common';
import { getParsedPayload } from '@app/widgets/trading/utils/parse-json-utils';
import { repairInvestorOrdersOnChange } from '@app/widgets/trading/repair-queue/grid-actions/repair.investor-orders.action';
import { retryInvestorOrdersOnChange } from '@app/widgets/trading/repair-queue/grid-actions/retry.investor-orders.action';
import { rejectRepairQueueInvestorOrdersOnChange } from '@app/widgets/trading/repair-queue/grid-actions/reject.repair-queue.investor-orders.action';
import { repairCreateTradeActionOnChange } from '@app/widgets/trading/repair-queue/grid-actions/repair.trades.action';
import { retryCreateTradeOnChange } from '@app/widgets/trading/repair-queue/grid-actions/retry.trades.action';
import { rejectRepairQueueTradesOnChange } from '@app/widgets/trading/repair-queue/grid-actions/reject.repair-queue.trades.action';
import { acceptPendingModificationOnChange } from '@app/widgets/trading/pending-modifications/grid-actions/accept.pending-modification.action';
import { rejectPendingModificationOnChange } from '@app/widgets/trading/pending-modifications/grid-actions/reject.pending-modification.action';
import { acceptInvestorOrderActionOnChange } from '../../investor-order-monitor/commands/accept-investor-order/accept-investor-order.action';
import { rejectInvestorOrderActionOnChange } from '../../investor-order-monitor/commands/reject-investor-order/reject-investor-order.action';
import { NotificationsService } from '@app/data-access/services/notifications/notifications.service';

// TODO: This is a placeholder until each of the notification actions' OnChange handlers are supported.
const placeholderOnChange = async (
  ctx: ActionContext<NotificationRow, ActionComponentConfig<NotificationRow>>
) => {
  const { lifecycle, workspace } = ctx;

  if (lifecycle === 'change') {
    try {
      const [_, api] = await openConfirmation(workspace, workspace.getLeaderProcessId(), {
        title: 'Placeholder',
        componentProps: {
          autoClose: true,
          message: 'Are you sure you want to do X?',
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        },
        windowOptions: {
          width: 400,
          height: 180
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          // For now do nothing.
          throw new Error('Function not implemented.');
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

const dismissNotificationOnChange = async (
  ctx: ActionContext<NotificationRow, ActionComponentConfig<NotificationRow>>
) => {
  const { lifecycle } = ctx;
  if (lifecycle === 'change') {
    const notificationId = ctx.data[0]?.id;
    if (notificationId) {
      const notificationsService = ctx.appContainer.resolve(NotificationsService);
      const dismissResponse = await notificationsService.dismissNotification(notificationId);
      if (dismissResponse.isFailure()) {
        console.error('Could not dismiss notification:', dismissResponse.errors);
      }
    }
  }
};

export const inlineNotificationsActions: ActionDefFactory<NotificationRow> = (builder) =>
  builder
    .name('inline_notifications_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(100)
        .filter(false)
        .cell((c) => c.renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' }))
    )
    .onChange<SplitButtonActionComponentConfig<NotificationRow>>(async (ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            case 'inline_notification_accept_io_new': {
              // Use the IO Monitor action acceptInvestorOrderOnChange with data in our notification's IO.
              return acceptInvestorOrderActionOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map((item) => {
                  const payload = getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(
                    item.targetObjectPayload
                  );
                  return {
                    id: payload?.orderId,
                    status: payload?.status
                  } as unknown as NotificationRow;
                })
              });
            }
            case 'inline_notification_reject_io_new': {
              // Use the IO Monitor action rejectInvestorOrderOnChange with data in our notification's IO.
              return rejectInvestorOrderActionOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map((item) => {
                  const payload = getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(
                    item.targetObjectPayload
                  );
                  return {
                    id: payload?.orderId,
                    status: payload?.status
                  } as unknown as NotificationRow;
                })
              });
            }
            case 'inline_notification_accept_pending_modification':
            case 'inline_notification_accept_pending_cancel': {
              // Use the Pending Modification action acceptPendingModificationOnChange with our modificationId
              return acceptPendingModificationOnChange({
                ...ctx,
                data: ctx.data.map((item) => {
                  const payload = getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(
                    item.targetObjectPayload
                  );
                  return {
                    id: payload?.modificationId
                  } as unknown as NotificationRow;
                })
              });
            }
            case 'inline_notification_reject_pending_modificcation':
            case 'inline_notification_reject_pending_cancel': {
              // Use the Pending Modification action rejectPendingModificationOnChange with our modificationId
              return rejectPendingModificationOnChange({
                ...ctx,
                data: ctx.data.map((item) => {
                  const payload = getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(
                    item.targetObjectPayload
                  );
                  return {
                    id: payload?.modificationId
                  } as unknown as NotificationRow;
                })
              });
            }
            case 'inline_notification_repair_failed_order':
              // Use the Repair Queue action repairInvestorOrdersOnChange with our targetObjectId
              return repairInvestorOrdersOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.IoCreate
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_repair_failed_order_modification':
              // Use the Repair Queue action repairInvestorOrdersOnChange our targetObjectId
              return repairInvestorOrdersOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.IoModify
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_retry_failed_order':
            case 'inline_notification_retry_failed_order_modification':
              // Use the Repair Queue action retryInvestorOrdersOnChange with our targetObjectId
              return retryInvestorOrdersOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_reject_failed_order':
            case 'inline_notification_reject_failed_order_modification':
              // Use the Repair Queue action rejectRepairQueueInvestorOrdersOnChange with our targetObjectId
              return rejectRepairQueueInvestorOrdersOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_repair_failed_trade':
              // Use the Repair Queue action repairCreateTradeActionOnChange with our targetObjectId
              return repairCreateTradeActionOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.TradeCreate
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_repair_failed_trade_modification':
              // Use the Repair Queue action repairCreateTradeActionOnChange with our targetObjectId
              return repairCreateTradeActionOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.TradeModify
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_retry_failed_trade':
              // Use the Repair Queue action retryCreateTradeOnChange with our targetObjectId
              return retryCreateTradeOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.TradeCreate
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_retry_failed_trade_modification':
              // Use the Repair Queue action retryCreateTradeOnChange with our targetObjectId
              return retryCreateTradeOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId,
                      category: RepairCategory.TradeModify
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_reject_failed_trade':
            case 'inline_notification_reject_failed_trade_modification':
              // Use the Repair Queue action rejectRepairQueueTradesOnChange with our targetObjectId
              return rejectRepairQueueTradesOnChange<NotificationRow>({
                ...ctx,
                data: ctx.data.map(
                  (item) =>
                    ({
                      id: item.targetObjectId
                    }) as unknown as NotificationRow
                )
              });
            case 'inline_notification_dismiss':
              return dismissNotificationOnChange(ctx);
            // TODO: For now, none of the following actions are implemented yet
            // and all call the placeholder onChange.
            case 'inline_notification_dismiss_capital_threshold_warning':
              return placeholderOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${String(selectedId)}`);
          }
        }
      }
    });

function rowActions(rowData: NotificationRow): SplitButtonActionConfig[] {
  switch (rowData.name as SharedNotification.DomainDefaultNotificationName) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW: {
      return [
        { id: 'inline_notification_accept_io_new', title: 'Accept' },
        { id: 'inline_notification_reject_io_new', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY: {
      return [
        { id: 'inline_notification_accept_pending_modification', title: 'Accept' },
        { id: 'inline_notification_reject_pending_modificcation', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED: {
      return [
        { id: 'inline_notification_repair_failed_order', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL: {
      return [
        { id: 'inline_notification_accept_pending_cancel', title: 'Accept' },
        { id: 'inline_notification_reject_pending_cancel', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED: {
      return [
        { id: 'inline_notification_repair_failed_order_modification', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order_modification', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order_modification', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED: {
      return [
        { id: 'inline_notification_repair_failed_trade', title: 'Repair' },
        { id: 'inline_notification_retry_failed_trade', title: 'Retry' },
        { id: 'inline_notification_reject_failed_trade', title: 'Reject' }
      ];
    }
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      return [
        { id: 'inline_notification_repair_failed_trade_modification', title: 'Repair' },
        { id: 'inline_notification_retry_failed_trade_modification', title: 'Retry' },
        { id: 'inline_notification_reject_failed_trade_modification', title: 'Reject' }
      ];
    }
    // Tmp comment out the following cases
    // case 'Capital Threshold Warning': {
    //   return [{ id: 'inline_notification_dismiss_capital_threshold_warning', title: '✕' }];
    // }
    // TODO: define actions for these other notification types. For now just display the dismiss button.
    // case 'Unmatched Trade Report':
    // case 'Trade Report Failed (rejected)':
    // case 'Trade Request (OTC)':
    case SharedNotification.DomainDefaultNotificationName.TO_CREATE_REJECTED:
    case SharedNotification.DomainDefaultNotificationName.TO_CANCEL_REJECTED:
    case SharedNotification.DomainDefaultNotificationName.TO_MODIFY_REJECTED:
    case SharedNotification.DomainDefaultNotificationName.TO_UNSOLICITED_CANCEL:
      return [
        // Use the special char ✕ to display the close button.
        { id: 'inline_notification_dismiss', title: '✕' }
      ];
    default:
      return [];
  }
}
