import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type { ActionDefFactory, SplitButtonActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import { acceptInvestorOrderActionOnChange } from '../../investor-order-monitor/commands/accept-investor-order/accept-investor-order.action';
import { rejectInvestorOrderActionOnChange } from '../../investor-order-monitor/commands/reject-investor-order/reject-investor-order.action';

export const inlineNewOrdersActions: ActionDefFactory<InvestorOrderRow> = (builder) =>
  builder
    .name('inline_new_orders_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(110)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            variant: 'secondary'
          })
        )
    )
    .lifecycles('change', 'init')
    .onChange<SplitButtonActionComponentConfig<InvestorOrderRow>>(async (ctx) => {
      const { state, lifecycle, appContainer } = ctx;

      const selectedId = state.selectedId;
      const selectedRow = ctx.data[0];

      const isAcceptingStatus =
        selectedRow && 'status' in selectedRow && selectedRow?.status !== InvestorOrderStatus.Unaccepted;

      const authService = appContainer.resolve(AuthService);
      const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);

      switch (lifecycle) {
        case 'init': {
          ctx.notify({
            actionsConfig:
              hasEntitlement && !isAcceptingStatus
                ? [
                    {
                      id: 'accept',
                      title: 'Accept'
                    },
                    {
                      id: 'reject',
                      title: 'Reject'
                    }
                  ]
                : []
          });
          break;
        }

        case 'change': {
          switch (selectedId) {
            case 'accept':
              return await acceptInvestorOrderActionOnChange(ctx);
            case 'reject':
              return await rejectInvestorOrderActionOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
          }
        }
      }
    });
