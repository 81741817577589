import type { Subscription } from 'rxjs';
import type { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Logger } from '@oms/shared/util';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import PositionsService from '@app/data-access/services/trading/positions/positions.service';
import { PrimaryPositionsGridType } from '../types';
import PositionsRowDataHelper from './positions-row-data-helper.class';
import type { ServerSideDatasource } from '@oms/frontend-vgrid';
import { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';

export type GroupKeys = IServerSideGetRowsParams<unknown>['request']['groupKeys'];

export class PositionsDatasourceService {
  public readonly gridType: PrimaryPositionsGridType;
  public readonly name: string;

  protected dataHelper: PositionsRowDataHelper;

  protected logger: Logger;

  protected positionsService: PositionsService;
  protected subscriptions = new Set<Subscription>();

  // 🏗️ Constructor ------------------------------------------------------- /

  public constructor(
    gridType: PrimaryPositionsGridType,
    positionsService: PositionsService,
    dataHelper: PositionsRowDataHelper
  ) {
    this.gridType = gridType;
    this.positionsService = positionsService;
    const name = PositionsDatasourceService.getName(gridType);
    this.name = name;
    this.logger = Logger.named(name);
    this.dataHelper = dataHelper;
  }

  /**
   * Call this to build your server-side database
   * @returns A datasource to pass to your grid
   */
  public createServerSideDatasource(): ServerSideDatasource<TreeDataSlice<PositionRow>> {
    return {
      getRows: ({ request, success, fail, api }: IServerSideGetRowsParams<TreeDataSlice<PositionRow>>) => {
        this.subscriptions.add(
          this.dataHelper.dataSource$.subscribe((result) => {
            result.mapSync(
              ({ results: data = [], isFetching }) => {
                if (isFetching) {
                  this.logger.scope(['createServerSideDatasource', 'success']).debug('💫 Loading...');
                } else {
                  if (data.length === 0) {
                    api.showNoRowsOverlay();
                  } else {
                    const rowData = this.dataHelper.onRowsRequest(request, data);
                    success({
                      rowData,
                      rowCount: rowData.length
                    });
                  }
                }
              },
              (error) => {
                this.logger.scope(['createServerSideDatasource', 'failure']).error(error);
                fail();
              }
            );
          })
        );
      },
      destroy: () => {
        this.cleanup();
      }
    };
  }

  // 🔒 Protected ----------------------------------------- /

  protected setDataHelper(dataHelper: PositionsRowDataHelper): PositionsRowDataHelper {
    this.dataHelper = dataHelper;
    return dataHelper;
  }

  /**
   * Perform all cleanup and unsubscribes all subscriptions by default.
   * This is called on the `destroy` callback returned by `createServerSideDatasource`.
   * Override if necessary.
   */
  protected cleanup() {
    this.unsubscribeAll();
    this.subscriptions.clear();
  }

  /** Cleanup any subscriptions */
  protected unsubscribeAll() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // Static ----------------------------------------------------- /

  protected static getName(gridType: PrimaryPositionsGridType): string {
    const unique = (() => {
      switch (gridType) {
        case 'positions-account-grid':
          return 'Account';
        case 'positions-instrument-grid':
          return 'Instrument';
      }
    })();
    return `Positions${unique}DatasourceService`;
  }
}

export default PositionsDatasourceService;
