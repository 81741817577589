import { Actor, isTauri, Plugin } from '@valstro/workspace';
import * as Sentry from '@sentry/react';
import { getAppEnv, getEnvVar, Logger } from '@oms/shared/util';
import { useEffect, useRef } from 'react';
import { useCurrentAppWidget, useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import { AppWindowActorSchema, AppWorkspace } from '@app/app-config/workspace.config';
import { RemoteFormComponentProps } from '@app/widgets/system/remote-form/remote-form.widget.config';
import { globalSentrySignal } from '@app/data-access/memory/sentry.signal';
import { SentryRateLimiter } from './sentry.rate.limit';
import { SentryReportABug } from './sentry.report-a-bug.plugin';
import { BUG_REPORTER_WINDOW_ID } from '@app/widgets/system/bug-reporter/bug-reporter.form-builder.config';
import { getPlatformInfo } from '@app/common/workspace/workspace.util';

export const sentryWidgetContext = 'Widget';
export const sentryWidgetSchemaVersion = 'widget_schema_version';
export const sentryWidgetValidSchema = 'widget_valid_schema';
export const sentryWidgetComponent = 'widget_component';
export const sentryWidgetProps = 'widget_props';
export const sentryWidgetRoles = 'widget_roles';
export const sentryWidgetTitle = 'widget_title';

// window context
export const sentryWidgetWindowId = 'widget_window_id';
export const sentryWidgetWindowParentId = 'widget_window_parent_id';
export const sentryWidgetWindowLeader = 'widget_window_leader';
export const sentryWidgetWindowType = 'widget_window_type';
export const sentryWidgetWindowActive = 'widget_window_active';
export const sentryWidgetType = 'widget_type';

// platform context
export const sentryPlatformContext = 'Platform';
export const sentryPlatformVersion = 'platform_version';
export const sentryPlatformOs = 'platform_os';
export const sentryPlatformArch = 'platform_arch';
export const sentryPlatformLocale = 'platform_locale';

/**
 * Should telemetry be enabled
 */
const enableTelemetry = getEnvVar('NX_UI_ENABLE_TELEMETRY') === 'true';

const logger = Logger.named('Sentry Plugin');

/**
 * Initializes Sentry
 */
export const createSentryPlugin = (disable: boolean = false) => {
  const appEnv = getAppEnv().toFullEnv();
  const appVersion = getEnvVar('NX_RELEASE_VERSION');
  const sentryUrl = getEnvVar('SENTRY_DSN');
  const enableSentry = getEnvVar('NX_UI_ENABLE_SENTRY', 'false') === 'true';

  Sentry.registerSpanErrorInstrumentation();

  Sentry.init({
    enabled: !!appVersion && enableSentry && !disable,
    debug: false,
    dsn: sentryUrl,
    integrations: [
      Sentry.browserProfilingIntegration(),
      new SentryReportABug({ windowId: BUG_REPORTER_WINDOW_ID }),
      new SentryRateLimiter({
        eventCount: 100,
        timeWindowInMin: 1,
        shouldRateLimit(event, hint, _client) {
          const isFeedback = event.type === 'feedback';

          logger.trace('Will this event be rate limited?', {
            event,
            hint,
            isFeedback
          });

          return !isFeedback;
        }
      })
    ],
    tracePropagationTargets: [/^\/graphql/, /localhost/],
    tracesSampleRate: enableTelemetry ? 0.2 : 0,
    profilesSampleRate: 0.2,
    environment: appEnv,
    release: appVersion,
    normalizeDepth: 6,
    maxBreadcrumbs: 10,
    beforeSend(event) {
      if (event.exception && event.event_id) {
        globalSentrySignal.set({
          lastException: {
            eventId: event.event_id,
            message: event.exception.values?.[0]?.value ?? '',
            timestamp: event.timestamp ?? 0
          }
        });
      }
      return event;
    }
  });

  Sentry.setTags({
    platform: isTauri() ? 'desktop' : 'browser'
  });

  return sentryPlugin;
};

const sentryPlugin = () => {
  const windowReadyTimeout = 60_000;
  const windowReady = Sentry.startInactiveSpan({
    name: 'app.window.ready',
    op: 'app.window'
  });

  const timeout = setTimeout(() => {
    if (windowReady.isRecording()) {
      windowReady.setStatus({ code: 2, message: 'deadline_exceeded' });
      windowReady.end();
    }
  }, windowReadyTimeout);

  return Plugin.create<AppWorkspace>({
    name: 'valstro-sentry-plugin',
    pluginFn: ({ workspace }) => {
      const unsub = workspace.addHook('windowReady', ({ rootWindowActor, isLeader = false }) => {
        // Set the platform info as context
        getPlatformInfo(workspace)
          .then((info) => {
            if (!info) {
              return;
            }

            Sentry.setContext(sentryPlatformContext, {
              [sentryPlatformVersion]: info.version,
              [sentryPlatformOs]: info.os,
              [sentryPlatformArch]: info.arch,
              [sentryPlatformLocale]: info.locale
            });
          })
          .catch(console.error);

        windowReady.setAttributes({
          'app.window.widget': (rootWindowActor as Actor<AppWindowActorSchema>).initialDefinition.context.meta
            .widgetType,
          'app.window.is_leader': isLeader
        });
        windowReady.setStatus({ code: 1 });
        windowReady.end();
      });

      return () => {
        unsub();
        clearTimeout(timeout);
      };
    }
  });
};

export const useSentryWidgetContext = () => {
  const metaRef = useRef<Record<string, any>>({});
  const widgetContextName = useRef<string | undefined>(undefined);
  const widget = useCurrentAppWidget();
  const appWindow = useCurrentAppWindow();

  useEffect(
    function updateSentryMetaForWindow() {
      const unsub = appWindow.listen('context', ({ title, meta, isFocused = false }) => {
        const { requiredEntitlements = [], windowType, widgetType } = meta;

        metaRef.current[sentryWidgetTitle] = title;
        metaRef.current[sentryWidgetRoles] = requiredEntitlements;
        metaRef.current[sentryWidgetWindowType] = windowType;
        metaRef.current[sentryWidgetType] = widgetType;
        metaRef.current[sentryWidgetWindowLeader] = appWindow.id === appWindow.parentId;
        metaRef.current[sentryWidgetWindowId] = appWindow.id;
        metaRef.current[sentryWidgetWindowParentId] = appWindow.parentId;
        metaRef.current[sentryWidgetWindowActive] = !!isFocused;

        widgetContextName.current && Sentry.setContext(widgetContextName.current, metaRef.current);
      });

      return () => {
        widgetContextName.current && Sentry.setContext(widgetContextName.current, null);
        unsub();
      };
    },
    [appWindow]
  );

  useEffect(
    function updateSentryMetaForWidget() {
      const unsub = widget.listen(
        'context',
        ({ componentId: componentIdEvent, componentProps, meta = {}, version }) => {
          let componentId = componentIdEvent;
          if (componentId === 'SYSTEM_REMOTE_FORM') {
            componentId = (componentProps as unknown as RemoteFormComponentProps).formBuilderId;
          }

          const { subSchemaInvalid } = meta;

          const previousContextName = widgetContextName.current;
          widgetContextName.current = `${sentryWidgetContext} ${widget.id}`;

          if (!widgetContextName.current && previousContextName) {
            Sentry.setContext(previousContextName, null);
          }

          if (widgetContextName.current) {
            metaRef.current[sentryWidgetComponent] = componentId;
            metaRef.current[sentryWidgetProps] = componentProps;
            metaRef.current[sentryWidgetSchemaVersion] = version;
            metaRef.current[sentryWidgetValidSchema] = !subSchemaInvalid;

            Sentry.setContext(widgetContextName.current, metaRef.current);
          }
        },
        { emitInitial: true }
      );

      return () => {
        widgetContextName.current && Sentry.setContext(widgetContextName.current, null);
        unsub();
      };
    },
    [widget]
  );
};
