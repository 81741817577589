import type { Level1Data } from '@app/data-access/services/marketdata/marketdata.common';
import { OrderSide, TableServerJoinedInvestorOrder } from '@oms/generated/frontend';
import { format } from '@oms/shared-frontend/ui-design-system';

export const getSuffix = ({
  averagePrice,
  side,
  vwap
}: {
  averagePrice: TableServerJoinedInvestorOrder['averagePrice'];
  side: TableServerJoinedInvestorOrder['side'];
  vwap: Level1Data['vwap'];
}): string | null => {
  if (!averagePrice || !vwap) {
    return null;
  }

  if (side === OrderSide.Buy) {
    return format('price-change', vwap - averagePrice, { decimalScale: 2 });
  }

  if (side === OrderSide.Sell) {
    return format('price-change', averagePrice - vwap, { decimalScale: 2 });
  }

  return null;
};

export const getADV = ({
  quantity,
  adv30day
}: {
  quantity: TableServerJoinedInvestorOrder['totalQuantity'];
  adv30day: Level1Data['adv30day'];
}): string => {
  if (!quantity || !adv30day) {
    return '-';
  }

  return format('decimal', (quantity / adv30day) * 100, {
    decimalScale: 3
  });
};

export const getFormattedVwap = ({
  midPrice,
  vwap
}: {
  midPrice: Level1Data['midPrice'];
  vwap: Level1Data['vwap'];
}): string => {
  if (!midPrice || !vwap) {
    return '-';
  }

  if (midPrice > 1) {
    return format('number', vwap, { decimalScale: 4 });
  }

  return format('number', vwap, { decimalScale: 6 });
};
