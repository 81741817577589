import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { BulkRouteOrderFormContract } from './bulk-route-order.contracts';
import { ioIds, investorOrders, percentage, venue, strategy } from './bulk-route-order.form-fields';

export const bulkRouteOrderContract = FormContract.create<BulkRouteOrderFormContract>()
  .fields({
    ioIds,
    percentage,
    venue,
    strategy,
    investorOrders
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.runway('runway', [
        {
          ...f.percentage,
          isPrimaryField: true,
          wrapperSx: { flexGrow: 1 },
          isDisabled: true
        },
        { ...f.venue, isPrimaryField: true, wrapperSx: { flexGrow: 1 } },
        { ...f.strategy, isPrimaryField: true, wrapperSx: { flexGrow: 1 } }
      ]),
      FieldDefinition.heading('Included orders', {
        name: 'included-orders-heading',
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1.5,
          paddingRight: 4,
          paddingLeft: 4,
          paddingTop: 2.5,
          paddingBottom: 2.5
        }
      }),
      {
        name: 'investor-orders-section',
        component: 'section',
        fields: [[f.investorOrders]],
        style: { minHeight: '258px' }, // This allows for about 8 rows in the grid before there's a vertical scroll bar.
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1,
          padding: 0
        }
      }
    ]
  }))
  .template('simple', { showSubmitButton: false });

export const bulkRouteOrderFormContractDefinition = bulkRouteOrderContract.build();

export type BulkRouteOrderContractType = typeof bulkRouteOrderContract;

export type BulkRouteOrderFormValues = InferFormValuesFromFormContract<BulkRouteOrderContractType>;
