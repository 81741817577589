import { useRef } from 'react';
import {
  useVGrid,
  VGrid,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  toClientsideGridData
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { getColumnLibraryByType } from './notification-settings.columns';
import { NotificatioSettingsGridEventHander } from './notification-settings.grid.event-handler';
import type {
  NotificationSettingsTypeValue,
  MappedCommonNotificationSettings
} from './notification-settings.contracts';
import { openNotificationEntryAction } from './grid-actions/open-notification-entry.action';
import { openNotificationDeleteAction } from './grid-actions/open-notification-delete.action';

export const NOTIFICATION_SETTINGS = 'notification-settings';

export type NotificationSettingsGridProps = {
  type: NotificationSettingsTypeValue;
};

export const NotificationsSettingsGrid = ({ type }: NotificationSettingsGridProps) => {
  const datasourceService = useService(NotificationSettingsService);
  const query$ = useRef(datasourceService.watch$(type).pipe(toClientsideGridData()));

  const gridProps = useVGrid<MappedCommonNotificationSettings>(
    NOTIFICATION_SETTINGS,
    (b) =>
      b
        .context({ gridType: type })
        .columnLibrary(getColumnLibraryByType(type))
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data.id))
        .rowSelection((c) => c.multiple())
        .injectEvents([NotificatioSettingsGridEventHander])
        .actions((a) =>
          a.schema((s) =>
            s
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(openNotificationEntryAction('create', type))
              .action(openNotificationEntryAction('update', type))
              .action(openNotificationDeleteAction(type))
          )
        ),

    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default NotificationsSettingsGrid;
