import { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@oms/shared/util';
import { useGridApi } from '../grid-api/use-grid-api.hook';
import { getOrInitTrackingInGridContext } from './grid-tracking.util';

const logger = Logger.create({ name: 'use-grid-tracking.hook', level: 'debug' });

interface UseGridTrackingReturn {
  isTracking$?: BehaviorSubject<boolean>;
  gridId?: string;
}

/**
 * Hook to access grid tracking functionality.
 * Returns tracking subject and grid ID if a grid API is available in context.
 *
 * @example
 * ```tsx
 * function MyGridComponent() {
 *   const { isTracking$ } = useGridTracking();
 *
 *   useEffect(() => {
 *     if (!isTracking$) return;
 *     isTracking$.next(true); // Enable tracking
 *   }, [isTracking$]);
 *
 *   return <div>...</div>;
 * }
 * ```
 */
export function useGridTracking(): UseGridTrackingReturn {
  const gridApi = useGridApi();

  const isTracking$ = useMemo(() => {
    if (!gridApi) return;
    return getOrInitTrackingInGridContext(gridApi, { logger });
  }, [gridApi]);

  return useMemo(() => {
    if (!isTracking$) return {};
    return {
      isTracking$,
      gridId: gridApi?.getGridId()
    };
  }, [isTracking$]);
}

/**
 * Hook that provides the current tracking state of a grid.
 * Subscribes to tracking changes and returns the active state.
 *
 * @returns boolean indicating if tracking is currently active
 *
 * @example
 * ```tsx
 * function MyGridComponent() {
 *   const isTracking = useGridTrackingState();
 *
 *   return (
 *     <div>
 *       Tracking is {isTracking ? 'active' : 'inactive'}
 *     </div>
 *   );
 * }
 * ```
 */
export function useGridTrackingState(): boolean {
  const { isTracking$, gridId } = useGridTracking();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isTracking$) return;
    const subscription = isTracking$.subscribe((isTracking) => {
      logger.debug(`${gridId ? `Grid ID ${gridId}: ` : ''}Tracking is ${isTracking ? 'ON 🟡' : 'OFF ⚫'}`, {
        gridId,
        isTracking
      });
      setIsActive(isTracking);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [isTracking$]);

  return isActive;
}
