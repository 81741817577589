import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type {
  AuditTrailInfoFragment,
  GetAuditStreamForTradingOrderQuery,
  GetAuditStreamForTradingOrderQueryVariables
} from '@oms/generated/frontend';
import { map, Observable } from 'rxjs';
import { GetAuditStreamForTradingOrderDocument } from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';
import User from '@app/common/types/users/user.class';

@testScoped
@singleton()
export class TradingOrderAuditTrailService {
  constructor(@inject(RxApolloClient) protected apolloClient: RxApolloClient) {}

  protected _watchQuery_GetAuditStreamForTradingOrderQuery$(
    tradingOrderId: string,
    pollInterval = 5000
  ): Observable<AuditTrailInfoFragment[]> {
    return this.apolloClient
      .rxWatchQuery<GetAuditStreamForTradingOrderQuery, GetAuditStreamForTradingOrderQueryVariables>({
        query: GetAuditStreamForTradingOrderDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradingOrderId
        }
      })
      .pipe(
        map(
          (res) =>
            (res.data.getAuditStreamForTradingOrder as AuditTrailInfoFragment[]) ||
            ([] as AuditTrailInfoFragment[])
        )
      );
  }

  public getAuditTrail$(tradingOrderId: string): Observable<OrderAuditTrail[]> {
    return this._watchQuery_GetAuditStreamForTradingOrderQuery$(tradingOrderId).pipe(
      map((results) => {
        return results.map((r): OrderAuditTrail => {
          return {
            id: `${r.aggregateId}-${r.aggregateVersion}`,
            eventTimestamp: r.timestamp,
            event: r?.friendlyEventName || undefined,
            eventDetails: r?.payload,
            orderEntryType: r?.metadata?.source || undefined,
            status: r?.metadata?.tradingOrderStatus || undefined,
            rejectReason: r?.metadata?.rejectReason || undefined,
            user: r?.user ? User.extend(r?.user) : undefined
          };
        });
      })
    );
  }
}
