import {
  useVGrid,
  VGrid,
  CLEAR_GRID_FILTER_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import { useScopedProps } from '@app/data-access/hooks/use-scoped-props.hook';
import { createCloseOnEmptyEventHandler } from '@app/common/grids/event-handlers/close-on-empty.event-handler';
import { PendingModificationsService } from './pending-modifications.datasource.service';
import { pendingModificationsColumnLibrary } from './pending-modifications.columns';
import { inlinePendingModificationsActions } from './grid-actions/inline.pending-modification.action';
import { acceptAllPendingModificationAction } from './grid-actions/accept-all.pending-modification.action';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { PendingModificationsLayoutProps } from './pending-modifications.layout.config';
import { acceptPendingModificationAction } from './grid-actions/accept.pending-modification.action';
import { rejectPendingModificationAction } from './grid-actions/reject.pending-modification.action';

export const PENDING_MODIFICATIONS = 'pending-modifications';

export const PendingModificationsGridWidget = () => {
  const { autoCloseOnEmpty } = useScopedProps<PendingModificationsLayoutProps>() ?? {};
  const datasourceService = useService(PendingModificationsService);

  const gridProps = useVGrid<VisibleModificationInfoFragment>(
    PENDING_MODIFICATIONS,
    (b) =>
      b
        .columnLibrary(pendingModificationsColumnLibrary)
        .datasource((d) =>
          d.source(datasourceService.getPendingModifications$()).rowId((r) => r.data.id || '')
        )
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .rowStateRules({
          // Use the Modification record's IO object to determine row states.
          pending: (params) => IOPending(params.data?.investorOrder),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data?.investorOrder),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data?.investorOrder)
        })
        .sideBar()
        .injectEvents([createCloseOnEmptyEventHandler(autoCloseOnEmpty)])
        .actions((a) =>
          a.schema((s) =>
            s
              .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
              .action(CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(inlinePendingModificationsActions)
              .action(acceptPendingModificationAction)
              .action(rejectPendingModificationAction)
              .action(acceptAllPendingModificationAction)
          )
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default PendingModificationsGridWidget;
