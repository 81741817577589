import type { Observable } from 'rxjs';
import { from, map } from 'rxjs';
import { DependencyContainer } from 'tsyringe';
import { cleanMaybe } from '@oms/shared/util';
import { AppWorkspace } from '@app/app-config/workspace.config';
import type { OrderSizeProfile } from '@app/data-access/offline/collections/user-preferences.collection';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { asDataSource, getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import { comboBoxItemFrom } from '@oms/shared-frontend/ui-design-system';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';

export const getOrderSizeProfiles = async (container: DependencyContainer): Promise<OrderSizeProfile[]> => {
  const authService = container.resolve(AuthService);
  const workspace = container.resolve(AppWorkspace);

  const collection = workspace.getMeta().memoryDatabase.collections.user_preferences;
  const userId = cleanMaybe(authService.getUserId(), '');

  const document = await collection.findOne(userId).exec();
  return document?.orderSettings?.profiles || [];
};

export const getOrderSizeProfiles$ = (container: DependencyContainer): Observable<OrderSizeProfile[]> =>
  from(getOrderSizeProfiles(container));

export const comboBoxItemFromOrderSize = (profile: OrderSizeProfile) =>
  comboBoxItemFrom.record<OrderSizeProfile>(profile, {
    id: ({ id }) => id,
    label: ({ description }) => description,
    sublabel: ({ rows }) => `${rows.length} row${rows.length === 1 ? '' : 's'}`
  });

export const watchAllOrderSizesQuery: AdvancedSelectQueryFn = (container) =>
  getAdvancedSelectQueryReturn<OrderSizeProfile>({
    queryFn: () => getOrderSizeProfiles$(container).pipe(map((orderSizes) => asDataSource(orderSizes))),
    resultMapper: comboBoxItemFromOrderSize
  });
