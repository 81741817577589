// External dependencies
import type { AnyRecord, Optional } from '@oms/shared/util-types';
import { Result } from '@oms/shared/util';
import type { ActionContext } from '@oms/frontend-vgrid';

// Frontend foundation
import { convertAlert } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';

// App dependencies
import { t } from '@oms/codegen/translations';
import type { PartialDialogDefinition } from '@app/generated/sdk';
import { openConfirmation } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import type { DialogDictTypeMap } from '@app/generated/common';

// Local dependencies
import { isConfiguredButton, requiresConfirmation } from './common.util';
import type { ConfirmationType } from './common.types';
import { DEFAULT_CONFIRMATION_TYPE } from './common.types';

/**
 * Represents the possible reasons for a confirmation operation failure
 */
export type ConfirmationErrorReason = 'User canceled' | 'Task failure';

/**
 * Base error class for confirmation-related errors
 */
export abstract class ConfirmationError extends Error {
  public reason: ConfirmationErrorReason;

  protected constructor(reason: ConfirmationErrorReason) {
    super(reason);
    this.reason = reason;
    Object.setPrototypeOf(this, ConfirmationError.prototype);
  }
}

/**
 * Error thrown when user cancels the confirmation dialog
 */
export class UserCanceledError extends ConfirmationError {
  public constructor() {
    super('User canceled');
    Object.setPrototypeOf(this, UserCanceledError.prototype);
  }
}

/**
 * Error thrown when the confirmed task fails
 */
export class TaskFailureError<TError> extends ConfirmationError {
  public error: TError;

  public constructor(error: TError) {
    super('Task failure');
    this.error = error;
    Object.setPrototypeOf(this, TaskFailureError.prototype);
  }
}

/**
 * Callback function type for handling confirmation actions
 */
export type OnConfirmCallbackFn<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
> = (ctx: ActionContext<TData, TConfig>) => Promise<Result<TResultValue, TError>>;

type DialogConfig = PartialDialogDefinition<DialogDictTypeMap['CONFIRMATION']>;

/**
 * Configuration interface for running a confirmation button action
 */
export interface RunConfirmationButtonSetup<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
> {
  /** Type of confirmation requested by the action */
  confirmationType?: ConfirmationType;
  /** Action context */
  ctx: ActionContext<TData, TConfig>;
  /** Optional dialog configuration */
  dialogConfig?: DialogConfig;
  /** Callback function to execute when confirmed */
  onConfirm: OnConfirmCallbackFn<TResultValue, TError, TData, TConfig>;
  /** Whether to allow retrying on failures */
  allowRetryOnFailures?: boolean;
  /** Internal flag for retry attempts */
  isRetry?: boolean;
  /** Error from previous attempt */
  error?: TError;
  /** Function to generate feedback messages from errors */
  getFeedback?: (error: TError) => FeedbackWrapper[];
}

/**
 * Handles the execution of a confirmation button action with optional retry functionality.
 * If confirmation is required, displays a confirmation dialog to the user before executing the action.
 * On failure, can optionally retry the action with error feedback.
 *
 * @param setup - Configuration for the confirmation button action
 * @param setup.confirmationType - Type of confirmation requested ('always', 'never', or 'auto')
 * @param setup.ctx - The action context
 * @param setup.dialogConfig - Optional configuration for the confirmation dialog
 * @param setup.onConfirm - Callback function to execute when confirmed
 * @param setup.allowRetryOnFailures - Whether to allow retrying on failures
 * @param setup.isRetry - Internal flag indicating if this is a retry attempt
 * @param setup.error - Error from previous attempt if retrying
 * @param setup.getFeedback - Optional function to generate feedback messages from errors
 * @returns A Result containing either the successful value or a ConfirmationError
 *
 * @example
 * ```typescript
 * const result = await runConfirmationButton({
 *   ctx,
 *   onConfirm: async (ctx) => {
 *     const orderService = ctx.container.resolve(OrderService);
 *     return await orderService.cancelOrder(orderId);
 *   },
 *   dialogConfig: {
 *     title: 'Cancel Order',
 *     message: 'Are you sure you want to cancel this order?'
 *   },
 *   allowRetryOnFailures: true,
 *   getFeedback: (error) => [{
 *     level: 'Error',
 *     message: error.message
 *   }]
 * });
 * ```
 */
export async function runConfirmationButton<
  TResultValue,
  TError,
  TData extends AnyRecord,
  TConfig extends AnyRecord = AnyRecord
>(
  setup: RunConfirmationButtonSetup<TResultValue, TError, TData, TConfig>
): Promise<Result<TResultValue, ConfirmationError>> {
  const {
    confirmationType = isConfiguredButton(setup.ctx) ? 'auto' : 'never',
    ctx,
    onConfirm,
    dialogConfig,
    isRetry,
    allowRetryOnFailures
  } = setup;

  const confirmDialogConfig = isRetry ? dialogConfigForRetry(setup, dialogConfig) : dialogConfig;

  const isConfirmed = await waitForConfirmation(ctx, {
    dialogConfig: confirmDialogConfig,
    confirmationType: isRetry ? 'always' : confirmationType
  });

  if (!isConfirmed) {
    return Result.failure(new UserCanceledError());
  }

  const result = await onConfirm(ctx);

  return result.mapToAsync(
    async (value) => {
      return Result.success(value);
    },
    async (error) => {
      if (!allowRetryOnFailures) {
        return Result.failure(new TaskFailureError(error));
      }
      return await runConfirmationButton({
        ctx,
        onConfirm,
        dialogConfig,
        allowRetryOnFailures,
        isRetry: true,
        error,
        getFeedback: setup.getFeedback
      });
    }
  );
}

/**
 * Generates a dialog configuration for retry attempts
 * @param setup - Setup object containing error and getFeedback function
 * @param dialogConfig - Optional base dialog configuration
 * @returns Dialog configuration with retry-specific properties
 * @internal
 */
function dialogConfigForRetry<TError>(
  setup: Pick<RunConfirmationButtonSetup<any, TError, any, any>, 'error' | 'getFeedback'>,
  dialogConfig: Optional<DialogConfig>
): DialogConfig {
  const { error, getFeedback } = setup;
  const { componentProps, ...rest } = dialogConfig || {};
  const {
    alerts: baseAlerts,
    message = t('app.common.dialogs.retryRequest'),
    confirmButtonText,
    ...otherProps
  } = componentProps || {};
  const alerts =
    error && getFeedback
      ? getFeedback(error).map((feedback) =>
          convertAlert.formValidationAlertItem.item(feedback).toAlertBannerStackItem()
        )
      : baseAlerts;
  return {
    ...rest,
    componentProps: {
      ...otherProps,
      alerts,
      message,
      confirmButtonText: t('app.common.retry')
    }
  };
}

interface WaitForConfirmationOptions {
  confirmationType?: ConfirmationType;
  dialogConfig?: DialogConfig;
}

/**
 * Waits for user confirmation via dialog based on the confirmation type and context
 * @param ctx - The action context
 * @param options - Optional configuration for the confirmation dialog
 * @param options.confirmationType - The type of confirmation to request ('always', 'never', or 'auto')
 * @param options.dialogConfig - Configuration for the confirmation dialog UI
 * @returns Promise that resolves to true if confirmed, false if canceled
 */
async function waitForConfirmation<TData extends AnyRecord, TConfig extends AnyRecord = AnyRecord>(
  ctx: ActionContext<TData, TConfig>,
  options?: WaitForConfirmationOptions
): Promise<boolean> {
  const { container, workspace } = ctx;
  const windowId = getLeaderOrTabId(container);

  const { confirmationType = DEFAULT_CONFIRMATION_TYPE, dialogConfig } = options || {};

  if (shouldRequestConfirmationFromUser(ctx, confirmationType)) {
    // Open confirmation dialog and wait for user response
    const [_, api] = await openConfirmation(workspace, windowId, dialogConfig);
    const { type } = await api.awaitFirstEvent;
    return type === DIALOG_EVENT_TYPE.OK;
  } else {
    // Skip confirmation dialog and return true since confirmation is not required
    return true;
  }
}

/**
 * Determines whether user confirmation should be requested based on the context and confirmation type
 * @param ctx - The action context
 * @param confirmationType - The type of confirmation to check
 * - 'always': Always request confirmation
 * - 'never': Never request confirmation
 * - 'auto': Request confirmation only if required by context
 * @returns True if confirmation should be requested, false otherwise
 */
function shouldRequestConfirmationFromUser(
  ctx: ActionContext<any, any>,
  confirmationType: ConfirmationType
): boolean {
  switch (confirmationType) {
    case 'always':
      return true;
    case 'never':
      return false;
    case 'auto':
      return requiresConfirmation(ctx);
  }
}
