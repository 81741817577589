import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from './signal';
import { tap } from 'rxjs';
import * as Sentry from '@sentry/react';

/**
 * Signal for UpdaterState
 *
 * Used to broadcast the current state of UI states replication
 * and to listen for changes to UI states replication
 *
 * Used primarily at the framework level to broadcast the current state of the AuthClient
 * @see app.stream.ts
 * @see plugin-updater
 *
 * @usage
 * ```ts
 * const updaterSignal = container.resolve(UpdaterSignal);
 * const subscription = updaterSignal.$.subscribe((state) => {
 *  console.log('Updater state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(UpdaterSignal) private updaterSignal: UpdaterSignal) {
 *  const subscription = this.updaterSignal.$.subscribe((state) => {
 *    console.log('Updater state changed', state);
 *  });
 * }
 */

export const DEFAULT_UPDATER_STATE: UpdaterState = {
  hasUpdateAvailable: false,
  isCheckingForUpdates: true,
  isUpdating: false,
  updateErrorMessage: null
};

export type UpdaterState = {
  hasUpdateAvailable: boolean;
  isCheckingForUpdates: boolean;
  isUpdating: boolean;
  updateErrorMessage?: string | null;
};

@testScoped
@singleton()
export class UpdaterSignal extends AbstractSignal<UpdaterState> {
  constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      'updater',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false
      },
      DEFAULT_UPDATER_STATE
    );

    this.signal.$ = this.signal.$.pipe(
      tap((e) => {
        Sentry.setContext('Updater State', e);
      })
    );
  }
}
