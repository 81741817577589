import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type {
  ActionContext,
  ActionDefFactory,
  SplitButtonActionComponentConfig,
  SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { routeOrderActionOnChange } from '@app/widgets/trading/route-order/commands/route-order/route-order.action';
import { viewInvestorOrderActionOnChange } from '../../investor-order-view/commands/view-investor-order/view-investor-order.action';
import { acceptInvestorOrderActionOnChange } from '../commands/accept-investor-order/accept-investor-order.action';
import { rejectInvestorOrderActionOnChange } from '../commands/reject-investor-order/reject-investor-order.action';
import { cancelInvestorOrderActionOnChange } from '../commands/cancel-investor-order/cancel-investor-order.action';
import { modifyInvestorOrderActionOnChange } from '../../investor-order-entry/commands/modify-investor-order/modify-investor-order.action';

type InlineInvestorOrderActionArgs = {
  id: string;
  status: string;
  side: string;
  openQuantity: number;
  orderType: string;
  limitPrice: string;
  totalQuantity: number;
  instrument: string;
  instrumentDisplayCode: string;
};

export const inlineInvestorOrderActions =
  <T extends InlineInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('inline_order_split_button')
      .inline((col) =>
        col
          .header('Actions')
          .initialWidth(140)
          .filter(false)
          .cell((c) =>
            c
              .dependsOn(['id', 'status', 'openQuantity'])
              .renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' })
          )
      )
      .onChange<SplitButtonActionComponentConfig<T>>(async (ctx) => {
        switch (ctx.lifecycle) {
          case 'init':
          case 'refresh': {
            const [rowData] = ctx.data;
            ctx.notify({ actionsConfig: rowActions(ctx, rowData) });
            break;
          }
          case 'change': {
            const { selectedId } = ctx.state;
            switch (selectedId) {
              case 'inline_view_investor_order':
                return viewInvestorOrderActionOnChange(ctx);
              case 'inline_modify_investor_order':
                return modifyInvestorOrderActionOnChange(ctx);
              case 'inline_accept_investor_order':
                return await acceptInvestorOrderActionOnChange(ctx);
              case 'inline_reject_investor_order':
                return await rejectInvestorOrderActionOnChange(ctx);
              case 'inline_route_investor_order':
                return routeOrderActionOnChange(ctx);
              case 'inline_cancel_investor_order':
                return await cancelInvestorOrderActionOnChange(ctx);
              default:
                throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
            }
          }
        }
      });

function hasOpenQuantity(rowData: InlineInvestorOrderActionArgs) {
  return (
    rowData?.openQuantity !== null && rowData?.openQuantity !== undefined && Number(rowData?.openQuantity) > 0
  );
}

function hasEntitlement<T extends InlineInvestorOrderActionArgs>(
  ctx: ActionContext<T, SplitButtonActionComponentConfig<T>>
): boolean {
  const authService = ctx.appContainer.resolve(AuthService);
  return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
}

function rowActions<T extends InlineInvestorOrderActionArgs>(
  ctx: ActionContext<T, SplitButtonActionComponentConfig<T>>,
  rowData: T
): SplitButtonActionConfig[] {
  const canManage = hasEntitlement(ctx);
  if (!canManage) {
    return rowData.id
      ? [
          {
            id: 'inline_view_investor_order',
            title: 'View'
          }
        ]
      : [];
  }
  switch (rowData.status) {
    case InvestorOrderStatus.Unaccepted:
      return [
        { id: 'inline_accept_investor_order', title: 'Accept' },
        { id: 'inline_reject_investor_order', title: 'Reject' }
      ];

    case InvestorOrderStatus.Active:
      return [
        hasOpenQuantity(rowData)
          ? { id: 'inline_route_investor_order', title: 'Route' }
          : { id: 'inline_view_investor_order', title: 'View' },
        { id: 'inline_cancel_investor_order', title: 'Cancel' }
      ];

    // TODO Inactive and PendingModifications

    case InvestorOrderStatus.ModifyRequested:
      return [
        { id: 'inline_accept_modification', title: 'Accept' },
        { id: 'inline_reject_modification', title: 'Reject' }
      ];

    default:
      return rowData.id
        ? [
            {
              id: 'inline_view_investor_order',
              title: 'View'
            }
          ]
        : [];
  }
}
