import {
  ExitIcon,
  ResetIcon,
  PopOutIcon,
  EnterIcon,
  LayoutIcon,
  ActionIcon,
  SettingsIcon,
  CheckIcon
} from '../../icons/icons';

export const comboBoxIconMap = {
  open: PopOutIcon,
  exit: ExitIcon,
  reset: ResetIcon,
  enter: EnterIcon,
  group: LayoutIcon,
  action: ActionIcon,
  settings: SettingsIcon,
  check: CheckIcon
};

export type ComboBoxIconUnion = keyof typeof comboBoxIconMap;
