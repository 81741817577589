import { MEMORY_DB_COLLECTIONS_MAP, OFFLINE_DB_COLLECTIONS_MAP } from './collections';
import type {
  OfflineDatabaseCollectionType,
  MemoryDatabaseCollectionType,
  OfflineDatabaseCollections,
  MemoryDatabaseCollections
} from './collections';
import type { MemoryDatabase } from './memory-database';
import type { OfflineDatabase } from './offline-database';
import { createLogger } from '@oms/shared/util';

const logger = createLogger({
  name: 'AppDatabase'
});

export class AppDatabase {
  public offline: OfflineDatabaseCollections;
  public memory: MemoryDatabaseCollections;

  constructor(
    public memoryDb: MemoryDatabase,
    public offlineDb: OfflineDatabase
  ) {
    this.offline = offlineDb.collections;
    this.memory = memoryDb.collections;
  }

  /**
   * Clears the memory database.
   * @param options - The collections to clear. If not provided, all collections will be cleared.
   */
  public async clearMemory(options?: {
    [key in MemoryDatabaseCollectionType]?: boolean;
  }) {
    const collectionsToClear = Object.keys(MEMORY_DB_COLLECTIONS_MAP)
      .filter((key) => {
        if (!options) return true; // If no options are provided, clear all collections
        if (!options[key as MemoryDatabaseCollectionType]) return false; // If the collection is not in the options OR is set to false, don't clear it
        return true; // Otherwise, clear the collection
      })
      .map((key) => {
        const collectionKey = key as MemoryDatabaseCollectionType;
        return {
          key: collectionKey,
          // Wrap the promise to find the docs first, then remove them per collection
          promise: new Promise<boolean>((resolve, reject) => {
            this.memory[collectionKey]
              .find()
              .exec()
              .then((docs) => {
                this.memory[collectionKey]
                  .bulkRemove(docs.map((doc) => doc.primary))
                  .then(() => resolve(true))
                  .catch(reject);
              })
              .catch(reject);
          })
        };
      });

    const collectionsStr = collectionsToClear.map(({ key }) => key).join(', ');
    logger.log('Clearing memory collections:', collectionsStr);
    await Promise.all(collectionsToClear.map(({ promise }) => promise));
    logger.log('Cleared memory collections:', collectionsStr);
  }

  /**
   * Clears the offline database.
   * @param options - The collections to clear. If not provided, all collections will be cleared.
   */
  public async clearOffline(options?: {
    [key in OfflineDatabaseCollectionType]?: boolean;
  }) {
    const collectionsToClear = Object.keys(OFFLINE_DB_COLLECTIONS_MAP)
      .filter((key) => {
        if (!options) return true; // If no options are provided, clear all collections
        if (!options[key as OfflineDatabaseCollectionType]) return false; // If the collection is not in the options OR is set to false, don't clear it
        return true; // Otherwise, clear the collection
      })
      .map((key) => {
        const collectionKey = key as OfflineDatabaseCollectionType;
        return {
          key: collectionKey,
          // Wrap the promise to find the docs first, then remove them per collection
          promise: new Promise<boolean>((resolve, reject) => {
            this.offline[collectionKey]
              .find()
              .exec()
              .then((docs) => {
                this.offline[collectionKey]
                  .bulkRemove(docs.map((doc) => doc.primary))
                  .then(() => resolve(true))
                  .catch(reject);
              })
              .catch(reject);
          })
        };
      });

    const collectionsStr = collectionsToClear.map(({ key }) => key).join(', ');
    logger.log('Clearing offline collections:', collectionsStr);
    await Promise.all(collectionsToClear.map(({ promise }) => promise));
    logger.log('Cleared offline collections:', collectionsStr);
  }

  public async destroy() {
    const offline = await this.offlineDb.destroy();
    const memory = await this.memoryDb.destroy();
    return { offline, memory };
  }
}
