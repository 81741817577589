import type {
  MarketDataItemsUnion,
  MarketDataWidgetType
} from '@app/data-access/services/marketdata/components/market-data.types';
import type { Unsubscribe } from 'final-form';
import { useEnhancedFormApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField, PriceInputValue } from '@oms/frontend-foundation';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { convertToNumber } from '@oms/shared/util';
import { isEqual } from 'lodash';
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { MarketDataWidget } from '@app/data-access/services/marketdata/components/market-data.widget';
import type { FORM_COMPONENT_TYPE } from '../../common/form.contracts';

export type IMarketDataFieldOptions = {
  trackableFieldMap?: Record<string, string>;
  instrumentFieldName: string;
  widgetType?: MarketDataWidgetType;
  orderQuantityField?: string;
  alterDoubleClick?: boolean;
};

export type IMarketDataField = ICommonField<typeof FORM_COMPONENT_TYPE.MARKET_DATA_FIELD> &
  IMarketDataFieldOptions;

export const MarketDataField: FC<FieldProps<IMarketDataField>> = React.memo(
  ({
    trackableFieldMap,
    instrumentFieldName = 'instrument',
    widgetType = 'om-banner',
    orderQuantityField = 'quantity',
    alterDoubleClick
  }) => {
    const formApi = useEnhancedFormApi();
    const _instrumentId = formApi.getFieldState(instrumentFieldName)?.value?.id as string | undefined;
    const [instrumentId, setInstrumentId] = useState<string | undefined>(_instrumentId);

    // state variables
    const lastFrozenValue = useRef('');
    const lastActiveField = useRef('');
    const inputModified = useRef(false);
    const [enableTracking, setEnableTracking] = useState(false);

    const orderQuantityFieldState = orderQuantityField
      ? (formApi.getFieldState(orderQuantityField)?.value as number | string | undefined)
      : 0;

    const [orderQuantity, setOrderQuantity] = useState(convertToNumber(orderQuantityFieldState));

    useEffect(() => {
      setEnableTracking(!!trackableFieldMap);
    }, [trackableFieldMap]);

    useEffect(() => {
      let unsubscribe: Unsubscribe;

      if (trackableFieldMap || orderQuantityField) {
        unsubscribe = formApi.subscribe(
          ({ values, active }) => {
            const instrumentValue: { id: string } | undefined = values[instrumentFieldName];
            if (instrumentFieldName && instrumentValue?.id !== _instrumentId) {
              setInstrumentId(instrumentValue?.id);
            }
            if (orderQuantityField && values[orderQuantityField] !== orderQuantity) {
              setOrderQuantity(convertToNumber(values[orderQuantityField] || 0));
            }
            if (active && trackableFieldMap && Object.values(trackableFieldMap).includes(active)) {
              if (!lastFrozenValue.current) {
                lastFrozenValue.current = values[active];
              }
            }
          },
          { values: true, active: true }
        );
      }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }, [formApi, _instrumentId, instrumentFieldName, orderQuantity, orderQuantityField, trackableFieldMap]);

    const updateField = useCallback(
      (field: string, updateFieldCallback: Function) => {
        if (!trackableFieldMap) {
          return;
        }

        const formField = trackableFieldMap[field];

        if (formField) {
          const fieldEl = document.querySelector(`input[name="${formField}"]`);

          // ddf form API doesn't provide field's disabled state
          if (fieldEl?.getAttribute('disabled') === null) {
            updateFieldCallback(formField);
          }
        }
      },
      [trackableFieldMap]
    );

    const onRealTimeUpdateNumeric = useCallback(
      (marketDataField: string, value: PriceInputValue) => {
        updateField(marketDataField, (formField: string) => {
          const state = formApi.getState();
          const currentInputValue = state.values[formField];

          if (state.active === formField) {
            if (!inputModified.current) {
              inputModified.current = lastFrozenValue.current !== currentInputValue;
            }
          } else {
            if (!inputModified.current) {
              formApi.change(formField, value);
            }
            lastFrozenValue.current = '';
          }

          if (inputModified.current) {
            setEnableTracking(false);
          }
        });
      },
      [formApi, updateField]
    );

    const onManualUpdateNumeric = useCallback(
      (marketDataField: string, value: string | number | (string | number)[]) => {
        updateField(marketDataField, (formField: string) => {
          formApi.change(formField, value);
          resetState();
          setEnableTracking(true);
        });
      },
      [formApi, updateField]
    );

    const resetState = () => {
      lastFrozenValue.current = '';
      lastActiveField.current = '';
      inputModified.current = false;
    };

    const trackableValues = useMemo(() => {
      return trackableFieldMap ? (Object.keys(trackableFieldMap) as MarketDataItemsUnion[]) : undefined;
    }, [trackableFieldMap]);

    return (
      <div>
        <MarketDataWidget
          type={widgetType}
          instrumentId={instrumentId || ''}
          orderQuantity={orderQuantity}
          width="100%"
          onRealTimeUpdateNumeric={trackableFieldMap ? onRealTimeUpdateNumeric : undefined}
          onManualUpdateNumeric={trackableFieldMap ? onManualUpdateNumeric : undefined}
          enableTracking={enableTracking}
          trackableValues={trackableValues}
          alterDoubleClick={alterDoubleClick}
        />
      </div>
    );
  },
  isEqual
);

export const SymbolInputPromptMessage: FC<{ isLoading?: boolean }> = ({ isLoading = false }) => {
  return (
    <Flex
      justify="center"
      type="smallB"
      sx={{ color: 'text.primary', px: 3, py: 4, backgroundColor: 'layout.level1', borderRadius: 'sm' }}
    >
      {isLoading ? 'Loading...' : 'Please enter a symbol to see market data'}
    </Flex>
  );
};
