import type { SummaryGridField } from '@oms/frontend-foundation';
import type { ExecutionRow } from '@oms/generated/frontend';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import type { FormatType } from '@oms/shared-frontend/ui-design-system';

type FieldMetadata = {
  label: string;
  format?: FormatType;
  mapper?: (value: any) => string | undefined;
};

export const EXECUTION_DETAILS_FIELDS_MAP: Partial<Record<keyof ExecutionRow, FieldMetadata>> = {
  id: { label: 'Execution ID' },
  tradeId: { label: 'Trade ID' },
  investorOrderId: { label: 'Investor Order ID' },
  executedPrice: { label: 'Executed Price', format: 'price' },
  executedQuantity: { label: 'Executed Quantity', format: 'quantity' },
  executionDateTime: { label: 'Execution Datetime', format: 'datetime' },
  snapshotDateTime: { label: 'Snapshot Datetime', format: 'datetime' },
  status: { label: 'Status' },
  capacity: { label: 'Capacity' },
  settleDate: { label: 'Settle Date', format: 'datetime' },
  settleType: { label: 'Settle Type', mapper: mapSettleType },
  settleCurrency: { label: 'Settle Currency' },
  settleFxRate: { label: 'Settle Fx Rate', format: 'price' },
  side: { label: 'Side' },
  tradeCounterParty: { label: 'Trade Counterparty' },
  tradeContraAccountName: { label: 'Trade Contra Account Name' },
  lastMarket: { label: 'Last Market' },
  lastTradeTime: { label: 'Last Trade Time', format: 'datetime' },
  trader: { label: 'Trader' },
  rawLiquidityIndicator: { label: 'Raw Liquidity Indicator' },
  normalizedLiquidityIndicator: { label: 'Normalized Liquidity Indicator' },
  instrumentDisplayCode: { label: 'Instrument Display Code' }
};

export type ExecutionSummaryFieldName = keyof typeof EXECUTION_DETAILS_FIELDS_MAP;

const executionSummaryFields = (fieldNames: ExecutionSummaryFieldName[]): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    return {
      fieldName,
      ...(EXECUTION_DETAILS_FIELDS_MAP[fieldName] as FieldMetadata)
    };
  });
};

const executionSummaryValues = (
  fieldNames: ExecutionSummaryFieldName[],
  execution: Partial<ExecutionRow>
) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        // Using const on the field name definition ensures we get type protection when specifying
        // the fields as a user, but using explicit types here under the hood is way to complex to appease
        // typescript :/ so I'm treating it as an explicit any
        const fieldDef = EXECUTION_DETAILS_FIELDS_MAP[fieldName] as Record<string, any>;
        const mapper = fieldDef.mapper;
        // Using lodash's get instead of reimplementing nested fields with . again
        const fieldValue = get(execution, fieldName, undefined);
        if (fieldValue === null || fieldValue === undefined) {
          return { ...acc, [fieldName]: null };
        }
        return { ...acc, [fieldName]: (mapper ? mapper(fieldValue) : '') || String(fieldValue) };
      },
      {} as Record<string, string>
    )
  ];
};

export const executionSummaryData = (
  label: string,
  fields: (keyof typeof EXECUTION_DETAILS_FIELDS_MAP)[],
  order: Partial<ExecutionRow>
) => {
  return {
    label,
    value: executionSummaryValues(fields, order),
    fields: executionSummaryFields(fields)
  };
};
