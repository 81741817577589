import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('EXECUTION_VIEW', {
  version: 0,
  schema: z.object({
    id: z.string(),
    executionId: z.string(),
    allocationMapId: z.string(),
    tradeId: z.string()
  })
});
