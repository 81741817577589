import { type FC } from 'react';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { useFieldApi } from '../../helpers';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { DisplayGrid as DisplayGridComp } from '@oms/shared-frontend/ui-design-system';

export interface IDisplayGridProps extends DisplayGridProps {}

export interface IDisplayGridField
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.DISPLAY_GRID, IDisplayGridProps, Validator> {}

export const DisplayGrid: FC<FieldProps<IDisplayGridField, IDisplayGridProps>> = (props) => {
  const field = useFieldApi(props);
  return <DisplayGridComp {...(field.input?.value || {})} />;
};
