import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import {
  RulesDefinitionRow,
  TsRulesDefinitionsWithFilterDocument,
  TsRulesDefinitionsWithFilterSubscription
} from '@oms/generated/frontend';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import { buildRoutingRulesColumnDefs } from './routing-rules-grid.columns';

export interface RoutingRulesGridPropsWidgetComponentProps {}

const RoutingRulesGridWidgetComponent = () => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<RulesDefinitionRow>(
    'routing-rules',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildRoutingRulesColumnDefs())
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<RulesDefinitionRow, TsRulesDefinitionsWithFilterSubscription>({
                query: TsRulesDefinitionsWithFilterDocument,
                getData: (r) => r.tsRulesDefinitionsWithFilter
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .rowStateRules({ disabled: (params) => !params.data?.active || false })
        .sideBar()
        .reactiveCustomComponents(),
    [datasourceService, buildRoutingRulesColumnDefs]
  );

  return <VGrid {...gridProps} />;
};

export default RoutingRulesGridWidgetComponent;
