import { useRef } from 'react';
import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  toClientsideGridData,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { CurrencyFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import { createAction, updateAction, deleteAction } from './currencies-grid.actions';
import { currencyColumnLibrary } from './currencies-grid.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';

export const CurrenciesGridWidgetComponent = () => {
  const currenciesService = useService(CurrenciesService);
  const query$ = useRef(currenciesService.watchAll$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<CurrencyFragment>(
    'currencies',
    (builder) =>
      builder
        .sideBar()
        .columnLibrary(currencyColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(query$.current).rowId((r) => r.data.id))
        .injectEvents([GridConfigEventHandler])
        .actions((a) =>
          a.schema((s) =>
            s
              .action(ROW_SELECTION_COUNT_ACTION_TYPE)
              .action(createAction)
              .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(updateAction)
              .action(deleteAction)
          )
        ),
    []
  );

  return <VGrid {...gridProps} />;
};

export default CurrenciesGridWidgetComponent;
