import type { FC } from 'react';
import { t } from '@oms/codegen/translations';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { Button, ButtonGroup } from '@oms/shared-frontend/ui-design-system';
import { useOpenCancelExecutionDialog } from '@app/common/types/orders/execution/cancel.execution.action';
import { useOpenApplySettlementFxRate } from '@app/common/types/orders/execution/apply-settlement-fx-rate.action';

interface ExecutionViewUtilityButtonGroupProps {
  executionId: string;
  investorOrderId: string;
  executionStatus?: string;
}

export const ExecutionViewUtilityButtonGroup: FC<ExecutionViewUtilityButtonGroupProps> = ({
  executionId,
  investorOrderId,
  executionStatus
}) => {
  const container = useWorkspaceContainer();

  const onCancel = useOpenCancelExecutionDialog(executionId, investorOrderId);
  const onModify = useOpenApplySettlementFxRate(executionId, investorOrderId);

  const authService = container.resolve(AuthService);
  const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);

  const isEnabled = hasEntitlement && executionStatus === InvestorOrderStatus.Active;

  return (
    <ButtonGroup spacing={0}>
      <Button isDisabled={!isEnabled} onClick={onModify}>
        {t('app.orders.orderMonitor.applySettlementFXRate')}
      </Button>
      <Button isDisabled={!isEnabled} onClick={onCancel}>
        {t('app.common.cancel')}
      </Button>
    </ButtonGroup>
  );
};
