import { useMemo } from 'react';
import { OrderType, Strategy } from '@oms/generated/frontend';
import type { TradingOrderRow } from '@oms/generated/frontend';
import { withUpdatedTradingOrder } from '@app/data-access/hoc/with-updated-trading-order';
import { Box, Text, DisplayGrid } from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { t } from '@oms/codegen/translations';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';
import { TradingOrderViewUtilityButtonGroup } from './trading-order-view.utility-button-group';

const getDisplayGridItems = (tradingOrder: TradingOrderRow): DisplayGridProps['items'] => {
  const isMarketOrder = tradingOrder.orderType === OrderType.Market;

  const strategy = tradingOrder.strategyDetails
    ? (JSON.parse(tradingOrder.strategyDetails as string) as Strategy)
    : undefined;

  return [
    {
      label: 'Side',
      component: {
        type: 'Badge',
        value: tradingOrder.side
      }
    },
    {
      label: 'Instrument',
      component: {
        type: 'Text',
        value: tradingOrder.instrumentDisplayCode
      }
    },
    {
      label: 'Account',
      component: {
        type: 'Text',
        value: tradingOrder.tradingAccountName
      }
    },
    {
      label: 'Quantity',
      component: {
        type: 'Numeric',
        value: tradingOrder.totalQuantity,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: isMarketOrder
        ? {
            type: 'Badge',
            value: 'Market'
          }
        : {
            type: 'Numeric',
            value: tradingOrder.limitPrice,
            format: 'price'
          }
    },
    {
      label: 'Order Status',
      component: {
        type: 'Badge',
        value: mapTradingOrderStatus(tradingOrder.status)
      }
    },
    {
      label: 'Category',
      component: {
        type: 'Badge',
        value: mapTradingOrderCategory(tradingOrder.category)
      }
    },
    {
      label: t('app.orders.orderMonitor.routeTo'),
      component: {
        type: 'Text',
        value: tradingOrder.venue
      }
    },
    {
      label: 'Strategy',
      component: {
        type: 'Text',
        value: strategy?.uirep || strategy?.name || ''
      }
    },
    {
      label: 'Locate',
      component: {
        type: 'Text',
        value: tradingOrder.locate
      }
    }
  ];
};

const gridProps: DisplayGridProps['gridProps'] = {
  columns: 3,
  columnGap: 10,
  rowGap: 3
};

export const TradingOrderViewOrderInformationWidget = ({ data: order }: { data: TradingOrderRow }) => {
  const displayGridItems = useMemo(() => {
    if (!order) {
      return [];
    }

    return getDisplayGridItems(order);
  }, [order]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Order Information</Text>
        <TradingOrderViewUtilityButtonGroup
          orderStatus={order.status}
          orderId={order.id}
          orderSymbol={order.instrumentDisplayCode}
        />
      </Box>
      <DisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedTradingOrder(TradingOrderViewOrderInformationWidget, { sx });
