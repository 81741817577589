import type { ICellRendererParams } from '@ag-grid-community/core';
import { chargeGroupTotal } from '@app/common/types/charges/charges.utils';
import { ChargeGroup } from '@oms/generated/frontend';
import { DisplayGrid, GridColValues, ResponsiveValue } from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';

export const CHARGE_FEE_TOTAL_CELL_RENDERER = 'ChargeFeeTotalCellRenderer';

const gridProps: DisplayGridProps['gridProps'] = {
  rows: 1,
  columns: 1 as ResponsiveValue<GridColValues>,
  columnGap: 0,
  rowGap: 0
};

export const ChargeFeeTotalCellRenderer: React.FC<ICellRendererParams<InvestorOrderWithChargesRow, any>> = (
  params
) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal({ chargeGroup: ChargeGroup.Fee, order: params?.data });

  return <DisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};

export const CHARGE_MARKET_TOTAL_CELL_RENDERER = 'ChargeMarketTotalCellRenderer';

export const ChargeMarketTotalCellRenderer: React.FC<
  ICellRendererParams<InvestorOrderWithChargesRow, any>
> = (params) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal({ chargeGroup: ChargeGroup.Market, order: params?.data });

  return <DisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};
