import { FormContract, FieldDefinition, FieldContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { ApplySettlementFxRateFormSchemaInput } from './apply-settlement-fx-rate.form-builder.config';
import type {
  SettlementFxRateInput,
  VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { createPriceField } from '@app/forms/common/fields/price-field/price-field.common';

// Common
export type ApplySettleFxRateFormInput = ApplySettlementFxRateFormSchemaInput;
export type ApplySettleFxRateFormOutput = SettlementFxRateInput;
export type ApplySettleFxRateFieldContract = ApplySettlementFxRateFormSchemaInput &
  SettlementFxRateInput & {
    currenciesData: Pick<VisibleInvestorOrderInfoWithAllocationsFragment, 'settleCurrency' | 'tradeCurrency'>;
  };

// Fields
const fc = FieldContract.create<ApplySettleFxRateFieldContract>();

const orderIdField = fc.field('orderId', 'hidden-field');
const executionIdField = fc.field('executionId', 'hidden-field');
const settleFxRateField = createPriceField(fc, 'settleFxRate', {
  label: 'Settlement FX rate',
  placeholder: undefined, // To remove the default Market placeholder
  wrapperSx: { flex: 1 },
  format: 'price-fx',
  minNumber: { value: 0, message: 'FX rate cannot be a negative value' }
});

const currenciesDisplayGrid = fc.field('currenciesData', 'display-grid').options({
  initialValue: {
    gridProps: {
      rows: 1,
      columns: 1
    },
    items: []
  }
});

// Form contract
export const applySettleFxRateFormContract = FormContract.create<ApplySettleFxRateFormOutput>()
  .fields({
    orderId: orderIdField,
    executionId: executionIdField,
    settleFxRate: settleFxRateField,
    currenciesData: currenciesDisplayGrid
  })
  .schema((f) => ({
    fields: [FieldDefinition.box('wrapper', [f.currenciesData, f.settleFxRate])]
  }))
  .template('simple', {
    submitLabel: 'Apply',
    showCancelButton: true,
    formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
  });

export type ApplySettleFxRateFormContractType = typeof applySettleFxRateFormContract;

export type ApplySettleFxRateFormValues = InferFormValuesFromFormContract<ApplySettleFxRateFormContractType>;
