import { omitBy, isUndefined } from 'lodash';
import { isPromiseLike } from '@oms/shared/util';
import { extractGQLEnvelope, FeedbackWrapper, GQLResult } from '@oms/frontend-foundation';
import type {
  AnyRecord,
  FormBuilder,
  FormRendererEventBase,
  GQLMutationEnvelopeShape
} from '@oms/frontend-foundation';
import type { ActionContext } from '@oms/frontend-vgrid';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { t } from '@oms/codegen/translations';
import type { HotErrorResp, ColdResp, ConfigButtonResponse } from './common.types';
import { isConfiguredButton, isHotButton } from './common.util';

export type ConfigButtonOptions<
  TRowData extends AnyRecord,
  TFormInput extends AnyRecord,
  TFormValues extends AnyRecord
> = {
  name: string;
  ctx: ActionContext<TRowData>;
  /* default to true */
  handleError?: boolean;
  input: TFormInput;
  formBuilder: FormBuilder<TFormInput, any, any, any, TFormValues>;
  fixedFormValueKeys?: Array<keyof TFormValues>;
  onOpenForm?: (result: HotErrorResp<TFormValues> | ColdResp<TFormValues>) => Promise<void> | void;
};

export async function runConfigButton<
  TRowData extends AnyRecord,
  TFormInput extends AnyRecord,
  TFormValues extends AnyRecord
>({
  name,
  ctx,
  handleError = true,
  formBuilder,
  input,
  onOpenForm,
  fixedFormValueKeys = []
}: ConfigButtonOptions<TRowData, TFormInput, TFormValues>): Promise<ConfigButtonResponse<TFormValues>> {
  const { appContainer, workspace, actionId, widgetActor, windowActor, config } = ctx;
  const { payload: configFormValues } = config || {};

  if (!isConfiguredButton(ctx)) {
    if (onOpenForm) {
      const value = onOpenForm({ type: 'cold', initialValues: undefined });
      if (isPromiseLike(value)) {
        await value;
      }
    }
    return { type: 'cold', initialValues: undefined };
  }

  try {
    const form = formBuilder.build();
    const formCtx = {
      ...form,
      formId: `${form.formBuilderId}${actionId}`,
      formSaveType: 'UNKNOWN',
      container: appContainer
    } as const;

    // Fetch the order info & regular initial values for form
    const regularInitialValues = await form.sanitizer.input(input, formCtx);

    // Get the overridable config values
    const overridableConfigValues: Partial<TFormValues> = omitBy(
      configFormValues,
      (value, key) => fixedFormValueKeys.includes(key as keyof TFormValues) || isUndefined(value)
    );

    // Merge the regular initial values with the overridable config values
    const initialValues = {
      ...regularInitialValues,
      ...overridableConfigValues
    } as TFormValues;

    let initialFeedback: GQLMutationEnvelopeShape<any>['feedback'] = [];

    if (!isHotButton(ctx)) {
      if (onOpenForm) {
        const value = onOpenForm({ type: 'cold', initialValues });
        if (isPromiseLike(value)) {
          await value;
        }
      }
      return { type: 'cold', initialValues };
    }

    // -------- Handle if 'hot' configured buttons --------

    const formOutput = await form.sanitizer.output(initialValues, formCtx);
    if (formOutput) {
      const apiResult = await form.change(
        {
          type: 'SUBMIT',
          payload: {
            formValues: configFormValues || {},
            output: formOutput,
            modifiedFields: []
          },
          meta: {
            ...formCtx,
            windowId: windowActor.id,
            widgetId: widgetActor.id
          }
        },
        {
          notify: (_e: FormRendererEventBase<any, any>) => {},
          container: appContainer,
          workspace
        }
      );

      if (apiResult instanceof GQLResult) {
        apiResult.mapSync(
          (data) => {
            const envelope = extractGQLEnvelope(data);
            if (envelope?.feedback?.length) {
              initialFeedback = envelope?.feedback;
            }
          },
          {
            ENVELOPE_FEEDBACK_ERROR: (errors) => {
              if (errors.length) {
                initialFeedback = errors;
              }
            }
          },
          (remainingErrors) => {
            initialFeedback = initialFeedback || [];
            initialFeedback.push(
              ...remainingErrors.map(
                (e: Error) =>
                  ({
                    code: e.name,
                    level: 'Error',
                    message: e.message
                  }) as FeedbackWrapper
              )
            );
          }
        );
      }
    } else {
      initialFeedback.push({
        code: 'MISSING_REQUIRED_FIELDS',
        level: 'Error',
        message: 'Missing required fields.'
      });
    }

    const result: ConfigButtonResponse<TFormValues> =
      initialFeedback.length > 0
        ? { type: 'hot-error', initialValues, initialFeedback }
        : { type: 'hot-success' };

    if (onOpenForm && result.type === 'hot-error') {
      const value = onOpenForm(result);
      if (isPromiseLike(value)) {
        await value;
      }
    }

    return result;
  } catch (e) {
    const err = e as Error;
    const errorMessage = err?.message || 'Unknown';
    console.error(e);
    if (handleError) {
      openMessageDialog(t('app.commands.common.runConfigButtonError', { name, error: errorMessage })).catch(
        console.error
      );
    }
    return { type: 'error', error: err };
  }
}
