import { testScoped } from '@app/workspace.registry';
import { createBroadcastSignal, Signal } from '@oms/shared-frontend/rx-broadcast';
import { UUID } from '@oms/shared/util';
import { singleton } from 'tsyringe';

export type ReconnectContext = {
  id?: string;
};

@testScoped
@singleton()
export class ReconnectSignal {
  private signal$: Signal<ReconnectContext>;

  constructor() {
    this.signal$ = createBroadcastSignal<ReconnectContext>('reconnect_data_access', { id: undefined });
  }

  public reconnect(retryId?: string): void {
    this.signal$.set({ id: retryId || UUID() });
  }

  public get $() {
    return this.signal$.$;
  }
}
