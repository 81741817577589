import { z } from 'zod';

// ------------ FILTER MODEL --------------------------------------------------------
// ----------------------------------------------------------------------------------

export const agGridBooleanFilterTypeSchema = z.boolean();

export type AgGridBooleanFilterType = z.infer<typeof agGridBooleanFilterTypeSchema>;

export const agGridTextFilterTypeSchema = z.enum([
  'equals',
  'notEqual',
  'contains',
  'notContains',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank'
]);

export type AgGridTextFilterType = z.infer<typeof agGridTextFilterTypeSchema>;

export const agGridScalarFilterTypeSchema = z.enum([
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'blank',
  'notBlank',
  'inRange'
]);

export type AgGridScalarFilterType = z.infer<typeof agGridScalarFilterTypeSchema>;

export const customFilterPeriodTypeSchema = z.enum([
  'no-filter',
  'today',
  'yesterday',
  '7-days',
  '30-days',
  '90-days',
  'range'
]);

export const agGridArrayFilterTypeSchema = z.enum([
  'equals',
  'notEqual',
  'contains',
  'notContains',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank'
]);

export type AgGridArrayFilterType = z.infer<typeof agGridArrayFilterTypeSchema>;

export type CustomFilterType = z.infer<typeof customFilterPeriodTypeSchema>;

export const agFilterOperatorSchema = z.enum(['AND', 'OR']);

export type AgFilterOperator = z.infer<typeof agFilterOperatorSchema>;

export const agGridBooleanFilterSchema = z.object({
  filterType: z.literal('boolean'),
  filter: agGridBooleanFilterTypeSchema.optional()
});

export type AgGridBooleanFilter = z.infer<typeof agGridBooleanFilterSchema>;

export const agGridTextFilterSchema = z.object({
  filterType: z.literal('text'),
  filter: z.string().optional(),
  type: agGridTextFilterTypeSchema.optional()
});

export type AgGridTextFilter = z.infer<typeof agGridTextFilterSchema>;

export const agGridNumberFilterSchema = z.object({
  filterType: z.literal('number'),
  filter: z.number().optional(),
  filterTo: z.number().nullable().optional(),
  type: agGridScalarFilterTypeSchema.optional()
});

export type AgGridNumberFilter = z.infer<typeof agGridNumberFilterSchema>;

export const agGridDateFilterSchema = z.object({
  filterType: z.literal('date'),
  dateFrom: z.string().optional(),
  dateTo: z.string().nullable().optional(),
  type: agGridScalarFilterTypeSchema.optional()
});

export type AgGridDateFilter = z.infer<typeof agGridDateFilterSchema>;

export const agGridCustomDateFilterSchema = z.object({
  filterType: z.literal('customDate'),
  period: customFilterPeriodTypeSchema,
  dateFrom: z.string().optional(),
  dateTo: z.string().nullable().optional()
});

export type AgGridCustomDateFilter = z.infer<typeof agGridCustomDateFilterSchema>;

export const agGridArrayFilterSchema = z.object({
  filterType: z.literal('array'),
  type: agGridArrayFilterTypeSchema.optional(),
  values: z.union([z.string(), z.array(z.string())]).optional()
});

export type AgGridArrayFilter = z.infer<typeof agGridArrayFilterSchema>;

export const agGridSetFilterSchema = z.object({
  filterType: z.literal('set'),
  values: z.array(z.string()).optional()
});

export type AgGridSetFilter = z.infer<typeof agGridSetFilterSchema>;

export const agGridRelationFilterSchema = z.object({
  filterType: z.literal('relation'),
  value: z.string()
});

export type AgGridRelationFilter = z.infer<typeof agGridRelationFilterSchema>;

export const agGridFilterModelUnionSchema = z.union([
  agGridBooleanFilterSchema,
  agGridTextFilterSchema,
  agGridNumberFilterSchema,
  agGridDateFilterSchema,
  agGridCustomDateFilterSchema,
  agGridArrayFilterSchema,
  agGridSetFilterSchema,
  agGridRelationFilterSchema
]);

export type AgGridFilterModelUnion = z.infer<typeof agGridFilterModelUnionSchema>;

const agGridFilterModelBaseSchema = z.object({
  filterType: z.union([
    z.literal('boolean'),
    z.literal('text'),
    z.literal('number'),
    z.literal('date'),
    z.literal('customDate'),
    z.literal('array'),
    z.literal('set'),
    z.literal('relation')
  ]),
  period: customFilterPeriodTypeSchema.optional(),
  filter: z.union([z.string(), z.number(), z.boolean()]).optional(),
  filterTo: z.number().nullable().optional(),
  type: z
    .union([agGridTextFilterTypeSchema, agGridScalarFilterTypeSchema, agGridArrayFilterTypeSchema])
    .optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().nullable().optional(),
  values: z.union([z.string(), z.array(z.string())]).optional(),
  operator: agFilterOperatorSchema.optional(),
  value: z.string().optional()
});

export type AgGridFilterModelBase = z.infer<typeof agGridFilterModelBaseSchema> & {
  conditions?: AgGridFilterModelBase[];
};

const agGridFilterModelSchema: z.ZodType<AgGridFilterModelBase> = agGridFilterModelBaseSchema.extend({
  conditions: z.lazy(() => z.array(agGridFilterModelSchema)).optional()
});

export const agGridFiltersModelSchema = z.record(agGridFilterModelSchema);

export type AgGridFiltersModel = z.infer<typeof agGridFiltersModelSchema>;

// ------------ SORT MODEL ----------------------------------------------------------
// ----------------------------------------------------------------------------------

export const agGridSortModelItemSchema = z.object({
  colId: z.string(),
  sort: z.enum(['asc', 'desc'])
});

type BaseSortModelItem = z.infer<typeof agGridSortModelItemSchema>;

export type SortModelItem<T = any> = Omit<BaseSortModelItem, 'colId'> & {
  colId: T extends object ? Extract<keyof T, string> : string;
};

export const agGridSortModelchema = z.array(agGridSortModelItemSchema);

export type SortModel = z.infer<typeof agGridSortModelchema>;
