import { OmsFormValidatorPreset } from '@app/common/types/field-validation/field-validation';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type { LenghtOptions, Validator } from '@data-driven-forms/react-form-renderer';
import type { ICommonField, INumberFormatField } from '@oms/frontend-foundation';
import type { IAdvancedSelectField } from '@oms/frontend-foundation';
import type { ComboBoxDefaultValue } from '@oms/shared-frontend/ui-design-system';
import type { RequiredFieldIndicatorStyle } from '@oms/shared-frontend/ui-design-system';
interface CommonRequiredPropOptions {
  indicatorStyle?: RequiredFieldIndicatorStyle;
  additionalValidators?: Validator[];
}
const { required } = OmsFormValidatorPreset;

// Instrument ------------------------------------------------------ /

interface CommonInstrumentPropOptions<T extends ComboBoxDefaultValue> {
  label?: string | { suppressLabel: true };
  isRequired?: boolean;
  requiredOptions?: CommonRequiredPropOptions;
  queryType?: 'full' | 'simple';
  suppressValidateInstrumentTypeahead?: boolean;
  isPrimaryField?: boolean;
  includeInputProps?: boolean;
  initialValue?: IAdvancedSelectField<T>['initialValue'];
}

export const makeInstrumentCommonProps = <T extends ComboBoxDefaultValue>(
  options?: CommonInstrumentPropOptions<T>
): Pick<
  IAdvancedSelectField<T, Validator>,
  | 'label'
  | 'isRequired'
  | 'isVisible'
  | 'isPrimaryField'
  | 'query'
  | 'autoSelectItemOnTab'
  | 'allowAnyValue'
  | 'requiredFieldIndicatorStyle'
  | 'validate'
  | 'popoverOptions'
  | 'style'
  | 'inputProps'
  | 'initialValue'
> => {
  const {
    label = 'Symbol',
    isRequired = false,
    requiredOptions,
    queryType,
    suppressValidateInstrumentTypeahead,
    isPrimaryField,
    includeInputProps,
    initialValue
  } = options ?? {};
  const validate: Validator[] = [];
  const allowAnyValue = !suppressValidateInstrumentTypeahead;
  if (!suppressValidateInstrumentTypeahead) validate.push({ type: 'validateInstrumentTypeahead' });
  if (requiredOptions?.additionalValidators?.length) validate.push(...requiredOptions.additionalValidators);
  if (isRequired) validate.push(required as Validator);
  return {
    label: typeof label === 'string' ? label : undefined,
    isRequired,
    isVisible: true,
    isPrimaryField,
    query: (() => {
      switch (queryType) {
        case 'simple':
          return ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_SIMPLE_QUERY;
        case 'full':
          return ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_QUERY;
      }
    })(),
    autoSelectItemOnTab: true,
    allowAnyValue,
    requiredFieldIndicatorStyle: requiredOptions?.indicatorStyle ?? 'default',
    validate,
    initialValue,
    style: {
      textTransform: 'uppercase'
    },
    inputProps: includeInputProps
      ? {
          variant: 'default',
          size: 'sm' as never
        }
      : undefined
  };
};

export const makeCommonRequiredProps = (
  options?: CommonRequiredPropOptions
): Pick<ICommonField<string, LenghtOptions>, 'isRequired' | 'requiredFieldIndicatorStyle' | 'validate'> => ({
  isRequired: true,
  requiredFieldIndicatorStyle: options?.indicatorStyle ?? 'default',
  validate: [...(options?.additionalValidators ?? []), required]
});

interface CommonQuantityPropOptions {
  label?: string;
  allowNegative?: boolean;
  isPrimaryField?: boolean;
  autoFocus?: boolean;
  suppressDisableNumericShortcuts?: boolean;
}

export const rightAlignFieldCommonProps: Pick<ICommonField<string, Validator>, 'sx'> = {
  sx: {
    textAlign: 'right'
  }
};

export const makeQuantityCommonProps = (
  options?: CommonQuantityPropOptions
): Pick<
  INumberFormatField<Validator>,
  | 'label'
  | 'isPrimaryField'
  | 'selectAllOnFocus'
  | 'allowNegative'
  | 'decimalScale'
  | 'autoFocus'
  | 'clipFormLabels'
  | 'sx'
> => ({
  label: options?.label ?? 'Quantity',
  isPrimaryField: options?.isPrimaryField,
  selectAllOnFocus: true,
  allowNegative: options?.allowNegative,
  decimalScale: 0,
  autoFocus: options?.autoFocus,
  clipFormLabels: false,
  ...rightAlignFieldCommonProps
});
