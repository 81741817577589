import { AUTH_EVENT_ACTION } from '@app/common/auth/auth.contracts';
import type {
  AuthClientState,
  ParsedIdentityToken,
  ValstroEntitlement
} from '@app/common/auth/keycloak.types';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { cleanMaybe } from '@oms/shared/util';
import type { Optional } from '@oms/shared/util-types';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';
import * as Sentry from '@sentry/react';
import omit from 'lodash/omit';

@testScoped
@singleton()
export class AuthService {
  constructor(@inject(AuthSignal) protected authState: AuthSignal) {}

  private setSentryUser(e: AuthClientState) {
    const { tokenParsed, isAuthenticated } = e;

    tokenParsed &&
      isAuthenticated &&
      Sentry.setUser({
        id: tokenParsed.id,
        roles: this.authState.signal.get().roles,
        email: tokenParsed.email,
        username: tokenParsed.name
      });

    !isAuthenticated && Sentry.setUser(null);
  }

  private setSentryAuthContext(e: AuthClientState) {
    Sentry.setContext('Auth State', {
      ...omit(e, 'idToken', 'token', 'tokenParsed', 'refreshToken', 'expiry'),
      expiry: e.expiry ? new Date(e.expiry).toISOString() : null
    });

    const event = e.lastAuthClientEvent;

    switch (event) {
      case 'onAuthError':
      case 'onAuthRefreshError':
      case 'onInitError':
        Sentry.captureMessage('Keycloak error', (scope) => {
          scope.setLevel('error');
          scope.setTag('keycloak_auth_state', event);

          return scope;
        });
        break;
    }
  }

  /**
   * Observable of the auth state
   */
  public get $() {
    return this.authState.signal.$.pipe(
      tap((e) => {
        this.setSentryUser(e);
        this.setSentryAuthContext(e);
      })
    );
  }

  /**
   * Current auth state
   */
  public get state() {
    const authState = this.authState.signal.get();
    this.setSentryUser(authState);
    this.setSentryAuthContext(authState);
    return authState;
  }

  /**
   * Broadcasts an event to log the user out.
   * This will trigger the keycloak logout process in the auth window actor
   */
  public logout() {
    this.authState.action$.next(AUTH_EVENT_ACTION.LOGOUT);
  }

  /**
   * Get the user ID of the currently logged in user
   *
   * @returns The user ID of the currently logged in user (null if not logged in)
   */
  public getUserId(): string | null {
    // Get our current keycloak instance's parsed token. The user ID is the "sub" prop. See:
    // https://www.keycloak.org/docs/latest/server_development/index.html#_action_token_anatomy
    const parsedToken = this.authState.signal.get()?.tokenParsed;
    return parsedToken && 'valstroUserId' in parsedToken ? (parsedToken['valstroUserId'] as string) : null;
  }

  public get currentUser(): Optional<ParsedIdentityToken> {
    return cleanMaybe(this.state.tokenParsed);
  }
  public get currentUser$(): Observable<Optional<ParsedIdentityToken>> {
    return this.$.pipe(map(({ tokenParsed }) => cleanMaybe(tokenParsed)));
  }

  /**
   * Checks if the current user has **_any_** of the specified entitlements.
   *
   * @param {ValstroEntitlement[]} entitlements - An array of entitlements to check against.
   * @returns {boolean} True if the user has at least one of the specified entitlements, false otherwise.
   */
  public hasEntitlement(entitlements: ValstroEntitlement[]): boolean {
    return entitlements.some((e) => this.state.roles.includes(e));
  }
}
