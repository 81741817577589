import React from 'react';
import { MagnifyingGlassIcon, BellIcon } from '@radix-ui/react-icons';
import { Badge, Box, Center, Flex, HStack, IconButton } from '@oms/shared-frontend/ui-design-system';
import { useCommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.hooks';
import { LogoComponent } from '@app/components/logo/logo';
import { useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import { useOpenNotifications, useOpenUserPreferences } from '@app/generated/sdk';
import { getAppEnv, createLogger } from '@oms/shared/util';
import * as styles from './launcher-frame.container.css';
import { useObservableState } from 'observable-hooks';
import { isTauri } from '@app/common/workspace/workspace.constants';
import { UserAvatar } from '../user-avatar/user-avatar.container';
import { useAppStateStream } from '@app/data-access/memory/app.stream';
import { APP_STATE_TYPE } from '@app/common/app/app.contracts';

const logger = createLogger({
  name: 'LauncherFrameContainer'
});

export interface LauncherFrameContainerProps {
  showLauncher?: boolean;
  showShortcut?: boolean;
}

export const LauncherFrameContainer: React.FC<PropsWithChildren<LauncherFrameContainerProps>> = ({
  children,
  showLauncher = true,
  showShortcut = false
}) => {
  const commandPaletteService = useCommandPaletteService();
  const isCommandPaletteReady = useObservableState(commandPaletteService.isReady$, false);
  const appState = useAppStateStream();
  const isFullyReady = appState.state === 'Ready' && isCommandPaletteReady;
  const openNotificationsFn = useOpenNotifications();
  const openProfileFn = useOpenUserPreferences();

  const openCommandPalette = () => {
    // Timeout is to fix race condition with the blur event.
    // Command palette will be closed if it's opened immediately after the blur event (which it listens to)
    // This is a workaround until we have a better solution.
    setTimeout(() => {
      commandPaletteService.open();
    }, 50);
  };

  const openNotifications = () => {
    if (!isFullyReady) {
      return;
    }

    openNotificationsFn('currentWindow').catch(logger.error);
  };

  const openProfile = () => {
    if (!isFullyReady) {
      return;
    }

    openProfileFn('currentWindow').catch(logger.error);
  };

  const hasChildren = React.Children.count(children) > 0;

  const wrapperStyles = { height: '100%', width: '100%' };

  if (showLauncher === false) {
    return (
      <Flex style={wrapperStyles} direction="column">
        {children}
      </Flex>
    );
  }

  const dragStyles: React.CSSProperties = useMemo(() => {
    return {
      height: '100%',
      flexGrow: 1,
      ...(isTauri() ? { '-webkit-app-region': 'drag' } : {})
    };
  }, []);

  return (
    <LaunchStateWrapper>
      <Flex style={wrapperStyles} direction="column">
        <Flex
          style={{
            height: hasChildren ? 36 : '100%',
            paddingLeft: 6,
            paddingRight: 0,
            width: 'calc(100% - 12px)',
            flexShrink: 1
          }}
          align="center"
        >
          <div style={dragStyles}>
            <HStack align="center" style={{ height: '100%', alignItems: 'center', pointerEvents: 'none' }}>
              <LogoComponent />
              <BadgeState />
            </HStack>
          </div>

          <HStack align="center" sx={{ marginLeft: 'auto' }}>
            <IconButton
              aria-label="Open search"
              variant="ghost"
              isDisabled={!isCommandPaletteReady}
              data-suppress-event="click--inside"
              icon={<MagnifyingGlassIcon />}
              onMouseUp={openCommandPalette}
            />
            <IconButton
              aria-label="Notifications"
              variant="ghost"
              isDisabled={!isFullyReady}
              icon={<BellIcon />}
              onClick={openNotifications}
            />
            <UserAvatar
              style={{ opacity: !isFullyReady ? 0.5 : 1 }}
              className="launcher-frame-user-avatar"
              onClick={openProfile}
            />
          </HStack>
        </Flex>
        {hasChildren && (
          <Flex grow={1} sx={{ position: 'relative' }}>
            {showShortcut && (
              <Center sx={{ textAlign: 'center', width: 'full' }}>
                {isCommandPaletteReady && (
                  <Box
                    className={styles.commandPaletteShortcut}
                    aria-label="Open commands"
                    onClick={openCommandPalette}
                  >
                    <Box as="span" sx={{ paddingRight: 2 }}>
                      Open Commands
                    </Box>
                    <Box as="span" style={{ position: 'relative', top: -1 }}>
                      <Badge>Ctrl</Badge> + <Badge>\</Badge>
                    </Box>
                  </Box>
                )}
              </Center>
            )}
            {children}
          </Flex>
        )}
      </Flex>
    </LaunchStateWrapper>
  );
};

const badgeStyle: React.CSSProperties = { userSelect: 'none' };

export function BadgeState() {
  const { state } = useAppStateStream();
  const envBadge = getAppEnv().toFullEnv();

  switch (state) {
    case APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED:
      return <Badge style={badgeStyle}>Disconnected</Badge>;
    case APP_STATE_TYPE.SYNCRONISING:
      return <Badge style={badgeStyle}>Syncing...</Badge>;
    default:
      return <>{envBadge ? <Badge style={badgeStyle}>{envBadge}</Badge> : null}</>;
  }
}

export function LaunchStateWrapper({ children }: { children: React.ReactNode }) {
  const { state, updater } = useAppStateStream();

  switch (state) {
    case APP_STATE_TYPE.CHECKING_FOR_UPDATES:
      return <Center>Checking for updates...</Center>;
    case APP_STATE_TYPE.UPDATING:
      return updater.updateErrorMessage ? (
        <Center>{updater.updateErrorMessage}</Center>
      ) : (
        <Center>Updating...</Center>
      );
    default:
      return <>{children}</>;
  }
}
