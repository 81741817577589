import { type ComponentMapper, type Validator } from '@data-driven-forms/react-form-renderer';
import { type ISectionField, Section } from '../components/section/section.component';
import { DisplayField } from '../components/display-field/display-field.component';
import { type IDisplayField } from '../components/display-field/display-field.types';
import { DisplayGrid, type IDisplayGridField } from '../components/display-grid/display-grid.component';
import { FORM_COMPONENT_TYPE } from '../contracts';
import { NativeDatePicker } from '../components/native-date-picker/native-date-picker.component';
import { type INativeDatePickerField } from '../components/native-date-picker/native-date-picker.type';
import { NativeTimePicker } from '../components/native-time-picker/native-time-picker.component';
import { type INativeTimePickerField } from '../components/native-time-picker/native-time-picker.type';
import { Runway, type IRunwayField } from '../components/runway/runway.component';
import type { AnyRecord } from '../../common/type.helpers';
import { AdvancedMultiSelectField } from '../components/advanced-select/advanced-multi-select';
import { type IAdvancedMultiSelectField } from '../components/advanced-select/advanced-multi-select.types';
import { AdvancedSelectField } from '../components/advanced-select/advanced-select';
import { type IAdvancedSelectField } from '../components/advanced-select/advanced-select.types';
import { Box, type IBoxField } from '../components/box/box.component';
import { CheckboxButtonGroup } from '../components/checkbox-button-group/checkbox-button-group.component';
import { type ICheckboxButtonGroupField } from '../components/checkbox-button-group/checkbox-button-group.type';
import {
  CheckboxButton,
  type ICheckboxButtonField
} from '../components/checkbox-button/checkbox-button.component';
import {
  FormCheckboxGroup,
  type ICheckboxGroupField
} from '../components/checkbox-group/checkbox-group.component';
import { Checkbox, type ICheckboxField } from '../components/checkbox/checkbox.component';
import { Collapsible, type ICollapsibleField } from '../components/collapsible/collapsible.component';
import { type IComponentField } from '../components/component/component.types';
import { DdfComponent } from '../components/component/ddf.component';
import { DatePicker } from '../components/date-picker/date-picker.component';
import { type IDatePickerField } from '../components/date-picker/date-picker.type';
import { FormFieldArray } from '../components/field-array/field-array.component';
import { type IFieldArrayField } from '../components/field-array/field-array.type';
import {
  FormBuilderFeedback,
  type IFormBuilderFeedbackField
} from '../components/form-builder-feedback/form-builder-feedback.component';
import { Heading } from '../components/heading/heading.component';
import { type IHeadingField } from '../components/heading/heading.type';
import { HiddenField, type IHiddenField } from '../components/hidden-field/hidden-field.component';
import { NumberFormat } from '../components/number-format/number-format.component';
import { type INumberFormatField } from '../components/number-format/number-format.type';
import { PriceInput } from '../components/price-input/price-input.component';
import { type IPriceInputField } from '../components/price-input/price-input.type';
import { PlainText, type IPlainTextField } from '../components/plain-text/plain-text.component';
import { Radio, type IRadioField } from '../components/radio/radio.component';
import { ResetButton, type IResetButtonField } from '../components/reset-button/reset-button.component';
import { RuleBuilder } from '../components/rule-builder/rule.builder';
import { type RuleBuilderField } from '../components/rule-builder/rule.types';
import { Select, type ISelectField } from '../components/select/select.component';
import { SimpleGrid } from '../components/simple-grid/simple-grid.component';
import { type ISimpleGridField } from '../components/simple-grid/simple-grid.type';
import { Stack } from '../components/stack/stack.component';
import { type IStackField } from '../components/stack/stack.type';
import { SubmitButton, type ISubmitButtonField } from '../components/submit-button/submit-button.component';
import { SummaryField, type ISummaryField } from '../components/summary-field/summary-field.component';
import { Switch, type ISwitchField } from '../components/switch/switch.component';
import { type ITabField } from '../components/tabs/tabs.type';
import { TextField, type ITextField } from '../components/text-field/text-field.component';
import { FileField, type IFileField } from '../components/file-field/file-field.component';
import { Textarea, type ITextareaField } from '../components/textarea/textarea.component';
import { TimePicker, type ITimePicker } from '../components/time-picker/time-picker.component';
import { ToggleButton, type IToggleButtonField } from '../components/toggle-button/toggle-button.component';
import { ToggleGroupMulti } from '../components/toggle-button/toggle-group-multi.component';
import {
  type IToggleButtonGroupField,
  type IToggleButtonGroupMultiField
} from '../components/toggle-button/toggle-group.type';
import { ToggleGroup } from '../components/toggle-button/toggle-group.component';
import { Tabs } from '../components/tabs/tabs';
import { MultiInput, type IMultiInputField } from '../components/multi-input/multi-input';

export const FORM_COMPONENT_MAPPER: ComponentMapper = {
  [FORM_COMPONENT_TYPE.ADVANCED_MULTI_SELECT]: AdvancedMultiSelectField,
  [FORM_COMPONENT_TYPE.ADVANCED_SELECT]: AdvancedSelectField,
  [FORM_COMPONENT_TYPE.BOX]: Box,
  [FORM_COMPONENT_TYPE.CHECKBOX]: Checkbox,
  [FORM_COMPONENT_TYPE.CHECKBOX_BUTTON]: CheckboxButton,
  [FORM_COMPONENT_TYPE.CHECKBOX_BUTTON_GROUP]: CheckboxButtonGroup,
  [FORM_COMPONENT_TYPE.CHECKBOX_GROUP]: FormCheckboxGroup,
  [FORM_COMPONENT_TYPE.COLLAPSIBLE]: Collapsible,
  [FORM_COMPONENT_TYPE.COMPONENT_MAPPER]: DdfComponent,
  [FORM_COMPONENT_TYPE.DATE_PICKER]: DatePicker,
  [FORM_COMPONENT_TYPE.DISPLAY_FIELD]: DisplayField,
  [FORM_COMPONENT_TYPE.DISPLAY_GRID]: DisplayGrid,
  [FORM_COMPONENT_TYPE.FIELD_ARRAY]: FormFieldArray,
  [FORM_COMPONENT_TYPE.FORM_BUILDER_FEEDBACK]: FormBuilderFeedback,
  [FORM_COMPONENT_TYPE.HEADING]: Heading,
  [FORM_COMPONENT_TYPE.HIDDEN_FIELD]: HiddenField,
  [FORM_COMPONENT_TYPE.MULTI_INPUT]: MultiInput,
  [FORM_COMPONENT_TYPE.NATIVE_DATE_PICKER]: NativeDatePicker,
  [FORM_COMPONENT_TYPE.NATIVE_TIME_PICKER]: NativeTimePicker,
  [FORM_COMPONENT_TYPE.NUMBER_FORMAT]: NumberFormat,
  [FORM_COMPONENT_TYPE.PLAIN_TEXT]: PlainText,
  [FORM_COMPONENT_TYPE.PRICE_INPUT]: PriceInput,
  [FORM_COMPONENT_TYPE.RADIO]: Radio,
  [FORM_COMPONENT_TYPE.RESET_BUTTON]: ResetButton,
  [FORM_COMPONENT_TYPE.RULE_BUILDER]: RuleBuilder,
  [FORM_COMPONENT_TYPE.RUNWAY]: Runway,
  [FORM_COMPONENT_TYPE.SECTION]: Section,
  [FORM_COMPONENT_TYPE.SELECT]: Select,
  [FORM_COMPONENT_TYPE.SIMPLE_GRID]: SimpleGrid,
  [FORM_COMPONENT_TYPE.STACK]: Stack,
  [FORM_COMPONENT_TYPE.SUBMIT_BUTTON]: SubmitButton,
  [FORM_COMPONENT_TYPE.SUMMARY_FIELD]: SummaryField,
  [FORM_COMPONENT_TYPE.SWITCH]: Switch,
  [FORM_COMPONENT_TYPE.TABS]: Tabs,
  [FORM_COMPONENT_TYPE.TEXT_FIELD]: TextField,
  [FORM_COMPONENT_TYPE.TEXTAREA]: Textarea,
  [FORM_COMPONENT_TYPE.TOGGLE_BUTTON]: ToggleButton,
  [FORM_COMPONENT_TYPE.TOGGLE_GROUP]: ToggleGroup,
  [FORM_COMPONENT_TYPE.TOGGLE_GROUP_MULTI]: ToggleGroupMulti,
  [FORM_COMPONENT_TYPE.TIME_PICKER]: TimePicker,
  [FORM_COMPONENT_TYPE.FILE_FIELD]: FileField
};

export type FormFieldUnion<TValue = unknown, TValidator = Validator> =
  | IDatePickerField<TValidator>
  | IBoxField
  | ICheckboxField<TValidator>
  | ICheckboxButtonField<TValidator>
  | ICheckboxButtonGroupField<TValidator>
  | ICheckboxGroupField<TValidator>
  | ICollapsibleField
  | IComponentField
  | IDisplayField
  | IDisplayGridField
  | IFieldArrayField<TValue, TValidator>
  | IHeadingField
  | IRadioField<TValidator>
  | IResetButtonField
  | RuleBuilderField
  | ISectionField
  | ISelectField<TValue, TValidator>
  | ISimpleGridField
  | IStackField
  | ISubmitButtonField
  | ISummaryField
  | ISwitchField<TValidator>
  | ITabField
  | IPlainTextField
  | INumberFormatField<TValidator>
  | IPriceInputField<TValidator>
  | ITextField<TValidator>
  | ITextareaField<TValidator>
  | ITimePicker<TValidator>
  | IToggleButtonField<TValidator>
  | IToggleButtonGroupField<TValue, TValidator>
  | IToggleButtonGroupMultiField<TValue, TValidator>
  | IAdvancedSelectField<TValue, AnyRecord, TValidator>
  | IAdvancedMultiSelectField<TValue, AnyRecord, TValidator>
  | IMultiInputField<TValue extends string ? TValue : string, unknown, TValidator>
  | INativeDatePickerField<TValidator>
  | INativeTimePickerField<TValidator>
  | IRunwayField
  | IFormBuilderFeedbackField
  | IHiddenField<TValue, TValidator>
  | IFileField<TValidator>;
