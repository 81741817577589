import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldInputProps } from 'react-final-form';
import type { FieldProps, IAdvancedSelectField, AnyRecord } from '@oms/frontend-foundation';
import { AdvancedSelectField, useFieldApi } from '@oms/frontend-foundation';
import { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import type {
  InstrumentTrackingEvent,
  TrackingSourceType
} from '@app/common/grids/grid-tracking/grid-tracking.types';
import { useInstrumentTracking } from './instrument-follow.hooks';
import { InstrumentFollowToggleButton } from './instrument-follow.toggle-button';
import type { InstrumentFollowRegistryKey } from './instrument-follow.toggle-state.registry';
import { INSTRUMENT_TRACKING_STATE_REGISTRY } from './instrument-follow.toggle-state.registry';

export type IInstrumentInnerValue = {
  sourceId?: string;
  sourceType?: string;
};

export type IInstrumentFollowField<TValidator = Validator> = Omit<
  IAdvancedSelectField<IInstrumentInnerValue, AnyRecord, TValidator>,
  'component'
> & {
  toggleStateSource?: InstrumentFollowRegistryKey;
  sourceTypes?: TrackingSourceType[];
  component: typeof FORM_COMPONENT_TYPE.INSTRUMENT_FOLLOW;
};

export const InstrumentFollowField: React.FC<FieldProps<IInstrumentFollowField<any>>> = (props) => {
  const { toggleStateSource, sourceTypes = [], input } = useFieldApi<IInstrumentFollowField<any>>(props);
  const { onChange, value } = input as FieldInputProps<Partial<InstrumentTrackingEvent>>;

  const onChangeStableRef = useRef(onChange);
  onChangeStableRef.current = onChange;
  //
  const valueRef = useRef<Partial<InstrumentTrackingEvent>>(value);
  valueRef.current = value;

  const [isToggleEnabled, setIsToggleEnabled] = useState(false);

  const onInstrumentFollowEvent = useCallback(
    ({ sourceId, sourceType, instrumentId, instrumentDisplayCode }: InstrumentTrackingEvent) => {
      if (!isToggleEnabled || valueRef.current?.sourceId === sourceId) {
        return;
      }

      onChangeStableRef.current({
        id: instrumentId,
        value: {
          sourceId,
          sourceType
        },
        label: instrumentDisplayCode
      });
    },
    [isToggleEnabled]
  );

  useInstrumentTracking(onInstrumentFollowEvent, sourceTypes);

  const InstrumentStateWrapper = useMemo(() => {
    if (!toggleStateSource) {
      throw new Error('toggleStateSource is required for InstrumentFollowField');
    }

    const InstrumentStateWrapper =
      INSTRUMENT_TRACKING_STATE_REGISTRY[toggleStateSource as InstrumentFollowRegistryKey];

    if (!InstrumentStateWrapper) {
      throw new Error(`InstrumentStateWrapper not found for ${toggleStateSource}`);
    }
    return InstrumentStateWrapper;
  }, [toggleStateSource]);

  return (
    <AdvancedSelectField {...props} component="advanced-select">
      <InstrumentStateWrapper onPressedChange={setIsToggleEnabled}>
        {(state) => <InstrumentFollowToggleButton isPrimaryField={props.isPrimaryField} {...state} />}
      </InstrumentStateWrapper>
    </AdvancedSelectField>
  );
};
