import semver, { SemVer } from 'semver';
import type { Optional } from '@oms/shared/util-types';
import { AppEnv } from './app-env.class';

declare const __PROCESS_ENV__: string | Record<string, string> | null | undefined;

let parsedCache: Record<string, string> | NodeJS.ProcessEnv | undefined;

export const getEnvVar = (key: string, defaultValue = ''): string => {
  let envs = parsedCache || __PROCESS_ENV__ || process.env;

  if (typeof envs === 'string') {
    envs = JSON.parse(envs) as Record<string, string>;
    parsedCache = envs;
  }

  const envVar = envs?.[key];

  if (!envVar) {
    return defaultValue;
  }

  return `${envVar}`;
};

export const isDevEnv = () => getEnvVar('NODE_ENV') === 'development';
export const isTestEnv = () => getEnvVar('NODE_ENV') === 'test';

// App Env ---------------------------------------------------------------- /

export function getAppEnv() {
  const isTestOrDev = isTestEnv() || isDevEnv();
  const windowHost = typeof window !== 'undefined' && 'location' in window ? window.location.host : '';
  const host = getEnvVar('NX_NEST_API_HOST') || windowHost || undefined;
  return AppEnv.from(isTestOrDev ? getEnvVar('NODE_ENV') : host);
}

export function getAppEnvOrg(): string {
  const env = getAppEnv();
  return env?.org ?? AppEnv.defaultOrg;
}

// Version string ---------------------------------------------------------------- /

export interface VersionFormatOptions {
  prependV?: boolean;
}

export const formatVersion = (
  version?: string | SemVer,
  options?: VersionFormatOptions
): Optional<string> => {
  if (!version) return undefined;
  const prefix = () => (options?.prependV ? 'v' : '');
  if (version instanceof SemVer) {
    return prefix() + version.format();
  }
  if (!semver.valid(version)) {
    return undefined;
  }
  return prefix() + version;
};
