import { forwardRef, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { VGridTitle } from './vgrid.title.component';
import { VGridToolbar } from './vgrid.toolbar.component';
import { useComponentService } from '../hooks/component.service.hook';
import { useCustomContextMenu } from '../hooks/custom-context-menu.hook';
import { useDisplayStateService } from '../hooks/display-state.hook';
import type { VGridProps } from './vgrid.component';
import { ComponentLocation } from '../models/v.component.model';
import { AnyRecord } from '@oms/frontend-foundation';
import { useVGridId } from '../hooks/vgrid-id.hook';
import clsx from 'clsx';

const VGridDisplayComponentWithRef = <TData extends AnyRecord>(
  props: VGridProps<TData>,
  ref: React.LegacyRef<AgGridReact<TData>>
) => {
  const { onToolbarHotkey, animateRows = false } = props;
  const onCellContextMenu = useCustomContextMenu<TData>(props);
  const displayState = useDisplayStateService(props.title, props.masterDetail);
  const selectionComponents = useComponentService(ComponentLocation.Selection);
  const gridId = useVGridId(props.gridType, props.viewId);
  const popupParent = useMemo(() => {
    const body = document.querySelector('body');
    return body ? body : undefined;
  }, []);

  return (
    <div
      className={clsx(
        'vgrid-container',
        gridId ? `vgrid-id-${gridId}` : '',
        animateRows === false && 'no-animation'
      )}
      data-testid={gridId}
      aria-label={`${props.gridType} grid`}
      style={{ position: 'relative', height: '100%' }}
      role="presentation"
    >
      <div style={{ ...displayState.agGridContainerStyle, overflowX: 'hidden' }}>
        <VGridTitle agGridClassName={displayState.agGridClassName} gridTitle={props.title} />
        <div style={{ display: 'inline-flex', flexDirection: 'row', height: '100%', width: '100%' }}>
          <VGridToolbar
            location="left"
            onToolbarHotkey={onToolbarHotkey as VGridProps<AnyRecord>['onToolbarHotkey']}
          />
          <div
            style={{ ...displayState.agGridStyle, width: '100%' }}
            className={displayState.agGridClassName}
          >
            <AgGridReact<TData>
              popupParent={popupParent}
              {...props}
              ref={ref}
              columnMenu="new" // Gives us the ability to use the new column menu (right click for pin/filter etc)
              onCellContextMenu={onCellContextMenu}
            />
          </div>
          <VGridToolbar
            location="right"
            onToolbarHotkey={onToolbarHotkey as VGridProps<AnyRecord>['onToolbarHotkey']}
          />
        </div>
        {selectionComponents &&
          selectionComponents.map((c) => c.component && <c.component key={c.id} {...c.props} />)}
        <VGridToolbar
          location="bottom"
          onToolbarHotkey={onToolbarHotkey as VGridProps<AnyRecord>['onToolbarHotkey']}
        />
      </div>
    </div>
  );
};

// TODO: Breaks ESLint Server
export const VGridDisplay = forwardRef(VGridDisplayComponentWithRef) as <TData extends AnyRecord>(
  props: VGridProps<TData> & { ref?: React.LegacyRef<AgGridReact<TData>> }
) => React.ReactElement;
