import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import type { TradeRow } from '@oms/generated/frontend';

const executionViewTradesDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

const executionViewTradesColumns = {
  id: { extends: 'orders.id' },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  price: {
    extends: 'orders.price',
    cellRenderer: 'NumberWithTooltipCellRenderer',
    cellRendererParams: { format: 'price-trade' }
  },
  locate: { extends: 'generic.text' },
  side: {
    extends: 'orders.side'
  },
  quantity: { extends: 'orders.quantity' },
  capacity: { extends: 'orders.orderCapacity' },
  tradeCounterParty: { extends: 'generic.text', width: 168 },
  tradeContraAccount: { extends: 'generic.text', width: 180 },
  lastMarket: { extends: 'generic.text' },
  lastTradeTime: { extends: 'generic.dateTime' },
  createdTime: { extends: 'generic.dateTime' },
  updatedTime: { extends: 'generic.dateTime' },
  traderByName: { extends: 'orders.owner' },
  rawLiquidityIndicator: { extends: 'generic.text' },
  normalizedLiquidityIndicator: { extends: 'generic.text' },
  originalTradeId: { extends: 'orders.id' },
  linkedExecutionId: { extends: 'orders.id' },
  status: {
    extends: 'orders.tradeStatus'
  },
  settleType: {
    extends: 'orders.settleType',
    hide: true
  },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  }
} satisfies ColDefMap<TradeRow>;

const executionViewTradesColumnLibrary: TableServerColumnLibrary<TradeRow> = {
  defaultColumn: executionViewTradesDefaultColumn,
  columns: executionViewTradesColumns
};

export const buildExecutionViewTradesColumnDefs = () =>
  buildColumnDefs(executionViewTradesColumnLibrary, 'executionViewTrades');
