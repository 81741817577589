/**
 * 🎯 In-Window Context Menu Component
 * 
 * Provides a context menu system that appears at the cursor position
 * with support for primary actions, tabs, and searchable command items.
 */

// ===============================================================================
// 📦 Imports 📦
// ===============================================================================
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { CSSProperties } from 'react';
import clsx from 'clsx';
import type { ComboBoxItem } from '../combo-box/combo-box.types';
import { ComboBox } from '../combo-box/combo-box.component';
import * as MenuPrimitive from '@radix-ui/react-menu';
import { useObservableState } from 'observable-hooks';
import type { IContextMenu, MousePos, SimpleContextMenuItem } from './in-window.context-menu.model';
import { ContextMenuService } from './in-window.context-menu.service';
import { PrimaryButtonStack } from './primary-button-row/primary-button-row.component';
import { Box } from '../../system/components/box/box';
import { inWindowContextMenuStyle } from './in-window.context-menu.css';

// ===============================================================================
// 🌐 Context 🌐
// ===============================================================================

/**
 * Context for sharing context menu state throughout the component tree
 */
export const InWindowContextMenuContext = createContext<IContextMenu>({} as IContextMenu);

/**
 * Hook to access context menu API from any child component
 */
export const useInWindowContextMenuApi = () => {
  return useContext(InWindowContextMenuContext);
};

// ===============================================================================
// ⚙️ Constants ⚙️
// ===============================================================================

const POPOVER_WIDTH = 280;
const POPOVER_MIN_LIST_HEIGHT = 50;
const POPOVER_MAX_LIST_HEIGHT = 148;
const POPOVER_ITEM_HEIGHT = 32;
const PRIMARY_BUTTONS_HEIGHT = 36;
const TABS_HEIGHT = 36;
const INPUT_HEIGHT = 44;
const MARGIN = 6;

const popoverStyle: CSSProperties = { width: POPOVER_WIDTH };

const CONTEXT_MENU_WRAPPER_CLASS = 'context-menu-wrapper';

// ===============================================================================
// 🎨 Provider Component 🎨
// ===============================================================================

/**
 * Provider component that manages context menu state and renders the menu UI
 * Handles positioning, sizing, and state management for the context menu popup
 */
export function InWindowContextMenuProvider({
  children,
  contextMenu
}: {
  children: React.ReactNode;
  contextMenu?: IContextMenu;
}) {
  // -------------------------- State & Refs --------------------------
  const state = useMemo(() => contextMenu || new ContextMenuService(), []);
  const { primary, comboxItems, isOpen, mousePos } = useObservableState(state.$, {
    primary: [],
    secondary: [],
    comboxItems: [],
    isOpen: false,
    mousePos: { x: 0, y: 0 }
  });
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedItems] = useState<ComboBoxItem[]>([]);

  // Mouse position tracking for menu placement
  const mouseRef = useRef<MousePos>({ x: 0, y: 0 });
  mouseRef.current = mousePos;
  const virtualRef = React.useRef({
    getBoundingClientRect: () => DOMRect.fromRect({ width: 0, height: 0, ...mouseRef.current })
  });

  // ------------------------------- Flags ---------------------------------
  const hasPrimary = primary.length > 0;
  const hasTabs = useMemo(() => comboxItems.some((i) => i.type === 'tab'), [comboxItems]);

  // -------------------------- Size Calculations --------------------------
  // Calculate the height needed for the list of items
  const largestTabItemsLength = useMemo(() => {
    const rootItems = comboxItems.filter((i) => i.type === 'item');
    const tabs = comboxItems.filter((i) => i.type === 'tab');
    if (tabs.length === 0) {
      return rootItems.length;
    }
    let largest = 0;
    for (const tab of tabs) {
      const itemsInTabLength = tab.items.length;
      if (itemsInTabLength > largest) {
        largest = itemsInTabLength;
      }
    }
    return largest;
  }, [comboxItems]);

  const listHeight = largestTabItemsLength * POPOVER_ITEM_HEIGHT;
  const popoverListHeight =
    Math.min(Math.max(listHeight, POPOVER_MIN_LIST_HEIGHT), POPOVER_MAX_LIST_HEIGHT) + MARGIN;

  // Calculate total popover height including all sections
  let popoverHeight = hasPrimary
    ? popoverListHeight + PRIMARY_BUTTONS_HEIGHT + INPUT_HEIGHT
    : popoverListHeight + INPUT_HEIGHT;

  if (hasTabs) {
    popoverHeight += TABS_HEIGHT;
  }

  // -------------------------- Callbacks --------------------------
  const onOpen = useCallback((isOpen: boolean) => {
    state.setOpen(isOpen);
  }, []);

  const onSubmit = useCallback((selectedItems: ComboBoxItem[]) => {
    state.run(selectedItems.map((s) => s.id));
    state.setOpen(false);
    setSelectedItems([]);
  }, []);

  // -------------------------- Styles --------------------------
  const computedPopoverStyle = useMemo(() => {
    return {
      ...popoverStyle,
      height: popoverHeight
    };
  }, [popoverHeight]);

  const computedCmdStyles = useMemo(() => {
    return {
      '--cmdk-list-max-height': `${popoverListHeight}px`
    } as CSSProperties;
  }, [popoverListHeight]);

  const shouldRender = useCallback((items: SimpleContextMenuItem[]) => {
    return items.length > 0;
  }, []);

  // -------------------------- Render --------------------------
  return (
    <InWindowContextMenuContext.Provider value={state}>
      <MenuPrimitive.Root open={isOpen} onOpenChange={onOpen}>
        <MenuPrimitive.Anchor virtualRef={virtualRef} />
        {children}
        <MenuPrimitive.Portal>
          <MenuPrimitive.Content
            className="ContextMenuContent"
            side="bottom"
            align="start"
            sideOffset={2}
            alignOffset={-2}
            style={computedPopoverStyle}
          >
            <Box className={clsx(CONTEXT_MENU_WRAPPER_CLASS, inWindowContextMenuStyle)} cmdk-wrapper="" cmdk-sm="" style={computedCmdStyles}>
              <PrimaryButtonStack
                items={primary}
                state={state}
                maxLength={3}
                shouldRender={shouldRender}
              />
              <ComboBox
                items={comboxItems}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedItems={selectedOptions}
                setSelectedItems={setSelectedItems}
                removeOnBackspace={false}
                hiddenInput={false}
                onSubmit={onSubmit}
                hiddenSelectedItems={true}
                shouldAutoFilter={true}
                customFilter={customStartsWithFilter}
                submitReady={true}
                submitInstantly={true}
                strategy={'single-select'}
                allSwitchable={true}
                tabThroughTabs={true}
              />
            </Box>
          </MenuPrimitive.Content>
        </MenuPrimitive.Portal>
      </MenuPrimitive.Root>
    </InWindowContextMenuContext.Provider>
  );
}

// ===============================================================================
// 🛠️ Utilities 🛠️
// ===============================================================================

/**
 * Custom filter function for searching menu items
 * Performs case-insensitive starts-with matching
 */
function customStartsWithFilter(value: string, search: string) {
  return value.toLowerCase().startsWith(search.toLowerCase()) ? 1 : 0;
}
