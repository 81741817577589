import { FormSummary } from '@oms/frontend-foundation';
import React, { useMemo } from 'react';
import type { TradeRow } from '@oms/generated/frontend';
import { tradeSummaryData, TradeSummaryFieldName } from '@app/common/summaries/trade-fields';

const tradeDetailsFields: TradeSummaryFieldName[] = [
  'quantity',
  'side',
  'instrumentDisplayCode',
  'capacity',
  'price',
  'tradingAccountName',
  'tradeCounterParty',
  'traderByName'
];

const detailsFields: TradeSummaryFieldName[] = [
  'normalizedLiquidityIndicator',
  'rawLiquidityIndicator',
  'lastMarket',
  'tradingOrderId',
  'id'
];

export const TradeDetailsSummary = React.memo(({ trade }: { trade: TradeRow }) => {
  const data = useMemo(() => {
    const sections = [
      tradeSummaryData('TRADE DETAILS', tradeDetailsFields, trade),
      tradeSummaryData('DETAILS', detailsFields, trade)
    ];

    return sections;
  }, [trade]);

  return <FormSummary labeledData={data} />;
});
