import { ACTION_SIZE_TO_WIDTH } from '../types/action.types';
import type { Action } from '../types/action.types';
import { compileActions } from '@oms/frontend-vgrid';
import type { ActionsService, ActionSchema, ToolbarLocationKeys, ActionDef } from '@oms/frontend-vgrid';
import { ACTION_COMMAND_REGISTRY } from '../commands/command.registry';
import { COMMANDS_TO_META } from '../commands/command.registry.types';
import type { ActionCommandConfig, ActionCommands } from '../commands/command.registry.types';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { ButtonProps } from '@oms/shared-frontend/ui-design-system';
import type { ButtonConfigSubject } from '../events/button.config.subject';
import { renderCommand } from './command.render.strategy';

export type RenderActions<TData extends AnyRecord = AnyRecord> = {
  ignoredDefinitions: ActionDef<TData, AnyRecord>[];
  actionsService: ActionsService<TData>;
  actionContext$?: ButtonConfigSubject;
  actions: Action[];
  propOverrides: ButtonProps | undefined;
};

/**
 * Renders a list of actions on a grid.
 */
export const renderActions = <TData extends AnyRecord = AnyRecord>({
  actions,
  actionsService,
  actionContext$,
  ignoredDefinitions = [],
  propOverrides = {}
}: RenderActions<TData>): void => {
  const ignoreActionSet: Set<string> = new Set(ignoredDefinitions.map((a) => a.name.replaceAll('-', '_')));
  const currentActionSet: Set<string> = new Set(actions.map((a) => a.id.replaceAll('-', '_')));
  const actionsToRemove = actionsService
    .actions()
    .filter((a) => !ignoreActionSet.has(a.name) && !currentActionSet.has(a.name));
  actionsToRemove.length && actionsService.removeDefinitions(...actionsToRemove);
  actions.forEach(({ id, locationId, color, commandId, payload, confirmation, size }, idx) => {
    const actionId = id.replaceAll('-', '_');
    const currAction = actions[idx];
    const commandMeta = COMMANDS_TO_META[commandId as ActionCommands];
    const { allowsSize } = commandMeta;

    const commandConfig: ActionCommandConfig<AnyRecord> = {
      ...commandMeta,
      confirmation,
      payload,
      actionContext$
    };
    const isVertical = locationId.includes('Vert');
    const ignoreSizes = !allowsSize || isVertical;

    const actionsToCompile: ActionSchema<TData, ActionCommands>[] = [
      [
        commandId,
        (action) =>
          action
            .config({
              data: commandConfig
            })
            .toolbar((t) =>
              t
                .id(actionId)
                .location(locationId as ToolbarLocationKeys)
                .props({
                  ...propOverrides,
                  content: renderCommand({ actionContext$, ...currAction, ...commandMeta }),
                  style: {
                    ...color,
                    ...(ignoreSizes ? { alignSelf: 'normal' } : ACTION_SIZE_TO_WIDTH[size])
                  },
                  sx: { ...propOverrides.sx, ...(isVertical ? { flexGrow: 1 } : {}) }
                })
            )
            .name(actionId)
      ]
    ];

    const [def] = compileActions<TData, ActionCommands>(ACTION_COMMAND_REGISTRY, ...actionsToCompile);

    actionsService.upsertDefinitions(def);
  });

  actionsService.render();
};
