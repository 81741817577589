import { FormBuilder } from '@oms/frontend-foundation';
import orderVwapFormContract from './order-vwap.form-contract';
import type { OrderVwapFormContractType, OrderVwapFormValues } from './order-vwap.form-contract';
import type { OrderVwapFormInput, OrderVwapFormOutput } from './order-vwap.form-common';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { FactsetClient } from '@app/data-access/services/marketdata/factset/factset.client';
import { FactsetOrderVWAPResponse } from '@app/data-access/services/marketdata/factset/factset.types';
import { FactsetOrderVWAPRequest } from '@app/data-access/services/marketdata/factset/factset.types';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
export const orderVwapFormBuilder = FormBuilder.create<OrderVwapFormInput, OrderVwapFormOutput>(
  'order-vwap-form'
)
  .contract<OrderVwapFormContractType>(orderVwapFormContract)
  .type('order-vwap')
  .sanitizer((s) =>
    s
      .input(async (input, ctx): Promise<OrderVwapFormValues> => {
        const { investorOrderId } = input;

        if (!investorOrderId) {
          return {};
        }

        const investorOrderService = ctx.container.resolve(InvestorOrdersService);

        const instrument = await investorOrderService.getInstrumentById(investorOrderId);

        const investorOrder = await investorOrderService.getById(investorOrderId);

        if (investorOrder.isSuccess() && investorOrder.value.data.visibleInvestorOrder) {
          if (!instrument) {
            console.error('No instrument found for investorOrderId:', investorOrderId);
            return {};
          }

          if (!investorOrder) {
            console.error('No investorOrder found for investorOrderId:', investorOrderId);
            return {};
          }

          const startTime = DateTime.fromISO(
            String(investorOrder.value.data.visibleInvestorOrder.createdTimestamp)
          ).toFormat('HH:mm');

          const endTime = DateTime.now().toFormat('HH:mm');

          return {
            investorOrderId,
            instrument: {
              id: instrument.id,
              label: instrument.mappings?.displayCode,
              value: {
                sourceId: investorOrderId
              }
            },
            startTime,
            endTime
          };
        }

        return {};
      })
      .output((_) => undefined)
  )
  .change(() => {})
  .effect(async ({ formApi, container }) => {
    const factsetClient = container.resolve(FactsetClient);

    let factsetClientSub: Subscription;

    const factsetSub = formApi
      .get$({ values: true, fields: ['endTime', 'instrument', 'startTime', 'investorOrderId'] })
      .subscribe((formState) => {
        const { values } = formState;

        const { startTime: startTimeHHMM, endTime: endTimeHHMM, instrument } = values;

        if (!startTimeHHMM || !endTimeHHMM || !instrument?.label) {
          return undefined;
        }

        const now = DateTime.now();

        // Todo: when the start HH:MM is greater than the end HH:MM, we need to use the venue's opening time. For now, we're just using the previous day
        const start = DateTime.fromFormat(`${startTimeHHMM}:00`, 'HH:mm:ss')
          .set({ year: now.year, month: now.month, day: startTimeHHMM > endTimeHHMM ? now.day - 1 : now.day })
          .toISO();
        const end = DateTime.fromFormat(`${endTimeHHMM}:00`, 'HH:mm:ss')
          .set({ year: now.year, month: now.month, day: now.day })
          .toISO();

        if (!start || !end) {
          return;
        }

        factsetClientSub = factsetClient
          .requestEndpoint<FactsetOrderVWAPRequest, FactsetOrderVWAPResponse>({
            endpoint: '/prices/timeSeries/intraday/subsample/list',
            method: 'POST',
            payload: {
              data: {
                identifier: {
                  value: instrument.label,
                  type: 'tickerRegion'
                },
                type: 'trade',
                quality: 'BST',
                range: {
                  start,
                  end
                },
                granularity: '10s'
              },
              meta: {
                attributes: ['range', 'volumeWeightedAveragePrice'],
                pagination: {
                  limit: 5000
                }
              }
            }
          })
          .subscribe((response) => {
            formApi.change('vwap', response?.data?.volumeWeightedAveragePrice);
          });
      });

    return () => {
      factsetClientSub?.unsubscribe();
      factsetSub.unsubscribe();
    };
  });

export default orderVwapFormBuilder;
