import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import { FeedbackWrapper } from '@oms/generated/frontend';
import { FeedbackWrappersRenderer } from '@app/common/grids/cell-renderers/errors/errors.cell-renderer';

const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: false
};

export type BulkRouteOrderRow = Pick<
  InvestorOrderRow,
  | 'id'
  | 'investorAccountName'
  | 'instrumentDisplayCode'
  | 'side'
  | 'openQuantity'
  | 'limitPrice'
  | 'locate'
  | 'orderType'
>;

export type BulkRouteOrderRowWithValidation = BulkRouteOrderRow & { feedback: FeedbackWrapper[] };

export const bulkRouteOrderColumns: ColDefMap<BulkRouteOrderRowWithValidation> = {
  id: { extends: 'orders.id', hide: true },
  investorAccountName: { extends: 'orders.investorAccount', filter: false },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode', filter: false },
  side: { extends: 'orders.side', filter: false },
  openQuantity: { extends: 'orders.quantity', filter: false },
  limitPrice: { extends: 'orders.limitPrice', filter: false },
  locate: { extends: 'generic.text', filter: false, hide: true },
  feedback: {
    extends: 'generic.text',
    width: 300,
    filter: false,
    cellRenderer: FeedbackWrappersRenderer,
    headerName: 'Validation'
  }
};

export const bulkRouteOrderColumnLibrary: TableServerColumnLibrary<BulkRouteOrderRowWithValidation> = {
  defaultColumn,
  columns: bulkRouteOrderColumns
};

export const buildBulkRouteOrderColumnDefs = () =>
  buildColumnDefs(bulkRouteOrderColumnLibrary, 'bulkRouteOrder');
