import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { FoundationWorkspace } from '@oms/frontend-foundation';
import { useCallback } from 'react';
import { openTradeModify } from '@app/generated/sdk';

export const openTradeModifyOnChange = (workspace: FoundationWorkspace, id: string) => {
  openTradeModify(workspace.getLeaderProcessId(), {
    windowId: `trade-modify-${id}`,
    form: {
      formId: `trade-modify-form-${id}`,
      input: {
        id,
        entryType: 'trade-modify'
      }
    },
    title: `Modify Trade: ${id}`
  }).catch((e) => {
    openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
    console.error(e);
  });
};

export const useOpenTradeModify = (tradeId: string) => {
  const workspace = useAppWorkspace();
  return useCallback(() => openTradeModifyOnChange(workspace, tradeId), [workspace, tradeId]);
};
