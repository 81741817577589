import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import * as field from './investor-order-bulk-entry.form-fields';
import type { OrderParametersFields } from './investor-order-bulk-entry.form-common';

export const investorOrderBulkEntryContract = FormContract.create<OrderParametersFields>()
  .fields({
    id: field.id,
    investorAccount: field.investorAccountId,
    investorOrders: field.investorOrders,
    primaryBenchmark: field.primaryBenchmark,
    settleDate: field.settlementDate,
    settleType: field.settlementType,
    settleCurrency: field.settleCurrency,
    timeInForce: field.timeInForce,
    commission: field.commission.commission,
    rateType: field.commission.commissionType,
    locate: field.locate,
    notionalAmount: field.notionalAmount,
    discretionaryType: field.discretionaryType,
    stopPrice: field.stopPrice,
    customerNotes: field.customerNotes,
    orderTags: field.orderTags,
    receivedTimestamp: field.receivedTimestamp,
    owner: field.ownerId,
    representativeCode: field.representativeCode,
    preAllocationAccount: field.preAllocationAccount,
    tradeCurrency: field.tradeCurrency,
    stoppedQuantity: field.stoppedQuantity,
    stoppedPrice: field.stoppedPrice,
    stoppedTime: field.stoppedTime
  })
  .schema((f) => {
    const commissionContainer = field.commission.commissionContainer.build();
    return {
      fields: [
        f.id,
        FieldDefinition.runway(
          'runway',
          [{ ...f.investorAccount, isPrimaryField: true, wrapperSx: { flexGrow: 1 } }],
          {
            sx: {},
            style: {}
          }
        ),
        FieldDefinition.heading('Orders', {
          name: 'orders-heading',
          style: {
            height: '32px',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: '1px solid var(--colors-border-minimal)'
          },
          sx: {
            backgroundColor: 'layout.level2',
            marginTop: 2,
            padding: 2.5
          }
        }),
        {
          name: 'orders-grid-section',
          component: 'section',
          label: 'Orders',
          fields: [[f.investorOrders]],
          style: { height: '240px' },
          sx: {
            backgroundColor: 'layout.level1',
            marginTop: 0,
            padding: 0
          }
        },
        FieldDefinition.heading('Order parameters', {
          name: 'order-parameters-heading',
          style: {
            height: '32',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: '1px solid var(--colors-border-minimal)'
          },
          sx: {
            backgroundColor: 'layout.level2',
            marginTop: 1.5,
            padding: 2.5
          }
        }),
        createAdditionalAllFields({
          groups: ['Order', 'Settle', 'Stoped details'],
          fixedFields: [
            'primaryBenchmark',
            field.settlementContainer.name,
            'settleCurrency',
            'timeInForce',
            commissionContainer.name,
            'locate'
          ],
          fields: [
            [createEnrichedField(f.primaryBenchmark, { groupName: 'Order' })],
            [createEnrichedField(field.settlementContainer.build(), { groupName: 'Order' })],
            [createEnrichedField(f.settleCurrency, { groupName: 'Order' })],
            [createEnrichedField(f.timeInForce, { groupName: 'Order' })],
            [createEnrichedField(commissionContainer, { groupName: 'Order' })],
            [createEnrichedField(f.locate, { groupName: 'Order' })],
            // Order
            [createEnrichedField(f.notionalAmount, { groupName: 'Order' })],
            [createEnrichedField(f.discretionaryType, { groupName: 'Order' })],
            [createEnrichedField(f.stopPrice, { groupName: 'Order' })],
            [createEnrichedField(f.customerNotes, { groupName: 'Order' })],
            [createEnrichedField(f.orderTags, { groupName: 'Order', colSpan: 3 })],
            [createEnrichedField(f.receivedTimestamp, { groupName: 'Details' })],
            [createEnrichedField(f.owner, { groupName: 'Details' })],
            [createEnrichedField(f.representativeCode, { groupName: 'Details' })],
            // Settle
            [createEnrichedField(f.preAllocationAccount, { groupName: 'Settle' })],
            [createEnrichedField(f.tradeCurrency, { groupName: 'Settle' })],
            // Stoped details,
            [createEnrichedField(f.stoppedQuantity, { groupName: 'Stoped details' })],
            [createEnrichedField(f.stoppedPrice, { groupName: 'Stoped details' })],
            [createEnrichedField(f.stoppedTime, { groupName: 'Stoped details' })]
          ],
          sx: {
            marginTop: 0
          }
        })
      ]
    };
  })
  .template('simple', {
    showSubmitButton: false,
    showResetButton: true,
    resetLabel: 'Reset bulk create'
  });

export type InvestorOrderBulkEntryContractType = typeof investorOrderBulkEntryContract;

export type InvestorOrderBulkEntryFormValues =
  InferFormValuesFromFormContract<InvestorOrderBulkEntryContractType>;
