import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { t } from '@oms/codegen/translations';

import { TableServerMappedExecutions } from '@oms/generated/frontend';
import { openViewExecution } from '@app/common/types/orders/execution/open.view.execution';

function isVisible(data: TableServerMappedExecutions[]) {
  return data.length === 1;
}

export const viewExecutionOnChange = async (
  ctx: ActionContext<TableServerMappedExecutions, ActionComponentConfig<TableServerMappedExecutions>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewExecution({
      windowId: getLeaderOrTabId(ctx.appContainer),
      dialogInfo: {
        id: data[0].id,
        side: data[0].side,
        price: data[0].executedPrice,
        quantity: data[0].executedQuantity,
        symbol: data[0].instrumentDisplayCode
      },
      componentProps: {
        id: data[0].id,
        executionId: data[0].id,
        allocationMapId: data[0].allocationMapId,
        tradeId: data[0].tradeId
      }
    });
  }
};

export const viewExecutionAction: ActionDefFactory<TableServerMappedExecutions> = (builder) =>
  builder
    .name('view_execution')
    .toolbar((builder) =>
      builder
        .component('action-button')
        .id('view_execution_button')
        .location('UserToolbar')
        .props({
          isDisabled: true,
          content: t('app.commands.viewExecution.button')
        })
    )
    .customMenu((m) =>
      m
        .name(t('app.commands.viewExecution.contextMenu'))
        .visible(({ rowData }) => isVisible(rowData))
        .tabName(t('app.common.grids.contextMenuTabs.action'))
        .priority(30)
    )
    .onChange<ActionComponentConfig<TableServerMappedExecutions>>(viewExecutionOnChange);
