import React from 'react';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import { useVGrid, VGrid, toClientsideGridData } from '@oms/frontend-vgrid';
import type { FieldProps, ICommonTextField } from '@oms/frontend-foundation';
import { useEnhancedFormApi } from '@oms/frontend-foundation';
import { useFormBuilderTemplate, useService } from '@oms/frontend-foundation';
import type { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { InvestorOrderBulkEntryService } from '@app/data-access/services/trading/investor-order-bulk-entry/investor-order-bulk-entry.service';
import { InvestorOrderBulkEntryColumnLibrary } from './investor-order-bulk-entry.grid.cols';
import type { InvestorOrderItem } from '../investor-order-bulk-entry.form-common';
import { copyOrderAction, deleteOrderAction } from './investor-order-bulk-entry.grid.actions';
import { InvestorOrderBulkEntryGridEventHander } from './investor-order-bulk-entry.grid.event-handler';
import type { InvestorOrderBulkEntryFormValues } from '../investor-order-bulk-entry.form-contract';
import { isErrorRowState, processDataFromClipboard } from './investor-order-bulk-entry.grid.utils';

export interface IInvestorOrderBulkEntryGrid<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.INVESTOR_ORDER_BULK_ENTRY, any[], TValidator> {}

export type InvestorOrderBulkEntryGridRow = InvestorOrderItem;

export const InvestorOrderBulkEntryGrid: React.FC<FieldProps<{}>> = () => {
  const { formId } = useFormBuilderTemplate();
  const formApi = useEnhancedFormApi<InvestorOrderBulkEntryFormValues>();
  const bulkIOEntryService = useService(InvestorOrderBulkEntryService);
  const source$ = bulkIOEntryService.watchAllWithValidation$(formId).pipe(toClientsideGridData());
  const gridProps = useVGrid<InvestorOrderBulkEntryGridRow>(
    'investor-order-bulk-entry-grid',
    (builder) =>
      builder
        .context({ formApiContext: formApi, formId })
        .columnLibrary(InvestorOrderBulkEntryColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(source$).rowId((r) => r.data.id || ''))
        .rowStateRules({
          error: (params) => isErrorRowState(params.data!)
        })
        .injectEvents([InvestorOrderBulkEntryGridEventHander])
        .editType('fullRow')
        .actions((a) => a.schema((s) => s.action(copyOrderAction).action(deleteOrderAction))),
    []
  );

  return (
    <VGrid
      {...gridProps}
      pinnedTopRowData={[bulkIOEntryService.getDefaultRowData()]}
      suppressCellFocus={false}
      suppressClickEdit={false}
      suppressColumnVirtualisation={true}
      suppressNoRowsOverlay={true}
      suppressLoadingOverlay={true}
      processDataFromClipboard={processDataFromClipboard}
      enableRangeSelection={true}
      suppressLastEmptyLineOnPaste={true}
    />
  );
};
