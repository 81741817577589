import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { OrderVwapFormFields } from './order-vwap.form-common';
import { instrument, investorOrderId, startTime, endTime, vwap } from './order-vwap.form-fields';

export const orderVwapFormContract = FormContract.create<OrderVwapFormFields>()
  .fields({
    investorOrderId,
    instrument,
    startTime,
    endTime,
    vwap
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'wrapper',
        [
          FieldDefinition.box(
            'instrument-dropdown',
            [FieldDefinition.box('instrument-wrapper', [f.instrument])],
            {
              sx: {
                marginLeft: 'small'
              }
            }
          ),
          FieldDefinition.box(
            'time-range-wrapper',
            [FieldDefinition.box('time-range-wrapper', [f.startTime])],
            {
              sx: {
                marginLeft: 'small'
              }
            }
          ),
          FieldDefinition.box('end-time-wrapper', [f.endTime], {
            sx: {
              marginLeft: 'small'
            }
          })
        ],
        {
          sx: {
            display: 'flex',
            flexDirection: 'row',
            margin: 'small'
          }
        }
      ),
      f.vwap
    ]
  }))
  .template('simple', {
    showSubmitButton: false,
    showFormFeedback: false,
    hideScrollbar: true,
    sx: {
      backgroundColor: 'layout.level2',
      overflow: 'hidden',
      p: 0,
      mx: '-2'
    }
  });

export type OrderVwapFormContractType = typeof orderVwapFormContract;

export type OrderVwapFormValues = InferFormValuesFromFormContract<OrderVwapFormContractType>;

export default orderVwapFormContract;
