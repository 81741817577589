import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { buildInstrumentsGridColmuns } from './instument-restrictions.columns';
import { useService } from '@oms/frontend-foundation';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import {
  JoinedInstrumentRestrictionRow,
  TableServerJoinedInstrumentRestriction,
  TsJoinedInstrumentRestrictionsWithFilterDocument,
  TsJoinedInstrumentRestrictionsWithFilterSubscription
} from '@oms/generated/frontend';
import { openEntryInstrumentRestriction } from './grid-actions/open-entry.instrument-restriction';
import { openModifyInstrumentRestriction } from './grid-actions/open-modify.instrument-restriction';

export const InstrumentsGridWidgetComponent = () => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<JoinedInstrumentRestrictionRow>(
    'instrument_restrictions',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildInstrumentsGridColmuns())
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<
                TableServerJoinedInstrumentRestriction,
                TsJoinedInstrumentRestrictionsWithFilterSubscription
              >({
                query: TsJoinedInstrumentRestrictionsWithFilterDocument,
                getData: (r) => r.tsJoinedInstrumentRestrictionsWithFilter
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .actions((a) =>
          a.schema((s) => s.action(openEntryInstrumentRestriction).action(openModifyInstrumentRestriction))
        )
        .sideBar()
        .reactiveCustomComponents(),
    [buildInstrumentsGridColmuns, datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default InstrumentsGridWidgetComponent;
