import { singleton, inject, container } from 'tsyringe';
import type { Observable } from 'rxjs';
import { BehaviorSubject, map } from 'rxjs';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { v4 as UUID } from 'uuid';
import { testScoped } from '@app/workspace.registry';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { InvestorOrderEntryService } from '@app/data-access/services/trading/investor-order-entry/investor-order-entry.service';
import type { InvestorOrderInput } from '@oms/generated/frontend';
import {
  FeedbackWrapper,
  FormValidationFeedbackLevel,
  OrderType,
  ResolveInstrumentSymbolToIdDocument,
  ResolveInstrumentSymbolToIdQuery,
  ResolveInstrumentSymbolToIdQueryVariables
} from '@oms/generated/frontend';
import { DataSourceCommon } from '@oms/frontend-foundation';
import type { InvestorOrderItem } from '@app/widgets/trading/investor-order-bulk-entry/investor-order-bulk-entry.form-common';
import { Maybe } from '@oms/shared/util-types';
import { InvestorOrderEntryOutput } from '@app/widgets/trading/investor-order-entry/investor-order-entry.form-common';
import { convertStringToNumber } from '@oms/shared/util';

const inputRowData = (): InvestorOrderItem => ({
  id: UUID(),
  sideType: null,
  quantity: null,
  instrument: null,
  limitPrice: null,
  validation: []
});

export const INITIAL_ORDERS_STATE: InvestorOrderItem[] = []; // inputRowData()s
export const INITIAL_VALIDATION_STATE: FeedbackWrapper[] = [];

@testScoped
@singleton()
export class InvestorOrderBulkEntryService {
  /**
   * To support multi-window functionality, we need to keep the state of each widget  with its orders in a Set
   * key: string - formId
   * value: BehaviorSubject<InvestorOrderItem[]>
   */
  private ordersState: Map<string, BehaviorSubject<InvestorOrderItem[]>>;
  /**
   * Validation state for each order. Shared between widgets
   * key: string - orderId
   * value: BehaviorSubject<FeedbackWrapper[]>
   */
  private validationState: Map<string, BehaviorSubject<FeedbackWrapper[]>>;

  constructor(
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(RxApolloClient) private apolloClient: RxApolloClient
  ) {
    this.ordersState = new Map();
    this.validationState = new Map();
  }
  /**
   * Local validation state
   */

  public updateValidationState(orderId: string, validation: FeedbackWrapper[]): void {
    this.getValidationState(orderId).next(validation);
  }

  /**
   * Local data store methods
   */

  public getDefaultRowData(): InvestorOrderItem {
    return inputRowData();
  }

  public getOrdersValue(formId: string): InvestorOrderItem[] {
    return this.getOrdersState(formId).getValue();
  }

  public getOrderById(formId: string, id: string): Maybe<InvestorOrderItem> {
    return this.getOrdersValue(formId).find((order) => order.id === id);
  }

  public watchAll$(formId: string): Observable<DataSourceCommon<InvestorOrderItem>> {
    return this.getOrdersState(formId)
      .asObservable()
      .pipe(map((orders) => ({ isFetching: false, results: orders })));
  }

  public watchAllWithValidation$(formId: string): Observable<DataSourceCommon<InvestorOrderItem>> {
    return this.getOrdersState(formId)
      .asObservable()
      .pipe(
        map((orders) => {
          const ordersWithValidation = orders.map((order) => {
            const validation = this.validationState.get(order.id)?.getValue() || [];
            return { ...order, validation };
          });
          return { isFetching: false, results: ordersWithValidation };
        })
      );
  }

  public setOrdersState(formId: string, orders: InvestorOrderItem[]) {
    this.getOrdersState(formId).next(orders);
  }

  public updateOrdersState(formId: string, orderId: string, order: Partial<InvestorOrderItem>): void {
    const currentOrders = this.getOrdersValue(formId);
    const updatedOrders = currentOrders.map((currentOrder) =>
      currentOrder.id === orderId ? { ...currentOrder, ...order } : currentOrder
    );

    this.getOrdersState(formId).next(updatedOrders);
  }

  /**
   * Update or add new order in the list of orders
   */
  public upsert(formId: string, order?: InvestorOrderItem): void {
    /*
    1. Check for ID
    2. If ID is not present, create a new order with  { ...inputRowData, ...order }
      2.1. Add the new order to the list of orders
    3. If ID is present, try to find the order in the list of orders
      3.1. If the order is not found, add the order to the list of orders
      3.2. If the order is found, update the order in the list of orders
    4. Generate diff based on the updated list of orders
    5. Save diff and update the list of orders
    */
    const currentOrders = this.getOrdersValue(formId);
    const updatedOrders: InvestorOrderItem[] = [];

    if ((order && order?.id === undefined) || order?.id === null) {
      updatedOrders.push({
        ...inputRowData(),
        ...order
      });
    } else {
      currentOrders.forEach((currentOrder) => {
        if (currentOrder.id === order?.id) {
          updatedOrders.push({
            ...currentOrder,
            ...order
          });
        } else {
          updatedOrders.push(currentOrder);
        }
      });
    }

    this.getOrdersState(formId).next(updatedOrders);
  }

  /**
   * Copy provided items and keep copies next to original items
   * @param ids
   */
  public copy(formId: string, ids: string[]): InvestorOrderItem[] {
    const updatedOrders = this.getOrdersState(formId)
      .getValue()
      .map((order: InvestorOrderItem) => {
        if (ids.includes(order.id)) {
          return [order, { ...order, id: UUID() }];
        } else {
          return order;
        }
      })
      .flat();

    this.getOrdersState(formId).next(updatedOrders);

    return updatedOrders;
  }

  /**
   * Delete provided items from Orders list
   * @param formId - Form ID
   * @param ids - List of IDs to delete
   */
  public delete(formId: string, ids: string[]): InvestorOrderItem[] {
    const currentOrders = this.getOrdersState(formId).getValue();
    const updatedOrders = currentOrders.filter((order) => !ids.includes(order.id));

    this.getOrdersState(formId).next(updatedOrders);

    return updatedOrders;
  }

  /**
   * Reset the list of orders.
   * @param formId - Form ID
   */
  public reset(formId: string): void {
    this.getOrdersState(formId).next(INITIAL_ORDERS_STATE);
  }

  /**
   * API methods
   */

  public async validateInstrument(formId: string, order: InvestorOrderItem): Promise<void> {
    if (order.instrument === undefined || order.instrument === null || order.instrument === '') {
      this.updateValidationState(order.id, [this.getInvalidEnvelopeFeedback('Instrument is required')]);
      return;
    }

    try {
      const instrumentId = await this.getInstrumentIdForSymbol(order.instrument);
      if (!instrumentId) {
        this.updateValidationState(order.id, [this.getInvalidEnvelopeFeedback('Instrument is required')]);
      } else {
        // TODO: Check if also update the instrument displayCode or add longName
        this.updateOrdersState(formId, order.id, {
          instrumentId
        });
        this.updateValidationState(order.id, []);
      }
    } catch (_error) {
      this.updateValidationState(order.id, [
        this.getInvalidEnvelopeFeedback('Failed to validate instrument')
      ]);
    }
  }

  /**
   * Validate the order.
   * @param accountId - Account ID.
   * @param order - The order to validate.
   * @param orderFields - Common order fields.
   * @returns Validated order.
   */
  public validateOrder(
    accountId: string,
    order: InvestorOrderItem,
    orderFields: Partial<InvestorOrderEntryOutput>
  ): Promise<InvestorOrderItem> {
    const ioEntrySvc = container.resolve(InvestorOrderEntryService);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const instrumentId = await this.getInstrumentIdForSymbol(order.instrument || '');
        if (!instrumentId) {
          resolve({
            ...order,
            validation: [
              // Owerwrite the feedback with latest validation
              this.getInvalidEnvelopeFeedback('Invalid instrument symbol')
            ]
          });
        } else if (!this.isOrderFullyFilled(order) || !accountId) {
          resolve({
            ...order
          });
        } else {
          const orderInput = {
            ...orderFields,
            investorAccount: { id: accountId },
            limitPrice: convertStringToNumber(order.limitPrice),
            instrument: { id: instrumentId },
            quantity: order.quantity,
            sideType: order.sideType,
            orderType: !convertStringToNumber(order.limitPrice) ? OrderType.Market : OrderType.Limit
          } as InvestorOrderInput;

          const result = await ioEntrySvc.create({ order: orderInput, charges: [] }, true);

          if (result.isSuccess()) {
            const response = result.value.data?.addOrdersWithCharges;

            if (response?.feedback) {
              resolve({
                ...order,
                instrumentId: instrumentId,
                validation: [...response.feedback]
              });
            } else {
              resolve({
                ...order,
                instrumentId: instrumentId
              });
            }
          } else if (result.isFailure()) {
            const errors = result.value;
            reject({
              ...order,
              validation: errors
            });
          }
        }
      } catch (_error) {
        resolve({
          ...order,
          validation: [this.getInvalidEnvelopeFeedback('Failed to validate order')]
        });
      }
    });
  }

  /**
   * Retrieves the instrument ID for a given instrument symbol.
   * Returns an empty string if the instrument is not found or there are multiple instruments in responce.
   * @param instrumentSymbol The symbol of the instrument.
   * @returns instrument ID as figi code as a string.
   */
  public async getInstrumentIdForSymbol(instrumentSymbol: string): Promise<string> {
    const queryResponse = await this.apolloClient.query<
      ResolveInstrumentSymbolToIdQuery,
      ResolveInstrumentSymbolToIdQueryVariables
    >({
      query: ResolveInstrumentSymbolToIdDocument,
      fetchPolicy: 'network-only',
      variables: {
        instrumentSymbol
      }
    });

    if (queryResponse?.data?.instrumentBySymbol && queryResponse.data.instrumentBySymbol.length === 1) {
      return queryResponse.data.instrumentBySymbol[0]?.id || '';
    }

    return '';
  }

  /**
   * Helper methods
   */

  private getOrdersState(formId: string): BehaviorSubject<InvestorOrderItem[]> {
    if (!this.ordersState.has(formId)) {
      this.ordersState.set(formId, new BehaviorSubject<InvestorOrderItem[]>(INITIAL_ORDERS_STATE));
    }

    return this.ordersState.get(formId) as BehaviorSubject<InvestorOrderItem[]>;
  }

  private getValidationState(orderId: string): BehaviorSubject<FeedbackWrapper[]> {
    if (!this.validationState.has(orderId)) {
      this.validationState.set(orderId, new BehaviorSubject<FeedbackWrapper[]>(INITIAL_VALIDATION_STATE));
    }

    return this.validationState.get(orderId) as BehaviorSubject<FeedbackWrapper[]>;
  }

  private getInvalidEnvelopeFeedback(message?: string): FeedbackWrapper {
    return {
      __typename: 'FeedbackWrapper',
      code: 'CLIENT_failed-validation',
      message: message || 'Invalid instrument symbol',
      level: FormValidationFeedbackLevel.Error
    };
  }

  /**
   * Check if the order is fully filled.
   * @param order - The order to check.
   * @returns True if the order is fully filled, false otherwise.
   */
  private isOrderFullyFilled(order: InvestorOrderItem): boolean {
    return !!(order.sideType && order.quantity && order.instrument);
  }
}
