import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { FoundationWorkspace } from '@oms/frontend-foundation';
import { useCallback } from 'react';
import { openApplySettlementFxRate } from '@app/generated/sdk';

export const openApplySettlementFxRateOnChange = (
  workspace: FoundationWorkspace,
  executionId: string,
  orderId: string
) => {
  openApplySettlementFxRate(workspace.getLeaderProcessId(), {
    windowId: `apply-settlement-fx-rate-${executionId}`,
    form: {
      formId: `apply-settlement-fx-rate-${executionId}`,
      input: {
        orderId,
        executionId
      }
    },
    title: `Apply FX Rate: ${executionId}`
  }).catch((e) => {
    openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
    console.error(e);
  });
};

export const useOpenApplySettlementFxRate = (executionId: string, orderId: string) => {
  const workspace = useAppWorkspace();
  return useCallback(
    () => openApplySettlementFxRateOnChange(workspace, executionId, orderId),
    [workspace, orderId, executionId]
  );
};
