import type { Validator } from '@data-driven-forms/react-form-renderer';
import type {
  FORM_COMPONENT_TYPE,
  FORM_COMPONENT_TYPE as FOUNDATION_FORM_COMPONENT_TYPE,
  ICommonField,
  ICommonSelectField
} from '@oms/frontend-foundation';
import type { LayoutAction, DefinedColors } from '../types';
import type { Action } from '@app/actions/types/action.types';
import type { FieldOption, IDisplayField } from '@oms/frontend-foundation';
import { COMMANDS_TO_META } from '@app/actions/commands/command.registry.types';
import type { CommandMeta, ActionCommands } from '@app/actions/commands/command.registry.types';
import { t } from '@oms/codegen/translations';

export const actionButtonSizeOptions = (): FieldOption<Action['size']>[] => [
  { label: 'XS', value: 'XS' },
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  { label: 'XL', value: 'XL' }
];

export const actionButtonColorOptions = (): FieldOption<DefinedColors>[] => [
  { label: 'Blue', value: 'Blue' },
  { label: 'Light Blue', value: 'LightBlue' },
  { label: 'Dark Blue', value: 'DarkBlue' },
  { label: 'Red', value: 'Red' },
  { label: 'Grey', value: 'Secondary' }
];

export const ACTION_COMMANDS_TO_LABELS: Record<ActionCommands, string | (() => string)> = {
  accept_investor_order: () => t('app.commands.acceptInvestorOrder.selectOptionLabel'),
  cancel_investor_order: () => t('app.commands.cancelInvestorOrder.selectOptionLabel'),
  create_investor_order: () => t('app.commands.createInvestorOrder.selectOptionLabel'),
  create_unbound_to: () => t('app.commands.createUnboundTo.selectOptionLabel'),
  done_for_day: () => t('app.commands.doneForDay.selectOptionLabel'),
  modify_investor_order: () => t('app.commands.modifyInvestorOrder.selectOptionLabel'),
  montage_bump_price: () => t('app.commands.montageBumpPrice.selectOptionLabel'),
  montage_target_trading_order: () => t('app.commands.montageTargetTradingOrder.selectOptionLabel'),
  reject_investor_order: () => t('app.commands.rejectInvestorOrder.selectOptionLabel'),
  route_order: () => t('app.commands.route.selectOptionLabel'),
  view_investor_order: () => t('app.commands.viewInvestorOrder.selectOptionLabel')
};

export const GET_ACTION_LABELS_TO_COMMANDS = (): Record<string, ActionCommands> =>
  Object.entries(ACTION_COMMANDS_TO_LABELS).reduce(
    (result, [commandId, label]) => {
      result[typeof label === 'function' ? label() : label] = commandId as ActionCommands;
      return result;
    },
    {} as Record<string, ActionCommands>
  );

export const getCommandFromLabel = (label: string) => GET_ACTION_LABELS_TO_COMMANDS()[label];

export const actionButtonCommands = (): FieldOption<ActionCommands>[] =>
  Object.entries(ACTION_COMMANDS_TO_LABELS).map(([commandId, label]) => ({
    label: typeof label === 'function' ? label() : label,
    value: commandId as unknown as ActionCommands
  }));

export const buttonLayoutActionSchema: (
  fieldName: string,
  values: LayoutAction
) => (
  | ICommonField<
      | (typeof FORM_COMPONENT_TYPE)[keyof typeof FORM_COMPONENT_TYPE]
      | (typeof FOUNDATION_FORM_COMPONENT_TYPE)[keyof typeof FOUNDATION_FORM_COMPONENT_TYPE],
      any,
      Validator
    >
  | IDisplayField<Validator>
  | ICommonSelectField<
      | (typeof FORM_COMPONENT_TYPE)[keyof typeof FORM_COMPONENT_TYPE]
      | (typeof FOUNDATION_FORM_COMPONENT_TYPE)[keyof typeof FOUNDATION_FORM_COMPONENT_TYPE]
    >
)[] = (fieldName, values) => {
  const defaultCommandMeta: CommandMeta = {
    allowsConfirmation: true,
    allowsLabel: true,
    allowsColor: true,
    allowsSize: true,
    commandType: 'DialogWithDefaults'
  };

  const isVertical = values.locationId.includes('Vert');

  const commandMeta: CommandMeta = values.commandId
    ? COMMANDS_TO_META[getCommandFromLabel(values.commandId)] || defaultCommandMeta
    : defaultCommandMeta;

  const defaultDisplay: ReturnType<typeof buttonLayoutActionSchema> = [
    {
      component: 'display-field',
      name: `${fieldName}.label`,
      displayFieldComponentProps: {
        component: {
          type: 'Text',
          value: values.label
        },
        label: 'Label:'
      }
    },
    {
      component: 'display-field',
      name: `${fieldName}.command`,
      displayFieldComponentProps: {
        component: {
          type: 'Text',
          value: values.commandId
        },
        label: 'Command:'
      }
    },
    {
      component: 'select',
      name: `${fieldName}.size`,
      initialValue: values.size,
      options: actionButtonSizeOptions(),
      hideFormControls: true,
      style: { marginTop: 0 }
    },
    {
      component: 'select',
      name: `${fieldName}.color`,
      initialValue: values.color,
      options: actionButtonColorOptions(),
      hideFormControls: true,
      style: { marginTop: 0 }
    }
  ];

  return defaultDisplay.filter((d) => {
    const isLabelField = d.name.includes('label');
    const allowsLabel = isLabelField && commandMeta.allowsLabel;

    const isSizeField = d.name.includes('size');
    const allowsSize = isSizeField && commandMeta.allowsSize;

    const isColorField = d.name.includes('color');
    const allowsColor = isColorField && commandMeta.allowsColor;

    return (
      (isLabelField && allowsLabel) ||
      (isSizeField && allowsSize && !isVertical) ||
      (isColorField && allowsColor) ||
      (!isLabelField && !isColorField && !isSizeField)
    );
  });
};
