import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  AuditTrailInfoFragment,
  CancelTradeMutation,
  CancelTradeMutationVariables,
  CancelTradesInput,
  CancelTradesMutation,
  CancelTradesMutationVariables,
  GetTradeQuery,
  GetTradeQueryVariables,
  GetTradesByTradingOrderIdQuery,
  GetTradesByTradingOrderIdQueryVariables,
  ModifyTradeMutation,
  ModifyTradeMutationVariables,
  TradeInfoFragment,
  TradePatch,
  GetAuditStreamForTradeQueryVariables,
  GetAuditStreamForTradeQuery
} from '@oms/generated/frontend';
import {
  CancelTradeDocument,
  CancelTradesDocument,
  GetTradeDocument,
  GetTradesByTradingOrderIdDocument,
  ModifyTradeDocument,
  GetAuditStreamForTradeDocument
} from '@oms/generated/frontend';
import { map, Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';
import { TradeAuditTrail } from '@app/common/types/orders/orders.types';
import User from '@app/common/types/users/user.class';

@testScoped
@singleton()
export class TradesService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public watchAll$(tradingOrderId?: string, pollInterval = 5000) {
    return this.apolloClient
      .rxWatchQuery<GetTradesByTradingOrderIdQuery, GetTradesByTradingOrderIdQueryVariables>({
        query: GetTradesByTradingOrderIdDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradingOrderId ?? ''
        }
      })
      .pipe(
        map((res) => {
          const tradesResults = (res.data.tradingOrder?.trades?.nodes || []).filter(
            (t) => !!t
          ) as TradeInfoFragment[];
          return tradesResults;
        })
      );
  }

  public getById(tradeId: string) {
    return this.gqlResponse
      .wrapQuery<GetTradeQuery, GetTradeQueryVariables>({
        query: GetTradeDocument,
        variables: {
          id: tradeId
        }
      })
      .exec();
  }

  public cancel(tradeId: string) {
    const mutation = this.gqlResponse.wrapMutate<CancelTradeMutation, CancelTradeMutationVariables>({
      mutation: CancelTradeDocument,
      variables: {
        id: tradeId
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public cancelTrades(input: CancelTradesInput) {
    const mutation = this.gqlResponse.wrapMutate<CancelTradesMutation, CancelTradesMutationVariables>({
      mutation: CancelTradesDocument,
      variables: {
        input
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public modify(input: TradePatch, dryRun?: boolean) {
    const mutation = this.gqlResponse.wrapMutate<ModifyTradeMutation, ModifyTradeMutationVariables>({
      mutation: ModifyTradeDocument,
      variables: { modification: input, dryRun }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  protected _watchQuery_GetAuditStreamForTradeQuery$(
    tradeId: string,
    pollInterval = 5000
  ): Observable<AuditTrailInfoFragment[]> {
    return this.apolloClient
      .rxWatchQuery<GetAuditStreamForTradeQuery, GetAuditStreamForTradeQueryVariables>({
        query: GetAuditStreamForTradeDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: tradeId
        }
      })
      .pipe(
        map(
          (res) =>
            (res.data.getAuditStreamForTrade as AuditTrailInfoFragment[]) || ([] as AuditTrailInfoFragment[])
        )
      );
  }

  public getAuditTrail$(tradeId: string): Observable<TradeAuditTrail[]> {
    return this._watchQuery_GetAuditStreamForTradeQuery$(tradeId).pipe(
      map((results) => {
        return results.map((r): TradeAuditTrail => {
          return {
            id: `${r.aggregateId}-${r.aggregateVersion}`,
            eventTimestamp: r.timestamp,
            event: r.friendlyEventName || undefined,
            eventDetails: r.payload,
            bidPrice: r?.marketdataSnapshot?.bidPrice || undefined,
            askPrice: r?.marketdataSnapshot?.askPrice || undefined,
            lastTradePrice: r?.marketdataSnapshot?.lastTradePrice || undefined,
            cumulativeVolume: r?.marketdataSnapshot?.cumulativeVolume || undefined,
            user: r?.user ? User.extend(r?.user) : undefined
          };
        });
      })
    );
  }
}
