/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActorSchema,
  AnyRecord,
  CommonWindowActorSchemaOptions,
  WindowContext,
  WindowOperations
} from '@valstro/workspace';

export const POPOVER_DEFAULT_BUFFER_SIZE = 3;

/**
 * Common Popover Contracts
 */
export const COMMON_POPOVER = {
  ID_BASE: 'popover-window',
  getId: (winId: string, index: number) => `${winId}-popover-window-${index}`, // Keep same ID to prevent multiple windows
  TYPE: 'popover-window'
} as const;

export type TriggerType = 'click' | 'hover' | 'none';

export type ClosingStrategy =
  | 'click-outside'
  | 'click-outside-ex-trigger'
  | 'click-anywhere'
  | 'hover-outside'
  | 'none';

export type PopoverContext<TMeta extends AnyRecord = AnyRecord> = WindowContext<TMeta> & {
  windowId: string | null;
  popoverId: string | null;
  componentId: string | null;
  componentProps: AnyRecord;
  trigger: TriggerType | null;
  closingStrategy: ClosingStrategy | null;
  isDecorated: boolean | null;
  focusOnOpen: boolean | null;
  enterDelay: number | null;
  exitDelay: number | null;
  ariaLabel: string;
  popoverTestId: string | null;
  autoSizeHeight: boolean | null;
  autoSizeWidth: boolean | null;
  autoSizeWidthToTrigger: boolean | null;
  /**
   * The sensitivity of the resize trigger. If set to more than 0, the resize will only trigger if the
   * new size difference is more than the sensitivity value.
   */
  autoSizeTriggerResizePixelSensitivity?: number;
  isOpen: boolean;
  renderedSide: OpenPopoverBoundsOptions['side'];
  renderedAlign: OpenPopoverBoundsOptions['align'];
  autoSizePaddingWidth?: number;
  autoSizePaddingHeight?: number;
};

export type PopoverUpdateContext<TMeta extends AnyRecord = AnyRecord> = Partial<
  Pick<PopoverContext<TMeta>, 'componentProps'>
>;

export const EMPTY_POPOVER_CTX: Partial<PopoverContext> = {
  windowId: null,
  componentId: null,
  componentProps: {},
  popoverId: null,
  isDecorated: true,
  focusOnOpen: false,
  trigger: null,
  closingStrategy: null,
  enterDelay: null,
  exitDelay: null,
  ariaLabel: '',
  autoSizeHeight: null,
  autoSizeWidth: null,
  autoSizeWidthToTrigger: null,
  isOpen: false,
  renderedSide: null,
  renderedAlign: null,
  autoSizePaddingWidth: 0,
  autoSizePaddingHeight: 0,
  popoverTestId: null,
  autoSizeTriggerResizePixelSensitivity: 0
};

export const TAURI_POPOVER_WIN_CTX: Partial<PopoverContext> = {
  isClosable: false,
  isDecorated: false,
  alwaysOnTop: true,
  isVisible: false,
  transparent: true,
  isResizable: false,
  isMaximizable: false,
  isMinimizable: false,
  isPinnable: false,
  skipTaskbar: true,
  x: 20_000,
  y: 20_000
};

export type OpenPopoverBoundsOptions = {
  alignOffset?: number;
  sideOffset?: number;
  side?: 'top' | 'bottom' | 'left' | 'right' | null;
  align?: 'center' | 'end' | 'start' | null;
  width?: number;
  minWidth?: number;
  height?: number;
  minHeight?: number;
};

export type OpenPopoverBounds = {
  rectWithScaling: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  innerPosition: {
    x: number;
    y: number;
  };
} & OpenPopoverBoundsOptions;

export type OpenPopoverOptions<T extends AnyRecord = AnyRecord> = {
  windowId: string;
  popoverId: string;
  componentId: string;
  componentProps?: T;
  transparent?: boolean;
  focusOnOpen?: boolean;
  trigger?: TriggerType;
  closingStrategy?: ClosingStrategy;
  enterDelay?: number;
  exitDelay?: number;
  ariaLabel: string;
  popoverTestId?: string;
  autoSizeHeight?: boolean;
  autoSizeWidth?: boolean;
  autoSizeWidthToTrigger?: boolean;
  autoSizePaddingWidth?: number;
  autoSizePaddingHeight?: number;
  autoSizeTriggerResizePixelSensitivity?: number;
} & OpenPopoverBounds;

export const DEFAULT_POPOVER_OPTIONS: Required<
  Omit<OpenPopoverOptions, 'autoSizePaddingWidth' | 'autoSizePaddingHeight' | 'popoverTestId'>
> = {
  componentId: '',
  innerPosition: {
    x: 0,
    y: 0
  },
  popoverId: '',
  rectWithScaling: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  },
  windowId: '',
  align: 'center',
  alignOffset: 0,
  ariaLabel: 'Popover',
  autoSizeHeight: false,
  autoSizeWidth: false,
  autoSizeWidthToTrigger: false,
  autoSizeTriggerResizePixelSensitivity: 0,
  closingStrategy: 'click-outside',
  componentProps: {},
  focusOnOpen: false,
  width: 200,
  minWidth: 0,
  minHeight: 0,
  height: 300,
  enterDelay: 300,
  exitDelay: 700,
  transparent: true,
  side: 'bottom',
  sideOffset: 0,
  trigger: 'click'
};

export type PositionPopover = Omit<
  OpenPopoverOptions,
  'popoverId' | 'componentId' | 'componentProps' | 'enterDelay' | 'exitDelay' | 'trigger'
>;

export type PopoverOpenedBounds = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type PopoverOperations<TMeta extends AnyRecord = AnyRecord> = WindowOperations<TMeta> & {
  openPopover: (options: OpenPopoverOptions) => Promise<PopoverOpenedBounds>;
  discardPopover: () => Promise<void>;
  getMousePosition: () => Promise<{ x: number; y: number }>;
  updateContext: (ctx: PopoverUpdateContext<TMeta>) => Promise<void>;
  setComponentProps: (ctx: TMeta) => Promise<void>;
  updateComponentProps: (ctx: Partial<TMeta>) => Promise<void>;
};

export type PopoverActorComponents<TViewType = any> = Record<string, TViewType>;

export type PopoverActorSchemaOptions<TCompView = any> = CommonWindowActorSchemaOptions & {
  componentsMap: PopoverActorComponents<TCompView>;
};

export type CommonPopoverActorSchema = ActorSchema<
  PopoverContext<AnyRecord>,
  PopoverOperations<AnyRecord>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  PopoverActorSchemaOptions<any>
>;
