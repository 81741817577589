import { CommissionType, RateType } from '@oms/generated/frontend';
import { rateTypeMapping } from './order-enums';

export const mapRateType = (value?: RateType | string | null, defaultValue: string = value || ''): string => {
  return value && rateTypeMapping[value as RateType] ? rateTypeMapping[value as RateType] : defaultValue;
};

export const mapStringToRateType = (value?: string): RateType => {
  switch (value) {
    case 'Basis Point':
      return RateType.BasisPoint;
    case 'Cost Plus':
      return RateType.CostPlus;
    case 'Flat':
      return RateType.Flat;
    case 'Per Unit':
      return RateType.PerUnit;
    case 'Scale':
      return RateType.Scale;
    default:
      return RateType.Percentage;
  }
};

export const mapRateTypeToCommissionType = (value?: RateType): CommissionType | undefined => {
  switch (value) {
    case RateType.BasisPoint:
      return CommissionType.BasisPoints;
    case RateType.Flat:
      return CommissionType.Absolute;
    case RateType.PerUnit:
      return CommissionType.AmountPerUnit;
    case RateType.Percentage:
      return CommissionType.Percent;
    default:
      return undefined;
  }
};

export const mapCommissionTypeToRateType = (value: CommissionType): RateType => {
  switch (value) {
    case CommissionType.Absolute:
      return RateType.Flat;
    case CommissionType.AmountPerUnit:
      return RateType.PerUnit;
    case CommissionType.BasisPoints:
      return RateType.BasisPoint;
    default:
      return RateType.Percentage;
  }
};
