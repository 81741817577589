import { FieldContract } from '@oms/frontend-foundation';
import type { OrderVwapFormFields } from './order-vwap.form-common';
import { createFollowSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { makeInstrumentCommonProps } from '@app/forms/common/fields/shared-field-props';

export const orderVwapFieldContract = FieldContract.create<OrderVwapFormFields>();

export const investorOrderId = orderVwapFieldContract.field('investorOrderId', 'hidden-field');

export const instrument = createFollowSymbolField(orderVwapFieldContract, 'instrument', {
  ...makeInstrumentCommonProps({
    queryType: 'simple',
    label: { suppressLabel: true }
  }),
  isReadOnly: false,
  hideFormControls: false,
  toggleStateSource: 'order_vwap',
  sourceTypes: ['investor-order-monitor']
});

export const startTime = orderVwapFieldContract.field('startTime', 'native-time-picker').options({
  label: 'Start Time'
});
export const endTime = orderVwapFieldContract.field('endTime', 'native-time-picker').options({
  label: 'End Time'
});

export const vwap = orderVwapFieldContract.field('vwap', 'hidden-field');

export default orderVwapFieldContract;
