import { FilterOffIcon, ClearFilterIcon } from '@oms/frontend-foundation';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { ActionDefFactory } from '../models/actions.model';
import { t } from '@oms/codegen/translations';

export const CLEAR_GRID_FILTER_ACTION_TYPE = 'clear-grid-filter';

export function createClearGridFilterAction<TData extends AnyRecord>(): ActionDefFactory<TData> {
  return (b) =>
    b
      .name('clear_grid_filters')
      .toolbar((t) => t.component('action-icon-button').id('clear_filter_button').location('StaticToolbar'))
      .customMenu((m) =>
        m
          .name('Clear filters')
          .visible(({ gridApi }) => {
            const anyFiltersPresent = !!gridApi.isAnyFilterPresent();
            return anyFiltersPresent;
          })
          .priority(1_000)
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<AnyRecord>((e) => {
        const filtersPresent = e.api.isAnyFilterPresent();
        const icon = filtersPresent ? <ClearFilterIcon /> : <FilterOffIcon />;

        e.notify({
          icon
        });

        if (filtersPresent && e.lifecycle === 'change') {
          e.api.setFilterModel(null);
          e.api.onFilterChanged();
        }
      });
}

export const CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE = 'custom-menu-clear-grid-filter';

export function createCustomMenuClearGridFilterAction<TData extends AnyRecord>(): ActionDefFactory<TData> {
  return (b) =>
    createClearGridFilterAction<TData>()(b).customMenu((m) =>
      m
        .name('Clear filters')
        .visible(({ gridApi }) => {
          const anyFiltersPresent = !!gridApi.isAnyFilterPresent();
          return anyFiltersPresent;
        })
        .priority(1_000)
        .tabName(t('app.common.grids.contextMenuTabs.action'))
    );
}
