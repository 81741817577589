import type { ResolvePropsFunction } from '@data-driven-forms/react-form-renderer';
import type { ICommonTextField } from '@oms/frontend-foundation';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import type { RouteOrderFieldContract } from '@app/widgets/trading/route-order/route-order.form-common';

type RouteOrderAutoFocusField = keyof Pick<RouteOrderFieldContract, 'instrument' | 'quantity'>;

type CreatorFn<R> = (fieldType: RouteOrderAutoFocusField) => R;

export const getRouteOrderAutoFocusActionKey: CreatorFn<string> = (fieldType) =>
  `routeOrderAutoFocus_${fieldType}`;

export const createRouteOrderAutoFocusAction: CreatorFn<() => ResolvePropsFunction> =
  (fieldType) =>
  () =>
  (_props, _fieldApi, formOptions): Partial<ICommonTextField<any>> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenMode } = formValues || {};
    const { type = 'create' } = hiddenMode || {};

    switch (fieldType) {
      case 'instrument':
        return {
          autoFocus: type === 'create'
        };
      case 'quantity':
        return {
          autoFocus: type !== 'create'
        };
      default:
        return {};
    }
  };
