import { FormSummary } from '@oms/frontend-foundation';
import React, { useMemo } from 'react';
import type { ExecutionRow } from '@oms/generated/frontend';
import { executionSummaryData, ExecutionSummaryFieldName } from '@app/common/summaries/execution-fields';

const executionSummaryFields: ExecutionSummaryFieldName[] = [
  'capacity',
  'trader',
  //'tradeTags', add after TKT-12784 is resolved
  'rawLiquidityIndicator',
  'lastMarket',
  'investorOrderId',
  'tradeId',
  'id'
];
const executionSettleFields: ExecutionSummaryFieldName[] = ['settleType', 'settleCurrency', 'settleFxRate'];
export const ExecutionDetailsSummary = React.memo(({ execution }: { execution: ExecutionRow }) => {
  const data = useMemo(() => {
    const sections = [
      executionSummaryData('SUMMARY', executionSummaryFields, execution),
      executionSummaryData('SETTLE', executionSettleFields, execution)
    ];
    return sections;
  }, [execution]);

  return <FormSummary labeledData={data} />;
});
