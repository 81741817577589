import {
  useVGrid,
  VGrid,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { buildtradeViewTradingOrdersColumnDefs } from './trade-view.trading-order.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { viewTradingOrderAction } from '../../trading-order-monitor/grid-actions/view.trading-order.action';
import { modifyTradingOrderAction } from '../../trading-order-monitor/grid-actions/modify.trading-order.action';
import { cancelTradingOrderAction } from '../../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { TOHasExecutedQuantity, TONoExecutedQuantity, TOPending } from '../../utils/row-state-rule-utils';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TsTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type { TradingOrderRow, TsTradingOrdersWithFilterSubscription } from '@oms/generated/frontend';

export const TradeViewTradingOrderGrid = ({ id }: { id: string }) => {
  const datasourceService = useService(TableServerDatasourceService);

  const props = useVGrid<TradingOrderRow>(
    'trade-view-trading-order',
    (b: GridBuilder<TradingOrderRow>) =>
      b
        .tableServerColumnLibrary(buildtradeViewTradingOrdersColumnDefs())
        .sideBar()
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TradingOrderRow, TsTradingOrdersWithFilterSubscription>({
                query: TsTradingOrdersWithFilterDocument,
                getData: (r) => r.tsTradingOrdersWithFilter,
                filter: {
                  id: {
                    filterType: 'text',
                    type: 'equals',
                    filter: id
                  }
                }
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .injectEvents([GridConfigEventHandler])
        .rowStateRules({
          pending: (params) => TOPending(params.data),
          noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data)
        })
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a.schema((s) =>
            s
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(viewTradingOrderAction())
              .action(modifyTradingOrderAction())
              .action(cancelTradingOrderAction())
              .action(ROW_SELECTION_COUNT_ACTION_TYPE)
          )
        )
        .reactiveCustomComponents(),
    [datasourceService, id]
  );
  return <VGrid {...props} />;
};

export default TradeViewTradingOrderGrid;
