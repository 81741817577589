import React, { useCallback, useRef, useEffect } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons/icons';
import { Button } from '../button/button';
import { throttle } from 'lodash';
import * as styles from './fixed-sidebar-scroll.css';

export interface FixedSidebarScrollItem {
  name: string;
  title: string;
  menuLabel: string;
  component: React.ReactNode;
}

export interface RenderMenuItemProps {
  item: FixedSidebarScrollItem;
  elProps: {
    key: string;
    ['data-menu-name']: string;
    ['data-state']: string;
    className: string;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  };
}

export interface FixedSidebarScrollProps {
  items: FixedSidebarScrollItem[];
  showContentTitles?: boolean;
  finalActionsComponent?: React.ReactNode;
  actionsComponent?: React.ReactNode;
  showNavButtons?: boolean;
  areItemsFullHeight?: boolean;
  onMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, itemName: string) => void;
  onNext?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onPrev?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  renderMenuItem?: (item: RenderMenuItemProps) => React.ReactNode;
}

export const FixedSidebarScroll = React.forwardRef<HTMLDivElement, FixedSidebarScrollProps>(
  (
    {
      items,
      showContentTitles = true,
      showNavButtons = true,
      areItemsFullHeight = true,
      finalActionsComponent,
      actionsComponent,
      onNext = () => {},
      onMenu = () => {},
      onPrev = () => {},
      renderMenuItem = ({ item, elProps }: RenderMenuItemProps) => <div {...elProps}>{item.menuLabel}</div>
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleScrollToContent = useCallback(
      (_e: React.MouseEvent<HTMLElement, MouseEvent>, name: string) => {
        const contentItemEl = containerRef.current?.querySelector(`[data-content-name="${name}"]`);

        if (contentItemEl && containerRef.current) {
          containerRef.current.scrollTop = (contentItemEl as HTMLElement).offsetTop;
        }
      },
      []
    );

    useEffect(() => {
      const handleScroll = throttle(() => {
        const contentItemEls = containerRef.current?.querySelectorAll('[data-content-name]');
        if (!contentItemEls) {
          return;
        }
        for (const contentItemEl of contentItemEls) {
          const contentItemName = contentItemEl.getAttribute('data-content-name');
          const menuItemEl = menuRef.current?.querySelector(`[data-menu-name="${contentItemName}"]`);

          if (contentItemName && menuItemEl) {
            const scrollTop = containerRef.current?.scrollTop || 0;
            const clientHeight = containerRef.current?.clientHeight || 0;
            const viewportTop = scrollTop;
            const viewportBottom = viewportTop + clientHeight;

            const itemHeight = contentItemEl.clientHeight;
            const itemTop = (contentItemEl as HTMLElement).offsetTop;
            const itemBottom = itemTop + itemHeight;

            const topIntersectPerc = ((itemBottom - viewportTop) / itemHeight) * 100;
            const bottomIntersectPerc = ((viewportBottom - itemTop) / itemHeight) * 100;

            let useIntersect = null;

            // If the item is intersecting from the top OR both intersects are 100%
            // then we use the top intersect percentage
            if (
              (topIntersectPerc >= 0 && topIntersectPerc <= 100) ||
              (topIntersectPerc === 100 && bottomIntersectPerc === 100)
            ) {
              useIntersect = topIntersectPerc;
            }

            // If the item is intersecting from the bottom, use the bottom intersect percentage
            if (bottomIntersectPerc >= 0 && bottomIntersectPerc <= 100) {
              useIntersect = bottomIntersectPerc;
            }

            // Select the menu item that has the majority of the item in view (over 50%)
            if (useIntersect && useIntersect > 50) {
              menuItemEl.setAttribute('data-state', 'active');
            } else {
              // Else remove it
              menuItemEl.removeAttribute('data-state');
            }
          }
        }
      }, 200);

      containerRef.current?.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        containerRef.current?.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }, [containerRef]);

    const onNextClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, currentItemIndex: number) => {
        const nextItem = items[currentItemIndex + 1];
        if (nextItem) {
          handleScrollToContent(e, nextItem.name);
          onNext(e);
        }
      },
      [items, handleScrollToContent, onNext]
    );

    const onPrevClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, currentItemIndex: number) => {
        const prevItem = items[currentItemIndex - 1];
        if (prevItem) {
          onPrev(e);
          handleScrollToContent(e, prevItem.name);
        }
      },
      [items, handleScrollToContent, onPrev]
    );

    const onMenuClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>, itemName: string) => {
        e.preventDefault();
        onMenu(e, itemName);
        handleScrollToContent(e, itemName);
      },
      [handleScrollToContent, onMenu]
    );

    return (
      <div className={styles.wrapper} ref={ref}>
        <div style={{ flexBasis: 175 }} className={styles.menu} ref={menuRef}>
          {items.map((item) =>
            renderMenuItem({
              item,
              elProps: {
                key: `fss-menu-${item.name}`,
                [`data-menu-name`]: item.name,
                [`data-state`]: '',
                className: styles.menuItem,
                onClick: (e) => onMenuClick(e, item.name)
              }
            })
          )}
        </div>
        <div ref={containerRef} className={styles.content}>
          {items.map((item, index) => (
            <div
              data-content-name={item.name}
              key={`fss-content-${item.name}`}
              className={styles.contentItem}
              style={{ minHeight: areItemsFullHeight ? '100%' : undefined }}
            >
              <div className={styles.contentItemInner}>
                {showContentTitles && <div className={styles.contentTitle}>{item.title}</div>}
                <div className={styles.contentComponent}>{item.component}</div>

                <div className={styles.contentActions}>
                  {showNavButtons && (
                    <div>
                      <Button
                        type="button"
                        variant="secondary"
                        isTabbable={true}
                        sx={{
                          mr: 2
                        }}
                        leftIcon={<ArrowLeftIcon />}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onPrevClick(e, index)}
                        isDisabled={index === 0}
                      >
                        Previous
                      </Button>
                      {index === items.length - 1 && finalActionsComponent ? (
                        finalActionsComponent
                      ) : (
                        <Button
                          type="button"
                          isTabbable={true}
                          rightIcon={<ArrowRightIcon />}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => onNextClick(e, index)}
                          onKeyDown={(e: any) => {
                            if (e.disabled) {
                              return;
                            }
                            e.preventDefault();
                            if (e.key === 'Tab' || e.key === 'Enter') {
                              onNextClick(e, index);
                            }
                          }}
                          isDisabled={index === items.length - 1}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  )}
                  <div>
                    {actionsComponent}
                    {!showNavButtons &&
                      finalActionsComponent &&
                      index === items.length - 1 &&
                      finalActionsComponent}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);
