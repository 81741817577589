import type { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { DragItem } from '../drag-item/drag-item';
import { Box, Grid, GridItem, IconButton, Text } from '@oms/shared-frontend/ui-design-system';
import { CheckIcon, CopyIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { DragItemTypes } from '../types';
import type { DragNDrop, OnDelete } from '../types';
import { onPreventDefault } from '../utils/on.prevent.default';
import { useCopyAction } from '../action-button-layout/action-button-layout.copy-field.hook';
import type { ActionButtonFormInput } from './action-button.contracts';

export type ActionButtonProps = {
  onEdit: MouseEventHandler<HTMLElement>;
  inGroup?: boolean;
  action: ActionButtonFormInput;
  fieldId: string;
} & OnDelete &
  Omit<DragNDrop, 'type'>;

export const ActionButton: FC<PropsWithChildren<ActionButtonProps>> = ({
  onDelete,
  onEdit,
  onDrag,
  onDrop,
  inGroup,
  children,
  action,
  fieldId
}) => {
  // Handles copy for ALL actions
  const [onCopy, hasCopied] = useCopyAction();
  return (
    <DragItem
      onDrag={onDrag}
      onDrop={onDrop}
      iconContainer={{ sx: { backgroundColor: inGroup ? 'layout.level1' : 'layout.level2' } }}
      type={DragItemTypes.Action}
    >
      <Box
        sx={{
          backgroundColor: 'layout.level1',
          display: 'flex',
          flexDirection: 'column',
          width: 'full',
          padding: inGroup ? 0 : 1
        }}
      >
        {!inGroup && (
          <Text as={'div'} sx={{ backgroundColor: 'layout.level1', padding: 4 }} type="mediumB">
            Action
          </Text>
        )}
        <Grid
          columns={12}
          gap={0}
          sx={{
            width: 'full',
            backgroundColor: 'layout.level2',
            alignItems: 'center',
            borderRadius: 'xs',
            paddingY: 3,
            paddingX: 4
          }}
        >
          <GridItem
            colSpan={10}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignSelf: 'center',
              alignItems: 'center'
            }}
          >
            {children}
          </GridItem>
          <GridItem
            colSpan={2}
            gap={0}
            sx={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'flex-end' }}
          >
            <IconButton
              aria-label="copy-action"
              data-testid={`copy-action-${fieldId}`}
              isRound={true}
              sx={{ color: 'icons.semiMinor', backgroundColor: 'text.minor' }}
              variant="ghost"
              size={'sm'}
              isDisabled={hasCopied}
              onClick={(e) => onPreventDefault(e, () => onCopy(action))}
              icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
            />
            <IconButton
              aria-label="edit-action"
              data-testid={`edit-action-${fieldId}`}
              isRound={true}
              sx={{ color: 'icons.semiMinor', backgroundColor: 'text.minor' }}
              variant="ghost"
              size={'sm'}
              onClick={(e) => onPreventDefault(e, onEdit)}
              icon={<Pencil1Icon />}
            />
            <IconButton
              aria-label="delete-action"
              data-testid={`delete-action-${fieldId}`}
              isRound={true}
              sx={{ color: 'icons.semiMinor', backgroundColor: 'text.minor' }}
              variant="ghost"
              size={'sm'}
              onClick={(e) => onPreventDefault(e, onDelete)}
              icon={<TrashIcon />}
            />
          </GridItem>
        </Grid>
      </Box>
    </DragItem>
  );
};
