import { Logger } from '@oms/shared/util';
import type { Client, Event, Integration, EventHint } from '@sentry/types';
import { Actor } from '@valstro/workspace';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

export interface SentryReportABugConfig {
  windowId: string;
}

const INTEGRATION_NAME = 'sentry_report_a_bug';
const MAX_SENTRY_EVENT_SIZE = 1 * 1024 * 1024; // 1MB limit
const MAX_IMAGE_SIZE = 0.5 * 1024 * 1024; // 0.5MB file size limit
const MAX_EVENT_SIZE_WITH_ATTACHMENT = (MAX_SENTRY_EVENT_SIZE - MAX_IMAGE_SIZE) * 0.9; // 90% of the limit to be safe
const MAX_EVENT_SIZE = MAX_SENTRY_EVENT_SIZE * 0.9; // 90% of the limit to be safe

/**
 * Report a bug integration for closing a window when a bug is reported.
 */
export class SentryReportABug implements Integration {
  private logger = Logger.named(SentryReportABug.name);
  public name: string = INTEGRATION_NAME;
  public isDefaultInstance: boolean = true;
  private unsubscribe: (() => void) | null = null;

  constructor(private cfg: SentryReportABugConfig) {}

  public setup(client: Client): void {
    this.unsubscribe?.();
    this.unsubscribe = client.on('afterSendEvent', (event) => {
      const { windowId } = this.cfg;
      if (event.type === 'feedback') {
        this.logger.log('User feedback received. Closing the bug reporter window if it is open...');
        Actor.get<AppWindowActorSchema>(windowId)
          .then((actor) => actor.operations.close().catch(console.error))
          .catch(this.logger.warn);
      }
    });
  }

  public afterAllSetup(client: Client): void {
    const thisIntegration = client.getIntegrationByName(INTEGRATION_NAME);

    if (thisIntegration?.name !== this?.name) {
      this.logger.warn(
        'More than one instance of report a bug integration was provided. This may produce unexpected results.'
      );
    }
  }

  public async processEvent(event: Event, hint?: EventHint): Promise<Event | null> {
    let ev: Event | null = event;
    if (event.type === 'feedback') {
      const size = this.estimateEventSize(event);

      if (size > MAX_EVENT_SIZE) {
        this.logger.warn(`Feedback event exceeds ${MAX_EVENT_SIZE} bytes, trimming...`);
        ev = this.trimEvent(event, hint);
      }
    }
    return ev;
  }

  private estimateEventSize(event: Event): number {
    return new TextEncoder().encode(JSON.stringify(event)).length;
  }

  private trimEvent(event: Event, hint?: EventHint): Event | null {
    const hasAttachment = hint?.attachments && hint.attachments.length > 0;
    const maxEventSize = hasAttachment ? MAX_EVENT_SIZE_WITH_ATTACHMENT : MAX_EVENT_SIZE;
    while (this.estimateEventSize(event) > maxEventSize) {
      if (event.breadcrumbs && event.breadcrumbs.length > 0) {
        event.breadcrumbs.shift(); // Remove oldest breadcrumb first
      } else {
        this.logger.error(
          'Feedback event cannot be trimmed further but still exceeds size limit. Dropping event.'
        );
        return null;
      }
    }
    return event;
  }
}
