import { AuthClientState } from '@app/common/auth/keycloak.types';
import { createGraphQLAuthWsClient, createOrUpdateApolloClient } from '@app/data-access/api/apollo-client';
import { GraphqlSocketSignal, GraphqlSocketState } from '@app/data-access/memory/graphql.socket.signal';
import { Signal } from '@oms/shared-frontend/rx-broadcast';
import { Logger } from '@oms/shared/util';
import {
  audit,
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  from,
  map,
  Observable,
  of,
  startWith,
  tap
} from 'rxjs';
import { ApolloHTTPSetup, ApolloWSSetup } from './data-access.types';
import { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { ReconnectContext, ReconnectSignal } from '@app/data-access/memory/reconnect.signal';

const logger = Logger.named('Data Access State');

export type DataAccessContext = {
  authSignal: AuthSignal['signal'];
  graphqlSocketSignal: GraphqlSocketSignal['signal'];
  reconnectSignal: ReconnectSignal;
  websocket?: ApolloWSSetup;
  httpSetup: ApolloHTTPSetup;
  hasConnectedBefore: boolean;
  dataAccessSignal: Signal<DataAccessState>;
};

const isAuthError = ({ lastAuthClientEvent }: AuthClientState) => {
  return (
    lastAuthClientEvent === 'onAuthError' ||
    lastAuthClientEvent === 'onAuthRefreshError' ||
    lastAuthClientEvent === 'onInitError' ||
    lastAuthClientEvent === 'onTokenExpired'
  );
};

/**
 * Encapsulates the logic on determining the current state of data access.
 * @param context
 * @returns Observable of data access state
 */
export const dataAccessState$ = ({
  authSignal,
  graphqlSocketSignal,
  websocket,
  hasConnectedBefore,
  httpSetup,
  dataAccessSignal,
  reconnectSignal
}: DataAccessContext): Observable<[AuthClientState, GraphqlSocketState]> => {
  let hasErroredBefore = false;
  let lastReconnectEvent: ReconnectContext = { id: undefined };
  const isProcessing$ = new BehaviorSubject<boolean>(false);

  return combineLatest([authSignal.$, graphqlSocketSignal.$, reconnectSignal.$]).pipe(
    startWith<[AuthClientState, GraphqlSocketState, ReconnectContext]>([
      authSignal.get(),
      graphqlSocketSignal.get(),
      lastReconnectEvent
    ]),
    audit(() => isProcessing$.pipe(filter((tearingDown) => !tearingDown))),
    concatMap(([auth, socketState, retryState]) => {
      isProcessing$.next(true);
      const { id: retryId } = retryState;
      const { state = 'idle' } = socketState;
      const { isAuthenticated = false } = auth;
      const dataAccess = dataAccessSignal.get();
      const shouldRetry =
        retryId !== lastReconnectEvent.id && (dataAccess === 'interrupted' || dataAccess === 'idle');
      const shouldTeardown = ((!isAuthenticated && !isAuthError(auth)) || shouldRetry) && !!websocket;
      lastReconnectEvent = retryState;

      logger.debug(`Should teardown websocket? ${shouldTeardown ? 'Yes' : 'No'}`, {
        state,
        isAuthenticated,
        shouldTeardown,
        shouldRetry
      });

      return shouldTeardown && websocket // typescript 🙃
        ? from(websocket.teardown()).pipe(
            map(() => {
              websocket = undefined;
              return [auth, { ...socketState, state: 'closed' }] as [AuthClientState, GraphqlSocketState];
            }),
            catchError((err) => {
              logger.error(err);
              websocket = undefined;
              return of([auth, { ...socketState, state: 'closed' }] as [AuthClientState, GraphqlSocketState]);
            })
          )
        : of<[AuthClientState, GraphqlSocketState]>([auth, socketState]);
    }),
    tap(([authState, { state: socketState }]) => {
      try {
        const { isAuthenticated = false, lastAuthClientEvent } = authState;
        const shouldOpenSocket =
          socketState === 'closed' &&
          (lastAuthClientEvent === 'onAuthSuccess' || lastAuthClientEvent === 'onAuthRefreshSuccess') &&
          !websocket &&
          isAuthenticated;

        logger.debug(`Should open WS? ${shouldOpenSocket ? 'Yes' : 'No'}`, {
          socketState,
          lastAuthClientEvent,
          isAuthenticated,
          shouldOpenSocket
        });

        if (shouldOpenSocket) {
          websocket = setupApolloClientWebSocket(httpSetup);
        }

        const userLoggedOut = lastAuthClientEvent === 'onAuthLogout';
        const isConnected = socketState === 'connected';
        const isErrored = socketState === 'error';
        const authenticatedAndConnected = isAuthenticated && isConnected;

        if (isErrored) {
          hasErroredBefore = true;
        }

        if (userLoggedOut) {
          hasConnectedBefore = false;
          hasErroredBefore = false;
        } else if (authenticatedAndConnected) {
          hasConnectedBefore = true;
        }

        const dataAccessState: DataAccessState =
          socketState === 'closed' && !isAuthenticated
            ? 'idle'
            : socketState === 'error' ||
                ((hasConnectedBefore || hasErroredBefore) && !isConnected) ||
                isAuthError(authState)
              ? 'interrupted'
              : isConnected && isAuthenticated
                ? 'connected'
                : 'connecting';

        logger.trace(`🔍 Data access state is ${dataAccessState}.`, {
          dataAccessState,
          hasConnectedBefore,
          hasErroredBefore,
          isAuthenticated,
          lastAuthClientEvent,
          socketState,
          justLoggedOut: userLoggedOut
        });

        dataAccessSignal.set(dataAccessState);
      } catch (e) {
        logger.error(e);
      } finally {
        isProcessing$.next(false);
      }
    })
  );
};

function setupApolloClientWebSocket({
  client,
  getAuthToken,
  cache,
  isLeader,
  apolloMockLink,
  container
}: ApolloHTTPSetup): ApolloWSSetup {
  if (apolloMockLink) {
    logger.log('Skipping setup of GraphQL WS Client due to mock link');
    return {
      teardown: async () => {
        logger.debug('GraphQL WS Client not setup, so nothing to teardown');
      }
    };
  }

  const socketSignal = container.resolve(GraphqlSocketSignal);
  const wsClient = apolloMockLink
    ? undefined
    : createGraphQLAuthWsClient(isLeader, getAuthToken, socketSignal);

  createOrUpdateApolloClient(
    'auth-http-ws',
    {
      getAuthToken,
      cache,
      isLeader,
      signal: socketSignal,
      mockLink: apolloMockLink,
      graphqlWsClient: wsClient
    },
    client // Update the existing client with the new link
  );

  logger.debug('Opened WebSocket connection for GraphQL');

  return {
    teardown: async () => {
      if (wsClient) {
        await wsClient.dispose();
        socketSignal.reset();
      }

      logger.debug('Destroyed GraphQL WS Client');
    }
  };
}
