import { openInvestorOrderView } from '@app/generated/sdk';
import { getViewDialogTitle, ViewDialogInfo } from '../orders.utils';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';
import { useCallback } from 'react';

export interface IOpenViewInvestorOrderArgs {
  windowId: string;
  dialogInfo: ViewDialogInfo;
}

export const openViewInvestorOrder = async ({ windowId, dialogInfo }: IOpenViewInvestorOrderArgs) => {
  await openInvestorOrderView(windowId, {
    componentProps: {
      id: dialogInfo.id || ''
    },
    title: getViewDialogTitle(dialogInfo, 'investor-order')
  });
};

export const useOpenViewInvestorOrder = (dialogInfo?: ViewDialogInfo | null) => {
  const windowId = useLeaderOrTabId();
  return useCallback(() => {
    if (!dialogInfo) {
      console.error('investorOrderData is null or undefined');
      return;
    }
    openViewInvestorOrder({ windowId, dialogInfo }).catch((e) => console.error(e));
  }, [windowId, dialogInfo]);
};
