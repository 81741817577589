import { ICellRendererParams } from '@ag-grid-community/core';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { FC, useMemo } from 'react';

export const RULE_COUNT = 'rule_count';

export const RuleCountCell: FC<ICellRendererParams<number>> = (props) => {
  const { value } = props;

  const ruleText = useMemo(() => {
    return value === 1 ? 'rule' : 'rules';
  }, [value]);

  return (
    <Flex
      direction={'column'}
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'full',
        height: 'full',
        color: 'text.active',
        cursor: 'pointer'
      }}
    >
      {value} {ruleText}
    </Flex>
  );
};
