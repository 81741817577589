import { createBroadcastSignal } from '@oms/shared-frontend/rx-broadcast';

export type SentryException = {
  eventId: string;
  message: string;
  timestamp: number;
};

export type SentryState = {
  lastException: SentryException | null;
};

export const DEFAULT_SENTRY_STATE: SentryState = {
  lastException: null
};

export const globalSentrySignal = createBroadcastSignal<SentryState>('sentry-global', DEFAULT_SENTRY_STATE);
