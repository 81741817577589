import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FormatType, Tooltip } from '@oms/shared-frontend/ui-design-system';
import { format } from '@oms/shared-frontend/ui-design-system';

export const NUMBER_WITH_TOOLTIP_RENDERER = 'NumberWithTooltipCellRenderer';

export const NumberWithTooltipCellRenderer: React.FC<ICellRendererParams & { format: FormatType }> = ({
  value,
  format: formatType
}) => {
  return <Tooltip label={value}>{format(formatType || 'number', value)}</Tooltip>;
};
