import { useCallback, useState } from 'react';
import { withUpdatedTradingOrder } from '@app/data-access/hoc/with-updated-trading-order';
import type { UpdatedTradingOrder } from '@app/data-access/hoc/with-updated-trading-order';
import { Box, Flex, TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import {
  marketDataToggleBorder,
  marketDataToggleText,
  tabContentInner
} from '@app/widgets/common/styles/trading.styles.css';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { MarketDataWidget } from '@app/data-access/services/marketdata/components/market-data.widget';
import { TradingOrderDetailsSummary } from './trading-order-view.order-details.widget';

export const TradingOrderViewSidePanelWidget = ({ data: tradingOrder }: UpdatedTradingOrder) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <Tabs
      defaultValue="tabInstrumentData"
      sx={{ ...sx, height: 'full', flexDirection: 'column', display: 'flex' }}
    >
      <TabsList sx={{ paddingLeft: 4, marginBottom: 'medium' }}>
        <TabTrigger value="tabInstrumentData">Instrument data</TabTrigger>
        <TabTrigger value="tabOrderDetails">Order details</TabTrigger>
      </TabsList>

      <TabContent value="tabInstrumentData" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          {tradingOrder?.instrument && (
            <Flex direction="column">
              <Flex align="center" justify="center" sx={{ marginBottom: 'medium' }}>
                <div className={marketDataToggleBorder} />
                <div className={marketDataToggleText} onClick={handleToggle}>
                  Show {isExpanded ? 'Less' : 'More'}
                </div>
                <div className={marketDataToggleBorder} />
              </Flex>
              <MarketDataWidget
                instrumentId={tradingOrder.instrument}
                type={isExpanded ? 'om-grid' : 'om-grid-short'}
                orderQuantity={tradingOrder.totalQuantity}
              />
            </Flex>
          )}
        </Box>
      </TabContent>
      <TabContent value="tabOrderDetails" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          <TradingOrderDetailsSummary tradingOrder={tradingOrder} />
        </Box>
      </TabContent>
    </Tabs>
  );
};

export default withUpdatedTradingOrder(TradingOrderViewSidePanelWidget, { sx });
