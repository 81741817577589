import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { FieldContract } from '@oms/frontend-foundation';
import { InstrumentRestrictionEntryFieldContract } from './instrument-restriction-entry.form-common';
import { RefDataRestrictionTypeEnum } from '@oms/generated/frontend';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { INSTRUMENT_RESTRICTION_START_DATE } from './actions/instrument-restriction-entry-start-date-field.action';
import { INSTRUMENT_RESTRICTION_END_DATE } from './actions/instrument-restriction-entry-end-date-field.action';

const fc = FieldContract.create<InstrumentRestrictionEntryFieldContract, AdditionalFormFieldUnion>();
export const id = fc.field('id', 'hidden-field');

export const symbol = createSymbolField(fc, 'symbol', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  autoFocus: true,
  isRequired: true,
  query: ADVANCED_SELECT_QUERY_ENUM.LOOKUP_REF_DATA_INSTRUMENTS_QUERY
});

export const account = createAccountField(fc, 'account').options({
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  })
});

const restrictionTypeOptions = Object.entries(RefDataRestrictionTypeEnum).map(([_, value]) => ({
  label: value.replaceAll('_', ' '),
  value: value
}));

export const restrictionType = fc.field('restrictionType', 'select').options({
  label: 'Restriction type',
  options: restrictionTypeOptions,
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  })
});

export const fungible = fc.field('fungible', 'switch').options({ label: 'Fungible', type: 'checkbox' });

export const related = fc.field('related', 'switch').options({ label: 'Related', type: 'checkbox' });

export const restrictionEffectiveDate = fc.field('restrictionEffectiveDate', 'date-picker').options({
  minDate: new Date(),
  label: 'Restriciton effective date',
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  actions: {
    resolveProps: [INSTRUMENT_RESTRICTION_START_DATE]
  }
});

export const restrictionExpiryDate = fc.field('restrictionExpiryDate', 'date-picker').options({
  label: 'Restriciton expiry date',
  minDate: new Date(),
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  actions: {
    resolveProps: [INSTRUMENT_RESTRICTION_END_DATE]
  }
});
