import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import type { InvestorOrderRow } from '@oms/generated/frontend';

const principalFillDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

export const principalFillColumns = {
  id: { extends: 'orders.id' },
  investorAccountName: { extends: 'orders.investorAccount' },
  limitPrice: { extends: 'orders.limitPrice' },
  openQuantity: { extends: 'orders.quantity' }
} satisfies ColDefMap<InvestorOrderRow>;

const principalFillColumnLibrary: TableServerColumnLibrary<InvestorOrderRow> = {
  defaultColumn: principalFillDefaultColumn,
  columns: principalFillColumns
};

export const buildPrincipalFillColumnDefs = () =>
  buildColumnDefs(principalFillColumnLibrary, 'principalFill');
