import {
  Flex,
  IconButton,
  Select,
  SettingsIcon,
  TabContent,
  Tabs,
  TabsList,
  TabTrigger
} from '@oms/shared-frontend/ui-design-system';
import { useCallback, useMemo } from 'react';
import type { ChangeEventHandler, FC } from 'react';
import NotificationsGridWidget from './notifications.grid.widget';
import { BehaviorSubject } from 'rxjs';
import { openUserPreferences } from '@app/generated/sdk';
import { NOTIFICATION_VISIBILITY, NotificationVisibility } from '@app/notifications/notifications.contracts';
import { useNotificationsVisibilityService } from '@app/notifications/notifications.visibility.service';
import { useLeaderActor } from '@valstro/workspace-react';

export type SortType = 'priority' | 'event';

export const NotificationsWidget: FC = () => {
  const leaderActor = useLeaderActor();
  const service = useNotificationsVisibilityService();
  const sortRef$ = useMemo(() => new BehaviorSubject<SortType>('priority'), []);
  const onSortChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      sortRef$.next(e.target.value as SortType);
    },
    [sortRef$]
  );
  const onTabChange = useCallback(
    (e: string) => {
      service.setVisibility(e as NotificationVisibility);
    },
    [service]
  );
  const settingsButtonClickHandler = () => {
    openUserPreferences(leaderActor, {
      componentProps: {
        tabId: 'notification_settings'
      }
    }).catch(console.error);
  };

  return (
    <Tabs
      defaultValue={service.getVisibility()}
      sx={{ position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column' }}
      onValueChange={onTabChange}
    >
      <TabsList
        style={{
          padding: 0,
          marginLeft: 15,
          marginRight: 5,
          marginBottom: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Flex sx={{ gap: 4 }}>
          <TabTrigger value={NOTIFICATION_VISIBILITY.MINE}>My Alerts</TabTrigger>
          <TabTrigger value={NOTIFICATION_VISIBILITY.TEAM}>Team Alerts</TabTrigger>
          <TabTrigger value={NOTIFICATION_VISIBILITY.ALL}>All Alerts</TabTrigger>
        </Flex>
        <Flex sx={{ gap: 2 }}>
          <IconButton
            aria-label="Settings"
            variant="ghost"
            icon={<SettingsIcon />}
            onClick={settingsButtonClickHandler}
            style={{ color: 'grey' }}
          />
          <Select onChange={onSortChange}>
            <option value="priority">Priority</option>
            <option value="event">Event</option>
          </Select>
        </Flex>
      </TabsList>
      <TabContent value={NOTIFICATION_VISIBILITY.MINE} sx={{ height: 'full', flexDirection: 'column' }}>
        <NotificationsGridWidget variant$={service.watch$()} sort$={sortRef$} />
      </TabContent>
      <TabContent value={NOTIFICATION_VISIBILITY.TEAM} sx={{ height: 'full', flexDirection: 'column' }}>
        <NotificationsGridWidget variant$={service.watch$()} sort$={sortRef$} />
      </TabContent>
      <TabContent value={NOTIFICATION_VISIBILITY.ALL} sx={{ height: 'full', flexDirection: 'column' }}>
        <NotificationsGridWidget variant$={service.watch$()} sort$={sortRef$} />
      </TabContent>
    </Tabs>
  );
};

export default NotificationsWidget;
