import { useState, useLayoutEffect, RefObject, useCallback } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { debounce, throttle } from 'lodash';

// NOTE: useResizeObserver from @react-hook/resize-observer breaks tests. Using use-resize-observer instead.
export interface UseSizeOptions {
  /**
   * Debounce the size update. Default is 0 & throttling is used.
   */
  debounceTime?: number;

  /**
   * Throttle the size update. Default is 500ms.
   * Specify debounceTime to switch to debouncing.
   * Set to 0 to use real time updates
   */
  throttleTime?: number;
}

/**
 * Hook which which listens & returns size changes in a container using getBoundingClientRect
 *
 * @param target - Container ref to listen to
 * @param options - If empty, throttling is used.
 * @returns getBoundingClientRect() of target
 */
export const useSize = <T extends HTMLElement>(target: RefObject<T>, options?: UseSizeOptions) => {
  /**
   * Set defaults & state
   */
  const { debounceTime = 0, throttleTime = 500 } = options || {};
  const [size, setSize] = useState<DOMRect>();

  /**
   * Size update handler. Use debounce / throttle depending on options specified
   */
  const delaySizeUpdateHandler = useCallback(
    throttleTime > 0 ? throttle(setSize, throttleTime) : debounce(setSize, debounceTime),
    [setSize, debounceTime, throttleTime]
  );

  /**
   * Listen to target changes, which are handled by useResizeObserver
   */
  useLayoutEffect(() => {
    if (target && target.current && target.current.getBoundingClientRect) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  /**
   * Where the magic happens - pass update handler to callback
   */
  useResizeObserver(target, (entry: ResizeObserverEntry) => delaySizeUpdateHandler(entry.contentRect));

  /**
   * Return getBoundingClientRect() of the target
   */
  return size;
};
