import { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { hasGroupRow } from '@oms/frontend-vgrid';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { openViewTrade } from '@app/common/types/orders/trades/open.view.trade';
import { t } from '@oms/codegen/translations';

type ViewTradeActionArgs = {
  id: string;
  side: string;
  price: number;
  quantity: number;
  instrumentDisplayCode: string;
};

function isVisible(data: ViewTradeActionArgs[]) {
  return data.length === 1 && !hasGroupRow(data);
}

export const viewTradeOnChange = async <T extends ViewTradeActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewTrade({
      windowId: getLeaderOrTabId(ctx.appContainer),
      dialogInfo: {
        id: data[0].id,
        side: data[0].side,
        price: data[0].price,
        quantity: data[0].quantity,
        symbol: data[0].instrumentDisplayCode
      }
    });
  }
};

export const viewTradeAction =
  <T extends ViewTradeActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('view_trade')
      .toolbar((t) =>
        t.component('action-button').id('view_trade_button').location('UserToolbar').props({
          isDisabled: true,
          content: 'View'
        })
      )
      .customMenu((m) =>
        m
          .name('View Trade')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(viewTradeOnChange);
