import { IToolPanelParams, ToolPanelDef } from '@ag-grid-community/core';
import { isFunction } from 'lodash';
import { ComponentLocation } from '../models/v.component.model';
import { ComponentBuilder } from './component.builder';
import { AnyRecord } from '@oms/frontend-foundation';

export type AgToolbars = 'agFiltersToolPanel' | 'agColumnsToolPanel';
export type ToolPanel<
  TData extends AnyRecord,
  TProps extends IToolPanelParams<TData> = IToolPanelParams<TData>
> = ((builder: ComponentBuilder<TData, TProps>) => ComponentBuilder<TData, TProps>) | AgToolbars;

export class ToolPanelBuilder<TData extends AnyRecord> {
  private _toolPanel: ToolPanelDef = {} as ToolPanelDef;

  public id(id: string): ToolPanelBuilder<TData> {
    this._toolPanel.id = id;
    return this;
  }

  public label(label: string): ToolPanelBuilder<TData> {
    this._toolPanel.labelKey = label;
    this._toolPanel.labelDefault = label;
    return this;
  }

  public minWidth(width: number): ToolPanelBuilder<TData> {
    this._toolPanel.minWidth = width;
    return this;
  }

  public width(width: number): ToolPanelBuilder<TData> {
    this._toolPanel.width = width;
    return this;
  }

  public icon(icon: string): ToolPanelBuilder<TData> {
    this._toolPanel.iconKey = icon;
    return this;
  }

  public component<TProps extends IToolPanelParams<TData> = IToolPanelParams<TData>>(
    panel: ToolPanel<TData, TProps>
  ): ToolPanelBuilder<TData> {
    if (isFunction(panel)) {
      const componentInstance = new ComponentBuilder<TData, TProps>({
        location: ComponentLocation.Selection
      });
      const builder = panel(componentInstance);
      const comp = builder.build();
      this._toolPanel.toolPanelParams = comp.props;
      this._toolPanel.toolPanel = comp.component;
    } else {
      this._toolPanel.toolPanel = panel;
    }
    return this;
  }

  public suppressPivotMode(): ToolPanelBuilder<TData> {
    this._toolPanel.toolPanelParams = {
      ...this._toolPanel.toolPanelParams,
      suppressPivotMode: true
    };
    return this;
  }

  public build(): ToolPanelDef {
    return this._toolPanel;
  }
}
