import { BuilderCallback } from '@oms/shared/util-types';
import { compileActions } from './action.compiler';
import { DEFAULT_ACTIONS } from '../actions/action.registry';
import { AnyRecord } from '@oms/frontend-foundation';
import {
  DefaultActionDefs,
  ActionDef,
  ActionDefKey,
  ActionDefFactory,
  ActionSchema,
  ActionsConfig,
  ActionMessage,
  SourcelessActionMessage,
  InlineActionComponentProps,
  ActionComponentConfig,
  ActionNotificationOptions
} from '../models/actions.model';
import { Subject } from 'rxjs';
import { merge } from 'lodash';
import { notifyComponent } from '../services/actions.service';

export class GridActionsSchemaBuilder<
  TData extends AnyRecord,
  TActionDefs extends string = DefaultActionDefs
> {
  private _actions: ActionDef<TData>[] = [];
  private _registry: Record<ActionDefKey<TActionDefs>, ActionDefFactory> = {
    ...(DEFAULT_ACTIONS as Record<ActionDefKey<TActionDefs>, ActionDefFactory>)
  };
  private _actionEvents$: Subject<ActionMessage<TData>>;

  constructor(actionEvents$: Subject<ActionMessage<TData>>) {
    this._actionEvents$ = actionEvents$;
  }

  public registry(
    registry: Record<TActionDefs, ActionDefFactory>
  ): GridActionsSchemaBuilder<TData, TActionDefs> {
    this._registry = { ...this._registry, ...registry };
    return this;
  }

  public action(schema: ActionSchema<TData, TActionDefs>): GridActionsSchemaBuilder<TData, TActionDefs> {
    const defs = compileActions<TData, TActionDefs>(this._registry, schema);

    this._actions.push(...defs);

    return this;
  }

  public build(): Record<string, ActionDef<TData>> {
    const result: Record<string, ActionDef<TData>> = {};
    this._actions = this._actions?.length ? this._actions : [];
    this._actions.forEach((a) => {
      if (a.inline) {
        const actionParams: InlineActionComponentProps<TData> = merge<
          InlineActionComponentProps<TData>,
          InlineActionComponentProps<TData>
        >(a.inline.cellRendererParams, {
          action: {
            id: '',
            name: a.name,
            actionType: 'Inline',
            onChange: (e) => {
              this._actionEvents$.next({ ...(e as SourcelessActionMessage<TData>), source: 'component' });
            },
            notify: (n: ActionComponentConfig<TData>, opts?: ActionNotificationOptions<TData>) => {
              notifyComponent({ ...n, actionRoot: a.name, ...opts, events$: this._actionEvents$ });
            }
          }
        });

        a.inline.cellRendererParams = actionParams;
      }
      result[a.name] = a;
    });

    return result;
  }
}

export class GridActionsBuilder<TData extends AnyRecord> {
  private _actionsConfig: Partial<ActionsConfig<TData>> = {};

  private _actionEvents$: Subject<ActionMessage<TData>>;

  constructor(actionEvents$: Subject<ActionMessage<TData>>) {
    this._actionEvents$ = actionEvents$;
  }

  public schema<TActionDefs extends string = DefaultActionDefs>(
    cb: BuilderCallback<GridActionsSchemaBuilder<TData, TActionDefs>>
  ): GridActionsBuilder<TData> {
    this._actionsConfig.schema = cb(
      new GridActionsSchemaBuilder<TData, TActionDefs>(this._actionEvents$)
    ).build();
    return this;
  }

  public build(): ActionsConfig<TData> {
    this._actionsConfig.schema = this._actionsConfig.schema ? this._actionsConfig.schema : {};
    return this._actionsConfig as ActionsConfig<TData>;
  }
}
