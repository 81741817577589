import { openRejectPendingModification } from '@app/generated/sdk';
import type { ActionContext, ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import { PROCESS_ID } from '@valstro/workspace';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { doesRowHaveAcceptingStatus } from './inline.pending-modification.action';
import { t } from '@oms/codegen/translations';

type RejectPendingModificationActionArgs = { id?: string | null | undefined };

export const rejectPendingModificationOnChange = async <T extends RejectPendingModificationActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;
  const selectedRow = data[0];

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openRejectPendingModification(PROCESS_ID.LEADER, {
      form: {
        formId: `reject-pending-modification-${id}`,
        input: {
          modificationId: id
        }
      }
    });
  }
};

export const rejectPendingModificationAction: ActionDefFactory<VisibleModificationInfoFragment> = (builder) =>
  builder
    .name('reject_pending_modification')
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);
      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) =>
      m
        .name('Reject')
        .visible(({ rowData: [row] }) => !doesRowHaveAcceptingStatus(row))
        .tabName(t('app.common.grids.contextMenuTabs.action'))
    )
    .onChange<ActionComponentConfig<VisibleModificationInfoFragment>>(rejectPendingModificationOnChange);
