import type { EnvType } from './app-version.types';

export class AppEnv {
  public readonly type: EnvType;
  public readonly org: string;
  public static readonly defaultOrg: string = 'Valstro';

  protected constructor(type: EnvType, tag: string) {
    this.type = type;
    this.org = tag;
  }

  public static from(label?: string) {
    const [type, org] = AppEnv.extractFromEnvString(label);
    return new AppEnv(type, org);
  }

  public toFullEnv() {
    return `${this.org} ${this.type}`;
  }

  private static extractFromEnvString(label?: string): [EnvType, string] {
    if (!label) {
      return ['Dev', AppEnv.defaultOrg];
    }

    label = label.toLowerCase();

    // Handle localhost
    if (label === 'localhost') {
      return ['Dev', AppEnv.defaultOrg];
    }

    // Handle test environment
    if (label === 'test') {
      return ['Test', AppEnv.defaultOrg];
    }

    // Handle PR environments like ui-8119.dev.valstro.engineering or uinew-8119m.dev.valstro.engineering
    if (label.includes('.dev.valstro.engineering')) {
      // Match both formats: ui-8119 and ui8150
      const prMatch = label.match(/^(?:ui|uinew)(?:-?)(\d+m?)\.dev\.valstro\.engineering$/);
      if (prMatch) {
        return [`Dev-${prMatch[1]}` as EnvType, AppEnv.defaultOrg];
      }

      // Handle Tilt local environments
      const localMatch = label.match(/^(?:ui|uinew)-local-([a-z0-9-]+)\.dev\.valstro\.engineering$/);
      if (localMatch) {
        return [`Local-${localMatch[1]}` as EnvType, AppEnv.defaultOrg];
      }
    }

    // Handle domain-based environments
    if (label.includes('.')) {
      // Handle customer UAT environments with pattern: ui.us-east-1.customer-uat.valstroapps.com
      const customerUatMatch = label.match(
        /^(?:ui|uinew)\.(?:[a-z0-9-]+\.)?([a-z0-9-]+)-uat\.valstroapps\.com$/
      );
      if (customerUatMatch && customerUatMatch[1]) {
        // Extract customer name, split by hyphens, capitalize each word, and join with space
        const customerName = customerUatMatch[1]
          .split('-')
          .map((word) => word.toUpperCase())
          .join(' ');
        return ['UAT', customerName];
      }

      // Handle Valstro environments by subdomain pattern
      const parts = label.split('.');

      // Check for demo environment
      if (parts.includes('demo')) {
        return ['Demo', AppEnv.defaultOrg];
      }

      // Check for integration environment
      if (parts.includes('integration')) {
        return ['Int', AppEnv.defaultOrg];
      }

      // Check for UAT environment
      if (parts.includes('uat')) {
        return ['UAT', AppEnv.defaultOrg];
      }
    }

    // Handle legacy environment strings
    switch (label) {
      case 'production':
        return ['Prod', AppEnv.defaultOrg];
      case 'development':
        return ['Dev', AppEnv.defaultOrg];
      case 'test':
        return ['Test', AppEnv.defaultOrg];
      case 'valstro-demo':
        return ['Demo', AppEnv.defaultOrg];
      case 'integration':
      case 'valstro-integration':
        return ['Int', AppEnv.defaultOrg];
      default:
        return [label as EnvType, AppEnv.defaultOrg];
    }
  }
}
