import { openTradeModify } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { hasGroupRow } from '@oms/frontend-vgrid';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { getTradeDescription } from '@app/common/types/orders/orders.utils';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { t } from '@oms/codegen/translations';

type ModifyTradeActionActionArgs = {
  id: string;
  side: string;
  instrumentDisplayCode: string;
  quantity: number;
  price: number;
};

function isVisible(data: ModifyTradeActionActionArgs[]) {
  return data.length === 1 && !hasGroupRow(data);
}

export const openModifyTradeOnChange = async <T extends ModifyTradeActionActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const id = selectedRow.id;

    if (!id) {
      return;
    }

    openTradeModify(PROCESS_ID.LEADER, {
      windowId: `trade-modify-${id}`,
      form: {
        formId: `trade-modify-form-${id}`,
        input: {
          id,
          entryType: 'trade-modify'
        }
      },
      title: `Modify Trade: ${getTradeDescription(selectedRow)}`
    }).catch((e) => {
      openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
      console.error(e);
    });
  }
};

export const openModifyTradeAction =
  <T extends ModifyTradeActionActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('modify_trade')
      .toolbar((t) =>
        t.component('action-button').id('modify_trade_button').location('UserToolbar').props({
          isDisabled: true,
          content: 'Modify'
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.TradeManage]);
      })
      .customMenu((m) =>
        m
          .name('Modify')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(openModifyTradeOnChange);
