import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { openCreateTradingOrder } from '@app/widgets/trading/route-order/route-order.form-open';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { CreateActionIcon } from '@oms/frontend-foundation';

type OpenEntryTradingOrderActionArgs = {};

export const openEntryTradingOrderAction =
  <T extends OpenEntryTradingOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('open_trading_order_entry_trading_order')
      .toolbar((t) =>
        t
          .id('open_trading_order_entry_trading_order_button')
          .component('action-icon-button')
          .location('StaticToolbar')
          .props({
            icon: <CreateActionIcon />
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .onChange((ctx) => {
        if (ctx.lifecycle !== 'change') return;
        openCreateTradingOrder(ctx.workspace);
      });
