import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { TableServerJoinedInstrument } from '@oms/generated/frontend';

const instrumentsGridColumns: ColDefMap<TableServerJoinedInstrument> = {
  id: { extends: 'orders.id', hide: true },
  displayCode: { extends: 'generic.text', width: 200, headerName: 'Symbol' },
  active: { extends: 'generic.default', width: 100 },
  longName: { extends: 'orders.instrumentLongName', width: 250 },
  factsetPermanentId: { extends: 'generic.text', width: 150 },
  issueDate: { extends: 'generic.date' },
  adrRatio: { extends: 'generic.numeric', width: 100 },
  firmMarketMaker: { extends: 'generic.text', width: 200 },
  listingExchangeId: { extends: 'generic.text', width: 150 },
  countryOfIncorporationId: { extends: 'generic.text', width: 200 },
  tradedCurrencyId: { extends: 'generic.text', width: 150 },
  settleCurrencyId: { extends: 'generic.text', width: 150 },
  isin: { extends: 'generic.text', width: 150, headerName: 'ISIN' },
  cusip: { extends: 'generic.text', width: 150, headerName: 'CUSIP' },
  sedol: { extends: 'generic.text', width: 150, headerName: 'SEDOL' },
  exchCode: { extends: 'generic.text', width: 150, headerName: 'EXCH' },
  figi: { extends: 'generic.text', width: 150, headerName: 'Valstro ID' },
  factsetTickerRegion: { extends: 'generic.text', width: 200, headerName: 'Display' },
  bbgTickerExchange: { extends: 'generic.text', width: 200, headerName: 'Bloomberg Exchange Ticker' },
  bbgTickerComposite: { extends: 'generic.text', width: 250, headerName: 'Bloomberg Composite Ticker' },
  businessSectorName: { extends: 'generic.text', width: 150 },
  businessSectorId: { extends: 'generic.text', width: 150 },
  industryId: { extends: 'generic.text' },
  securityTypeId: {
    extends: 'generic.text',
    width: 150
  },
  industryName: { extends: 'generic.text' },
  parentInstrumentId: { extends: 'generic.text' },
  securityTypeDescription: { extends: 'generic.text' },
  daysToSettle: { extends: 'generic.numeric', width: 150 },
  lotSize: { extends: 'generic.numeric' },
  isCustomInstrument: { extends: 'generic.text' }
};

export const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

const instrumentsGridColumnLibrary: TableServerColumnLibrary<TableServerJoinedInstrument> = {
  defaultColumn,
  columns: instrumentsGridColumns
};

export const buildInstrumentsGridColmuns = () => buildColumnDefs(instrumentsGridColumnLibrary, 'instruments');
