import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type {
  InvestorOrderBulkEntryInput,
  InvestorOrderBulkEntryOutput
} from './investor-order-bulk-entry.form-common';
import type { InvestorOrderBulkEntryContractType } from './investor-order-bulk-entry.form-contract';
import { investorOrderBulkEntryContract } from './investor-order-bulk-entry.form-contract';
import { sanitizeFormValuesToOutput } from './investor-order-bulk-entry.form-sanitizers';

export const investorOrderBulkEntryBuilder = FormBuilder.create<
  InvestorOrderBulkEntryInput,
  InvestorOrderBulkEntryOutput
>('investor-order-bulk-entry-form')
  .contract<InvestorOrderBulkEntryContractType>(investorOrderBulkEntryContract)
  .type('investor-order-bulk-entry')
  .sanitizer((s) =>
    s
      .input(function initializeFormValues(_input, _ctx) {
        // TODO: Check if initialFormValues values for edit/fix invalid IO mode needed
        const initialFormValues = {};

        return initialFormValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.investorAccount?.value) return;

        const output = sanitizeFormValuesToOutput(formValues);
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        const { formValuesDiff } = event.payload;

        if (formValuesDiff.investorAccount) {
          ctx.notify({
            type: 'SET_FIELD_VALUES',
            payload: {
              fieldValues: {
                orderTags: undefined,
                representativeCode: undefined,
                primaryBenchmark: undefined
              }
            }
          });
        }
        break;
      }
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        // TODO: Check how to handle fields enrichment
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        // TODO: Implement
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_SUCCESS: {
        // TODO: Check if needed
        // ctx.notify({
        //   type: 'RESET'
        // });
        break;
      }
    }
  });

export type InvestorOrderBulkEntryBuilderType = typeof investorOrderBulkEntryBuilder;

export default investorOrderBulkEntryBuilder;
