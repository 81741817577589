import { createLocateField } from '@app/forms/common/fields/locate-field/locate-field.common';
import type { InvestorOrderEntryFieldContract } from './investor-order-entry.form-common';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { createSideField } from '@app/forms/common/fields/side-field/side-field.common';
import { createQuantityField } from '@app/forms/common/fields/quantity-field/quantity-field.common';
import { createPriceField } from '@app/forms/common/fields/price-field/price-field.common';
import { createCurrencyField } from '@app/forms/common/fields/currency-field/currency.field';
import { createRepCodeField } from '@app/forms/common/fields/rep-code-field/rep-code.field';
import { createSettlementFields } from '@app/forms/common/fields/settlement-field/settlement.field';
import { createCustomerNotesField } from '@app/forms/common/fields/customer-notes-field/customer-notes.field';
import { createOrderTagsField } from '@app/forms/common/fields/order-tags-field/order-tags.field';
import { TagTypeEnum } from '@oms/generated/frontend';
import type { TimeInForce } from '@oms/generated/frontend';
import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { createCommissionFields } from '@app/forms/common/fields/commission-field/commission.field';
import { createStopPriceField } from '@app/forms/common/fields/stop-price-field/stop-price.field';
import { createPrimaryBenchmarkField } from '@app/forms/common/fields/primary-benchmark-field/primary-benchmark.field';
import { createNotionalAmountField } from '@app/forms/common/fields/notional-amt-field/notional-amt.field';
import { createDiscretionaryTypeField } from '@app/forms/common/fields/discretionary-type-field/discretionary-type.field';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { createActiveUserField } from '@app/forms/common/fields/active-user-field/active-user.field';
import {
  commonTimeInForceOptions,
  getTomorrowMidnight,
  makeTifGtdTimestampOption
} from '@app/forms/common/fields/TIF-field/TIF-field.common';
const fc = FieldContract.create<InvestorOrderEntryFieldContract, AdditionalFormFieldUnion>();

// ----------- Hidden fields --------------------------------------
// ----------------------------------------------------------------
export const hiddenFormInfo = fc.field('hiddenFormInfo', 'hidden-field');

export const timeInForce = fc.field('timeInForce', 'multi-input').multiInputOptions<TimeInForce, string>({
  name: 'tif',
  label: 'TIF',
  validate: [{ type: 'validateTifInput' }, { type: 'afterDate', message: 'Must be future date.' }],
  options: commonTimeInForceOptions.concat(makeTifGtdTimestampOption('gtdTimestamp', getTomorrowMidnight()))
});

export const id = fc.field('id', 'text-field').options({
  isVisible: false
});

export const instrument = createSymbolField(fc, 'instrument', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  isPrimaryField: true,
  actions: {
    resolveProps: ['ioRunwayDisableAction']
  }
});

export const parentTradingOrderId = fc.field('parentTradingOrderId', 'hidden-field');

export const investorAccountId = createAccountField(fc, 'investorAccountId', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  autoFocus: true,
  isPrimaryField: true,
  actions: {
    resolveProps: ['ioRunwayDisableAction']
  }
});

export const sideType = createSideField(fc, 'sideType', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  isPrimaryField: true,
  actions: {
    resolveProps: ['ioRunwayDisableAction']
  }
});

export const ownerId = createActiveUserField(fc, 'owner');

export const quantity = createQuantityField(fc, 'quantity', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  isPrimaryField: true,
  actions: {
    resolveProps: ['ioRunwayDisableAction', { checkParentTradingOrder: true }]
  }
});

export const limitPrice = createPriceField(fc, 'limitPrice', {
  isPrimaryField: true,
  actions: {
    resolveProps: ['ioRunwayDisableAction', { checkParentTradingOrder: true }]
  }
});

export const locate = createLocateField(fc, 'locate');

export const settleCurrency = createCurrencyField(fc, 'settleCurrency', { label: 'Settle Currency' });

export const tradeCurrency = createCurrencyField(fc, 'tradeCurrency', {
  label: 'Trade Currency',
  forceIsDisabled: true,
  isDisabled: true
});

export const representativeCode = createRepCodeField(fc, 'representativeCode');

export const { settlementContainer, settlementDate, settlementType } = createSettlementFields(
  fc,
  'settleType',
  'settleDate'
);

export const settleCounterpartyId = fc
  .field('settleCounterpartyId', 'text-field')
  .options({ label: 'Settle Counterparty' });

export const stoppedQuantity = fc.field('stoppedQuantity', 'number-format').options({
  label: 'Stopped Quantity',
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedTime', 'stoppedPrice'],
      message: 'Required'
    }
  ]
});

export const stoppedPrice = fc.field('stoppedPrice', 'number-format').options({
  label: 'Stopped Price',
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedTime', 'stoppedQuantity'],
      message: 'Required'
    }
  ]
});

export const stoppedTime = fc.field('stoppedTime', 'native-date-picker').options({
  label: 'Stopped Time',
  timePicker: true,
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedPrice', 'stoppedQuantity'],
      message: 'Required'
    }
  ]
});

export const customerNotes = createCustomerNotesField(fc, 'customerNotes', { label: 'Customer Notes' });

export const notionalAmount = createNotionalAmountField(fc, 'notionalAmount');

export const orderTags = createOrderTagsField(fc, 'orderTags', [TagTypeEnum.InvestorOrder]);

export const receivedTimestamp = fc.field('receivedTimestamp', 'native-date-picker').options<any>({
  wrapperSx: {
    width: 'full'
  },
  label: 'Received Time',
  timePicker: true
});

export const discretionaryType = createDiscretionaryTypeField(fc, 'discretionaryType');

export const commission = createCommissionFields(fc, 'rateType', 'commission', {}, { decimalScale: 9 });

export const stopPrice = createStopPriceField(fc, 'stopPrice');

export const primaryBenchmark = createPrimaryBenchmarkField(fc, 'primaryBenchmark');

export const preAllocationAccount = fc.field('preAllocationAccount', 'text-field').options({
  label: 'Pre-allocation Account'
});

export const chargeSchedule = fc.field('chargeSchedule', 'display-grid').options({
  initialValue: {
    gridProps: {
      columns: 1
    },
    items: [],
    style: {
      display: 'flex'
    }
  }
});
