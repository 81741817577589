import { ComponentService } from '../services/component.service';
import { useGridContainer } from './grid.container.hook';
import { ComponentEvent, ComponentLocation, VComponent } from '../models/v.component.model';
import { useEffect, useState } from 'react';
import { startWith } from 'rxjs';

export const useComponentService = (location: ComponentLocation): VComponent[] => {
  const container = useGridContainer();
  const componentContainerService = container.resolve(ComponentService);
  const [renderEvent, setRenderEvent] = useState<ComponentEvent>({
    location,
    components: []
  });

  useEffect(() => {
    const sub = componentContainerService
      .observe(location)
      .pipe(
        startWith({
          location,
          components: componentContainerService.getComponents(location)
        })
      )
      .subscribe((e) => {
        setRenderEvent(e);
      });

    return () => {
      sub.unsubscribe();
    };
  }, [componentContainerService]);

  return renderEvent?.components || [];
};
