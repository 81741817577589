import { useCallback, useMemo, useRef } from 'react';
import { POPOVER_EVENT } from '@valstro/workspace-plugin-popover';
import {
  usePopoverComponentProps,
  usePopoverWindow,
  usePopoverTransport
} from '@valstro/workspace-react-plugin-popover';
import {
  useAutocompleteKeyboardSubject,
  AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT
} from './popover.autocomplete.events';
import { ComboBox } from '@oms/shared-frontend/ui-design-system';
import type { ComboBoxItemUnion, ComboBoxItem } from '@oms/shared-frontend/ui-design-system';

export type PopoverAutocompleteComponentProps = {
  inputValue: string;
  options: ComboBoxItemUnion[];
  selectedOptions: ComboBoxItem[];
  isMultiSelect?: boolean;
  isLoading?: boolean;
  isInvalidated?: boolean;
  errorMessage?: string;
  highlightedItem?: ComboBoxItem | null;
  allowAnyValue?: boolean;
  filterStrategy?: 'default' | 'startsWith' | 'contains' | 'none' | 'us-symbol';
  maxListHeight?: number;
};

export function PopoverAutocompleteComponent() {
  const popoverEventsTransport = usePopoverTransport();
  const popoverEventsTransportRef = useRef(popoverEventsTransport); // Need to do this because popoverEventsTransport is not stable at the mo.
  const { popoverId, windowId } = usePopoverWindow();
  const {
    inputValue = '',
    options = [],
    selectedOptions = [],
    allowAnyValue = false,
    isInvalidated = false,
    errorMessage = '',
    isLoading = false,
    isMultiSelect = false,
    filterStrategy = 'default',
    maxListHeight = 200
  } = usePopoverComponentProps<PopoverAutocompleteComponentProps>();

  /**
   * Determine if the autocomplete popover should auto filter based on the filter strategy
   * If the filter strategy is set to 'default', the popover will auto filter
   * If the filter strategy is set to 'startsWith' or 'contains', the popover will not auto filter
   * and will instead use the custom filter function
   */
  const shouldAutoFilter = filterStrategy !== 'none';
  const customFilter = useMemo(() => {
    switch (filterStrategy) {
      case 'startsWith':
        return (value: string, search: string) => {
          return value.toLowerCase().startsWith(search.toLowerCase()) ? 1 : 0;
        };
      case 'contains':
        return (value: string, search: string) => {
          return value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
        };
      case 'us-symbol':
        // TODO: Work on this, does not seem to be working completely
        // US symbol filter strategy is used to filter US symbols by symbol
        // First, we check if the value starts with the search string
        // We want to sort the results that end with "-US" first
        return (value: string, search: string) => {
          const valueLower = value.toLowerCase();
          const searchLower = search.toLowerCase();
          const startsWithSearch = valueLower.startsWith(searchLower);
          const endsWithUS = valueLower.endsWith('-us');

          if (startsWithSearch && endsWithUS) {
            return 1;
          } else if (startsWithSearch) {
            return 0.5;
          }
          return 0;
        };
      default:
        return undefined;
    }
  }, [filterStrategy]);

  /**
   * Create a custom keyboard subject for the autocomplete popover based on the popover ID
   * this is used to emit keyboard events to the popover trigger & vice versa
   */
  const keyboard$ = useAutocompleteKeyboardSubject(popoverId || 'unknown');

  /**
   * Set the selected items for the autocomplete popover
   * by emitting an event, that's picked up by the popover trigger
   */
  const setSelectedItems = useCallback(
    (selectedItems: ComboBoxItem<any>[]) => {
      if (!popoverId || !windowId) {
        throw new Error('Popover ID or Window ID not found');
      }

      popoverEventsTransportRef.current
        .emit(POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE, {
          popoverId,
          windowId,
          method: 'merge',
          componentProps: {
            selectedOptions: selectedItems
          }
        })
        .catch(console.error);
    },
    [popoverId, windowId]
  );

  /**
   * Set the highlighted item for the autocomplete popover
   * by emitting an event, that's picked up by the popover trigger
   */
  const onHighlightedItemChange = useCallback(
    (item: ComboBoxItem<any> | null | undefined) => {
      if (!popoverId || !windowId) {
        throw new Error('Popover ID or Window ID not found');
      }
      popoverEventsTransportRef.current
        .emit(POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE, {
          popoverId,
          windowId,
          method: 'merge',
          componentProps: {
            highlightedItem: item
          }
        })
        .catch(console.error);
    },
    [popoverId, windowId]
  );

  /**
   * Handle item click by emitting an event, that's picked up by the popover trigger
   */
  const onItemClick = useCallback(
    (item: ComboBoxItem) => {
      if (!popoverId || !windowId) {
        throw new Error('Popover ID or Window ID not found');
      }
      popoverEventsTransportRef.current
        .emit(POPOVER_EVENT.POPOVER_CUSTOM_EVENT, {
          popoverId,
          windowId,
          event: {
            type: AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT,
            payload: {
              item
            }
          }
        })
        .catch(console.error);
    },
    [popoverId, windowId]
  );

  const computedStyles = useMemo(() => {
    return { '--cmdk-list-max-height': `${maxListHeight}px` } as React.CSSProperties;
  }, [maxListHeight]);

  return (
    <div cmdk-sm="" cmdk-frameless="" cmdk-wrapper="" style={computedStyles}>
      <ComboBox
        inputValue={inputValue}
        items={options}
        selectedItems={selectedOptions}
        setSelectedItems={setSelectedItems}
        removeOnBackspace={false}
        onItemClick={onItemClick}
        hiddenInput={true}
        hiddenSelectedItems={true}
        shouldAutoFilter={shouldAutoFilter}
        customFilter={customFilter}
        submitReady={false}
        strategy={isMultiSelect ? 'default' : 'single-select'}
        keydown$={keyboard$}
        isLoading={isLoading}
        onHighlightedItemChange={onHighlightedItemChange}
        allowAnyValue={allowAnyValue}
        isInvalidated={isInvalidated}
        errorMessage={errorMessage}
      />
    </div>
  );
}
