import { useCallback, useRef, useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import type { LayoutAction } from '../types';
import type { ActionButtonLayoutFormInput } from './action-button-layout.contracts';
import { UUID, createLogger } from '@oms/shared/util';
import type { ActionButtonLayoutValue } from './action-button-layout.field';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';
import type { ActionButtonLayoutValues } from './action-button-layout.form-contract';
import { ActionButtonFormInput } from '../action-button/action-button.contracts';

const logger = createLogger({
  name: 'action-button-layout.copy-field.hook',
  level: 'info'
});

export const CLIPBOARD_ID = 'copy-action-buttons';
export const CLIPBOARD_UI_RESET_MS = 750;

export type ActionsClipboardData = {
  id: typeof CLIPBOARD_ID;
  actions: LayoutAction[];
};

/**
 * Hook to copy all actions (form state) to the clipboard
 * - Sets the hasCopied state to true
 * - Resets the hasCopied state after 750ms (so we can show the user that the copy was successful in the UI)
 *
 * @param formApi - The form API
 * @returns The onCopy function and the hasCopied state
 */
export function useCopyAllActions(formApi: EnhancedFormOptions<ActionButtonLayoutValues>) {
  const [hasCopied, setHasCopied] = useState(false);

  /**
   * Handles the copy event
   *
   * @returns void
   */
  const onCopy = useCallback(() => {
    const clipboardData: ActionsClipboardData = {
      id: CLIPBOARD_ID,
      actions: formApi.getState().values?.layout?.actions || []
    };
    const result = copy(JSON.stringify(clipboardData));
    logger.info('Copying actions to clipboard', { result, clipboardData: JSON.stringify(clipboardData) });
    if (result) {
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, CLIPBOARD_UI_RESET_MS);
    }
  }, [formApi.getState]);

  return [onCopy, hasCopied] as const;
}

/**
 * Hook to copy single action to the clipboard
 * - Sets the hasCopied state to true
 * - Resets the hasCopied state after 750ms (so we can show the user that the copy was successful in the UI)
 *
 * @returns The onCopy function and the hasCopied state
 */
export function useCopyAction() {
  const [hasCopied, setHasCopied] = useState(false);

  /**
   * Handles the copy event
   *
   * @param singleAction - The single action to copy
   * @returns void
   */
  const onCopy = useCallback((singleAction: ActionButtonFormInput) => {
    const clipboardData: ActionsClipboardData = {
      id: CLIPBOARD_ID,
      actions: [singleAction as LayoutAction]
    };
    const result = copy(JSON.stringify(clipboardData));
    logger.info('Copying action to clipboard', { result, clipboardData: JSON.stringify(clipboardData) });
    if (result) {
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, CLIPBOARD_UI_RESET_MS);
    }
  }, []);

  return [onCopy, hasCopied] as const;
}

/**
 * Hook to paste the form state from the clipboard
 * - Extracts the actions from the clipboard data
 * - Merges the pasted actions with the current actions
 * - Updates the form state with the merged actions
 *
 * @param formApi - The form API
 * @param name - The name of the field
 * @param input - The input options
 * @returns The wrapper reference
 */
export function usePasteActions(
  formApi: EnhancedFormOptions<ActionButtonLayoutValues>,
  name: string,
  input: ActionButtonLayoutFormInput
) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputOptionsRef = useRef(input);
  inputOptionsRef.current = input;

  /**
   * Handles the paste event
   * - Extracts the actions from the clipboard data
   * - Merges the pasted actions with the current actions
   * - Updates the form state with the merged actions
   *
   * @param clipboardData - The clipboard data to paste
   * @returns void
   */
  const pasteActionHandler = useCallback(
    (clipboardData: DataTransfer | null) => {
      const pastedActions = extractClipboardActions(clipboardData);
      if (!pastedActions) return;
      logger.info('Pasting actions', { pastedActions: JSON.stringify(pastedActions) });

      const fieldValue = formApi.getState().values[name] as ActionButtonLayoutValue;
      const currentActions: LayoutAction[] = fieldValue.actions || [];
      const currentActionsSortedByOrder = currentActions.sort((a, b) => a.order - b.order);
      const lastActionOrder = currentActionsSortedByOrder[currentActions.length - 1]?.order || 0;

      const newActions = pastedActions
        .filter((a) => {
          const isSameType = a.widgetTypeId === inputOptionsRef.current.widgetTypeId;
          const isSameObject = a.objectId === inputOptionsRef.current.objectId;
          return isSameType && isSameObject;
        })
        .sort((a, b) => a.order - b.order)
        .map((a, i) => {
          const action: LayoutAction = {
            ...a,
            locationId: inputOptionsRef.current.locationId, // Ensure that the locationId is the same as the current location
            gridStateId: inputOptionsRef.current.gridStateId, // Ensure that the gridStateId is the same as the current grid
            order: lastActionOrder + i + 1, // Increment the order from the last action
            id: UUID()
          };
          return action;
        });

      const mergedActions = [...currentActionsSortedByOrder, ...newActions];

      formApi.change(name, { ...fieldValue, actions: mergedActions });
      logger.info('Pasted actions', {
        mergedActions: JSON.stringify(mergedActions),
        newActions: JSON.stringify(newActions)
      });
    },
    [formApi.change, formApi.getState, name]
  );

  /**
   * Adds a paste event listener to the document
   * - Listens for the paste event
   * - Only pastes into the field wrapper element OR its children/parent wrapper
   * - Prevents the default paste action
   * - Calls the pasteActionHandler with the clipboard data
   */
  useEffect(
    function handlePasteEvent() {
      // Ensure that the parent element of the wrapper is focusable
      // So we can paste into it
      let parentEl: HTMLElement | null | undefined = null;
      if (wrapperRef.current) {
        parentEl = wrapperRef.current.parentElement?.parentElement; // Need the parent of the parent here (as this is the element that is full height in the window)
        if (parentEl) {
          parentEl.tabIndex = 0;
        }
      }

      const handlePaste = (e: ClipboardEvent) => {
        const currentFocusedElement = document.activeElement;
        if (
          wrapperRef.current &&
          currentFocusedElement &&
          (currentFocusedElement === parentEl ||
            wrapperRef.current === currentFocusedElement ||
            wrapperRef.current.contains(currentFocusedElement))
        ) {
          e.preventDefault();
          pasteActionHandler(e.clipboardData);
        }
      };

      document.addEventListener('paste', handlePaste);

      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    },
    [wrapperRef.current]
  );

  return wrapperRef;
}

/**
 * Extracts the actions from the clipboard data
 *
 * @param clipboardData - The clipboard data to extract the actions from
 * @returns The extracted actions or null if the clipboard data is invalid
 */
function extractClipboardActions(clipboardData: DataTransfer | null): LayoutAction[] | null {
  if (!clipboardData) return null;
  const clipboardText = clipboardData.getData('text');
  try {
    const parsed = JSON.parse(clipboardText);

    if (isClipboardData(parsed)) {
      return parsed.actions;
    }

    return null;
  } catch (_e) {
    // Not a valid JSON
    // Ignore, could be anything
    return null;
  }
}

/**
 * Type guard to check if the clipboard data is valid & contains the correct data
 *
 * @param clipboardData - The clipboard data to check
 * @returns Whether the clipboard data is valid & contains the correct data
 */
function isClipboardData(clipboardData: ActionsClipboardData): clipboardData is ActionsClipboardData {
  return typeof clipboardData === 'object' && 'id' in clipboardData && clipboardData.id === CLIPBOARD_ID;
}
