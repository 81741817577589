import React, { useCallback, useRef } from 'react';
import { Textarea as TextareaComponent } from '@oms/shared-frontend/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import type { FieldProps, ICommonTextField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ITextareaField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.TEXTAREA, string, TValidator> {
  input?: any;
  isDisabled?: boolean;
  label: string;
  isRequired?: boolean;
  providerRequired?: React.ReactNode;
  disabled?: boolean;
  rows?: number;
}

export const Textarea: React.FC<FieldProps<ITextareaField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    requiredFieldIndicatorStyle,
    selectAllOnFocus,
    autoFocus = false,
    isInvalid,
    hideFormControls,
    rows
  } = useFieldApi<ITextareaField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const textareaRef = useRef<HTMLInputElement>();

  const onInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }

      if (selectAllOnFocus) {
        textareaRef?.current?.select();
      }
    },
    [selectAllOnFocus, onFocus]
  );

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isVisible={isVisible}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
    >
      <TextareaComponent
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        name={name}
        onFocus={onInputFocus as any}
        ref={textareaRef as any}
        rows={rows}
      />
    </FieldWrapper>
  );
};
