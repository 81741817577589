import { useService } from '@oms/frontend-foundation';
import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type { TradeRow, TsTradesWithFilterSubscription } from '@oms/generated/frontend';
import { buildExecutionViewTradesColumnDefs } from './execution-view.trade.columns';
import { viewTradeAction } from '../trade-monitor/grid-actions/view.trade.action';
import { openModifyTradeAction } from '../trade-monitor/grid-actions/open-modify.trade.action';
import { cancelTradeAction } from '../trade-monitor/grid-actions/cancel.trade.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';

export const ExecutionTradesGrid = ({ tradeId }: { tradeId: string }) => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid(
    'execution-view-trades',
    (b: GridBuilder<TradeRow>) =>
      b
        .tableServerColumnLibrary(buildExecutionViewTradesColumnDefs())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TradeRow, TsTradesWithFilterSubscription>({
                query: TsTradesWithFilterDocument,
                getData: (r) => r.tsTradesWithFilter,
                filter: {
                  id: {
                    filterType: 'text',
                    type: 'equals',
                    filter: tradeId
                  }
                }
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .injectEvents([GridConfigEventHandler])
        .rowStateRules({})
        .sideBar()
        .rowSelection((c) => c.single())
        .actions((a) =>
          a.schema((s) =>
            s
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(viewTradeAction())
              .action(openModifyTradeAction())
              .action(cancelTradeAction())
              .action(ROW_SELECTION_COUNT_ACTION_TYPE)
              .action(refreshServersideCustomMenuAction)
          )
        )
        .reactiveCustomComponents(),
    [datasourceService, tradeId]
  );

  return <VGrid {...gridProps} />;
};
