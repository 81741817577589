import { BulkRouteOrderFormContract } from './bulk-route-order.contracts';
import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { FIXatdlStrategyValue } from '../route-order/fixatdl-strategy-field/fixatdl-strategy-field.contracts';

const fc = FieldContract.create<BulkRouteOrderFormContract, AdditionalFormFieldUnion>();

// Hidden field of IO Ids
export const ioIds = fc.field('ioIds', 'hidden-field');

export const percentage = fc.field('percentage', 'number-format').options({
  label: 'Percentage',
  format: 'percentage',
  // We hard code 100 percent in the sanitizer input, rather than set initialValue here.
  isDisabled: true
});

export const venue = createVenueField(fc, 'venue', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY,
  label: 'Route To'
});

export const strategy = fc.field('strategy', 'fixatdl-strategy').advancedSelectOptions<FIXatdlStrategyValue>({
  validate: [{ type: 'validateRouteOrderStrategy' }]
});

// Specify the component type of 'bulk-route-order'.
export const investorOrders = fc.field('investorOrders', 'bulk-route-order').options({});
