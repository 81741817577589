import { useService } from '@oms/frontend-foundation';
import { Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { NotificationSignal, NotificationVisibility } from './notifications.contracts';
import { NotificationVisiblitySignal } from './notifications.signals';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class NotificationsVisibilityService {
  constructor(@inject(NotificationVisiblitySignal) public visibilitySignal: NotificationVisiblitySignal) {}

  public watch$(): Observable<NotificationSignal> {
    return this.visibilitySignal.signal.$;
  }

  public getVisibility(): NotificationVisibility {
    return this.visibilitySignal.signal.get();
  }

  public setVisibility(visibility: NotificationVisibility): void {
    // In case Signal will contain Obj, destructuring will be needed to override only visibility

    this.visibilitySignal.signal.set(visibility);
  }
}

export const useNotificationsVisibilityService = () => {
  return useService(NotificationsVisibilityService);
};
