import './valstro.icon.css';

export const ValstroIcon = ({ width = 12, height = 12 }) => {
  return (
    <svg
      className="valstro-icon"
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="valstro-icon-background" x="0.5" width={width} height={height} rx="2" />
      <path
        className="valstro-icon-shape"
        d="M6.13827 9.56243H6.82238L9.5 2.51999H8.70283L6.48269 8.36714L4.29622 2.51999H3.5L6.13827 9.56243Z"
      />
    </svg>
  );
};
