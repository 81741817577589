import { Logger } from '@oms/shared/util';
import type { Client, Event, EventHint, Integration } from '@sentry/types';

export interface SentryRateLimiterConfig {
  /**
   * Maximum number of events to allow in a window of time
   */
  eventCount: number;

  /**
   * The window of time
   */
  timeWindowInMin: number;

  /**
   * When an event is being processed, return whether the event should be rate limited or not. If nothing is provided, all events will be rate limited.
   * @param args Matches the args of Sentry's processEvent fn
   * @returns A boolean where true says the event should adhere to rate limiting, and false ignores the event.
   */
  shouldRateLimit?: (...args: Parameters<SentryRateLimiter['processEvent']>) => boolean | Promise<boolean>;
}

const INTEGRATION_NAME = 'sentry_rate_limit';

/**
 * Configurable Rate Limiter for Sentry events.
 */
export class SentryRateLimiter implements Integration {
  private logger = Logger.named(SentryRateLimiter.name, { level: 'warn' });
  /**
   * Point in time(ms) when count should be reset.
   */
  private rateTimeWindow: number | undefined;

  /**
   * Number of events in a given window of time
   */
  private rateCount: number = 0;

  public name: string = INTEGRATION_NAME;
  public isDefaultInstance: boolean = true;

  constructor(private cfg: SentryRateLimiterConfig) {}

  public afterAllSetup(client: Client): void {
    const thisIntegration = client.getIntegrationByName(INTEGRATION_NAME);

    if (thisIntegration !== this) {
      this.logger.warn(
        'More than one instance of rate limit integration was provided. This may produce unexpected results.'
      );
    }
  }

  public async processEvent(event: Event, hint: EventHint, client: Client): Promise<Event | null> {
    const { shouldRateLimit: shouldRateLimitFn = () => true, timeWindowInMin, eventCount } = this.cfg;

    const shouldRateLimit = await shouldRateLimitFn(event, hint, client);

    if (!shouldRateLimit) {
      this.logger.trace('Rate limit ignored for event', { event, hint, client });
      return event;
    }

    const withinTimeWindow = this.rateTimeWindow && new Date().getTime() < this.rateTimeWindow;

    if (withinTimeWindow) {
      this.rateCount++;
      this.logger.trace('Rate limit count increased', {
        timeWindow: new Date(this.rateTimeWindow!).toISOString(),
        rateCount: this.rateCount
      });
    } else {
      const timestamp = new Date();
      this.rateTimeWindow = timestamp.getTime() + timeWindowInMin * 60_000;
      this.rateCount = 1;
      const timeoutTimestamp = new Date(this.rateTimeWindow).toISOString();
      this.logger.trace(`Rate limit time window set to [${timestamp.toISOString()} - ${timeoutTimestamp}]`);
    }

    const shouldIgnoreEvent = this.rateCount > eventCount;

    if (shouldIgnoreEvent) {
      this.logger.trace('Rate limit reached, ignoring event', {
        event,
        hint,
        client,
        rateCount: this.rateCount,
        rateTimeWindow: new Date(this.rateTimeWindow!).toISOString()
      });

      return null;
    }

    return event;
  }
}
