import { useMemo } from 'react';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import { ViewTabsList } from '@app/widgets/common/components/view.tabs-list';
import type { TabsComponentProps } from '@app/widgets/common/components/view.tabs-list';
import { ExecutionTradesGrid } from './execution-view.trade.grid';
import { ExecutionViewAuditTrailGrid } from './execution-view.audit-trail.grid';
import { withUpdatedExecution } from '@app/data-access/hoc/with-updated-execution';

const getTabsListProps = ({
  executionId,
  tradeId
}: {
  executionId: string;
  tradeId: string;
}): TabsComponentProps['tabs'] => [
  { name: 'Trade', value: 'trade', tabContent: <ExecutionTradesGrid tradeId={tradeId} /> },
  {
    name: 'Audit trail',
    value: 'auditTrail',
    tabContent: <ExecutionViewAuditTrailGrid executionId={executionId} />
  }
];

export const ExecutionViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<ExecutionViewLayoutComponentProps>();

  if (!props) {
    throw new Error('ExecutionViewTabbedGridsWidget requires a execution ID');
  }
  const tabsListProps = useMemo(
    () => getTabsListProps({ executionId: props.id, tradeId: props.tradeId }),
    [props.id, props.tradeId]
  );

  return <ViewTabsList defaultValue="trade" tabs={tabsListProps} />;
};

export default withUpdatedExecution(ExecutionViewTabbedGridsWidget);
