import type { SummaryGridField } from '@oms/frontend-foundation';
import type { TradeRow } from '@oms/generated/frontend';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import type { FormatType } from '@oms/shared-frontend/ui-design-system';

type FieldMetadata = {
  label: string;
  format?: FormatType;
  mapper?: (value: any) => string | undefined;
};

export const TRADE_DETAILS_FIELDS_MAP: Partial<Record<keyof TradeRow, FieldMetadata>> = {
  capacity: { label: 'Capacity' },
  createdTime: { label: 'Created time', format: 'datetime' },
  enteredBy: { label: 'Entered by' },
  id: { label: 'Trade ID', format: 'text' },
  instrumentDisplayCode: { label: 'Instrument' },
  lastMarket: { label: 'Last market' },
  lastTradeTime: { label: 'Trade datetime', format: 'datetime' },
  locate: { label: 'Locate' },
  normalizedLiquidityIndicator: { label: 'Normalized liquidity indicator' },
  owner: { label: 'Owner' },
  price: { label: 'Trade price', format: 'price' },
  quantity: { label: 'Quantity', format: 'quantity' },
  rawLiquidityIndicator: { label: 'Raw liquidity indicator' },
  settleDate: { label: 'Settle date' },
  settleType: { label: 'Settle type', mapper: mapSettleType },
  side: { label: 'Side' },
  status: { label: 'Status' },
  tradeContraAccount: { label: 'Trade contra account' },
  tradeContraAccountName: { label: 'Trade contra account name' },
  tradeCounterParty: { label: 'Trade counterparty' },
  tradingAccount: { label: 'Trading account' },
  tradingAccountName: { label: 'Account' },
  traderByName: { label: 'Trader' },
  tradingOrderId: { label: 'Trading order ID', format: 'text' },
  updatedTime: { label: 'Updated datetime', format: 'datetime' }
};

export type TradeSummaryFieldName = keyof typeof TRADE_DETAILS_FIELDS_MAP;

const tradeSummaryFields = (fieldNames: TradeSummaryFieldName[]): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    return {
      fieldName,
      ...(TRADE_DETAILS_FIELDS_MAP[fieldName] as FieldMetadata)
    };
  });
};

const tradeSummaryValues = (fieldNames: TradeSummaryFieldName[], order: Partial<TradeRow>) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        // Using const on the field name definition ensures we get type protection when specifying
        // the fields as a user, but using explicit types here under the hood is way to complex to appease
        // typescript :/ so I'm treating it as an explicit any
        const fieldDef = TRADE_DETAILS_FIELDS_MAP[fieldName] as Record<string, any>;
        const mapper = fieldDef.mapper;
        // Using lodash's get instead of reimplementing nested fields with . again
        const fieldValue = get(order, fieldName, undefined);
        if (fieldValue === null || fieldValue === undefined) {
          return { ...acc, [fieldName]: null };
        }
        return { ...acc, [fieldName]: (mapper ? mapper(fieldValue) : '') || String(fieldValue) };
      },
      {} as Record<string, string>
    )
  ];
};

export const tradeSummaryData = (
  label: string,
  fields: (keyof typeof TRADE_DETAILS_FIELDS_MAP)[],
  order: Partial<TradeRow>
) => {
  return {
    label,
    value: tradeSummaryValues(fields, order),
    fields: tradeSummaryFields(fields)
  };
};
