import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { JoinedInstrumentRestrictionRow, ValstroEntitlements } from '@oms/generated/frontend';
import { openInstrumentRestrictionEntry } from '@app/generated/sdk';
import { CreateActionIcon } from '@oms/frontend-foundation';

export const openEntryInstrumentRestriction: ActionDefFactory<JoinedInstrumentRestrictionRow> = (builder) =>
  builder
    .name('open_instrument_restriction_entry')
    .toolbar((toolbar) =>
      toolbar
        .id('open_instrument_restriction_entry_button')
        .component('action-icon-button')
        .location('StaticToolbar')
        .props({
          icon: <CreateActionIcon />
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .onChange((ctx) => {
      if (ctx.lifecycle !== 'change') return;

      openInstrumentRestrictionEntry(ctx.windowActor.id).catch((e) => console.error(e));
    });
