import { COMMON_PLATFORM_NAME, Plugin } from '@valstro/workspace';
import hotkeys from 'hotkeys-js';
import { SHORTCUT_CONFIG } from '@app/app-config/shortcut.config';
import type { ShortcutUnion } from '@app/app-config/shortcut.config';
import { globalShortcut$, windowShortcut$ } from '@app/data-access/memory/shortcuts.subject';
import * as globalShortcut from '@tauri-apps/plugin-global-shortcut';

export interface ShortcutsPluginOptions {
  onReady?: () => void;
}

/**
 * Shortcuts plugin
 * Registers cross-platform shortcuts
 */
export const shortcutsPlugin = ({ onReady }: ShortcutsPluginOptions = {}) =>
  Plugin.create({
    name: 'valstro-shortcuts-plugin',
    pluginFn: ({ workspace }) => {
      workspace.addHook('windowReady', async ({ isLeader, platformName }) => {
        disablePrintShortcutForDesktop(platformName);

        if (isLeader && platformName === COMMON_PLATFORM_NAME.TAURI) {
          await registerTauriShortcuts();
        } else if (platformName === COMMON_PLATFORM_NAME.BROWSER) {
          registerBrowserShortcuts();
        }

        onReady?.();
      });

      return function unsubscribe() {};
    }
  });

let hasRegistered = false;

/**
 * Registers Tauri shortcuts
 *
 * @param workspace - Workspace
 * @returns void
 */
export async function registerTauriShortcuts() {
  if (hasRegistered) {
    return;
  }

  const tauriShortcuts = Object.entries(SHORTCUT_CONFIG)
    .filter(([, value]) => value[COMMON_PLATFORM_NAME.TAURI])
    .map(([key, value]) => ({
      shortcut: key as ShortcutUnion,
      ...value[COMMON_PLATFORM_NAME.TAURI]
    }));

  for (const shortcut of tauriShortcuts) {
    const isRegistered = await globalShortcut.isRegistered(shortcut.keys);

    if (isRegistered) {
      await globalShortcut.unregister(shortcut.keys);
    }

    await globalShortcut.register(shortcut.keys, () => {
      globalShortcut$.next({
        platformName: COMMON_PLATFORM_NAME.TAURI,
        shortcut: shortcut.shortcut
      });
    });
  }

  hasRegistered = true;
}

/**
 * Registers Browser Shortcuts
 *
 * @param workspace - Workspace
 * @returns void
 */
export function registerBrowserShortcuts() {
  if (hasRegistered) {
    return;
  }

  const browserShortcuts = Object.entries(SHORTCUT_CONFIG)
    .filter(([, value]) => value[COMMON_PLATFORM_NAME.BROWSER])
    .map(([key, value]) => ({
      shortcut: key as ShortcutUnion,
      ...value[COMMON_PLATFORM_NAME.BROWSER]
    }));

  for (const shortcut of browserShortcuts) {
    hotkeys(shortcut.keys, (e: KeyboardEvent | undefined) => {
      e?.preventDefault();
      globalShortcut$.next({
        platformName: COMMON_PLATFORM_NAME.BROWSER,
        shortcut: shortcut.shortcut
      });
      windowShortcut$.next({
        platformName: COMMON_PLATFORM_NAME.BROWSER,
        shortcut: shortcut.shortcut
      });
    });
  }

  hasRegistered = true;
}

/**
 * Disables the print shortcut for desktop
 *
 * @param platformName - Platform name
 */
function disablePrintShortcutForDesktop(platformName: string) {
  const handler = (event: KeyboardEvent) => {
    if ((event.key === 'p' || event.key === 'P') && (event.ctrlKey || event.metaKey) && !event.altKey) {
      event.preventDefault();
      if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      } else {
        event.stopPropagation();
      }
      return;
    }
  };

  if (platformName === COMMON_PLATFORM_NAME.TAURI) {
    window.addEventListener('keydown', handler);
    document.addEventListener('keydown', handler);
  }
}
