import { RemoteProxy, Unsubscribe, createProxy, expose } from '@valstro/remote-link';
import { COMMON_PLATFORM_NAME, CreateActorDefinition, Plugin, internalWorkspace } from '../../../../core';
import {
  getDeactivatedWorkspaceURL,
  isDeactivatedWorkspaceURL,
  pageAccessedByReload
} from '../../../../core/utils';
import { WebviewWindow, getCurrentWebviewWindow } from '@tauri-apps/api/webviewWindow';
import { WindowOptions } from '@tauri-apps/api/window';
import type { WindowReadyContext, Workspace, WorkspaceWindowBootOptions } from '../../../../core/workspace';
import { logger } from '../../../../core/logger';
import { Event, UnlistenFn, listen } from '@tauri-apps/api/event';
import { EVENT_TYPE } from '../../../../core/events';
import { WindowContext } from '../../../common';
import { CUSTOM_WINDOW_EVENT, CustomWindowEvent } from '../window.actor.tauri.util';

// TODO: Move everything to the executor service?
// TODO: Check the popovers are NOT using quick windows

/**
 * Types
 */
type QuickWindowInternalAPI = {
  rebootWindow: (options?: WorkspaceWindowBootOptions) => Promise<void>;
};

export interface QuickTauriWindowPluginOptions {
  /**
   * Number of quick windows to pool in the background
   */
  bufferSize?: number;
  /**
   * Flag to determine if we should log debug information
   */
  debug?: boolean;
  /**
   * Default Tauri window options to apply to quick windows
   */
  defaultTauriOptions?: Partial<WindowOptions>;
  /**
   * List of window IDs that are ignored for quick windows
   */
  ignoredWindowIds?: string[];
  /**
   * Flag to determine if we should use quick windows when applying a snapshot
   */
  useForApplySnapshot?: boolean;
  /**
   * List of window IDs that are already opening.
   * Note, this is ONLY used if you preload the windows by adding it directly to the tauri conf file.
   */
  preloadedQuickWindowIds?: string[];
}

/**
 * Constants
 */
const QUICK_TAURI_CONSTANT = {
  PLUGIN_NAME: 'quick-tauri-windows-plugin',
  STORE_NAME: 'quick-tauri-windows-store',
  API_NAME: 'quick-tauri-windows-internal-api',
  IS_QUICK_WINDOW_URL_PARAM: 'isQuickWindow'
} as const;

export const QUICK_WINDOW_EVENT = {
  QUICK_WINDOW_READY: 'quick-window-ready'
} as const;

const defaultOptions = {
  bufferSize: 2,
  debug: false,
  defaultTauriOptions: {}
};

/**
 * Event helpers
 */

/**
 * Listen for the quick window ready event
 *
 * @param workspace - Workspace
 * @param callback - () => void
 * @returns Unsubscribe
 */
export function onQuickWindowReady(workspace: Workspace, callback: () => void) {
  return workspace.listen(EVENT_TYPE.CUSTOM_EVENT, (e) => {
    if (e.event === QUICK_WINDOW_EVENT.QUICK_WINDOW_READY) {
      callback();
    }
  });
}

/**
 * Listen for the quick window ready event AND the window ready event
 *
 * @param workspace - Workspace
 * @param callback - () => void
 * @returns Unsubscribe
 */
export function onQuickWindowAndWindowReady(
  workspace: Workspace,
  callback: (data: WindowReadyContext) => void
) {
  let quickWindowReady = false;
  let windowReadyContext: WindowReadyContext | null = null;

  // Function to check if both conditions are met and execute callback
  const checkAndExecuteCallback = () => {
    if (windowReadyContext && quickWindowReady) {
      callback(windowReadyContext);
    }
  };

  // Listen for window ready event
  const windowReadyUnsubscribe = workspace.addHook('windowReady', (data) => {
    windowReadyContext = data;
    checkAndExecuteCallback();
  });

  // Listen for quick window ready event
  const quickWindowReadyUnsubscribe = onQuickWindowReady(workspace, () => {
    quickWindowReady = true;
    checkAndExecuteCallback();
  });

  // Return a combined unsubscribe function
  return () => {
    windowReadyUnsubscribe();
    quickWindowReadyUnsubscribe();
  };
}

/**
 * Listen for the quick window ready event AND the leader window ready event
 *
 * @param workspace - Workspace
 * @param callback - (data: WindowReadyContext) => void
 * @returns Unsubscribe
 */
export function onQuickWindowAndLeaderWindowReady(
  workspace: Workspace,
  callback: (data: WindowReadyContext) => void
) {
  return onQuickWindowAndWindowReady(workspace, (data) => {
    if (data.isLeader) {
      callback(data);
    }
  });
}

/**
 * List of window IDs that are ignored for quick windows
 * Note: It checks if the ID includes the string, so it's not an exact match
 */
const ignoredWindowIds: string[] = ['popover-window'];

/**
 * Flag to determine if we should await any preloaded quick windows set out in preloadedQuickWindowIds
 */
let preloadedQuickWindowIds: string[] = [];
let usePreloadedQuickWindows = false;

/**
 * Flag to determine if we should use quick windows when applying a snapshot
 */
let useForApplySnapshot = false;

/**
 * Store Class
 * Keeps track of quick windows and their states
 */
class QuickWindowStore {
  /**
   * Keeps track of quick windows that are opening
   */
  opening: string[] = [];
  /**
   * Keeps track of quick windows that are active (after successfully opening)
   */
  active: string[] = [];
  /**
   * Keeps track of quick windows that are now being used. active → markedForUsage → removed
   * This marks the quick window for removal so the controller can replace it with a new one.
   */
  markedForUsage: string[] = [];

  constructor(opening: string[] = [], active: string[] = []) {
    this.opening = opening;
    this.active = active;
  }

  /**
   * Add a quick window to the opening state
   *
   * @param label - string
   */
  addOpening(label: string) {
    this.opening = this._ensureUnique([...this.opening, label]);
  }

  /**
   * Make a quick window active
   * by removing it from the opening state and adding it to the active state
   *
   * @param label - string
   */
  makeActive(label: string) {
    this._removeOpening(label);
    this.addActive(label);
  }

  /**
   * Add a quick window to the active state
   *
   * @param label - string
   */
  addActive(label: string) {
    this.active = this._ensureUnique([...this.active, label]);
  }

  /**
   * Remove all quick windows that are markedForUsage
   *
   * @returns number - count
   */
  removeAllMarkedForUsage() {
    const removedCount = this.markedForUsage.length;
    this.markedForUsage = [];
    return removedCount;
  }

  /**
   * Reset a quick window by removing it from all states
   *
   * @param label - string
   * @returns counts
   */
  reset(label: string) {
    const removedOpeningCount = this._removeOpening(label);
    const removedActiveCount = this._removeActive(label);
    return {
      removedOpeningCount,
      removedActiveCount
    };
  }

  /**
   * Reset a quick window by removing it from all states
   *
   * @param label - string
   * @returns counts
   */
  markActiveForRemoval(label: string) {
    this._removeActive(label);
    this.markedForUsage = this._ensureUnique([...this.markedForUsage, label]);
  }

  /**
   * Remove a quick window from the active state
   *
   * @param label - string
   * @returns counts - number
   */
  private _removeActive(label: string) {
    const currentLength = this.active.length;
    this.active = this.active.filter((l) => l !== label);
    return currentLength - this.active.length;
  }

  /**
   * Remove a quick window from the opening state
   *
   * @param label - string
   * @returns counts - number
   */
  private _removeOpening(label: string) {
    const currentLength = this.opening.length;
    this.opening = this.opening.filter((l) => l !== label);
    return currentLength - this.opening.length;
  }

  /**
   * Ensure labels are unique
   *
   * @param labels - string[]
   * @returns string[]
   */
  private _ensureUnique(labels: string[]) {
    return [...new Set(labels)];
  }
}

/**
 * Create a plugin that will open quick windows on the Tauri platform.
 * Quick windows are windows that are created in the background, so they are active to be used when needed.
 * This dramatically improves the performance of opening windows especially on Windows.
 *
 * Note: This plugin will NOT work when applying snapshots.
 *
 * @param options - QuickTauriWindowPluginOptions
 * @returns Plugin
 */
export const quickTauriWindowsPlugin = (options?: QuickTauriWindowPluginOptions) =>
  Plugin.create({
    name: QUICK_TAURI_CONSTANT.PLUGIN_NAME,
    pluginFn: async ({ workspace }) => {
      if (pageAccessedByReload === false) {
        preloadedQuickWindowIds = options?.preloadedQuickWindowIds || [];
        usePreloadedQuickWindows = preloadedQuickWindowIds.length > 0;
      } else {
        usePreloadedQuickWindows = false;
        preloadedQuickWindowIds = [];
      }

      useForApplySnapshot = options?.useForApplySnapshot || useForApplySnapshot;

      /**
       * Add ignored window IDs from options
       */
      if (options?.ignoredWindowIds) {
        ignoredWindowIds.push(...options.ignoredWindowIds);
      }

      /**
       * Only run on Tauri platform
       */
      if (workspace.getPlatformName() !== COMMON_PLATFORM_NAME.TAURI) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return function unsubscribe() {};
      }

      /**
       * Merge options with defaults
       */
      const { bufferSize, debug, defaultTauriOptions } = {
        ...defaultOptions,
        ...(options || {})
      };

      /**
       * Run logic directly after the leader is elected
       */
      workspace.addHook('leaderElection', async ({ isLeader }) => {
        /**
         * If leader, setup quick window controller
         * to manage quick windows from a central location.
         */
        if (isLeader) {
          await setupQuickWindowController(workspace, defaultTauriOptions, bufferSize, debug);
          return;
        }

        /**
         * Otherwise, setup quick window
         * This only applies if the window is actually a quick window.
         * If not, it will just return.
         *
         * Its job is to wait for usage by the TauriWindowActor
         * and then to be used instead of creating a new window
         */
        await setupQuickWindow(workspace);
      });

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return function unsubscribe() {};
    }
  });

/**
 * Variables to keep track of for the setupQuickWindowController function
 * So we can garbage collect them if we re-run this plugin (e.g. on hot reload)
 */
let unexposeStore: Unsubscribe | null = null;
let debugInterval: NodeJS.Timeout | null = null;
let unlistenWindowDestroyed: UnlistenFn | null = null;
let unsubscribeActive: Unsubscribe | null = null;

/**
 * Setup quick window controller to manage quick windows from a central location
 *
 * @param bufferSize - number
 * @returns void
 */
async function setupQuickWindowController(
  workspace: Workspace,
  defaultTauriOptions: Partial<WindowOptions>,
  bufferSize: number,
  debug: boolean
) {
  logger.debug('[Quick Tauri Windows Plugin] Setup Quick Window Controller');
  /**
   * Garbage collection if we re-run this plugin (e.g. on hot reload)
   */
  if (unexposeStore) {
    unexposeStore();
  }

  if (debugInterval) {
    clearInterval(debugInterval);
  }

  if (unlistenWindowDestroyed) {
    unlistenWindowDestroyed();
  }

  if (unsubscribeActive) {
    unsubscribeActive();
  }

  /**
   * Create store to keep track of quick windows and their states
   */
  const store = new QuickWindowStore(preloadedQuickWindowIds);

  /**
   * Debug store every 10 seconds
   */
  debugInterval = setInterval(() => {
    logger.debug('[Quick Tauri Windows Plugin] Store', {
      opening: [...store.opening],
      active: [...store.active],
      markedForUsage: [...store.markedForUsage]
    });
  }, 10_000);

  /**
   * Expose store to be accessed anywhere & create proxy to access store
   */
  [unexposeStore] = expose(store, QUICK_TAURI_CONSTANT.STORE_NAME, {
    awaitConfirmation: false
  });

  const storeProxy = quickTauriWindowStoreProxy();

  /**
   * Set-up a tauri listener to keep it's quick windows state in sync based on whether the quick windows are some-how closed.
   * Also, create new quick windows to replace the ones that have been closed
   */
  unlistenWindowDestroyed = await listen(
    CUSTOM_WINDOW_EVENT,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async (e: Event<CustomWindowEvent>) => {
      // Ignore leader window
      if (
        e.payload.event !== 'tauri://destroyed' ||
        e.payload.windowLabel === workspace.getLeaderProcessId()
      ) {
        return;
      }

      // If root is unavailable OR is NOT running, return early
      const rootActor = workspace.getRootWindowActor();
      if (!rootActor || (await workspace.getRootWindowActor()?.state()) !== 'running') {
        return;
      }

      const { removedActiveCount } = await storeProxy.reset.call(e.payload.windowLabel);

      if (removedActiveCount === 0) {
        return;
      }

      logger.debug(
        '[Quick Tauri Windows Plugin] Opening new quick windows to replace closed ones',
        removedActiveCount
      );

      // Create new quick window promises to replace the ones that have been closed
      for (let i = 0; i < removedActiveCount; i++) {
        await createQuickWindow(workspace, storeProxy, defaultTauriOptions, debug);
      }

      logger.debug(
        '[Quick Tauri Windows Plugin] Opened new quick windows to replace closed ones',
        removedActiveCount
      );
    },
    { target: { kind: 'Any' } }
  );

  /**
   * Set-up a window used listener to keep track of which quick windows have been used
   * So we can remove them from the store & create a new one to replace it.
   */
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  unsubscribeActive = storeProxy.markedForUsage.subscribe(async (markedForUsage) => {
    if (markedForUsage.length === 0) {
      return;
    }

    const removedUsedLabelsCount = await storeProxy.removeAllMarkedForUsage.call();

    if (removedUsedLabelsCount === 0) {
      return;
    }

    logger.debug(
      '[Quick Tauri Windows Plugin] Opening new quick windows to replace used ones',
      removedUsedLabelsCount
    );

    // await before creating new quick windows to allow the ACTUAL window to open first
    // Ironically, this makes the opening of a window quicker for the user
    await new Promise((resolve) => setTimeout(resolve, 500));

    for (let i = 0; i < removedUsedLabelsCount; i++) {
      await createQuickWindow(workspace, storeProxy, defaultTauriOptions, debug);
    }

    logger.debug(
      '[Quick Tauri Windows Plugin] Opened new quick windows to replace used ones',
      removedUsedLabelsCount
    );
  });

  /**
   * Start the opening of quick windows based on buffer size
   */
  if (usePreloadedQuickWindows) {
    logger.debug('[Quick Tauri Windows Plugin] Using preloaded quick windows', preloadedQuickWindowIds);

    // Poll active windows until all preloaded windows are active
    await new Promise<void>((resolve, reject) => {
      let timeout: NodeJS.Timeout | null = null;

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      const interval = setInterval(async () => {
        const active = await storeProxy.active.get();
        if (active.length === preloadedQuickWindowIds.length) {
          if (timeout) {
            clearTimeout(timeout);
          }
          clearInterval(interval);
          resolve();
        }
      }, 100);

      timeout = setTimeout(() => {
        clearInterval(interval);
        reject('Preloaded quick windows did not all become active');
      }, 60_000);
    });
  } else {
    logger.debug('[Quick Tauri Windows Plugin] Leading opening windows x', bufferSize);
    await openQuickWindows(workspace, storeProxy, defaultTauriOptions, bufferSize, debug);
  }

  internalWorkspace(workspace)
    ._eventsTransport.emit(EVENT_TYPE.CUSTOM_EVENT, {
      event: QUICK_WINDOW_EVENT.QUICK_WINDOW_READY
    })
    .catch(console.error);

  logger.debug('[Quick Tauri Windows Plugin] Quick Window Controller Ready');
}

/**
 * Variables to keep track of for the setupQuickWindow function
 * So we can garbage collect them if we re-run this plugin (e.g. on hot reload)
 */
let unexposeInternalAPI: Unsubscribe | null = null;
let unsubscribeActorDestroy: Unsubscribe | null = null;

/**
 * Setup quick window
 * This only applies if the window is actually a quick window.
 */
async function setupQuickWindow(workspace: Workspace) {
  logger.debug('[Quick Tauri Windows Plugin] Setup Quick Window', {
    tauriLabel: getCurrentWebviewWindow().label,
    unexposeInternalAPI,
    unsubscribeActorDestroy
  });
  /**
   * Garbage collection if we re-run this plugin (e.g. on hot reload)
   */
  if (unexposeInternalAPI) {
    unexposeInternalAPI();
  }

  if (unsubscribeActorDestroy) {
    unsubscribeActorDestroy();
  }

  /**
   * Return early if it's NOT a deactivated workspace URL
   * Because this means it's not a quick window
   */
  const isDeactivated = isDeactivatedWorkspaceURL();
  if (!isDeactivated) {
    logger.debug('[Quick Tauri Windows Plugin] De-activated, waiting');
    return;
  }

  /**
   * Return early if it's NOT a quick window
   */
  const storeProxy = await quickTauriWindowStoreProxyAsync();
  const storeData = await storeProxy.get();
  if (!storeData.opening.includes(getCurrentWebviewWindow().label)) {
    logger.debug(
      '[Quick Tauri Windows Plugin] Not an opening window, ignore: ',
      getCurrentWebviewWindow().label
    );
    return;
  }

  /**
   * Set-up a listener to detect if the main actor is destroyed
   * If so, close the quick window
   */
  unsubscribeActorDestroy = workspace.listen(EVENT_TYPE.ACTOR_BEFORE_DESTROY, (e) => {
    if (e.id === workspace.getLeaderProcessId()) {
      if (unsubscribeActorDestroy) {
        unsubscribeActorDestroy();
      }
      getCurrentWebviewWindow()
        .close()
        .catch((e) => console.error(e));
    }
  });

  /**
   * Expose an internal API to allow the quick window to reboot itself using the workspace.bootWindow method
   */
  const internalAPI: QuickWindowInternalAPI = {
    rebootWindow: async (options) => {
      // Unsubscribe & unexpose internal API if we are rebooting the window
      if (unsubscribeActorDestroy) {
        unsubscribeActorDestroy();
      }

      if (unexposeInternalAPI) {
        unexposeInternalAPI();
      }

      // Reboot window with new options
      await internalWorkspace(workspace).windowBoot({
        ...options,
        syncRootActor: true
      });
    }
  };

  [unexposeInternalAPI] = expose(internalAPI, getInternalAPIId(getCurrentWebviewWindow().label), {
    awaitConfirmation: false
  });

  /**
   * Remove quick window from opening window labels and add to active window labels
   * TODO: Error with subscribing & setting entire objects
   */
  logger.debug('[Quick Tauri Windows Plugin] Make active', getCurrentWebviewWindow().label);
  await storeProxy.makeActive.call(getCurrentWebviewWindow().label);
}

let proxy: RemoteProxy<QuickWindowStore, never> | null = null;

/**
 * Helper function to create a proxy to the quick window store
 *
 * @returns RemoteProxy<QuickWindowStore>
 */
function quickTauriWindowStoreProxy() {
  if (proxy) {
    return proxy;
  }
  proxy = createProxy<QuickWindowStore>(QUICK_TAURI_CONSTANT.STORE_NAME, {
    awaitConfirmation: false,
    responseTimeout: 30_000
  });
  return proxy;
}

/**
 * Helper function to create a proxy to the quick window store
 *
 * @returns RemoteProxy<QuickWindowStore>
 */
async function quickTauriWindowStoreProxyAsync() {
  if (proxy) {
    return proxy;
  }

  proxy = await createProxy<QuickWindowStore>(QUICK_TAURI_CONSTANT.STORE_NAME, {
    awaitConfirmation: true,
    responseTimeout: 30_000,
    retryEvery: 100
  });

  return proxy;
}

/**
 * Helper function to create a proxy to the quick window internal API
 *
 * @param label - string
 * @returns RemoteProxy<QuickWindowInternalAPI>
 */
function quickTauriWindowInternalAPIProxy(label: string) {
  return createProxy<QuickWindowInternalAPI>(getInternalAPIId(label), {
    awaitConfirmation: false,
    responseTimeout: 5_000
  });
}

/**
 * Helper function to get the internal API ID for the exposed internal API
 *
 * @param label - string
 * @returns string
 */
function getInternalAPIId(label: string) {
  return `${QUICK_TAURI_CONSTANT.API_NAME}_${label}`;
}

/**
 * Loop through and open quick windows based on buffer size
 *
 * @param store - QuickWindowStore
 * @param proxy - RemoteProxy<QuickWindowStore>
 * @param bufferSize - number
 * @returns void
 */
async function openQuickWindows(
  workspace: Workspace,
  storeProxy: RemoteProxy<QuickWindowStore>,
  defaultTauriOptions: Partial<WindowOptions>,
  bufferSize: number,
  debug: boolean
) {
  const promises: Promise<void>[] = [];
  for (let i = 0; i < bufferSize; i++) {
    promises.push(createQuickWindow(workspace, storeProxy, defaultTauriOptions, debug));
  }

  await Promise.allSettled(promises);
}

/**
 * Create a quick window
 * It only resolves when the quick window is in active state, not when it's opened according to Tauri.
 *
 * @param id - string
 * @param proxy - RemoteProxy<QuickWindowStore>
 * @returns Promise<void>
 */
function createQuickWindow(
  workspace: Workspace,
  storeProxy: RemoteProxy<QuickWindowStore>,
  defaultTauriOptions: Partial<WindowOptions>,
  debug = false
) {
  return new Promise<void>((resolve, reject) => {
    let unsub: Unsubscribe | null = null;
    let timeout: NodeJS.Timeout | null = null;
    const id = workspace.generateUUID();

    // Resolve when quick window is in active state
    // TODO: Error with subscribing & setting entire objects
    unsub = storeProxy.active.subscribe((active) => {
      if (active.find((l) => l === id)) {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (unsub) {
          unsub();
        }
        logger.debug('[Quick Tauri Windows Plugin] Quick window found', id);
        resolve();
      }
    });

    storeProxy.addOpening
      .call(id)
      .then(() => {
        // Create timeout if quick window is taking too long, probably due to an error/failed stated.
        timeout = setTimeout(() => {
          if (unsub) {
            unsub();
          }
          reject(`Quick Tauri window ${id} opening timeout`);
        }, 60_000);

        // Open quick window
        createTauriWindow(id, {
          url: createQuickWindowURL(),
          width: 250,
          height: 250,
          shadow: false,
          windowEffects: {
            effects: []
          },
          dragDropEnabled: false,
          ...defaultTauriOptions,
          visible: debug ? true : false,
          focus: false,
          skipTaskbar: true
        }).catch((e) => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (unsub) {
            unsub();
          }
          console.error(e);
          reject(e);
        });
      })
      .catch((e) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (unsub) {
          unsub();
        }
        console.error(e);
        reject(e);
      });
  });
}

/**
 * Create a Tauri window & resolve when it's actually created
 *
 * @param label - string
 * @param options - WindowOptions
 * @returns WebviewWindow
 */
function createTauriWindow(
  label: string,
  options: WindowOptions & { url?: string; dragDropEnabled?: boolean }
): Promise<WebviewWindow> {
  return new Promise<WebviewWindow>((resolve, reject) => {
    const window = new WebviewWindow(label, { ...options, useHttpsScheme: true });
    resolve(window);
    window
      .once('tauri://created', () => {
        resolve(window);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
    window
      .once('tauri://error', (e) => {
        console.error(e);
        reject(e);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

/**
 * Create a quick window URL
 *
 * @returns string
 */
function createQuickWindowURL() {
  const url = getDeactivatedWorkspaceURL();
  url.searchParams.set(QUICK_TAURI_CONSTANT.IS_QUICK_WINDOW_URL_PARAM, 'true');
  return url.toString();
}

/**
 * Maybe use a quick window if some are active
 * This will remove the quick window from the active window labels
 * And make sure to open a new quick window to replace it
 *
 * @param label - string
 * @param proxy - RemoteProxy<QuickWindowStore>
 * @returns WebviewWindow
 */
export async function maybeUseQuickWindowWebview<TContext extends WindowContext = WindowContext>(
  workspace: Workspace,
  options: CreateActorDefinition<TContext>
): Promise<WebviewWindow | null> {
  try {
    // Return early if we are applying a snapshot and we are not allowed to use quick windows
    if (options.advanced?.applyingSnapshotFromId && !useForApplySnapshot) {
      return null;
    }

    // First check the opening options do not violate the quick window rules
    if (options.id && ignoredWindowIds.some((id) => options.id?.includes(id))) {
      logger.debug(`[Quick Tauri Window Plugin] Skipping quick window for ${options.id} due to banned ID`);
      return null;
    }

    // First check the plugin exists
    const plugin = workspace.getPlugin(QUICK_TAURI_CONSTANT.PLUGIN_NAME);
    if (!plugin) {
      return null;
    }

    // Then check if a quick window is active
    const storeProxy = await quickTauriWindowStoreProxyAsync();
    const currentActiveLabels = await storeProxy.active.get();
    const hasQuickWindow = currentActiveLabels.length > 0;

    if (!hasQuickWindow) {
      return null;
    }

    const pluckedLabel = currentActiveLabels[0];

    const proxyInternalAPI = quickTauriWindowInternalAPIProxy(pluckedLabel);

    await storeProxy.markActiveForRemoval.call(pluckedLabel);

    await proxyInternalAPI.rebootWindow.call({
      forceActive: true,
      initialDefinition: {
        ...options,
        context: {
          ...options.context,
          isFocused: options.context?.isFocused === undefined ? true : options.context.isFocused,
          isVisible: true,
          skipTaskbar: false
        }
      }
    });

    // Return the webview for this quick window
    const webview = await WebviewWindow.getByLabel(pluckedLabel);

    if (!webview) {
      throw new Error(`No webview with label ${pluckedLabel} found`);
    }

    return webview;
  } catch (e) {
    console.warn(`Failed to use a quick window for ${options.id}, using a regular window instead`, e);
    return null;
  }
}
