import type { ColDef, GridApi } from '@ag-grid-community/core';
import type { Optional } from '@oms/shared/util-types';
import { Logger } from '@oms/shared/util';
import type { ColumnBuilderField } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';

type InternalToggle = 'groupedByInstrument';
type InternalToggleStatus = Partial<Record<InternalToggle, boolean>>;

interface InternalMemory {
  instrumentColumnHidden?: boolean;
  accountColumnHidden?: boolean;
}

interface InitOptions {
  gridId?: string;
}

export class PositionsInstrumentGridService {
  public readonly scopedActorId: string;
  protected _gridId?: string;
  protected gridApi?: GridApi<PositionRow>;

  protected _toggles: InternalToggleStatus = {};
  protected _memory?: Partial<InternalMemory>;

  protected logger: Logger;

  // 🏗️ Constructor --------------------------------------------------------------- /

  public constructor(scopedActorId: string) {
    this.scopedActorId = scopedActorId;
    this.logger = Logger.named(this.label);
  }

  // 📢 Public --------------------------------------------------------------- /

  public get isReady(): boolean {
    return typeof this.gridApi !== 'undefined';
  }

  public get gridId(): Optional<string> {
    return this._gridId;
  }

  public get isGroupedByInstrument(): boolean {
    return this._toggles.groupedByInstrument ?? false;
  }

  public init(gridApi: GridApi<PositionRow>, options?: InitOptions): PositionsInstrumentGridService {
    const { gridId } = options ?? {};
    this.gridApi = gridApi;
    if (gridId) this._gridId = gridId;
    this.logger.scope('init').debug(`Initialized${gridId ? ` for grid ID: ${gridId}` : ''}`);
    return this;
  }

  public toggleIsGroupedByInstrument(): Record<'before' | 'after', boolean> {
    const { isGroupedByInstrument: before } = this;
    const isGrouped = !before;
    this._toggles.groupedByInstrument = isGrouped;
    this.onGroupByInstrument(isGrouped);
    return { before, after: isGrouped };
  }

  // 👁️ Protected / Private --------------------------------------------------------------- /

  // Group by instrument ------------------------------------------------- /

  protected onGroupByInstrument(isGrouped?: boolean): boolean {
    if (!this.gridApi) throw this.error('Grid API is not available');
    const { columnDefs, instrumentColDef, accountColDef } = this.getColumnDefsFor({
      instrumentColDef: 'instrument.mappings.displayCode',
      accountColDef: 'account.name'
    });
    if (instrumentColDef && accountColDef) {
      const isGroupedByInstrument = typeof isGrouped == 'boolean' ? isGrouped : this.isGroupedByInstrument;
      const { hide: instrumentColumnHidden } = instrumentColDef;
      const { hide: accountColumnHidden } = accountColDef;
      if (isGroupedByInstrument) this.updateMemory({ instrumentColumnHidden, accountColumnHidden });
      instrumentColDef.rowGroup = isGroupedByInstrument;
      instrumentColDef.hide = isGroupedByInstrument ? true : this.getMemory('instrumentColumnHidden', false);
      accountColDef.hide = isGroupedByInstrument ? true : this.getMemory('accountColumnHidden', false);
      this.gridApi.updateGridOptions({ columnDefs });
      this.logger
        .scope('onGroupByInstrument')
        .debug(isGroupedByInstrument ? 'Grid grouped by instrument' : 'Grid grouping off');
    }
    return this.isGroupedByInstrument;
  }

  protected getColumnDefsFor<Key extends string>(columns: Record<Key, ColumnBuilderField<PositionRow>>) {
    const { columnDefs } = this;
    const requestedColDefs = (Object.entries(columns) as [Key, ColumnBuilderField<PositionRow>][]).reduce(
      (colDefs, [key, column]) => {
        colDefs[key] = columnDefs.find(({ colId }) => colId === column);
        return colDefs;
      },
      {} as Record<Key, Optional<ColDef<PositionRow>>>
    );
    return { columnDefs, ...requestedColDefs };
  }

  protected get columnDefs(): ColDef<PositionRow>[] {
    return (this.gridApi?.getColumnDefs() ?? []) as ColDef<PositionRow>[];
  }

  /// Internal memory -------------------------------------------------------- /

  protected getMemory<T extends keyof InternalMemory>(key: T): Optional<InternalMemory[T]>;
  protected getMemory<T extends keyof InternalMemory>(
    key: T,
    or: Required<InternalMemory>[T]
  ): Required<InternalMemory>[T];
  // Implementation only
  protected getMemory<T extends keyof InternalMemory>(
    key: T,
    or?: InternalMemory[T]
  ): Optional<InternalMemory[T]> {
    return this._memory?.[key] ?? or;
  }

  protected updateMemory(values: Partial<InternalMemory>): void {
    const current = this._memory ?? {};
    this._memory = { ...current, ...values };
  }

  protected clearMemory(...keys: (keyof InternalMemory)[]): void {
    if (this._memory) {
      keys.forEach((key) => {
        delete this._memory?.[key];
      });
      if (Object.keys(this._memory).length === 0) delete this._memory;
    }
  }

  /// Error handling and logging ------------------------------------------------------ /

  protected error(message: string): Error {
    return new Error(`${this.label}: ${message}`);
  }

  protected get label(): string {
    return `PositionsInstrumentGridService(${this.scopedActorId})`;
  }
}

export default PositionsInstrumentGridService;
