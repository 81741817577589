import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import {
  instrumentRestrictionContract,
  InstrumentRestrictionFormContract
} from './instrument-restriction-entry.form-contract';

import {
  InstrumentRestrictionEntryInput,
  InstrumentRestrictionEntryOutput
} from './instrument-restriction-entry.form-common';
import { RefDataRestrictionTypeEnum } from '@oms/generated/frontend';
import { ReferenceDataService } from '@app/data-access/services/trading/reference-data/reference-data.service';

export const instrumentRestrictionEntryBuilder = FormBuilder.create<
  InstrumentRestrictionEntryInput,
  InstrumentRestrictionEntryOutput
>('instrument-restriction-entry')
  .contract<InstrumentRestrictionFormContract>(instrumentRestrictionContract)
  .type('instrument-restriction-entry')
  .sanitizer((s) =>
    s
      .input((formValues) => ({
        ...formValues,
        restrictionEffectiveDate: formValues.restrictionEffectiveDate
          ? new Date(formValues.restrictionEffectiveDate)
          : undefined,
        restrictionExpiryDate: formValues.restrictionExpiryDate
          ? new Date(formValues.restrictionExpiryDate)
          : undefined
      }))
      .output((formValues) => ({
        ...formValues,
        instrumentId: formValues.symbol?.id || '',
        restrictionType: formValues.restrictionType ?? RefDataRestrictionTypeEnum.Restricted,
        account: formValues.account?.id || '',
        id: formValues.id ? parseInt(formValues.id) : undefined,
        applyToFungible: formValues.fungible,
        applyToRelated: formValues.related
      }))
  )
  .change(async (event, ctx) => {
    const refDataService = ctx.container.resolve(ReferenceDataService);
    if (event.type === FORM_EVENT_TYPE.SUBMIT) {
      if ('id' in event.payload.output && event.payload.output.id) {
        //If we pass 'id' property in output it is a modify instrument restriction form.
        return await refDataService.modifyInstrumentRestriction(event.payload.output);
      }

      return await refDataService.createInstrumentRestriction(event.payload.output);
    }
  });

export default instrumentRestrictionEntryBuilder;
export type RouteOrderFormBuilderType = typeof instrumentRestrictionEntryBuilder;
