import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import {
  Box,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip
} from '@oms/shared-frontend/ui-design-system';
import { FormValidationFeedbackLevel } from '@oms/generated/frontend';
import type { FeedbackWrapper, RepairError } from '@oms/generated/frontend';
export const ERRORS_CELL_RENDERER = 'errorsCellRenderer';

const ErrorText = ({ error }: { error: FeedbackWrapper }) => {
  const warnStyle =
    error?.level === FormValidationFeedbackLevel.Warning ? { color: 'var(--colors-text-warning)' } : {};
  return (
    <Text type={'gridBase'} style={warnStyle}>
      {error?.level}: {error?.message}
    </Text>
  );
};

const ErrorList = ({ errors }: { errors: FeedbackWrapper[] }) => (
  <ul style={{ padding: 0 }}>
    {errors.map((error) => (
      <li key={error?.code} style={{ listStyleType: 'none' }}>
        <ErrorText error={error} />
      </li>
    ))}
  </ul>
);

/**
 * Renderer for the ValidationError object type.
 */
export const ErrorsCellRenderer: React.FC<ICellRendererParams<FeedbackWrapper>> = ({
  value
}: {
  value: FeedbackWrapper[];
}) => {
  if (!value || value.length === 0) return null;

  return value.length === 1 ? (
    <ErrorText error={value[0]} />
  ) : (
    <Tooltip label={<ErrorList errors={value} />}>{value.length} errors</Tooltip>
  );
};

/**
 * Renderer for the RepairError object, meant for the error message column.
 */
export const RepairErrorMessageRenderer: React.FC<ICellRendererParams> = ({
  value
}: {
  value: RepairError[];
}) => {
  if (!value) return null;

  if (value.length === 1) {
    return <span>{value[0]?.message}</span>;
  }

  if (value.length > 1) {
    const list = value.map((error) => (
      <DropdownMenuItem key={error?.errorCode || ''}>{error?.message}</DropdownMenuItem>
    ));

    return (
      <DropdownMenuRoot>
        <DropdownMenuTrigger asChild>
          <Box type="button" role="button" sx={{ cursor: 'pointer', color: 'BrandBlue.300' }}>
            {value.length} error messages
          </Box>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent>{list}</DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenuRoot>
    );
  }

  return null;
};

/**
 * Renderer for the RepairError object, meant for the error code column.
 */
export const RepairErrorCodeRenderer: React.FC<ICellRendererParams> = ({
  value
}: {
  value: RepairError[];
}) => {
  if (!value) return null;

  if (value.length === 1) {
    return <span>{value[0]?.errorCode}</span>;
  }

  if (value.length > 1) {
    const list = value.map((error) => (
      <DropdownMenuItem key={error?.errorCode || ''}>{error?.errorCode}</DropdownMenuItem>
    ));

    return (
      <DropdownMenuRoot>
        <DropdownMenuTrigger asChild>
          <Box type="button" role="button" sx={{ cursor: 'pointer', color: 'BrandBlue.300' }}>
            {value.length} error codes
          </Box>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent>{list}</DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenuRoot>
    );
  }

  return null;
};

/**
 * Renderer for a FeedbackWrapper[] object, meant for a validation column.
 */
export const FeedbackWrappersRenderer: React.FC<ICellRendererParams> = ({
  value
}: {
  value: FeedbackWrapper[];
}) => {
  if (!value) return null;

  if (value.length === 1) {
    // If it's only 1, make it a tooltip. Also set the style if it's a warning.
    return (
      <Tooltip label={value[0]?.message}>
        {value[0]?.level === FormValidationFeedbackLevel.Warning ? (
          <span style={{ color: 'var(--colors-text-warning)' }}>{value[0]?.message}</span>
        ) : (
          <span>{value[0]?.message}</span>
        )}
      </Tooltip>
    );
  }

  if (value.length > 1) {
    const list = value.map((error) => (
      <Flex
        key={error?.code || ''}
        style={{
          alignItems: 'center',
          height: 24,
          padding: '0 4px',
          backgroundColor:
            error?.level === FormValidationFeedbackLevel.Error ? 'var(--colors-base-Red-900)' : 'transparent'
        }}
      >
        <Text style={{ whiteSpace: 'nowrap' }}>{error?.message}</Text>
        <Text type="smallR" sx={{ marginLeft: 'small', color: 'text.semiMinor' }}>
          {error?.level === FormValidationFeedbackLevel.Error
            ? 'Validation Error'
            : error?.level === FormValidationFeedbackLevel.Warning
              ? 'Warning'
              : error?.level === FormValidationFeedbackLevel.Info
                ? 'Info'
                : ''}
        </Text>
      </Flex>
    ));

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Box type="button" role="button" sx={{ cursor: 'pointer', color: 'BrandBlue.300' }}>
            {value.length} messages
          </Box>
        </PopoverTrigger>
        <PopoverContent
          hasClose={false}
          hasArrow={false}
          style={{
            width: '100%',
            backgroundColor: 'var(--colors-layout-level3)'
          }}
        >
          {list}
        </PopoverContent>
      </Popover>
    );
  }

  return null;
};
