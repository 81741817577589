import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import type { AnyRecord } from '@oms/frontend-foundation';
import { type ActionDefFactory } from '../models/actions.model';
import { ColDef } from '@ag-grid-community/core';
import { FC } from 'react';

export const TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE = 'toggle-filters-and-sidebar';

export function createToggleFiltersAndSidebarAction<TData extends AnyRecord>(): ActionDefFactory<TData> {
  return (b) =>
    b
      .name('toggle_filters_and_sidebar')
      .toolbar((t) =>
        t.component('action-icon-button').id('toggle_filters_and_sidebar_button').location('StaticToolbar')
      )
      .onChange<AnyRecord>((e) => {
        if (e.lifecycle === 'init') {
          const columnDefs = e.api.getColumnDefs() as ColDef[];
          const filtersVisible = columnDefs.some((colDef) => colDef.floatingFilter);
          e.api.setSideBarVisible(filtersVisible);

          e.notify({
            icon: filtersVisible ? <HideFiltersAndSidebarIcon /> : <ShowFiltersAndSidebarIcon />
          });
        }

        if (e.lifecycle === 'change') {
          const columnDefs = e.api.getColumnDefs() as ColDef[];
          const filtersVisible = columnDefs.some((colDef) => colDef.floatingFilter);
          const showFilters = !filtersVisible;

          columnDefs.forEach((colDef) => {
            colDef.floatingFilter = showFilters;
            colDef.suppressHeaderFilterButton = !showFilters;
          });
          e.api.updateGridOptions({ columnDefs });
          e.api.setSideBarVisible(showFilters);
          e.notify({
            icon: showFilters ? <HideFiltersAndSidebarIcon /> : <ShowFiltersAndSidebarIcon />
          });
        }
      });
}

export const ShowFiltersAndSidebarIcon: FC<{}> = () => {
  return <EyeOpenIcon aria-label="Show filters and sidebar" />;
};

export const HideFiltersAndSidebarIcon: FC<{}> = () => {
  return <EyeNoneIcon aria-label="Hide filters and sidebar" />;
};
