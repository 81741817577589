import { openTradeView } from '@app/generated/sdk';
import { getViewDialogTitle } from '../orders.utils';
import type { ViewDialogInfo } from '../orders.utils';
import { useCallback } from 'react';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';

export interface OpenViewTradeArgs {
  windowId: string;
  dialogInfo: ViewDialogInfo;
}

export const openViewTrade = async ({ windowId, dialogInfo }: OpenViewTradeArgs) => {
  await openTradeView(windowId, {
    componentProps: {
      id: dialogInfo.id || ''
    },
    title: getViewDialogTitle(dialogInfo, 'trade')
  });
};

export const useOpenViewTrade = (dialogInfo?: ViewDialogInfo) => {
  const windowId = useLeaderOrTabId();

  return useCallback(() => {
    if (!dialogInfo) {
      console.error('dialogInfo is null or undefined');
      return;
    }
    openViewTrade({ windowId, dialogInfo }).catch((e) => console.error(e));
  }, [windowId, dialogInfo]);
};
