import { useLayoutEffect, useRef } from 'react';
import { type AnyRecord } from '../../../../../common/type.helpers';
import type { EnhancedFormOptions } from '../../../../types';
import { useEnhancedFormApi } from '../../../helpers/form-api/form-api';
import type {
  FormBuilderContext,
  OnValuesChanging,
  OnSanitizedValuesChanged,
  OnValuesChanged,
  OnValuesChangedCtx
} from '../../../form-builder.common.types';
import {
  FORM_RENDERER_EVENT_TYPE,
  type FormRendererEvent,
  type FormRendererEventReset,
  type FormRendererEventSetFieldValues
} from '../../../form-builder.events.renderer';
import { filter, map, tap, debounceTime, Subject, merge } from 'rxjs';
import type { FormBuilderSanitizerDefinition } from '../../../form-builder-sanitizer.class';
import type { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { isPromiseLike } from '@oms/shared/util';
import {
  scanActiveValues,
  scanActiveSmartDiff,
  scanValues
} from './form-builder-template.wrapper.hooks.operators';
import {
  FORM_EVENT_TYPE,
  formBuilderRemoteEvent$,
  type RemoteFormBuilderEventValuesChanged
} from '../../../form-builder.events';
import { useCurrentWindow } from '@valstro/workspace-react';

export const VALUES_CHANGE_DEBOUNCE_TIME = 75;

/**
 * Hook to get the formApi and set it in the parent component / context
 *
 * @param setFormApi - Callback to set the formApi in the parent component / context
 * @returns The formApi
 */
export function useHandleFormApi<TFormFieldValues extends AnyRecord>(
  setFormApi: (formApi: EnhancedFormOptions<TFormFieldValues>) => void
): EnhancedFormOptions<TFormFieldValues> {
  const formApi = useEnhancedFormApi<TFormFieldValues>();
  const hasSetFormApiRef = useRef(false);

  useLayoutEffect(() => {
    if (!hasSetFormApiRef.current) {
      setFormApi(formApi);
      hasSetFormApiRef.current = true;
    }

    return () => {
      hasSetFormApiRef.current = false;
    };
  }, [setFormApi, formApi]);

  return formApi;
}

/**
 * Listem for external form events and update the internal form values
 *
 * @param formApi - The formApi to use
 * @param formId - The formId to listen for
 */
export function useHandleExternalFormEvents<TFormFieldValues extends AnyRecord>(
  eventBus$: BroadcastSubject<FormRendererEvent<AnyRecord, AnyRecord>>,
  formApi: EnhancedFormOptions<TFormFieldValues>,
  formId: string
) {
  const currentWindow = useCurrentWindow();
  useLayoutEffect(() => {
    const valuesSubscription = eventBus$
      .pipe(
        filter((e) => e.meta.formId === formId && e.type === FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES),
        map((e) => e as FormRendererEventSetFieldValues<TFormFieldValues>)
      )
      .subscribe((e) => {
        for (const key in e.payload.fieldValues) {
          formApi.change(key, e.payload.fieldValues[key]);
        }
      });

    const resetSubscription = eventBus$
      .pipe(
        filter((e) => e.meta.formId === formId && e.type === FORM_RENDERER_EVENT_TYPE.RESET),
        map((e) => e as FormRendererEventReset<TFormFieldValues>)
      )
      .subscribe((e) => {
        formApi.reset(e?.payload?.formValues);
      });

    const closeSubscription = eventBus$
      .pipe(filter((e) => e.meta.formId === formId && e.type === FORM_RENDERER_EVENT_TYPE.CLOSE))
      .subscribe(() => {
        currentWindow.operations.close().catch(console.error);
      });

    return () => {
      valuesSubscription.unsubscribe();
      resetSubscription.unsubscribe();
      closeSubscription.unsubscribe();
    };
  }, [eventBus$, formApi, formId, currentWindow]);
}

/**
 * Hook to handle form values changing and run the appropriate callbacks
 *
 * @param formApi - The formApi to use
 * @param onValuesChanging - The callback to call when the values are changing
 * @param onValuesChanged - The callback to call when the values have changed
 * @param onSanitizedValuesChanged - The callback to call when the sanitized values have changed
 */
export function useHandleFormValuesChanged<
  TFormFieldValues extends AnyRecord,
  TOutputContract extends AnyRecord
>(
  formId: string,
  formApi: EnhancedFormOptions<TFormFieldValues>,
  eventContext: FormBuilderContext<TFormFieldValues>,
  sanitizer?: FormBuilderSanitizerDefinition<any, any, TFormFieldValues>,
  onValuesChanging?: OnValuesChanging<TFormFieldValues>,
  onValuesChanged?: OnValuesChanged<TFormFieldValues>,
  onSanitizedValuesChanged?: OnSanitizedValuesChanged<TFormFieldValues, TOutputContract>
) {
  useLayoutEffect(() => {
    const valuesChanging$ = formApi
      .get$({ values: true })
      .pipe(debounceTime(VALUES_CHANGE_DEBOUNCE_TIME), scanValues<TFormFieldValues>());

    const activeValues$ = formApi
      .get$({ active: true, values: true })
      .pipe(debounceTime(VALUES_CHANGE_DEBOUNCE_TIME));

    const valuesChanged$ = activeValues$.pipe(scanActiveValues<TFormFieldValues>());

    const smartValuesChanged$ = valuesChanged$.pipe(scanActiveSmartDiff<TFormFieldValues>());

    const valuesChangingSub = valuesChanging$
      .pipe(
        tap(({ currentValues, formValuesDiff, prevFormValues }) => {
          async function runCallback() {
            const result = onValuesChanging?.({ formValues: currentValues, formValuesDiff, prevFormValues });
            if (isPromiseLike(result)) {
              await result;
            }
          }
          runCallback().catch(console.error);
        })
      )
      .subscribe();

    // Remote values changed is fired from all fields dialog
    const remoteValuesChangedEvent$ = formBuilderRemoteEvent$.asObservable().pipe(
      filter((e) => e.type === FORM_EVENT_TYPE.REMOTE_VALUES_CHANGED && e.meta.formId === formId),
      map((e) => (e as RemoteFormBuilderEventValuesChanged<TFormFieldValues>).payload)
    );

    const smartValuesChangedSubject = new Subject<OnValuesChangedCtx<TFormFieldValues>>();

    const smartValuesChangedSub = merge(smartValuesChanged$, remoteValuesChangedEvent$)
      .pipe(
        filter((v) => {
          const isSmartEvent = 'hasValuesDiff' in v;
          if (isSmartEvent) {
            return v.hasValuesDiff;
          } else {
            return true; // Always pass through remote events from remoteValuesChangedEvent$
          }
        }),
        tap((value) => {
          async function runCallback() {
            const payload =
              'hasValuesDiff' in value
                ? {
                    formValues: value.currentValues,
                    formValuesDiff: value.valuesDiff,
                    prevFormValues: value.prevValues,
                    modifiedFields: formApi.getModifiedFields()
                  }
                : value;

            const result = onValuesChanged?.(payload);

            if (isPromiseLike(result)) {
              await result;
            }

            smartValuesChangedSubject.next(payload);
          }
          runCallback().catch(console.error);
        })
      )
      .subscribe();

    // onSanitizedValuesChanged is called after onValuesChanged, but with a debounced delay, this is to ensure that any
    // changes made by onValuesChanged are reflected in the sanitized values
    const sanitizedValuesChangedSub = smartValuesChangedSubject
      .asObservable()
      .pipe(
        debounceTime(VALUES_CHANGE_DEBOUNCE_TIME),
        tap((value) => {
          async function runCallback() {
            if (sanitizer && onSanitizedValuesChanged) {
              const output = await sanitizer.output(value.formValues, eventContext);
              if (output) {
                const result = onSanitizedValuesChanged({ ...value, output });
                if (isPromiseLike(result)) {
                  await result;
                }
              }
            }
          }
          runCallback().catch(console.error);
        })
      )
      .subscribe();

    return () => {
      valuesChangingSub.unsubscribe();
      smartValuesChangedSub.unsubscribe();
      sanitizedValuesChangedSub.unsubscribe();
    };
  }, [formApi, onValuesChanged, onSanitizedValuesChanged, onValuesChanging, sanitizer]);
}
