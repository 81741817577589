import { COMPONENT_ENUM } from '@app/generated/common';
import TimeAndSalesComponent from '@app/widgets/marketdata/time-and-sales/time-and-sales.widget';
import CurrenciesGridComponent from '@app/widgets/reference-data/currencies/currencies-grid.widget';
import ExchangesGridComponent from '@app/widgets/reference-data/exchanges/exchanges-grid.widget';
import InstrumentRestrictionsGridComponent from '@app/widgets/reference-data/instrument-restrictions/instrument-restrictions.widget';
import InstrumentsComponent from '@app/widgets/reference-data/instruments/instruments-grid.widget';
import RoutingRulesGridComponent from '@app/widgets/reference-data/routing-rules/routing-rules-grid.widget';
import AboutValstroComponent from '@app/widgets/system/about/about-valstro.widget';
import SystemLauncherComponent from '@app/widgets/system/launcher/launcher.widget';
import SystemPlaceholderComponent from '@app/widgets/system/placeholder/placeholder.widget';
import SystemRemoteFormComponent from '@app/widgets/system/remote-form/remote-form.widget';
import SystemImportSnapshotComponent from '@app/widgets/system/snapshots/import-snaphot.widget';
import SystemLayoutsComponent from '@app/widgets/system/snapshots/layouts.widget';
import SystemSnapshotsComponent from '@app/widgets/system/snapshots/snapshots.widget';
import EventDetailsComponent from '@app/widgets/trading/event-details/event-details.widget';
import ExecutionMonitorComponent from '@app/widgets/trading/execution-monitor/executions.widget';
import ExecutionViewInformationComponent from '@app/widgets/trading/execution-view/execution-view.information.widget';
import ExecutionViewSidePanelComponent from '@app/widgets/trading/execution-view/execution-view.side-panel.widget';
import ExecutionViewTabbedDetailsComponent from '@app/widgets/trading/execution-view/execution-view.tabbed-details.widget';
import ExecutionViewTabbedGridsComponent from '@app/widgets/trading/execution-view/execution-view.tabbed-grids.widget';
import InvestorOrderMonitorGridComponent from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.widget';
import IoViewOrderInformationComponent from '@app/widgets/trading/investor-order-view/investor-order-view.order-information.widget';
import IoViewSidePanelComponent from '@app/widgets/trading/investor-order-view/investor-order-view.side-panel.widget';
import IoViewTabbedGridsComponent from '@app/widgets/trading/investor-order-view/investor-order-view.tabbed-grids.widget';
import IoViewTabbedSummariesComponent from '@app/widgets/trading/investor-order-view/investor-order-view.tabbed-summaries.widget';
import MappableOrdersComponent from '@app/widgets/trading/mappable-orders/mappable-orders.widget';
import MontageDataPanelComponent from '@app/widgets/trading/montage/montage.data.panel.widget';
import MontageSidePanelComponent from '@app/widgets/trading/montage/montage.side-panel.widget';
import NewOrdersGridComponent from '@app/widgets/trading/new-orders/new-orders.grid.widget';
import NewOrdersSidePanelComponent from '@app/widgets/trading/new-orders/new-orders.side-panel.widget';
import NotificationsComponent from '@app/widgets/trading/notifications/notifications.widget';
import OrderVwapComponent from '@app/widgets/trading/order-vwap/order-vwap.widget';
import PendingModificationsGridComponent from '@app/widgets/trading/pending-modifications/pending-modifications.grid.widget';
import PendingModificationsSidePanelComponent from '@app/widgets/trading/pending-modifications/pending-modifications.side-panel.widget';
import RepairQueueInvestorOrdersGridComponent from '@app/widgets/trading/repair-queue/repair-queue.investor-orders.widget';
import RepairQueueTradesGridComponent from '@app/widgets/trading/repair-queue/repair-queue.trades.widget';
import TradeMonitorGridComponent from '@app/widgets/trading/trade-monitor/trade-monitor.widget';
import TradingOrderMonitorGridComponent from '@app/widgets/trading/trading-order-monitor/trading-order-monitor.widget';
import ToViewOrderInformationComponent from '@app/widgets/trading/trading-order-view/trading-order-view.order-information.widget';
import ToViewSidePanelComponent from '@app/widgets/trading/trading-order-view/trading-order-view.side-panel.widget';
import ToViewTabbedGridsComponent from '@app/widgets/trading/trading-order-view/trading-order-view.tabbed-grids.widget';
import ToViewTabbedSummariesComponent from '@app/widgets/trading/trading-order-view/trading-order-view.tabbed-summaries.widget';
import UserPreferencesComponent from '@app/widgets/user/user-preferences/user-preferences.widget';
import MontageToolbarComponent from '@app/widgets/trading/montage/montage-toolbar/montage-toolbar.widget';
import StrategyParametersComponent from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-parameters.widget';
import TradeViewExecutionsGridComponent from '@app/widgets/trading/trade-monitor/trade-view/trade-view.executions-grid.widget';
import TradeViewSidePanelComponent from '@app/widgets/trading/trade-monitor/trade-view/trade-view.side-panel.widget';
import TradeViewTabbedGridsComponent from '@app/widgets/trading/trade-monitor/trade-view/trade-view.tabbed-grids.widget';
import TradeViewInformationComponent from '@app/widgets/trading/trade-monitor/trade-view/trade-view.trade-information.widget';
import TradeViewTabbedDetailsComponent from '@app/widgets/trading/trade-monitor/trade-view/trading-view.tabbed-details.widget';
import PositionsAccountGridComponent from '@app/widgets/trading/positions/grids/account/positions-account-grid.widget';
import PositionsInstrumentGridComponent from '@app/widgets/trading/positions/grids/instrument/positions-instrument-grid.widget';
import NotificationsSettingsComponent from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.widget';
import { DIALOG_ENUM } from '@app/generated/common';
import ConfirmationDialog from '@app/dialogs/confirmation/confirmation.dialog';
import RejectWithReasonDialog from '@app/dialogs/reject-with-reason/reject-with-reason.dialog';
import AllFieldsDialog from '@app/forms/form-builder/fields/additional-fields/all-fields-form.dialog';
import { FORM_ENUM } from '@app/generated/common';
import ActionButtonForm from '@app/actions/forms/action-button/action-button.form-builder';
import ActionButtonLayoutForm from '@app/actions/forms/action-button-layout/action-button-layout.form-builder';
import CurrencyFormForm from '@app/widgets/reference-data/currencies/currency.form-builder';
import ExchangeFormForm from '@app/widgets/reference-data/exchanges/exchange.form-builder';
import InstrumentRestrictionEntryForm from '@app/widgets/reference-data/instrument-restriction-entry/instrument-restriction-entry.form-builder';
import BugReporterForm from '@app/widgets/system/bug-reporter/bug-reporter.form-builder';
import SaveSnapshotFormForm from '@app/widgets/system/snapshots/save-snapshot.form-builder';
import BulkRouteOrderFormForm from '@app/widgets/trading/bulk-route-order/bulk-route-order.form-builder';
import CrossPrincipalFillForm from '@app/widgets/trading/cross-principal-fill/cross-principal-fill.form-builder';
import InvestorOrderBulkEntryForm from '@app/widgets/trading/investor-order-bulk-entry/investor-order-bulk-entry.form-builder';
import InvestorOrderEntryForm from '@app/widgets/trading/investor-order-entry/investor-order-entry.form-builder';
import PrincipleFillFormForm from '@app/widgets/trading/principal-fill/principal-fill.form-builder';
import RouteOrderForm from '@app/widgets/trading/route-order/route-order.form-builder';
import TradeModifyForm from '@app/widgets/trading/trade-modify/trade-modify.form-builder';
import CustomNotificationEntryForm from '@app/widgets/user/custom-notification-entry/custom-notification-entry.form-builder';
import RejectInvestorOrderForm from '@app/widgets/trading/investor-order-monitor/grid-forms/reject-investor-order.form-builder';
import ApplySettlementFxRateForm from '@app/widgets/trading/investor-order-view/grid-forms/apply-settlement-fx-rate.form-builder';
import CancelExecutionsForm from '@app/widgets/trading/investor-order-view/grid-forms/cancel-executions.form-builder';
import OrderVwapFormForm from '@app/widgets/trading/order-vwap/form/order-vwap.form-builder';
import RejectPendingModificationForm from '@app/widgets/trading/pending-modifications/grid-forms/reject-pending-modification.form-builder';
import BulkRepairTradeForm from '@app/widgets/trading/repair-queue/bulk-repair-trade/bulk-repair-trade.form-builder';
import EnableQuoteForm from '@app/widgets/trading/montage/depth-grid/forms/enable-quote.form-builder';
import MontageFiltersFormForm from '@app/widgets/trading/montage/montage-toolbar/montage-filters.form/montage-filters.form-builder';
import MockUserSettingsForm from '@app/widgets/user/user-preferences/preferences/mock-user-settings/mock-user-settings.form-builder';
import MontageSettingsForm from '@app/widgets/user/user-preferences/preferences/montage-settings/montage-settings.form-builder';
import OrderSettingsForm from '@app/widgets/user/user-preferences/preferences/order-settings/order-settings.form-builder';
import MontageBumpPriceForm from '@app/widgets/trading/montage/commands/bump-price/form/bump-price.form-builder';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import ExchangeCountryWatchQueryAdvancedSelectQuery from '@app/widgets/reference-data/exchanges/exchange.form-field.country.select-query';
import ExchangeMicWatchQueryAdvancedSelectQuery from '@app/widgets/reference-data/exchanges/exchange.form-field.mic.select-query';
import ExchangeTimezoneWatchQueryAdvancedSelectQuery from '@app/widgets/reference-data/exchanges/exchange.form-field.timezone.select-query';
import FirmAccountsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/account-field/firm-accounts.form-field.select-query';
import IntermediaryAccountsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/account-field/intermediary-accounts.form-field.select-query';
import VisibleAccountsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/account-field/visible-accounts.form-field.select-query';
import VisibleFirmAccountsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/account-field/visible-firm-accounts.form-field.select-query';
import ActiveUserWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/active-user-field/active-user.form-field.select-query';
import CurrenciesWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/currency-field/currency.form-field.select-query';
import OrderSizesWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/order-size-field/order-size.form-field.select-query';
import OrderTagsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/order-tags-field/order-tags.form-field.select-query';
import RepCodesWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/rep-code-field/rep-code.form-field.select-query';
import LookupInstrumentsQueryAdvancedSelectQuery from '@app/forms/common/fields/symbol-field/symbol.form-field.select-query';
import LookupRefDataInstrumentsQueryAdvancedSelectQuery from '@app/forms/common/fields/symbol-field/symbol_ref_data.form-field.select-query';
import LookupInstrumentsSimpleQueryAdvancedSelectQuery from '@app/forms/common/fields/symbol-field/symbol_simple.form-field.select-query';
import TagsWatchQueryAdvancedSelectQuery from '@app/forms/common/fields/tags-field/tags.form-field.select-query';
import TradeCounterpartyQueryAdvancedSelectQuery from '@app/forms/common/fields/trade-counterparty-field/trade-counterparty-field.select-query';
import WatchAllVenuesForUserWithDestinationTypesQueryAdvancedSelectQuery from '@app/forms/common/fields/venue-field/venues-for-user-with-destination-types.form-field.select-query';
import WatchAllVenuesForUserQueryAdvancedSelectQuery from '@app/forms/common/fields/venue-field/venues-for-user.form-field.select-query';
import WatchAllRoutableUsersAdvancedSelectQuery from '@app/widgets/trading/route-order/advanced-select-queries/routable-users.select-query';
import type { RegistryExcludePropsFromSnapshotMapper } from '@app/app-config/registry.config';

export const COMPONENT_MAP = {
  [COMPONENT_ENUM.TIME_AND_SALES]: TimeAndSalesComponent,
  [COMPONENT_ENUM.CURRENCIES_GRID]: CurrenciesGridComponent,
  [COMPONENT_ENUM.EXCHANGES_GRID]: ExchangesGridComponent,
  [COMPONENT_ENUM.INSTRUMENT_RESTRICTIONS_GRID]: InstrumentRestrictionsGridComponent,
  [COMPONENT_ENUM.INSTRUMENTS]: InstrumentsComponent,
  [COMPONENT_ENUM.ROUTING_RULES_GRID]: RoutingRulesGridComponent,
  [COMPONENT_ENUM.ABOUT_VALSTRO]: AboutValstroComponent,
  [COMPONENT_ENUM.SYSTEM_LAUNCHER]: SystemLauncherComponent,
  [COMPONENT_ENUM.SYSTEM_PLACEHOLDER]: SystemPlaceholderComponent,
  [COMPONENT_ENUM.SYSTEM_REMOTE_FORM]: SystemRemoteFormComponent,
  [COMPONENT_ENUM.SYSTEM_IMPORT_SNAPSHOT]: SystemImportSnapshotComponent,
  [COMPONENT_ENUM.SYSTEM_LAYOUTS]: SystemLayoutsComponent,
  [COMPONENT_ENUM.SYSTEM_SNAPSHOTS]: SystemSnapshotsComponent,
  [COMPONENT_ENUM.EVENT_DETAILS]: EventDetailsComponent,
  [COMPONENT_ENUM.EXECUTION_MONITOR]: ExecutionMonitorComponent,
  [COMPONENT_ENUM.EXECUTION_VIEW_INFORMATION]: ExecutionViewInformationComponent,
  [COMPONENT_ENUM.EXECUTION_VIEW_SIDE_PANEL]: ExecutionViewSidePanelComponent,
  [COMPONENT_ENUM.EXECUTION_VIEW_TABBED_DETAILS]: ExecutionViewTabbedDetailsComponent,
  [COMPONENT_ENUM.EXECUTION_VIEW_TABBED_GRIDS]: ExecutionViewTabbedGridsComponent,
  [COMPONENT_ENUM.INVESTOR_ORDER_MONITOR_GRID]: InvestorOrderMonitorGridComponent,
  [COMPONENT_ENUM.IO_VIEW_ORDER_INFORMATION]: IoViewOrderInformationComponent,
  [COMPONENT_ENUM.IO_VIEW_SIDE_PANEL]: IoViewSidePanelComponent,
  [COMPONENT_ENUM.IO_VIEW_TABBED_GRIDS]: IoViewTabbedGridsComponent,
  [COMPONENT_ENUM.IO_VIEW_TABBED_SUMMARIES]: IoViewTabbedSummariesComponent,
  [COMPONENT_ENUM.MAPPABLE_ORDERS]: MappableOrdersComponent,
  [COMPONENT_ENUM.MONTAGE_DATA_PANEL]: MontageDataPanelComponent,
  [COMPONENT_ENUM.MONTAGE_SIDE_PANEL]: MontageSidePanelComponent,
  [COMPONENT_ENUM.NEW_ORDERS_GRID]: NewOrdersGridComponent,
  [COMPONENT_ENUM.NEW_ORDERS_SIDE_PANEL]: NewOrdersSidePanelComponent,
  [COMPONENT_ENUM.NOTIFICATIONS]: NotificationsComponent,
  [COMPONENT_ENUM.ORDER_VWAP]: OrderVwapComponent,
  [COMPONENT_ENUM.PENDING_MODIFICATIONS_GRID]: PendingModificationsGridComponent,
  [COMPONENT_ENUM.PENDING_MODIFICATIONS_SIDE_PANEL]: PendingModificationsSidePanelComponent,
  [COMPONENT_ENUM.REPAIR_QUEUE_INVESTOR_ORDERS_GRID]: RepairQueueInvestorOrdersGridComponent,
  [COMPONENT_ENUM.REPAIR_QUEUE_TRADES_GRID]: RepairQueueTradesGridComponent,
  [COMPONENT_ENUM.TRADE_MONITOR_GRID]: TradeMonitorGridComponent,
  [COMPONENT_ENUM.TRADING_ORDER_MONITOR_GRID]: TradingOrderMonitorGridComponent,
  [COMPONENT_ENUM.TO_VIEW_ORDER_INFORMATION]: ToViewOrderInformationComponent,
  [COMPONENT_ENUM.TO_VIEW_SIDE_PANEL]: ToViewSidePanelComponent,
  [COMPONENT_ENUM.TO_VIEW_TABBED_GRIDS]: ToViewTabbedGridsComponent,
  [COMPONENT_ENUM.TO_VIEW_TABBED_SUMMARIES]: ToViewTabbedSummariesComponent,
  [COMPONENT_ENUM.USER_PREFERENCES]: UserPreferencesComponent,
  [COMPONENT_ENUM.MONTAGE_TOOLBAR]: MontageToolbarComponent,
  [COMPONENT_ENUM.STRATEGY_PARAMETERS]: StrategyParametersComponent,
  [COMPONENT_ENUM.TRADE_VIEW_EXECUTIONS_GRID]: TradeViewExecutionsGridComponent,
  [COMPONENT_ENUM.TRADE_VIEW_SIDE_PANEL]: TradeViewSidePanelComponent,
  [COMPONENT_ENUM.TRADE_VIEW_TABBED_GRIDS]: TradeViewTabbedGridsComponent,
  [COMPONENT_ENUM.TRADE_VIEW_INFORMATION]: TradeViewInformationComponent,
  [COMPONENT_ENUM.TRADE_VIEW_TABBED_DETAILS]: TradeViewTabbedDetailsComponent,
  [COMPONENT_ENUM.POSITIONS_ACCOUNT_GRID]: PositionsAccountGridComponent,
  [COMPONENT_ENUM.POSITIONS_INSTRUMENT_GRID]: PositionsInstrumentGridComponent,
  [COMPONENT_ENUM.NOTIFICATIONS_SETTINGS]: NotificationsSettingsComponent
} as const;

export type ComponentMap = typeof COMPONENT_MAP;

export const COMPONENT_PROPS_TO_EXCLUDE_MAP: RegistryExcludePropsFromSnapshotMapper = {
  [COMPONENT_ENUM.SYSTEM_REMOTE_FORM]: ['schema', 'initialFeedback', 'initialValues']
};

export const DIALOG_MAP = {
  [DIALOG_ENUM.CONFIRMATION]: ConfirmationDialog,
  [DIALOG_ENUM.REJECT_WITH_REASON]: RejectWithReasonDialog,
  [DIALOG_ENUM.ALL_FIELDS]: AllFieldsDialog
} as const;

export type DialogMap = typeof DIALOG_MAP;

export const LAYOUT_PROPS_TO_EXCLUDE_MAP: RegistryExcludePropsFromSnapshotMapper = {};

export const FORM_MAP = {
  [FORM_ENUM.ACTION_BUTTON]: ActionButtonForm,
  [FORM_ENUM.ACTION_BUTTON_LAYOUT]: ActionButtonLayoutForm,
  [FORM_ENUM.CURRENCY_FORM]: CurrencyFormForm,
  [FORM_ENUM.EXCHANGE_FORM]: ExchangeFormForm,
  [FORM_ENUM.INSTRUMENT_RESTRICTION_ENTRY]: InstrumentRestrictionEntryForm,
  [FORM_ENUM.BUG_REPORTER]: BugReporterForm,
  [FORM_ENUM.SAVE_SNAPSHOT_FORM]: SaveSnapshotFormForm,
  [FORM_ENUM.BULK_ROUTE_ORDER_FORM]: BulkRouteOrderFormForm,
  [FORM_ENUM.CROSS_PRINCIPAL_FILL]: CrossPrincipalFillForm,
  [FORM_ENUM.INVESTOR_ORDER_BULK_ENTRY]: InvestorOrderBulkEntryForm,
  [FORM_ENUM.INVESTOR_ORDER_ENTRY]: InvestorOrderEntryForm,
  [FORM_ENUM.PRINCIPLE_FILL_FORM]: PrincipleFillFormForm,
  [FORM_ENUM.ROUTE_ORDER]: RouteOrderForm,
  [FORM_ENUM.TRADE_MODIFY]: TradeModifyForm,
  [FORM_ENUM.CUSTOM_NOTIFICATION_ENTRY]: CustomNotificationEntryForm,
  [FORM_ENUM.REJECT_INVESTOR_ORDER]: RejectInvestorOrderForm,
  [FORM_ENUM.APPLY_SETTLEMENT_FX_RATE]: ApplySettlementFxRateForm,
  [FORM_ENUM.CANCEL_EXECUTIONS]: CancelExecutionsForm,
  [FORM_ENUM.ORDER_VWAP_FORM]: OrderVwapFormForm,
  [FORM_ENUM.REJECT_PENDING_MODIFICATION]: RejectPendingModificationForm,
  [FORM_ENUM.BULK_REPAIR_TRADE]: BulkRepairTradeForm,
  [FORM_ENUM.ENABLE_QUOTE]: EnableQuoteForm,
  [FORM_ENUM.MONTAGE_FILTERS_FORM]: MontageFiltersFormForm,
  [FORM_ENUM.MOCK_USER_SETTINGS]: MockUserSettingsForm,
  [FORM_ENUM.MONTAGE_SETTINGS]: MontageSettingsForm,
  [FORM_ENUM.ORDER_SETTINGS]: OrderSettingsForm,
  [FORM_ENUM.MONTAGE_BUMP_PRICE]: MontageBumpPriceForm
} as const;

export type FormMap = typeof FORM_MAP;

export const ADVANCED_SELECT_QUERY_MAP = {
  [ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_COUNTRY_WATCH_QUERY]: ExchangeCountryWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_MIC_WATCH_QUERY]: ExchangeMicWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_TIMEZONE_WATCH_QUERY]: ExchangeTimezoneWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.FIRM_ACCOUNTS_WATCH_QUERY]: FirmAccountsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.INTERMEDIARY_ACCOUNTS_WATCH_QUERY]:
    IntermediaryAccountsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.VISIBLE_ACCOUNTS_WATCH_QUERY]: VisibleAccountsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY]:
    VisibleFirmAccountsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.ACTIVE_USER_WATCH_QUERY]: ActiveUserWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.CURRENCIES_WATCH_QUERY]: CurrenciesWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.ORDER_SIZES_WATCH_QUERY]: OrderSizesWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.ORDER_TAGS_WATCH_QUERY]: OrderTagsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.REP_CODES_WATCH_QUERY]: RepCodesWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_QUERY]: LookupInstrumentsQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.LOOKUP_REF_DATA_INSTRUMENTS_QUERY]:
    LookupRefDataInstrumentsQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_SIMPLE_QUERY]:
    LookupInstrumentsSimpleQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.TAGS_WATCH_QUERY]: TagsWatchQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.TRADE_COUNTERPARTY_QUERY]: TradeCounterpartyQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY]:
    WatchAllVenuesForUserWithDestinationTypesQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_QUERY]: WatchAllVenuesForUserQueryAdvancedSelectQuery,
  [ADVANCED_SELECT_QUERY_ENUM.WATCH_All_ROUTABLE_USERS]: WatchAllRoutableUsersAdvancedSelectQuery
} as const;

export type AdvancedSelectQueryMap = typeof ADVANCED_SELECT_QUERY_MAP;
