import type { Observable, Subscription } from 'rxjs';
import { inject, Lifecycle, scoped } from 'tsyringe';
import type { AnyRecord } from '@oms/shared/util-types';
import { Logger } from '@oms/shared/util';
import { GridApi } from '@ag-grid-community/core';
import { EventSource } from '@oms/frontend-vgrid';
import type { EventHandler, GridEventType } from '@oms/frontend-vgrid';
import { GridTrackingSignal } from './grid-tracking.signal';
import type {
  OrderTrackingEvent,
  OrderTypeFilter,
  TrackedGridOrderData,
  TrackingSourceType
} from './grid-tracking.types';
import { trackingFilter, getOrInitTrackingInGridContext } from './grid-tracking.util';

/**
 * Context object passed to the selection callback function
 * @template TData The type of data in the grid
 */
type SelectionCallbackContext<TData> = {
  /** The AG Grid API instance */
  api: GridApi;
  /** Selected data rows */
  data: TData;
  /** Unique identifier for the tracking source */
  sourceId: string;
  /** Type of the tracking source */
  sourceType: TrackingSourceType;
  /** Column identifier if the selection was triggered from a specific column */
  colId?: string;
  /** Whether tracking is currently active */
  isTracking: boolean;
};

/**
 * Configuration options for the tracking consumer
 */
type ConsumerOptions = {
  /** Limit tracking to specific source types */
  sourceTypes?: TrackingSourceType[];
  /** Filter orders by type (all, investor, or trading) */
  orderType?: OrderTypeFilter;
  /** Callback function triggered when orders are selected */
  onOrderSelection?: (context: SelectionCallbackContext<TrackedGridOrderData[]>) => void;
};

export const GRID_TRACKING_CONSUMER_EVENT_HANDLER_NAME = 'grid-tracking-consumer-events';

/**
 * Creates an event handler for consuming grid tracking events
 * @template TData The type of data in the grid
 * @param options Configuration options for the tracking consumer
 * @returns A class that implements the EventHandler interface
 */
export function createGridTrackingConsumerEventHandler<TData extends AnyRecord>({
  sourceTypes,
  orderType = 'all',
  onOrderSelection
}: ConsumerOptions) {
  const logger = Logger.create({
    name: 'grid-tracking.consumer.handler',
    level: 'debug'
  });

  /**
   * Handles grid tracking events and manages the subscription lifecycle
   * for tracking order selections in the grid
   */
  @scoped(Lifecycle.ContainerScoped)
  class GridTrackingConsumerEventHandler implements EventHandler<TData> {
    public name = GRID_TRACKING_CONSUMER_EVENT_HANDLER_NAME;
    private subscriptions = new Set<Subscription>();

    public constructor(@inject(GridTrackingSignal) public signal: GridTrackingSignal) {}

    /**
     * Sets up event listeners for the grid
     * @param eventSource The event source to attach listeners to
     */
    public addEvents(eventSource: EventSource<GridEventType, TData>): void {
      eventSource.add('onGridReady', ({ api }) => {
        const isTracking$ = getOrInitTrackingInGridContext(api, { logger });
        if (onOrderSelection)
          this.subscriptions.add(
            this.orders$
              .pipe(
                trackingFilter(isTracking$, {
                  onStopTracking: (event) =>
                    ({
                      ...event,
                      rows: []
                    }) as OrderTrackingEvent
                })
              )
              .subscribe(({ sourceId, sourceType, colId, rows }) => {
                logger.debug(
                  `${sourceType}: ${rows.length} ${orderType === 'all' ? '' : `${orderType} `}order${rows.length === 1 ? '' : 's'} selected${colId ? ` on column "${colId}"` : ''}:`,
                  { sourceTypes, sourceType, colId, selectedOrders: rows }
                );
                onOrderSelection({
                  api,
                  data: rows,
                  sourceId,
                  sourceType,
                  colId,
                  isTracking: isTracking$.getValue()
                });
              })
          );
      });
    }

    /**
     * Cleans up all subscriptions when the handler is destroyed
     */
    public removeEvents(): void {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions.clear();
    }

    /**
     * Returns the appropriate observable stream based on the order type filter
     * @private
     * @returns Observable stream of order tracking events
     */
    private get orders$(): Observable<OrderTrackingEvent> {
      switch (orderType) {
        case 'investor':
          return this.signal.investorOrders$({ sourceTypes });
        case 'trading':
          return this.signal.tradingOrders$({ sourceTypes });
        case 'all':
          return this.signal.allOrders$({ sourceTypes });
      }
    }
  }

  return GridTrackingConsumerEventHandler;
}
