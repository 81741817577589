import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from './signal';
import { tap } from 'rxjs';
import * as Sentry from '@sentry/react';

/**
 * Signal for SyncronisationState
 *
 * Used to broadcast the current state of UI states replication
 * and to listen for changes to UI states replication
 *
 * Used primarily at the framework level to broadcast the current state of the AuthClient
 * @see app.stream.ts
 * @see plugin-syncronisation
 *
 * @usage
 * ```ts
 * const syncronisationSignal = container.resolve(SyncronisationSignal);
 * const subscription = syncronisationSignal.$.subscribe((state) => {
 *  console.log('Syncronisation state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(SyncronisationSignal) private syncronisationSignal: SyncronisationSignal) {
 *  const subscription = this.syncronisationSignal.$.subscribe((state) => {
 *    console.log('Syncronisation state changed', state);
 *  });
 * }
 */

export const DEFAULT_SYNCRONISATION_STATE: SyncronisationState = {
  isSyncronising: false,
  isSyncronised: false,
  isSyncronisationError: false,
  syncronisationErrorMessage: null
};

export type SyncronisationState = {
  isSyncronising: boolean;
  isSyncronised: boolean;
  isSyncronisationError: boolean;
  syncronisationErrorMessage?: string | null;
};
export const SYNCRONISATION_ACTION = {
  START: 'start',
  STOP: 'stop'
} as const;

export type SyncronisationAction = (typeof SYNCRONISATION_ACTION)[keyof typeof SYNCRONISATION_ACTION];

@testScoped
@singleton()
export class SyncronisationSignal extends AbstractSignal<SyncronisationState> {
  public action$: BroadcastSubject<SyncronisationAction>;

  constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      'syncronisation',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false
      },
      DEFAULT_SYNCRONISATION_STATE
    );
    this.action$ = new BroadcastSubject<SyncronisationAction>(`${this.channelName}-action`);

    this.signal.$ = this.signal.$.pipe(
      tap((e) => {
        Sentry.setContext('Synchronisation State', e);
      })
    );
  }
}
