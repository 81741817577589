import type { Prettify } from '@oms/shared/util-types';
import { ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { runConfigButton } from '@app/actions/util/run-config-button.util';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import investorOrderEntryBuilder from '../../investor-order-entry.form-builder';
import type { InvestorOrderEntryInput } from '../../investor-order-entry.form-common';
import type { InvestorOrderEntryValues } from '../../investor-order-entry.form-contract';
import { openInvestorOrderEntryFromActionButton } from '../../investor-order-entry.form-open';
import { CREATE_INVESTOR_ORDER_ACTION_NAME } from './create-investor-order.action.types';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type CreateInvestorOrderActionArgs = Partial<Prettify<InvestorOrderWithChargesRow>>;

// Action --------------------------------------------------------------------- /

export const createCreateInvestorOrderAction =
  <T extends CreateInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? CREATE_INVESTOR_ORDER_ACTION_NAME : 'create_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('UserToolbar')
                .component('action-button')
                .id('create_investor_order_config_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.createInvestorOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) => m.name(t('app.commands.createInvestorOrder.contextMenu')).priority(10).visible()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['create_investor_order']>>(
        createInvestorOrderActionOnChange
      );

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the new investor order action
 * - This function will open the create investor order form
 * - This function will also handle the initial values of the form (if config is provided)
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function createInvestorOrderActionOnChange<TData extends CreateInvestorOrderActionArgs>(
  ctx: ActionContext<TData>
): Promise<void> {
  const { lifecycle, notify, workspace } = ctx;

  // -------- Handle button state --------

  notify({ isDisabled: false });

  if (lifecycle !== 'change') {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true });

  await runConfigButton<TData, InvestorOrderEntryInput, InvestorOrderEntryValues>({
    ctx,
    name: t('app.commands.createInvestorOrder.button'),
    formBuilder: investorOrderEntryBuilder,
    input: { entryType: 'create' },
    fixedFormValueKeys: [],
    handleError: true,
    onOpenForm: async ({ initialValues, initialFeedback }) => {
      const result = await openInvestorOrderEntryFromActionButton(
        workspace,
        {},
        { form: { initialValues, initialFeedback } }
      );

      await result.mapSync(
        (_form) => {},
        ([message, workspace]) => {
          const messageCtx = t('app.common.errorWithMessage', { message });
          void openMessageDialog(messageCtx, workspace).catch(console.error);
        }
      );
    }
  });

  notify({ isLoading: false });
}
