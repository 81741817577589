import { sharedDefaultCol, sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import {
  sharedDateTimeCol,
  sharedTextCol,
  sharedPriceCol,
  sharedUserCol
} from '@app/common/grids/columns/generic-cols';
import type { TradeAuditTrail } from '@app/common/types/orders/orders.types';

const defaultAuditTrailCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedDefaultCol<TradeAuditTrail>(c).minWidth(100);

const eventTimestampCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Event time').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(220);

const userCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedUserCol(c, 'user').header('User').width(200);

// TODO: This column will be implemented properly post-MVP
const bidPriceCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedPriceCol(c, 'bidPrice')
    .header('Bid')
    .cell((c) => c.valueGetter(() => '-'));

// TODO: This column will be implemented properly post-MVP
const askPriceCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedPriceCol(c, 'askPrice')
    .header('Ask')
    .cell((c) => c.valueGetter(() => '-'));

// TODO: This column will be implemented properly post-MVP
const lastTradePriceCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedPriceCol(c, 'lastTradePrice')
    .header('Last Trd')
    .shortHeader('Last')
    .cell((c) => c.valueGetter(() => '-'));

// TODO: This column will be implemented properly post-MVP
const cumulativeVolumeCol: ColumnBuilderCallback<TradeAuditTrail> = (c) =>
  sharedQuantityCol(c, 'cumulativeVolume')
    .header('Cumulative Volume')
    .shortHeader('Vol')
    .cell((c) => c.valueGetter(() => '-'));

export const auditTrailColumnLibrary: ColumnLibrary<TradeAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [
    eventTimestampCol,
    eventCol,
    bidPriceCol,
    askPriceCol,
    lastTradePriceCol,
    cumulativeVolumeCol,
    userCol
  ]
};
