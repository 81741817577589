export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

const valueToStatus = new Map<boolean, Status>([
  [true, Status.Active],
  [false, Status.Inactive]
]);

export const mapBooleanToStatus = (value: boolean): Status | string => valueToStatus.get(value) ?? '';
