import { CustomNotificationName, DefaultNotificationName } from '@oms/generated/frontend';
import type { CustomNotificationEntrySchemaInput } from './custom-notification-entry.form-builder.config';

export type CustomNotificationEntryType = 'create' | 'update';
export type CustomNotificationEntryNotificationName =
  (typeof CustomNotificationName)[keyof typeof CustomNotificationName];

export const CustomNotificationNameType = {
  [CustomNotificationName.IoNew]: CustomNotificationName.IoNew,
  [CustomNotificationName.IoModifyFailed]: CustomNotificationName.IoModifyFailed,
  [CustomNotificationName.IoModify]: CustomNotificationName.IoModify,
  [CustomNotificationName.IoFailed]: CustomNotificationName.IoFailed,
  [CustomNotificationName.IoCancel]: CustomNotificationName.IoCancel
};

export type CustomNotificationEntryInput = CustomNotificationEntrySchemaInput;

export type CustomNotificationEntryOutput = {
  id?: string;
  notificationName: DefaultNotificationName;
  description: string;
  matchedAccounts: string[];
  isPopup: boolean;
  isSound: boolean;
  isShown: boolean;
  hiddenFormInfo?: {
    entryType: string;
  };
};
