import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';
import { RefDataRestrictionTypeEnum } from '@oms/generated/frontend';

export default createAppVersionedSchema('INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT', {
  version: 0,
  schema: z.object({
    restrictionEffectiveDate: z.string(),
    restrictionExpiryDate: z.string(),
    symbol: z.object({
      id: z.string().uuid(),
      label: z.string().optional(),
      value: z.string()
    }),
    id: z.string(),
    related: z.boolean(),
    fungible: z.boolean(),
    restrictionType: z.nativeEnum(RefDataRestrictionTypeEnum),
    account: z.object({
      id: z.string()
    })
  })
});
