import React, {
  useState,
  useCallback,
  forwardRef,
  useEffect
} from 'react';
import type {
  KeyboardEvent,
  ChangeEvent,
  CSSProperties,
  RefObject,
  FocusEvent
} from 'react';
import { NumberFormat } from '../number-format/number-format.component';
import type { OnNumberFormatValueChange } from '../number-format/number-format.component';
import { Input } from '../input/input';
import type { StackProps } from '../../layout/stack/stack';
import type { Optional } from '@oms/shared/util-types';

interface IShortcut {
  key: string;
  value: string;
}

interface IPriceInputProps {
  onValueChange: (value: string | number) => void;
  onBlur: (event?: FocusEvent<HTMLElement, Element> | undefined) => void;
  onFocus: (event: FocusEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  sx: StackProps['sx'];
  shortcuts?: IShortcut[];
  style?: CSSProperties;
  ref?: RefObject<HTMLInputElement>;
  placeholder?: string;
}

type InputMode = 'text' | 'number';

export const PriceInput = forwardRef<HTMLInputElement, IPriceInputProps>(
  ({ onValueChange, onBlur, onFocus, value: _value, style, shortcuts, sx, name }, ref) => {
    const isControlled = onValueChange !== undefined;

    const [mode, setMode] = useState<InputMode>('text');
    const [inputValue, setInputValue] = useState<Optional<string>>(_value);
    const [hasFocus, setHasFocus] = useState(false);

    // Update mode and value when controlled value changes
    useEffect(() => {
      if (isControlled) {
        const valueInShortcuts = shortcuts?.find((s) => s.value === _value);
        setMode(valueInShortcuts ? 'text' : 'number');
        setInputValue(_value);
      }
    }, [_value, isControlled, shortcuts]);

    // Restore focus after mode changes
    useEffect(() => {
      if (hasFocus && ref && typeof ref !== 'function' && ref.current) {
        ref.current?.focus();
      }
    }, [mode, hasFocus]);

    const handleTextInputKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();
        const keyFoundInShortcuts = shortcuts?.find((s) => s.key === key);

        if (isNumber(key)) {
          e.preventDefault();
          e.stopPropagation();
          setMode('number');
          setInputValue(key);
          onValueChange(key);
        } else if (keyFoundInShortcuts) {
          e.preventDefault();
          e.stopPropagation();
          setMode('text');
          setInputValue(keyFoundInShortcuts.value);
          onValueChange(keyFoundInShortcuts.value);
        }
      },
      [onValueChange, shortcuts]
    );

    const handleTextInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const valueInShortcuts = shortcuts?.find((s) => s.value === value);
      if (valueInShortcuts) {
        setInputValue(valueInShortcuts.value);
      }
    }, [shortcuts]);

    const handleNumberInputKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();
        const keyFoundInShortcuts = shortcuts?.find((s) => s.key === key);

        if (isLetter(key) && keyFoundInShortcuts) {
          e.preventDefault();
          e.stopPropagation();
          setMode('text');
          setInputValue(keyFoundInShortcuts.value);
          onValueChange(keyFoundInShortcuts.value);
        }
      },
      [onValueChange, shortcuts]
    );

    const onValueChangeHandler: OnNumberFormatValueChange = useCallback(
      (values) => {
        if (onValueChange) {
          onValueChange(values.formattedValue);
        }
        setInputValue(values.formattedValue);
      },
      [onValueChange]
    );

    const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
      setHasFocus((previous) => {
        if (!previous) {
          onFocus(event);
        }
        return true;
      });
    }, [onFocus]);

    const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
      setHasFocus((previous) => {
        if (previous) {
          onBlur(event);
        }
        return false;
      });
    }, [onBlur]);

    return (
      <div>
        {mode === 'text' ? (
          <Input
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
            name={name}
            type="text"
            value={inputValue}
            style={style}
            onKeyDown={handleTextInputKeyDown}
            onChange={handleTextInputChange}
            sx={{
              textAlign: 'right',
              ...sx
            }}
          />
        ) : (
          <NumberFormat
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
            name={name}
            value={inputValue}
            style={style}
            onKeyDown={handleNumberInputKeyDown}
            onValueChange={onValueChangeHandler}
            thousandSeparator=","
            valueIsNumericString={true}
          />
        )}
      </div>
    );
  }
);

function isLetter(v: string) {
  return /^[a-z]$/i.test(v.toLowerCase());
}

function isNumber(v: string) {
  return /^[0-9.]$/.test(v);
}
