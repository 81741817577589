import { useMemo } from 'react';
import { Box, type BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { mapValues } from 'lodash';
import { ResponsiveValue, GridTemplateRowCols, Sprinkles } from '../../system/sprinkles.css';

type ResponsiveGridTemplate = ResponsiveValue<GridTemplateRowCols>;

export type SimpleGridProps = {
  gap?: Sprinkles['gap'];
  columns: ResponsiveValue<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'none'>;
  columnGap?: Sprinkles['columnGap'];
  rowGap?: Sprinkles['rowGap'];
} & BoxProps;

export const SimpleGrid = polymorphicComponent<'div', SimpleGridProps>((props, ref) => {
  const { as, children, gap = 3, columns, rowGap, columnGap, sx = {}, ...rest } = props;

  const gridTemplateColumns = useMemo(() => {
    if (!columns) {
      return undefined;
    }
    if (typeof columns === 'object') {
      return mapValues(columns, (col) => `repeat(${col}, minmax(0px, 1fr))`);
    } else {
      return `repeat(${columns}, minmax(0px, 1fr))`;
    }
  }, [columns]);

  return (
    <Box
      as={as}
      sx={{
        display: 'grid',
        gridTemplateColumns: gridTemplateColumns as ResponsiveGridTemplate,
        rowGap,
        columnGap,
        gap,
        ...sx
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  SimpleGrid.displayName = 'SimpleGrid';
}
