//@ts-nocheck
import { MutableRefObject } from 'react';
import * as keyUtils from './utils/key-utils';
import { ValueHistory } from './value-history';
import { omitBy, isUndefined } from 'lodash';
import { getActionType, getHandlerForAction } from './constants/actions';
import { DECIMAL_DELIMITER, THOUSAND_DELIMITER } from './constants/delimiters';
import { INumericInputOptions } from './contracts/config-types';
import { NumericInputRange, DragState, NumericInputActionType as ActionType } from './contracts/enum-types';
import { INumericInputListenerMap } from './contracts/event-types';
import { INumericInputState, IKeyInfo } from './contracts/shared-types';
import {
  getNumberOfDecimals,
  fullFormat,
  formattedToRaw,
  rawToFormatted,
  partialFormat,
  extractNumberFromString,
  calculateOffset
} from './utils/formatting-utils';
import { parseValueString } from './utils/parsing-utils';

const noop = () => {
  /**/
};

const DEFAULTS: INumericInputOptions = {
  decimal: DECIMAL_DELIMITER,
  fixed: true,
  onFocus: noop,
  onInvalidKey: noop,
  range: NumericInputRange.ALL,
  scale: 2,
  disableShortcuts: false,
  shortcuts: {
    b: 1000000000,
    k: 1000,
    m: 1000000
  },
  thousands: THOUSAND_DELIMITER
};

/**
 * Wrapper for a numeric input
 */
export class NumericInputBehavior {
  public options: INumericInputOptions;
  private readonly element: MutableRefObject<HTMLInputElement>;

  private readonly history: ValueHistory;
  private readonly listeners: INumericInputListenerMap;
  private dragState: DragState = DragState.NONE;

  constructor(element: MutableRefObject<HTMLInputElement>, options: INumericInputOptions = {}) {
    this.element = element;
    // Only merge defined option properties to this.options.
    this.options = { ...DEFAULTS, ...omitBy(options, isUndefined) };
    if (this.options.disableShortcuts) {
      this.options = {
        ...this.options,
        shortcuts: {}
      };
    }

    this.history = new ValueHistory();

    this.listeners = {
      blur: { element: this.element.current, handler: () => this.onBlur() },
      dragend: { element: document, handler: () => this.onDragend() },
      dragstart: {
        element: document,
        handler: (e) => this.onDragstart(e as DragEvent)
      },
      drop: {
        element: this.element.current,
        handler: (e) => this.onDrop(e as DragEvent)
      },
      focus: {
        element: this.element.current,
        handler: (e) => {
          this.onFocus(e as FocusEvent);
        }
      },
      input: {
        element: this.element.current,
        handler: (_e) => {
          // Causing an issue when manually focusing field, because it triggers a dispatch
          // And then a maximum stack error
          // console.log(_e);
          // this.onInput();
        }
      },
      keydown: {
        element: this.element.current,
        handler: (e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            // On an Enter press, set the value explicitly.
            this.setValue(this.element?.current?.value, false);
          }
          this.onKeydown(e);
        }
      },
      keyup: {
        element: this.element.current,
        handler: this.onInput
      },
      paste: {
        element: this.element.current,
        handler: (e) => this.onPaste(e as ClipboardEvent)
      }
    };

    this.removeListeners();
    (Object.keys(this.listeners) as Array<keyof INumericInputListenerMap>).forEach((key) =>
      this.listeners[key].element.addEventListener(key, this.listeners[key].handler)
    );
  }

  public setOptions = (options: Partial<INumericInputOptions>) => {
    this.options = { ...this.options, ...options };
  };

  public setNativeValue = (val: string) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(this.element.current, val);
  };

  public dispatchInputEvent = () => {
    const inputEvent = new Event('input', { bubbles: true });
    this.element.current.dispatchEvent(inputEvent);
  };

  public dispatchChangeEvent = () => {
    const inputEvent = new Event('change', { bubbles: true });
    this.element.current.dispatchEvent(inputEvent);
  };

  public setValue = (val: string, notNull: boolean) => {
    // if format is passed, get maximum number of decimals. Otherwise leave the default value
    if (this.options.format) {
      this.options.scale = getNumberOfDecimals({
        format: this.options.format,
        value: val,
        thousandDelimiter: this.options.thousands,
        decimalDelimiter: this.options.decimal
      });
    }
    const newValue = fullFormat(val, this.options);

    if (!newValue) {
      this.element.current.classList.remove('has-value');
    } else {
      this.element.current.classList.add('has-value');
    }

    if (notNull ? val : true) {
      // console.log(`--> setValue actually setting: ${newValue}`);

      // Using native input setter to trigger react onChange vs OLD: this.element.current.value = newValue;
      this.setNativeValue(newValue);
      this.history.addValue(newValue);
      this.dispatchChangeEvent();
    }
  };

  public get rawValue() {
    return formattedToRaw(this.element.current.value, this.options);
  }

  public setRawValue = (val: any) => {
    let value: string;
    if (typeof val === 'number' && !isNaN(val)) {
      value = rawToFormatted(val, this.options);
    } else if (typeof val === 'string') {
      value = val;
    } else if (!val) {
      value = '';
    } else {
      return;
    }
    const newValue = parseValueString(value, this.options);
    this.setValue(newValue, false);
  };

  public destroy = () => {
    // console.log('-- removing listeners', this.listeners)
    this.removeListeners();
  };

  private removeListeners = () => {
    (Object.keys(this.listeners) as Array<keyof INumericInputListenerMap>).forEach((key) => {
      // console.log(`-- removeListeners for key: ${key}`)
      this.listeners[key]?.element?.removeEventListener(key, this.listeners[key]?.handler);
    });
  };

  private onBlur = () => {
    if (this.element?.current?.value) {
      this.setValue(this.element.current.value, false);
    }
  };

  private onFocus = (e: FocusEvent) => {
    const selection = this.options.onFocus(e);
    if (selection) {
      this.element.current.selectionStart = selection ? selection.start : 0;
      this.element.current.selectionEnd = selection ? selection.end : this.element.current.value.length;
    }
  };

  private onDrop = (e: DragEvent) => {
    switch (this.dragState) {
      case DragState.INTERNAL:
        // This case is handled by the 'onInput' function
        break;
      case DragState.EXTERNAL:
        // eslint-disable-next-line no-case-declarations
        const val = parseValueString(e.dataTransfer ? e.dataTransfer.getData('text') : '', this.options);
        this.setValue(val, true);
        e.preventDefault();
        break;
      default:
        // Do nothing;
        break;
    }
  };

  private onDragstart = (e: DragEvent) => {
    this.dragState = e.target === this.element.current ? DragState.INTERNAL : DragState.EXTERNAL;
  };

  private onDragend = () => {
    this.dragState = DragState.NONE;
  };

  private onPaste = (e: ClipboardEvent) => {
    // paste uses a DragEvent on IE and clipboard data is stored on the window
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const val = parseValueString(clipboardData.getData('text'), this.options);
    this.setValue(val, true);
    e.preventDefault();
  };

  private onKeydown = (e: KeyboardEvent) => {
    if (!e || !e.key) {
      return;
    }

    const currentState: INumericInputState = {
      caretEnd: this.element.current.selectionEnd || 0,
      caretStart: this.element.current.selectionStart || 0,
      valid: true,
      value: this.element.current.value
    };
    const keyInfo: IKeyInfo = {
      modifiers: keyUtils.getPressedModifiers(e),
      name: e && e.key ? e.key.toLowerCase() : ''
    };

    const actionType = getActionType(keyInfo, this.options);
    const handler = getHandlerForAction(actionType);
    const newState = handler(currentState, keyInfo, this.options, this.history);

    if (!newState.valid) {
      this.options.onInvalidKey(e);
      e.preventDefault();
      return;
    }

    const shouldHandleValue = actionType !== ActionType.UNKNOWN;
    if (!shouldHandleValue) {
      return;
    }

    e.preventDefault();

    if (this.options.format && newState.value) {
      this.options.scale = getNumberOfDecimals({
        format: this.options.format,
        value: newState.value,
        thousandDelimiter: this.options.thousands,
        decimalDelimiter: this.options.decimal,
        trimTrailingZeros: false
      });
    }

    const valueWithThousandsDelimiter = newState.value ? partialFormat(newState.value, this.options) : '';
    const valueWithoutThousandsDelimiter = newState.value ?? '';

    // If value matches a shortcut, don't add delimiter
    const matchingStrShorcut = Object.values(this.options.shortcuts).find(
      (shortcut) => typeof shortcut === 'string' && shortcut === valueWithoutThousandsDelimiter
    );

    if (matchingStrShorcut) {
      this.setNativeValue(valueWithoutThousandsDelimiter);

      return;
    }

    const isRealNumber = formattedToRaw(valueWithThousandsDelimiter, this.options);
    if (Number.isNaN(isRealNumber)) {
      const numberFromInput = extractNumberFromString(newState.value);
      this.setNativeValue(
        numberFromInput === '' && actionType === ActionType.DECIMAL ? '0.' : numberFromInput
      );
      return;
    }

    // Again, using a native value setter vs this.element.current.value = valueWithThousandsDelimiter
    this.setNativeValue(valueWithThousandsDelimiter);

    const offset = calculateOffset(
      valueWithoutThousandsDelimiter,
      valueWithThousandsDelimiter,
      newState.caretStart,
      this.options
    );
    const newCaretPos = newState.caretStart + offset;
    this.element.current.setSelectionRange(newCaretPos, newCaretPos);

    const shouldRecord = actionType !== ActionType.UNDO && actionType !== ActionType.REDO;
    if (shouldRecord) {
      this.history.addValue(valueWithThousandsDelimiter);
    }
  };

  private onInput = () => {
    this.dispatchInputEvent();
  };
}

export default (elementRef: MutableRefObject<HTMLInputElement>, options?: INumericInputOptions) =>
  new NumericInputBehavior(elementRef, options);
