import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import type { MappedTradingOrderRow, TradingOrderRow } from '@oms/generated/frontend';

const investorOrderTradingOrdersDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const investorOrderTradingOrdersColumns: ColDefMap<MappedTradingOrderRow> = {
  id: { extends: 'orders.id', hide: true },
  limitPrice: { extends: 'orders.limitPrice' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  totalQuantity: { extends: 'orders.quantity' },
  capacity: { extends: 'orders.tradingOrderCapacity' },
  mappedQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity' },
  venue: { extends: 'generic.text' },
  averagePrice: { extends: 'orders.price' },
  createdTimestamp: { extends: 'generic.dateTime', sort: 'desc' }
};

const investorOrderTradingOrdersColumnLibrary: TableServerColumnLibrary<TradingOrderRow> = {
  defaultColumn: investorOrderTradingOrdersDefaultColumn,
  columns: investorOrderTradingOrdersColumns
};

export const buildInvestorOrderViewTradingOrdersColumnDefs = () =>
  buildColumnDefs(investorOrderTradingOrdersColumnLibrary, 'investorOrderViewTradingOrders');
