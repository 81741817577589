import type { OrderVwapFormValues } from './order-vwap.form-contract';
import type { OrderVwapFormOutput } from './order-vwap.form-common';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { DependencyContainer } from 'tsyringe';
import { DateTime } from 'luxon';

export async function orderVwapFormValuesToOutput(
  formValues: OrderVwapFormValues,
  container: DependencyContainer
): Promise<OrderVwapFormOutput | undefined> {
  const investorOrderIdFromInstrument = formValues?.instrument?.value?.sourceId;

  if (!investorOrderIdFromInstrument) {
    return;
  }

  const investorOrderService = container.resolve(InvestorOrdersService);
  const investorOrder = await investorOrderService.getById(investorOrderIdFromInstrument);

  if (investorOrder.isSuccess() && investorOrder.value.data.visibleInvestorOrder) {
    const startTimeDate = new Date(String(investorOrder.value.data.visibleInvestorOrder.createdTimestamp));

    if (isNaN(startTimeDate.getTime())) {
      console.error(
        'Not a valid date - investorOrder createdTimestamp timestamp is:',
        investorOrder.value.data.visibleInvestorOrder.createdTimestamp
      );
    }

    const startTime = DateTime.fromJSDate(startTimeDate).toFormat('HH:mm');
    const endTime = DateTime.now().toFormat('HH:mm');

    return {
      investorOrder: investorOrder.value.data.visibleInvestorOrder,
      startTime,
      endTime,
      vwap: formValues.vwap
    };
  }

  if (investorOrder.isFailure()) {
    console.error('Failed to get investor order', investorOrder.errors);
  }

  return undefined;
}
