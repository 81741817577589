import { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import type { FieldProps, ICommonField } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';
import type { AutoCompleteApi } from '../../../popovers/autocomplete/popover.autocomplete';
import { Box, dataAttr, Flex, inputRecipe } from '@oms/shared-frontend/ui-design-system';
import { useAdvancedSelectQuery } from '../advanced-select/advanced-select-query.hook';
import { useEnhancedFormApi } from '../../builder/helpers/form-api/form-api';
import { type Validator, useFormApi, type Field, FormRenderer } from '@data-driven-forms/react-form-renderer';
import type { FORM_COMPONENT_TYPE } from '../../contracts';
import * as styles from './multi-input.css';
import type { IAdvancedSelectField } from '../advanced-select/advanced-select.types';
import type { ComboBoxItem } from '@oms/shared-frontend/ui-design-system';
import clsx from 'clsx';
import { useFieldApi } from '../../helpers';
import { FORM_COMPONENT_MAPPER } from '../../mappers/ddf-mapper';

type ComboWithoutValue<TValue extends string = string> = Omit<ComboBoxItem<unknown, TValue>, 'value'>;

export type MultiInputOption<TValue extends string = string> = ComboWithoutValue<TValue> & {
  subcomponent?: Field;
};

export type MultiSelectValue<TValue extends string = string, TSubValue = unknown> = {
  id: TValue;
  subValue?: TSubValue;
};

export type IMultiInputField<
  TValue extends string = string,
  TSubValue = unknown,
  TValidator = Validator
> = ICommonField<typeof FORM_COMPONENT_TYPE.MULTI_INPUT, MultiSelectValue<TValue, TSubValue>, TValidator> & {
  options: MultiInputOption<TValue>[];
};

export const MultiInput = (props: FieldProps<IAdvancedSelectField<MultiInputOption>>) => {
  const field = useFieldApi<IAdvancedSelectField<MultiInputOption>>(props);
  const formApi = useEnhancedFormApi();
  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(field);
  const subCompRef = useRef<Field | undefined>(undefined);
  const valueRef = useRef<MultiSelectValue | undefined>(undefined);
  const autoCompleteApiRef = useRef<AutoCompleteApi | null>(null);

  const variant = field.isPrimaryField ? 'primary' : 'default';
  const size = variant === 'primary' ? 'md' : 'sm';

  const onAutoCompleteReady = useCallback((api: AutoCompleteApi) => {
    autoCompleteApiRef.current = api;
  }, []);

  const subcomponentField = useMemo<Field | undefined>(() => {
    const { options } = props;
    const value = field.input.value as MultiSelectValue;

    // Find the option that matches the current value's id
    const foundOption = options?.filter(
      (option) => option?.type === 'item' && option?.id === value?.id
    ) as unknown as Pick<MultiInputOption, 'id' | 'label' | 'subcomponent'>[];

    // Extract the subcomponent from the found option (if any)
    if (foundOption?.[0]?.subcomponent) {
      foundOption[0].subcomponent.hideFormControls = true;
      if (value.subValue) {
        foundOption[0].subcomponent.initialValue = value.subValue;
      }

      foundOption[0].subcomponent.isDisabled = field.isDisabled;

      return foundOption[0].subcomponent;
    } else {
      return undefined;
    }
  }, [props.options, field.input.value, field.isDisabled]);

  subCompRef.current = subcomponentField;
  valueRef.current = field.input.value;

  const formTemplate: React.FC<any> = useCallback(({ formFields }) => {
    return (
      <FormTemplate
        formFields={formFields}
        onValuesChange={(values) => {
          if (subCompRef.current) {
            formApi.change(props.name, {
              ...valueRef.current,
              subValue: values?.[subCompRef.current?.name as keyof typeof values]
            });
          }
        }}
      />
    );
  }, []);

  const onOpenCaretClick = useCallback(() => {
    if (field.isDisabled) return;

    if (autoCompleteApiRef.current) {
      autoCompleteApiRef.current.toggleOpen();
    }
  }, [field.isDisabled]);

  return (
    <FieldWrapper {...fieldWrapperProps} {...field}>
      <Flex
        aria-invalid={dataAttr(field.meta.invalid && field.meta.touched)}
        className={clsx(
          styles.wrapper,
          !props.isPrimaryField && styles.wrapperNonPrimary,
          inputRecipe({ size, variant })
        )}
        data-disabled={field.isDisabled}
      >
        <Box className={clsx(styles.innerWrapper, props.isPrimaryField && styles.wrapperPrimary)}>
          <Box className={subcomponentField ? styles.subcomponentDisplay : styles.parentComponentDisplay}>
            <Autocomplete
              {...autoCompleteProps}
              onReady={onAutoCompleteReady}
              className={styles.autocomplete}
            />
          </Box>
          {subcomponentField ? (
            <Box className={styles.subcomponentWrapper}>
              <FormRenderer
                FormTemplate={formTemplate}
                schema={{
                  fields: [subcomponentField]
                }}
                componentMapper={FORM_COMPONENT_MAPPER}
              />
            </Box>
          ) : null}
        </Box>
        <Flex
          direction="column"
          justify="center"
          onClick={onOpenCaretClick}
          style={{ cursor: field.isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <Box className={props.isPrimaryField ? styles.icon.primaryField : styles.icon.nonPrimaryField} />
        </Flex>
      </Flex>
    </FieldWrapper>
  );
};

function FormTemplate({
  formFields,
  onValuesChange
}: {
  formFields: ReactNode;
  onValuesChange: (values: MultiSelectValue[]) => void;
}) {
  const { subscribe } = useFormApi();

  useEffect(() => {
    const unsubscribe = subscribe(
      ({ values }) => {
        onValuesChange(values as MultiSelectValue[]);
      },
      { values: true }
    );
    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  return formFields;
}
