import { Plugin } from '@valstro/workspace';
import { Logger } from '@oms/shared/util';
import { ConsoleLogger, DeploymentStage, LogLevel, Mdg2Client } from '@fds/frontgate-js-sdk';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { AppState } from '@app/data-access/memory/app.stream';
import { combineLatest, filter } from 'rxjs';
import type { DependencyContainer } from 'tsyringe';
import { FactsetClient } from '@app/data-access/services/marketdata/factset/factset.client';
import { getEnvVar } from '@oms/shared/util';
import {
  MarketDataConnectionStrategy,
  MarketDataConnectionStrategyToken,
  MarketDataService
} from '@app/data-access/services/marketdata/marketdata.service';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';

export interface MarketdataPluginOptions {
  MdgClient?: new (...args: any[]) => Mdg2Client;
  container: DependencyContainer;
}

const factsetEnv: keyof typeof DeploymentStage = (getEnvVar('LEVEL1_FACTSETMDAAS_DEPLOYMENT_STAGE') ||
  'testing') as keyof typeof DeploymentStage;

const logger = Logger.named('marketdataPlugin');

/**
 * Creates the marketdata provider when the leader window is ready
 */
export const marketdataPlugin = ({ MdgClient = Mdg2Client, container }: MarketdataPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-marketdata-plugin',
    pluginFn: ({ workspace }) => {
      let marketDataService: MarketDataService;
      const appStateService = container.resolve(AppState);
      const processState = container.resolve(ProcessState);
      const factsetLogger = new ConsoleLogger('Level 1 Mkt');
      let hasBooted = false;
      factsetLogger.setLevel(LogLevel.INFO);

      container.register<MarketDataConnectionStrategy>(MarketDataConnectionStrategyToken, {
        useValue: 'single-process'
      });

      const appStateReady$ = appStateService.ready$;

      const appStateSub = combineLatest([appStateReady$, processState.processId$])
        .pipe(filter(([_, process]) => !!process))
        .subscribe(() => {
          if (hasBooted) return;
          const rxApolloClient = container.resolve(RxApolloClient);
          const factsetClient = new FactsetClient(
            rxApolloClient,
            DeploymentStage[factsetEnv],
            new MdgClient(undefined, { shouldRestartSubscriptions: true }),
            factsetLogger
          );
          container.register(FactsetClient, {
            useValue: factsetClient
          });

          marketDataService = container.resolve(MarketDataService);
          marketDataService.start(true);
          hasBooted = true;
        });

      const disconnect = async () => {
        await marketDataService?.disconnect();
        appStateSub.unsubscribe();
        logger.warn('🔴 Disconnected from market data');
      };

      const unsub = workspace.listen('actor/before-destroy', async (actor) => {
        const isMyWindowClosing = hasBooted && actor.id === container.resolve(ProcessState).processId;
        if (isMyWindowClosing) {
          logger.warn(`❌ Window ${actor.id} is being destroyed. Disconnecting Market data.`);
          await disconnect();
        }
      });

      // Cleanup function
      return async () => {
        unsub();
        await disconnect();
      };
    }
  });
