import type {
  CompositeRoleFragment,
  CoveragePersonFragment,
  DepartmentTypes,
  DeskTypes,
  UserAccessFragment,
  UserDefaultsAggregationUnitFragment,
  UserDefaultsCountryFragment,
  UserDefaultsFragment,
  UserDefaultsOrderTagFragment,
  UserDefaultsTradingEntityFragment,
  UserExecutionVenueFragment,
  UserFragment,
  UserGroupFragment,
  UserInvestorAccountFragment,
  UserPositionAccessFragment,
  UserRepresentationFragment
} from '@oms/generated/frontend';
import type { Identifiable, Labeled, Maybe, Named, RemoveNullFrom } from '@oms/shared/util-types';

export type BaseUserObject = UserFragment;

export type AnyUserInput = Partial<
  Pick<BaseUserObject, 'id' | 'firstName' | 'lastName' | 'username' | 'email'>
>;

export type BaseGroupObject = UserGroupFragment;

export type ExtendedGroupObject = RemoveNullFrom<BaseGroupObject> &
  Identifiable &
  Labeled &
  Partial<Named> & {
    enabled?: boolean;
  };

export type AnyBaseUserObject = Omit<UserFragment, 'defaults' | 'access'> | UserRepresentationFragment;

export type ExtendedUserObject = RemoveNullFrom<Omit<BaseUserObject, 'defaults' | 'access'>> &
  Identifiable &
  Labeled &
  Partial<Named> & {
    userRoles: UserRole[];
    team?: UserGroup;
    defaults?: ExtendedUserDefaultsObject;
    access?: ExtendedUserAccessObject;
    // Misc ----- /
    displayInfo: UserDisplayInfo;
    isExtendedUserObject: true;
    _serializedOriginalObject: string;
  };

export type ExtendedCoveragePersonObject = ExtendedUserObject | ExtendedGroupObject;

export const isExtendedUserObject = (user?: Partial<AnyBaseUserObject>): user is ExtendedUserObject => {
  if (!user) return false;
  const { isExtendedUserObject, _serializedOriginalObject } = user as Partial<ExtendedUserObject>;
  return isExtendedUserObject === true && typeof _serializedOriginalObject === 'string';
};

export const isExtendedGroupObject = (obj?: Partial<BaseGroupObject>): obj is ExtendedGroupObject => {
  if (!obj) {
    return false;
  }
  return obj?.__typename === 'Group';
};

export const isCoveragePerson = (
  obj?: Partial<CoveragePersonFragment> | Partial<AnyBaseUserObject> | Partial<BaseGroupObject>
): obj is CoveragePersonFragment => {
  if (!obj) {
    return false;
  }
  return obj?.__typename === 'CoveragePerson';
};

// 🖊️ Input ------------------------------------------------------ /

export type UserInputProps = {
  initialValues?: Partial<BaseUserObject>;
  type?: 'create' | 'update';
};

// 🎛️ Defaults ------------------------------------------------------ /

export type BaseUserDefaultsObject = UserDefaultsFragment;

export type ExtendedUserDefaultsObject = RemoveNullFrom<BaseUserDefaultsObject> & {
  aggregationUnit?: UserAggregationUnit;
  receivingDeskType: DeskTypes;
  departmentType: DepartmentTypes;
  tradingEntity: UserTradingEntity;
  country: UserCountry;
  firmAccount?: UserAccount;
  intermediaryAccount?: UserAccount;
  orderTags: UserOrderTag[];
};

export type UserAggregationUnit = UserDefaultsAggregationUnitFragment;

export type UserOrderTag = UserDefaultsOrderTagFragment;

export type UserTradingEntity = UserDefaultsTradingEntityFragment;

export type UserCountry = UserDefaultsCountryFragment;

export type UserAccount = UserInvestorAccountFragment;

export type UserPositionAccess = UserPositionAccessFragment;

// 🔏 Access ------------------------------------------------------ /

export type UserAccess = UserAccessFragment;

export type UserExecutionVenue = UserExecutionVenueFragment;

export type ExtendedUserAccessObject = RemoveNullFrom<UserAccess> & {
  groupAccess: UserGroup[];
  executionVenues: UserExecutionVenue[];
  positionsAccess: UserPositionAccess[];
};

// 🎠 Roles ------------------------------------------------------ /

export type UserRole = CompositeRoleFragment;

// 👪 Groups ------------------------------------------------------ /

export type UserGroupAttributes = {
  description?: string[];
};

export type UserGroup = Omit<UserGroupFragment, 'attributes'> & {
  attributes?: Maybe<UserGroupAttributes>;
};

export type UserGroupWithMembers = UserGroup & {
  members: ExtendedUserObject[];
};

// 📺 Display ------------------------------------------------------ /

export type UserDisplayInfo = Identifiable &
  Labeled & {
    avatar?: string;
    isActive?: boolean;
    children?: UserDisplayInfo[];
  };

// 💁 Properties ------------------------------------------------------ /

type Excluded =
  | '__typename'
  | 'compositeRoles'
  | 'defaults'
  | 'access'
  | 'executionVenues'
  | 'label'
  | 'displayInfo'
  | 'isExtendedUserObject'
  | '_serializedOriginalObject';

export type UserProperty = Exclude<
  | keyof ExtendedUserObject
  | keyof ExtendedUserDefaultsObject
  | keyof ExtendedUserAccessObject
  | 'executionVenueAccess',
  Excluded
>;
