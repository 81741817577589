import { DisplayGrid, Switch, Flex } from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridItemProps, DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { useCallback, useEffect, useState, useMemo } from 'react';
import type { ChangeEventHandler } from 'react';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import { useEnhancedFormApi, useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import * as styles from './trade-through-obligation-field.component.css';
import { useLevel2SipQuotes } from '../../../data-access/services/trading/level2/use-level2-connection.hook';
import { RegNmsApplicability } from '@oms/generated/frontend';
import { getTradeThroughDetails } from '@oms/shared/regnms';
import type { TradeThroughDetails } from '@oms/shared/regnms';
import type { PrincipalFillFormContract } from '../principal-fill/principal-fill.contracts';
import type { CrossPrincipalFillFieldContract } from '../cross-principal-fill/cross-principal-fill.form-common';
import {
  DATA_AVAILABLE_ON_DESKTOP_APPLICATION_ONLY,
  FORCE_CONNECT_TO_SIDECAR,
  useShouldConnectToSidecar
} from '@app/tauri/useShouldConnectToSidecar.hook';
import { isDevEnv } from '@oms/shared/util';

const applicabilityMap: Partial<{ [key in RegNmsApplicability]: string }> = {
  [RegNmsApplicability.Exempt]: 'Exempt',
  [RegNmsApplicability.NotApplicable]: 'N/A',
  [RegNmsApplicability.Error]: 'N/A'
};

const getTradeThroughLabel = (data: TradeThroughDetails, regNmsApplicability: RegNmsApplicability) =>
  regNmsApplicability === RegNmsApplicability.Applicable
    ? data.tradeThrough
      ? 'Yes'
      : data.malformed
        ? 'Error'
        : 'No'
    : applicabilityMap[regNmsApplicability];

const getGridItems = (
  data: TradeThroughDetails,
  regNmsApplicability: RegNmsApplicability,
  shouldConnectToSidecar: boolean
): DisplayGridProps => {
  // These are assigned variables because they are used to calculate the width of other columns
  const tradeThroughColSpan = 3;
  const sideColSpan = 2;
  const qtyColSpan = 2;
  const avgPxColSpan = 2;

  const items: DisplayGridItemProps[] = [
    {
      label: 'Trade through',
      component: {
        type: 'Text',
        value: getTradeThroughLabel(data, regNmsApplicability) || '-'
      },
      colSpan: tradeThroughColSpan
    },
    {
      label: 'Side',
      component: {
        type: 'Text',
        value: shouldConnectToSidecar
          ? regNmsApplicability === RegNmsApplicability.Applicable
            ? data?.side || '-'
            : '-'
          : '-'
      },
      colSpan: sideColSpan
    },
    {
      label: 'Qty',
      component: {
        type: 'Numeric',
        value: shouldConnectToSidecar
          ? regNmsApplicability === RegNmsApplicability.Applicable
            ? data?.quantity || '-'
            : '-'
          : '-',
        format: 'quantity'
      },
      colSpan: qtyColSpan
    },
    {
      label: 'Avg px',
      component: {
        type: 'Numeric',
        value: shouldConnectToSidecar
          ? regNmsApplicability === RegNmsApplicability.Applicable
            ? data?.averagePrice || '-'
            : '-'
          : '-',
        format: 'number'
      },
      colSpan: avgPxColSpan
    }
  ];

  if (!shouldConnectToSidecar) {
    items.concat(
      // This item is used as a spacer to take up the same width as the `Trade through` column
      {
        label: '',
        component: {
          type: 'Text',
          value: ''
        },
        colSpan: tradeThroughColSpan
      },
      {
        label: '',
        component: {
          type: 'Text',
          value: DATA_AVAILABLE_ON_DESKTOP_APPLICATION_ONLY,
          textProps: {
            sx: {
              textAlign: 'center'
            }
          }
        },
        colSpan: sideColSpan + qtyColSpan + avgPxColSpan
      }
    );
  }

  return {
    gridProps: {
      columns: 9,
      rows: 2,
      rowGap: 'medium'
    },
    items: items
  };
};

export type TradeThrough = { allowSweep: boolean };

export interface ITradeThroughObligation<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.TRADE_THROUGH_OBLIGATION, TradeThrough, TValidator> {}

export const TradeThroughObligation = (
  props: FieldProps<{
    name: Extract<keyof (PrincipalFillFormContract | CrossPrincipalFillFieldContract), 'tradeThrough'>;
  }>
) => {
  const isDevMode: boolean = isDevEnv();

  const shouldConnectToSidecar = useShouldConnectToSidecar(
    localStorage.getItem(FORCE_CONNECT_TO_SIDECAR) === 'true' || isDevMode
  );

  const formApi = useEnhancedFormApi<PrincipalFillFormContract | CrossPrincipalFillFieldContract>();
  const { instrument, price, limitPrice, regNmsApplicability } = formApi.getState().values;

  const {
    input: { value }
  } = useFieldApi<{}, TradeThrough>(props);

  const level2SipQuotes = useLevel2SipQuotes(instrument.id, regNmsApplicability, shouldConnectToSidecar);

  const [sipQuotesData, setSipQuotesData] = useState<TradeThroughDetails>({
    tradeThrough: undefined,
    side: undefined,
    quantity: undefined,
    averagePrice: undefined,
    bids: [],
    asks: [],
    malformed: false
  });

  const [allowSweep, setAllowSweep] = useState(value.allowSweep);

  useEffect(() => {
    setAllowSweep(value.allowSweep);
  }, [value.allowSweep]);

  useEffect(() => {
    if (regNmsApplicability === RegNmsApplicability.Applicable) {
      const tradeThroughDetails = getTradeThroughDetails(level2SipQuotes, price || limitPrice);
      setSipQuotesData(tradeThroughDetails);
    }
  }, [level2SipQuotes, regNmsApplicability, price, limitPrice]);

  const handleAllowSeepChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      formApi.change(props.name, { allowSweep: target.checked });
    },
    [formApi, props.name]
  );

  const displayGridItems = useMemo(() => {
    return getGridItems(sipQuotesData, regNmsApplicability, shouldConnectToSidecar);
  }, [sipQuotesData, regNmsApplicability, shouldConnectToSidecar]);

  return (
    <Flex>
      <DisplayGrid {...displayGridItems} labelMargin={6} width="550px" />
      <Switch
        checked={allowSweep}
        onChange={handleAllowSeepChange}
        sx={{ marginLeft: 10, alignSelf: 'flex-start' }}
        className={styles.allowSweepSwitch}
      >
        Allow sweep
      </Switch>
    </Flex>
  );
};
