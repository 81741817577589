import { ExecutionAuditTrail } from '@app/common/types/orders/orders.types';

import { useService } from '@oms/frontend-foundation';
import { GridBuilder, useVGrid, VGrid } from '@oms/frontend-vgrid';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import {
  inlineViewEventDetailsAction,
  viewEventDetailsAction
} from '@app/common/grids/actions/view-event-details.action';
import { buildAuditTrailColumnDefs } from './execution-view.audit-trail.columns';
import { InvestorOrderExecutionsService } from '@app/data-access/services/trading/investor-orders/investor-order-executions.service';

export const ExecutionViewAuditTrailGrid = ({ executionId }: { executionId: string }) => {
  const executionsService = useService(InvestorOrderExecutionsService);

  const props = useVGrid(
    'execution-audit-trail',
    (b: GridBuilder<ExecutionAuditTrail>) =>
      b
        .tableServerColumnLibrary(buildAuditTrailColumnDefs())
        .datasource((d) => d.source(executionsService.getAuditTrail$(executionId)).rowId((r) => r.data.id))
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .injectEvents([GridConfigEventHandler])
        .sideBar()
        .actions((a) =>
          a.schema((s) => s.action(viewEventDetailsAction).action(inlineViewEventDetailsAction))
        ),
    [executionsService, executionId]
  );
  return <VGrid {...props} />;
};
