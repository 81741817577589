import {
  FC,
  useCallback,
  useMemo,
  MouseEventHandler,
  useState,
  useEffect,
  CSSProperties,
  useRef
} from 'react';
import { Box, Divider, Flex, Stack } from '@oms/shared-frontend/ui-design-system';
import { useComponentService } from '../hooks/component.service.hook';
import { VGridProps } from './vgrid.component';
import { AnyRecord } from '@oms/frontend-foundation';
import { useGridContainer } from '../hooks/grid.container.hook';
import { useActionsService } from '../hooks/action.events.hook';
import { ComponentLocation, ComponentLocationKeys, VComponent } from '../models/v.component.model';
import { ToolbarService } from '../services/toolbar.service';
import { ToolbarLocationKeys, ToolbarStrategy } from '../models/toolbar.model';
import { getCurrentWebviewWindow } from '@tauri-apps/api/webviewWindow';
import { UnlistenFn } from '@tauri-apps/api/event';
import { isTauri } from '@valstro/workspace';

type RenderLocation = 'left' | 'right' | 'bottom';

export type VGridActionsToolbar = {
  location: RenderLocation;
  onToolbarHotkey?: VGridProps<AnyRecord>['onToolbarHotkey'];
};

const shouldRenderToolbar = (strat: ToolbarStrategy | undefined, comps: VComponent<AnyRecord>[]): boolean => {
  if (!strat) return false;
  switch (strat) {
    case 'visible':
      return true;
    case 'hidden':
      return false;
    case 'has-components':
      return !!comps?.length;
  }
};

const calcComponentLocation = (location: VGridActionsToolbar['location'], renderLocation: RenderLocation) =>
  location === 'bottom' && renderLocation === 'left'
    ? 'StaticToolbar'
    : location === 'bottom' && renderLocation === 'right'
      ? 'UserToolbar'
      : location === 'left' && renderLocation === 'left'
        ? 'LeftVerticalToolbar'
        : location === 'left'
          ? 'LeftVerticalToolbar'
          : location === 'right'
            ? 'RightVerticalToolbar'
            : 'RightVerticalToolbar';

const useToolbarStrategy = (
  toolbarLocation: VGridActionsToolbar['location'],
  renderLocation: RenderLocation
) => {
  const location = calcComponentLocation(toolbarLocation, renderLocation);
  const container = useGridContainer();
  const [toolbarStrategy, setToolbarStrategy] = useState<ToolbarStrategy>();

  useEffect(() => {
    const toolbarService = container.resolve(ToolbarService);
    const sub = toolbarService.byLocation$(location).subscribe((strategy) => {
      setToolbarStrategy(strategy);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [location]);

  return toolbarStrategy;
};

export const VGridToolbar: FC<VGridActionsToolbar> = ({ location, onToolbarHotkey }) => {
  const container = useGridContainer();
  const actionService = useActionsService();
  const [hotkeyOverlay, setHotkeyOverlay] = useState(false);
  const tauriFocusSub = useRef<UnlistenFn>();

  useEffect(() => {
    if (isTauri()) {
      if (tauriFocusSub.current) {
        tauriFocusSub.current();
      }

      getCurrentWebviewWindow()
        .onFocusChanged((e) => {
          if (!e.payload) {
            setHotkeyOverlay(false);
          }
        })
        .then((unsub) => {
          tauriFocusSub.current = unsub;
        });
    }

    const keydownCb: (e: KeyboardEvent) => any = (e) => {
      if (e.key === 'Control' && !hotkeyOverlay) {
        setHotkeyOverlay(true);
      }
    };

    const keyupCb: (e: KeyboardEvent) => any = (e) => {
      if (e.key === 'Control' && hotkeyOverlay) {
        setHotkeyOverlay(false);
      }
    };

    const blurCb: (e: FocusEvent) => any = (_e) => {
      setHotkeyOverlay(false);
    };

    document.addEventListener('keydown', keydownCb);
    document.addEventListener('keyup', keyupCb);
    document.addEventListener('blur', blurCb);

    return () => {
      document.removeEventListener('keydown', keydownCb);
      document.removeEventListener('keyup', keyupCb);
      document.removeEventListener('blur', blurCb);
      tauriFocusSub.current?.();
    };
  }, [hotkeyOverlay]);

  const userToolbarComps = useComponentService(ComponentLocation.UserToolbar);
  const staticToolbarComps = useComponentService(ComponentLocation.StaticToolbar);
  const infoToolbarComps = useComponentService(ComponentLocation.InfoToolbar);
  const suggestionToolbarComps = useComponentService(ComponentLocation.SuggestionToolbar);
  const leftVertComps = useComponentService(ComponentLocation.LeftVerticalToolbar);
  const rightVertComps = useComponentService(ComponentLocation.RightVerticalToolbar);
  const compsToDisplay = useMemo(
    () =>
      location === 'bottom'
        ? [staticToolbarComps, userToolbarComps]
        : location === 'left'
          ? [leftVertComps]
          : [rightVertComps],
    [staticToolbarComps, userToolbarComps, leftVertComps, location, rightVertComps]
  );
  const findComponentLocation: (renderLocation: RenderLocation) => ComponentLocationKeys = useCallback(
    (renderLocation: RenderLocation) => calcComponentLocation(location, renderLocation),
    [location]
  );

  const onToolbarClick: (renderLocation: RenderLocation) => MouseEventHandler<HTMLDivElement> = useCallback(
    (renderLocation: RenderLocation) => async () => {
      if (hotkeyOverlay) {
        const componentLocation = findComponentLocation(renderLocation);
        await onToolbarHotkey?.({
          location: componentLocation as ToolbarLocationKeys,
          actionService,
          container
        });

        // In the desktop app, when a new window pops up, we need the parent window to remove the overlay.
        const ctrlKeyupEvent = new KeyboardEvent('keyup', {
          key: 'Control',
          code: 'ControlLeft',
          keyCode: 17,
          charCode: 0,
          ctrlKey: true,
          bubbles: true
        });
        document.dispatchEvent(ctrlKeyupEvent);
      }
    },
    [findComponentLocation, onToolbarHotkey, actionService, container, hotkeyOverlay]
  );

  const leftmostToolbarStrategy = useToolbarStrategy(location, 'left');
  const rightmostToolbarStrategy = useToolbarStrategy(location, 'right');
  const shouldRender = [
    shouldRenderToolbar(leftmostToolbarStrategy, compsToDisplay[0]),
    shouldRenderToolbar(rightmostToolbarStrategy, compsToDisplay[1])
  ];

  const showOverlay = Boolean(hotkeyOverlay && onToolbarHotkey);

  return (
    <Flex
      data-testid={location}
      align="center"
      justify="space-between"
      sx={{
        px: 5,
        backgroundColor: 'layout.level3'
      }}
      className="ag-toolbar vgrid-toolbar"
      role="toolbar"
      style={{
        borderBottom: '0',
        height: location === 'bottom' ? 'auto' : '100%',
        flexDirection: location === 'bottom' ? 'row' : 'column',
        justifyContent: 'flex-start',
        width: location === 'bottom' ? undefined : '100px',
        display: shouldRender[0] || shouldRender[1] ? 'inherit' : 'none',
        position: 'relative'
      }}
    >
      {location === 'bottom' && (
        <VGridHorizontalToolbar
          staticToolbarComponents={compsToDisplay[0]}
          infoToolbarComponents={infoToolbarComps}
          userToolbarComponents={compsToDisplay[1]}
          suggestionToolbarComponents={suggestionToolbarComps}
          showOverlay={showOverlay}
          onClick={onToolbarClick}
          location={location}
        />
      )}

      {location !== 'bottom' && (
        <VGridVerticalToolbar
          leftToolbarComponents={compsToDisplay[0]}
          rightToolbarComponents={compsToDisplay[1]}
          showOverlay={showOverlay}
          onClick={onToolbarClick}
          location={location}
        />
      )}
    </Flex>
  );
};

type VGridHorizontalToolbarProps = {
  staticToolbarComponents: VComponent<AnyRecord>[];
  infoToolbarComponents: VComponent<AnyRecord>[];
  userToolbarComponents: VComponent<AnyRecord>[];
  suggestionToolbarComponents: VComponent<AnyRecord>[];
  showOverlay: boolean;
  onClick: (renderLocation: RenderLocation) => MouseEventHandler<HTMLDivElement>;
  location: RenderLocation;
};

const VGridHorizontalToolbar: FC<VGridHorizontalToolbarProps> = ({
  staticToolbarComponents,
  infoToolbarComponents,
  userToolbarComponents,
  suggestionToolbarComponents,
  onClick,
  showOverlay,
  location
}) => {
  return (
    <>
      {/* Only allow the overlay to be clicked if the location is bottom right */}
      <ToolbarOverlay show={showOverlay} testId={`edit-bottom-right`} onClick={onClick('right')} />
      {/* Static Toolbar */}
      <Stack
        data-testid={`${location}-left`}
        direction="horizontal"
        sx={{
          height: 'full',
          alignItems: 'center',
          position: 'relative',
          flexShrink: 0,
          py: 3.5
        }}
        spacing={2}
      >
        {staticToolbarComponents.map((e) => e.component && <e.component {...e.props} key={e.id} />)}
      </Stack>

      {/* Info Toolbar */}
      {infoToolbarComponents.length > 0 && (
        <Stack data-testid="info-toolbar" spacing={2} sx={{ marginLeft: 'auto' }}>
          {infoToolbarComponents.map((e) => e.component && <e.component {...e.props} key={e.id} />)}
        </Stack>
      )}

      {/* User Toolbar */}
      <Stack
        data-testid={`${location}-right`}
        sx={{
          height: 'full',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          position: 'relative',
          pt: 3.5,
          pb: 2
        }}
        onClick={onClick('right')}
        direction="horizontal"
        spacing={2}
      >
        <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
          {userToolbarComponents.map(
            (e) => e.component && <e.component {...e.props} sx={{ mr: 1, mb: 1 }} key={e.id} />
          )}
        </Box>
      </Stack>

      {/* Suggestion Toolbar */}
      {suggestionToolbarComponents.length > 0 && (
        <>
          <Box data-testid="suggestion-toolbar" sx={{ padding: 4, height: 'full' }}>
            <Divider orientation="vertical" borderColor="border.strong" />
          </Box>
          <Stack spacing={2}></Stack>
        </>
      )}
    </>
  );
};

type VGridVerticalToolbarProps = {
  showOverlay: boolean;
  leftToolbarComponents: VComponent<AnyRecord>[];
  rightToolbarComponents: VComponent<AnyRecord>[];
  onClick: (renderLocation: RenderLocation) => MouseEventHandler<HTMLDivElement>;
  location: RenderLocation;
};

export const VGridVerticalToolbar: FC<VGridVerticalToolbarProps> = ({
  showOverlay,
  leftToolbarComponents,
  rightToolbarComponents,
  onClick,
  location
}) => {
  return (
    <>
      <Stack
        data-testid={`${location}-left`}
        onClick={onClick('left')}
        direction="vertical"
        sx={{ height: 'full', width: 'full', alignItems: 'center', position: 'relative' }}
        spacing={2}
      >
        <ToolbarOverlay show={showOverlay} testId={`edit-${location}-left`} />
        {leftToolbarComponents?.map((e) => e.component && <e.component {...e.props} key={e.id} />)}
      </Stack>

      {rightToolbarComponents && (
        <Stack
          data-testid={`${location}-right`}
          sx={{
            height: 'full',
            width: 'full',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative'
          }}
          onClick={onClick('right')}
          direction="vertical"
          spacing={2}
        >
          <ToolbarOverlay show={showOverlay} testId={`edit-${location}-right`} />
          {rightToolbarComponents.map((e) => e.component && <e.component {...e.props} key={e.id} />)}
        </Stack>
      )}
    </>
  );
};

type ToolbarOverlayProps = {
  show: boolean;
  testId: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const ToolbarOverlay: FC<ToolbarOverlayProps> = ({ show, testId, onClick }) => {
  const overlayStyles: CSSProperties = useMemo(
    () => ({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      display: show ? 'flex' : 'none',
      position: show ? 'absolute' : 'inherit',
      zIndex: show ? 2 : 'inherit'
    }),
    [show]
  );

  return (
    <Box
      as={'div'}
      className="grid-toolbar-overlay"
      style={overlayStyles}
      data-testid={testId}
      onClick={onClick}
    />
  );
};
