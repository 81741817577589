import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { ExecutionRow } from '@oms/generated/frontend';

export const executionMonitorColumns = {
  id: {
    headerName: 'Execution Id',
    extends: 'generic.text'
  },
  side: {
    extends: 'orders.side'
  },
  investorAccountName: {
    extends: 'generic.text',
    minWidth: 180
  },
  instrumentDisplayCode: {
    extends: 'orders.instrumentDisplayCode'
  },
  executedQuantity: {
    extends: 'orders.quantity'
  },
  executedPrice: {
    extends: 'orders.price'
  },
  status: {
    extends: 'orders.tradeStatus'
  },
  executionDateTime: {
    extends: 'generic.dateTime'
  },
  createdTimestamp: {
    extends: 'generic.dateTime'
  },
  updatedTimestamp: {
    extends: 'generic.dateTime'
  },
  locate: {
    extends: 'generic.text'
  },
  normalizedLiquidityIndicator: {
    extends: 'generic.text'
  },
  rawLiquidityIndicator: {
    extends: 'generic.text'
  },
  capacity: {
    extends: 'orders.orderCapacity'
  },
  settleType: {
    extends: 'orders.settleType'
  },
  settleDate: { extends: 'generic.dateTime' },
  visibilityReason: {
    extends: 'orders.visibilityReason',
    hide: true
  },
  ownerID: {
    extends: 'orders.ownerId',
    hide: true
  },
  investorOrderId: {
    extends: 'orders.id'
  },
  trader: {
    extends: 'generic.text'
  },
  tradeCounterParty: {
    extends: 'generic.text'
  },
  lastTradeTime: { extends: 'generic.dateTime' },
  lastMarket: {
    extends: 'generic.text'
  }
} satisfies ColDefMap<ExecutionRow>;

const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

const executionsMonitorColumnLibrary: TableServerColumnLibrary<ExecutionRow> = {
  defaultColumn,
  columns: executionMonitorColumns
};

export const buildExecutionsMonitorColumnDefs = () =>
  buildColumnDefs(executionsMonitorColumnLibrary, 'executionMonitor');
