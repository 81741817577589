import type { DependencyContainer } from 'tsyringe';
import { InvestorOrderEntryService } from '@app/data-access/services/trading/investor-order-entry/investor-order-entry.service';
import { InvestorOrderRepairRequestService } from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import type {
  InvestorOrderEntryInput,
  InvestorOrderEventPayload,
  InvestorOrderFormInfo
} from './investor-order-entry.form-common';
import type { InvestorOrderEntryValues } from './investor-order-entry.form-contract';
import { OrderType, OrderEntryType, TimeInForce, OrderSettleType } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoFragment, IoRepairRequestFragment } from '@oms/generated/frontend';
import { convertStringToNumber, convertToNumber } from '@oms/shared/util';
import { mapCommissionTypeToRateType, mapRateTypeToCommissionType } from '@app/common/mappers/map-rate-type';
import { createChargeScheduleDisplayFromCompositeCharges } from './charge.schedule.display';
import {
  getTomorrowMidnight,
  getTimeInForceOptions
} from '@app/forms/common/fields/TIF-field/TIF-field.common';

export async function getInitialCommonIOFieldValues(
  input: InvestorOrderEntryInput,
  container: DependencyContainer
): Promise<InvestorOrderFormInfo> {
  const ioEntryService = container.resolve(InvestorOrderEntryService);
  const repairIoService = container.resolve(InvestorOrderRepairRequestService);

  const entryType = input.entryType;

  switch (entryType) {
    case 'update': {
      if (!input.id) throw new Error('Investor Order ID is required');
      const response = await ioEntryService.getById(input.id);

      if (response.isFailure()) {
        console.error(response.errors);
        throw new Error('Failed to get investor order');
      }

      if (response.isSuccess() && response.value.data.visibleInvestorOrder) {
        const visibleInvestorOrder = response.value.data.visibleInvestorOrder;

        return {
          type: 'update',
          investorOrder: visibleInvestorOrder
        };
      }
      break;
    }
    case 'repair': {
      if (!input.id) throw new Error('Repair Investor Order ID is required');
      const response = await repairIoService.getRepairRequestById(input.id);

      if (response.isFailure()) {
        console.error(response.errors);
        throw new Error('Failed to get repair request');
      }

      if (response.isSuccess() && response.value.data.getIoRepairRequestById) {
        const repairRequest = response.value.data.getIoRepairRequestById;

        return {
          type: 'repair',
          repairIO: repairRequest
        };
      }
      break;
    }
  }

  return {
    type: 'create'
  };
}

export function getInitialCommonFormValues(
  visibleInvestorOrder?: VisibleInvestorOrderInfoFragment
): Partial<InvestorOrderEntryValues> {
  let initialFormValues: Partial<InvestorOrderEntryValues> = {};

  initialFormValues.parentTradingOrderId = visibleInvestorOrder?.parentTradingOrderId;
  initialFormValues.owner = visibleInvestorOrder?.owner
    ? {
        id: visibleInvestorOrder.owner.id
      }
    : undefined;
  initialFormValues.rateType = visibleInvestorOrder?.commType
    ? mapCommissionTypeToRateType(visibleInvestorOrder?.commType)
    : undefined;
  initialFormValues.commission = visibleInvestorOrder?.commission || undefined;
  initialFormValues.customerNotes = visibleInvestorOrder?.customerNotes || undefined;
  initialFormValues.id = visibleInvestorOrder?.id;
  initialFormValues.instrument = visibleInvestorOrder?.instrument
    ? {
        id: visibleInvestorOrder.instrument.id
      }
    : undefined;
  initialFormValues.investorAccountId = visibleInvestorOrder?.investorAccount
    ? {
        id: visibleInvestorOrder.investorAccount.id
      }
    : undefined;
  initialFormValues.limitPrice = visibleInvestorOrder?.limitPrice || undefined;
  initialFormValues.locate = visibleInvestorOrder?.locate || undefined;
  initialFormValues.notionalAmount = visibleInvestorOrder?.notionalAmount || undefined;
  initialFormValues.orderTags =
    visibleInvestorOrder?.orderTags && visibleInvestorOrder.orderTags.length
      ? visibleInvestorOrder.orderTags.map((tag) => ({ id: tag?.id || '' }))
      : undefined;
  initialFormValues.quantity = visibleInvestorOrder?.quantity || undefined;
  initialFormValues.receivedTimestamp = visibleInvestorOrder?.receivedTimestamp || undefined;
  initialFormValues.representativeCode = visibleInvestorOrder?.representativeCode
    ? {
        id: visibleInvestorOrder.representativeCode.id
      }
    : undefined;
  initialFormValues.settleCurrency = visibleInvestorOrder?.settleCurrency
    ? {
        id: visibleInvestorOrder.settleCurrency
      }
    : undefined;
  initialFormValues.settleDate = visibleInvestorOrder?.settleDate;
  initialFormValues.settleType = visibleInvestorOrder?.settleType
    ? visibleInvestorOrder.settleType
    : undefined;
  initialFormValues.sideType = visibleInvestorOrder?.sideType;
  initialFormValues.stopPrice = visibleInvestorOrder?.stopPrice || undefined;
  initialFormValues.tradeCurrency = visibleInvestorOrder?.tradeCurrency
    ? {
        id: visibleInvestorOrder.tradeCurrency
      }
    : undefined;
  initialFormValues.chargeSchedule = createChargeScheduleDisplayFromCompositeCharges(
    visibleInvestorOrder?.compositeCharges ?? []
  );

  if (!visibleInvestorOrder) {
    // Set initial values in case of 'create'
    initialFormValues.timeInForce ??= getTimeInForceOptions().find((option) => option.id === TimeInForce.Day);
    initialFormValues.settleType ??= OrderSettleType.Regular;
    initialFormValues.settleDate = undefined;
  } else {
    initialFormValues.timeInForce = visibleInvestorOrder.timeInForce
      ? {
          id: visibleInvestorOrder.timeInForce,
          subValue: visibleInvestorOrder.gtdTimestamp
        }
      : undefined;
  }

  return initialFormValues;
}

export function getInitialRepairOnlyFormValues(
  repairRequest: IoRepairRequestFragment
): Partial<InvestorOrderEntryValues> {
  const initialFormValues: Partial<InvestorOrderEntryValues> = {};

  initialFormValues.instrument = repairRequest?.ioInstrument
    ? {
        id: repairRequest.ioInstrument.id
      }
    : undefined;

  initialFormValues.investorAccountId = repairRequest?.ioAccount
    ? {
        id: repairRequest.ioAccount.id
      }
    : undefined;

  return initialFormValues;
}

export function sanitizeFormValuesToCommonOutput(formValues: InvestorOrderEntryValues) {
  let extraProperties: {
    gtdTimestamp?: string;
    gtdUseMarketEndTime?: boolean;
    tifDuration?: string;
  } = {};

  if (formValues?.timeInForce?.subValue) {
    if (formValues?.timeInForce?.id === TimeInForce.Gtd) {
      extraProperties = {
        gtdTimestamp: formValues?.timeInForce?.subValue,
        gtdUseMarketEndTime: formValues.timeInForce?.subValue === getTomorrowMidnight()
      };
    }
    if (formValues?.timeInForce?.id === TimeInForce.Duration) {
      extraProperties = { tifDuration: formValues?.timeInForce?.subValue };
    }
  }

  return {
    id: formValues.id || '',
    locate: formValues.locate,
    customerNotes: formValues.customerNotes ?? null,
    limitPrice: convertStringToNumber(formValues.limitPrice) || null, // This has to be null otherwise BE won't create the order?
    orderTags: formValues.orderTags && formValues.orderTags.length > 0 ? formValues.orderTags : null,
    orderType: !convertStringToNumber(formValues.limitPrice) ? OrderType.Market : OrderType.Limit,
    quantity: convertStringToNumber(formValues.quantity),
    receivedTimestamp: formValues.receivedTimestamp,
    representativeCode: formValues.representativeCode,
    settleDate: formValues.settleDate || undefined,
    settleType: formValues.settleType,
    stopPrice: convertStringToNumber(formValues.stopPrice) || undefined,
    timeInForce: formValues?.timeInForce?.id,
    ...extraProperties
  };
}

export function sanitizeFormValuesToCreateOnlyOutput(formValues: InvestorOrderEntryValues) {
  return {
    instrument: formValues.instrument ? { id: formValues.instrument.id } : { id: '' },
    commType: mapRateTypeToCommissionType(formValues.rateType),
    commission: convertStringToNumber(formValues.commission),
    transmittedTimestamp: new Date().toISOString(),
    investorAccount: formValues.investorAccountId ? { id: formValues.investorAccountId.id } : undefined,
    settleCurrency: formValues.settleCurrency?.id,
    sideType: formValues.sideType,
    tradeCurrency: formValues.tradeCurrency?.id,
    orderEntryType: OrderEntryType.Manual,
    parentTradingOrderId: formValues.parentTradingOrderId,
    notionalAmount: convertStringToNumber(formValues.notionalAmount),
    owner: formValues.owner
    // stoppedPrice, stoppedTime and stoppedQuantity are mocked but are not supported by the backend yet.
  };
}

export function sanitizeFormValuesToUpdateOnlyOutput(formValues: InvestorOrderEntryValues) {
  return {
    commissionRateType: formValues.rateType,
    commissionRateValue: convertToNumber(formValues.commission)
  };
}

export function sanitizeFormValuesToRepairOnlyOutput(investorOrder: InvestorOrderEventPayload['order']) {
  return {
    orderEntryType: investorOrder.orderEntryType as OrderEntryType,
    id: investorOrder.id,
    fixMessageId: investorOrder.fixMessageId || '',
    side: investorOrder.side,
    fixMessage: investorOrder.fixMessage || '',
    clientOrderId: investorOrder.clientOrderId || ''
  };
}
