import { Lifecycle, scoped } from 'tsyringe';
import type {
  IRowNode,
  RowDataUpdatedEvent,
  RowEditingStoppedEvent,
  PasteEndEvent
} from '@ag-grid-community/core';
import type { EventHandler, EventSource, GridEvent } from '@oms/frontend-vgrid';
import { InvestorOrderItem } from '../investor-order-bulk-entry.form-common';

@scoped(Lifecycle.ContainerScoped)
export class InvestorOrderBulkEntryGridEventHander implements EventHandler {
  public name = 'new-portfolio-orders-grid-events';

  private prevRows: any[] = [];

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    eventSource
      .add('onRowDataUpdated', (e: RowDataUpdatedEvent) => {
        this.onRowDataChange(e).catch((error) => {
          console.error('onRowDataUpdated validation failed', error);
        });
      })
      .add('onRowEditingStopped', (e: RowEditingStoppedEvent) => {
        this.onRowDataChange(e).catch((error) => {
          console.error('onRowEditingStopped validation failed', error);
        });
      })
      .add('onPasteEnd', (e: PasteEndEvent) => {
        console.warn('onPasteEndEvent', e);
      });
  }

  private async onRowDataChange(e: RowDataUpdatedEvent | RowEditingStoppedEvent) {
    const _newRows = e.api.getRenderedNodes().map((node: IRowNode<InvestorOrderItem>) => node.data);
    const _formValues = e.context.formApiContext.getState().values;
    // TODO: implement
  }

  public removeEvents(): void {}
}
