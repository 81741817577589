import { TradingOrderTradesGrid } from './trading-order-view.trades.grid';
import { TradingOrderInvestorOrdersGrid } from './trading-order-view.investor-orders.grid';
import { TradingOrderAuditTrailGrid } from './trading-order-view.audit-trail.grid';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { TradingOrderViewLayoutComponentProps } from './trading-order-view.layout.config';
import { ViewTabsList } from '@app/widgets/common/components/view.tabs-list';
import type { TabsComponentProps } from '@app/widgets/common/components/view.tabs-list';

const getTabsListProps = (tradingOrderId: string): TabsComponentProps['tabs'] => [
  { name: 'Trades', value: 'tabTrades', tabContent: <TradingOrderTradesGrid id={tradingOrderId} /> },
  {
    name: 'Investor Orders',
    value: 'tabInvestorOrders',
    tabContent: <TradingOrderInvestorOrdersGrid id={tradingOrderId} />
  },
  {
    name: 'Audit Trail',
    value: 'tabAuditTrail',
    tabContent: <TradingOrderAuditTrailGrid tradingOrderId={tradingOrderId} />
  }
];
export const TradingOrderViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<TradingOrderViewLayoutComponentProps>();

  if (!props) {
    throw new Error('TradingOrderViewTabbedGridsWidget requires a trading order ID');
  }
  const { id: tradingOrderId } = props;

  return <ViewTabsList tabs={getTabsListProps(tradingOrderId)} defaultValue="tabTrades" />;
};

export default TradingOrderViewTabbedGridsWidget;
