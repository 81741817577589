import { CurrenciesService } from '@app/data-access/services/reference-data/currencies/currencies.service';
import type { UseFieldApiProps } from '@data-driven-forms/react-form-renderer';
import type {
  AdvancedSelectParams,
  AdvancedSelectQueryFn,
  DataSourceCommon,
  IAdvancedSelectField
} from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/shared-frontend/ui-design-system';
import type { CurrencyFragment } from '@oms/generated/frontend';
import { map } from 'rxjs';

export type CurrencyComboBoxData = CurrencyFragment;
export type CurrencyComboBoxValue = string;
export type AdvancedSelectCurrencyField = IAdvancedSelectField;

export const watchAllCurrenciesQuery: AdvancedSelectQueryFn<CurrencyComboBoxValue> = (container) => {
  const currenciesService = container.resolve(CurrenciesService);
  return {
    type: 'watchAll',
    query: (_params: AdvancedSelectParams<any, UseFieldApiProps<any>>) =>
      currenciesService.watchAll$().pipe(
        map<DataSourceCommon<CurrencyComboBoxData>, DataSourceCommon<ComboBoxItem<CurrencyComboBoxValue>>>(
          (r) => ({
            results: r.results?.map((currency) => ({
              id: currency.id,
              value: currency.id,
              sublabel: String(currency.longName || ''),
              label: currency.id,
              type: 'item'
            })),
            isFetching: r.isFetching,
            error: r.error
          })
        )
      )
  };
};

export default watchAllCurrenciesQuery;
