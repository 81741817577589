import {
  commonTimeInForceOptions,
  getTomorrowMidnight,
  makeTifGtdTimestampOption
} from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createCurrencyField } from '@app/forms/common/fields/currency-field/currency.field';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { TimeInForce } from '@oms/generated/frontend';
import { TagTypeEnum } from '@oms/generated/frontend';
import { FieldContract } from '@oms/frontend-foundation';
import { createOrderTagsField } from '@app/forms/common/fields/order-tags-field/order-tags.field';
import { createRepCodeField } from '@app/forms/common/fields/rep-code-field/rep-code.field';
import { createCommissionFields } from '@app/forms/common/fields/commission-field/commission.field';
import { createPrimaryBenchmarkField } from '@app/forms/common/fields/primary-benchmark-field/primary-benchmark.field';
import { createSettlementFields } from '@app/forms/common/fields/settlement-field/settlement.field';
import { createDiscretionaryTypeField } from '@app/forms/common/fields/discretionary-type-field/discretionary-type.field';
import { createStopPriceField } from '@app/forms/common/fields/stop-price-field/stop-price.field';
import { createActiveUserField } from '@app/forms/common/fields/active-user-field/active-user.field';
import { createCustomerNotesField } from '@app/forms/common/fields/customer-notes-field/customer-notes.field';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { createLocateField } from '@app/forms/common/fields/locate-field/locate-field.common';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { InvestorOrderBulkEntryOutput } from './investor-order-bulk-entry.form-common';
import type { IInvestorOrderBulkEntryGrid } from './orders-grid/investor-order-bulk-entry.grid';
import { createNotionalAmountField } from '@app/forms/common/fields/notional-amt-field/notional-amt.field';

const fc = FieldContract.create<
  InvestorOrderBulkEntryOutput,
  AdditionalFormFieldUnion | IInvestorOrderBulkEntryGrid
>();

export const id = fc.field('id', 'text-field').options({
  isVisible: false
});

// ----------- Runway fields --------------------------------------
// ----------------------------------------------------------------
export const investorAccountId = createAccountField(fc, 'investorAccount', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  autoFocus: false,
  isPrimaryField: true
});

// ----------- Orders entry grid ----------------------------------
// ----------------------------------------------------------------
export const investorOrders = fc.field('investorOrders', 'investor-order-bulk-entry');

// ----------- Oeder parameter fields -----------------------------
// ----------------------------------------------------------------
export const primaryBenchmark = createPrimaryBenchmarkField(fc, 'primaryBenchmark');

export const { settlementContainer, settlementDate, settlementType } = createSettlementFields(
  fc,
  'settleType',
  'settleDate'
);

export const settleCurrency = createCurrencyField(fc, 'settleCurrency', { label: 'Settle Currency' });

export const timeInForce = fc.field('timeInForce', 'multi-input').multiInputOptions<TimeInForce, string>({
  name: 'tif',
  label: 'TIF',
  validate: [{ type: 'validateTifInput' }, { type: 'afterDate', message: 'Must be future date.' }],
  options: commonTimeInForceOptions.concat(makeTifGtdTimestampOption('gtdTimestamp', getTomorrowMidnight()))
});

export const commission = createCommissionFields(fc, 'rateType', 'commission', {}, { decimalScale: 9 });

export const locate = createLocateField(fc, 'locate');

// ----------- All fields -----------------------------------------
// ----------------------------------------------------------------
export const notionalAmount = createNotionalAmountField(fc, 'notionalAmount');

export const discretionaryType = createDiscretionaryTypeField(fc, 'discretionaryType');

export const stopPrice = createStopPriceField(fc, 'stopPrice');

export const customerNotes = createCustomerNotesField(fc, 'customerNotes', { label: 'Customer notes' });

export const orderTags = createOrderTagsField(fc, 'orderTags', [TagTypeEnum.InvestorOrder]);

export const receivedTimestamp = fc.field('receivedTimestamp', 'native-date-picker').options<any>({
  wrapperSx: {
    width: 'full'
  },
  label: 'Received Time',
  timePicker: true
});

export const ownerId = createActiveUserField(fc, 'owner');

export const representativeCode = createRepCodeField(fc, 'representativeCode');

export const preAllocationAccount = fc.field('preAllocationAccount', 'text-field').options({
  label: 'Pre-allocation Account'
});

export const tradeCurrency = createCurrencyField(fc, 'tradeCurrency', {
  label: 'Trade Currency'
});

export const stoppedQuantity = fc.field('stoppedQuantity', 'number-format').options({
  label: 'Stopped Quantity',
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedTime', 'stoppedPrice'],
      message: 'Required'
    }
  ]
});

export const stoppedPrice = fc.field('stoppedPrice', 'number-format').options({
  label: 'Stopped Price',
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedTime', 'stoppedQuantity'],
      message: 'Required'
    }
  ]
});

export const stoppedTime = fc.field('stoppedTime', 'native-date-picker').options({
  label: 'Stopped Time',
  timePicker: true,
  validate: [
    {
      type: 'onlyIfRequired',
      fields: ['stoppedPrice', 'stoppedQuantity'],
      message: 'Required'
    }
  ]
});
