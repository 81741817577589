import type { RxCollection } from 'rxdb-v15';
import type { MigrationCollectionName } from './migrations';
import { REPLICATION_MIGRATIONS } from './migrations';
import type { AllRxDBMigrationItems, RxDBMigrationItem } from '@oms/frontend-schemas';
import type { ReplicableRxDocumentType } from '../types';
import { CompletedMigrationsDocType } from '../collections/completed-migrations.collection';
import { AppDatabase } from '../app-database';
import { MemoryDatabaseCollections } from '../collections';
import { UUID } from '@oms/shared/util';
import { inject, singleton } from 'tsyringe';
import UsersService from '@app/data-access/services/reference-data/users/users.service';

export const getCollectionVersionNumber = (collection: RxCollection) => collection.schema.jsonSchema.version;

export const getMigrationItems = (collection: RxCollection): AllRxDBMigrationItems => {
  if (!(collection.name in REPLICATION_MIGRATIONS)) {
    return [];
  }
  return REPLICATION_MIGRATIONS[collection.name as MigrationCollectionName];
};

export const MAX_MIGRATION_DEPTH = 100;
export const migrateDocument = (
  document: DocumentWithSchemaVersion,
  collection: RxCollection,
  migrations: AllRxDBMigrationItems = getMigrationItems(collection)
) => {
  let migratedDocument = structuredClone(document.document);
  let migration;
  let migrationDepth = 1;

  do {
    if (migrationDepth > MAX_MIGRATION_DEPTH) {
      throw new Error('Migration depth exceeded');
    }

    migration = Object.values(migrations).find(
      (m: RxDBMigrationItem) => m.version === document.schemaVersion + migrationDepth
    );

    if (!migration?.migration) {
      break;
    }

    migratedDocument = migration.migration(migratedDocument, collection);
    migrationDepth++;

    setTimeout(() => {
      void collection.database?.collections.completed_migrations
        ?.insert({
          id: UUID(),
          documentCollectionName: collection.name as keyof MemoryDatabaseCollections,
          documentPrimaryKey: String(
            migratedDocument[collection.schema.primaryPath as keyof typeof migratedDocument]
          )
        })
        .catch(console.error);
    }, 0);
  } while (migration);
  return { ...document, document: migratedDocument };
};

export const migrationNeeded = (documentSchemaVersion: number, collection: RxCollection): Boolean =>
  typeof getCollectionVersionNumber(collection) === 'number' &&
  documentSchemaVersion < getCollectionVersionNumber(collection);

export type DocumentWithSchemaVersion = {
  document: ReplicableRxDocumentType;
  schemaVersion: number;
  sharedBy?: string | null;
};

export function migrateDocumentsWithOutdatedSchemaVersion(
  documents: DocumentWithSchemaVersion[],
  collection: RxCollection,
  migrations?: AllRxDBMigrationItems // for testing
): DocumentWithSchemaVersion[] {
  return documents.map((document) => {
    const documentSchemaVersion = document.schemaVersion;
    if (migrationNeeded(documentSchemaVersion, collection)) {
      return migrateDocument(document, collection, migrations);
    } else {
      return document;
    }
  });
}

export function excludeDocumentsWithInvalidSchemaVersion(
  documents: DocumentWithSchemaVersion[],
  collection: RxCollection
): DocumentWithSchemaVersion[] {
  return documents.filter(({ schemaVersion: documentSchemaVersion }) => {
    const collectionVersionNumber = getCollectionVersionNumber(collection);
    if (typeof collectionVersionNumber !== 'number') {
      return true; // no schema expected
    }
    if (collectionVersionNumber === 0 && documentSchemaVersion === undefined) {
      return true; // no schema comparison required
    }
    return (
      typeof documentSchemaVersion === 'number' &&
      documentSchemaVersion <= getCollectionVersionNumber(collection) &&
      getCollectionVersionNumber(collection) - documentSchemaVersion < MAX_MIGRATION_DEPTH
    );
  });
}

export const lastUpdatedAtHooks = (collection: RxCollection) => {
  collection.preInsert((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
  collection.preSave((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
  collection.preRemove((data: { lastUpdatedAt: string }) => {
    data.lastUpdatedAt = new Date().toISOString();
    return data;
  }, true);
};

export const pushCompletedMigrations = (db: AppDatabase) => {
  return db.memory.completed_migrations.$.subscribe(
    (changeEvent: { documentData: CompletedMigrationsDocType }) => {
      const data = changeEvent.documentData;
      const collection = db.memoryDb.collections[data.documentCollectionName];
      const doc = collection.findOne(data.documentPrimaryKey); // TODO: await?
      doc.incrementalPatch({ lastUpdatedAt: new Date().toISOString() }).catch(console.error);
    }
  );
};

@singleton()
export class RxdbReplicationDecorator {
  constructor(@inject(UsersService) private usersService: UsersService) {}

  public setSharerName = async (documents: DocumentWithSchemaVersion[]) =>
    await Promise.all(
      documents.map(async (document) => {
        if (document.sharedBy) {
          const sharer = await this.usersService.getUser(document.sharedBy);
          if (sharer) {
            document.document.sharerName = sharer.name;
          }
        }
        return document;
      })
    );
}
