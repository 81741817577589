import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { DEFAULT_DATA_ACCESS_STATE as initalState } from '@app/common/data-access/data-access.contracts';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from './signal';
import { audit, distinctUntilChanged, tap, timer } from 'rxjs';
import * as Sentry from '@sentry/react';
import { Logger } from '@oms/shared/util';

const logger = Logger.named('Data Access Signal');

/**
 * Signal for DataAccessState
 *
 * Used to broadcast the current state of data access (graphql, rest, etc)
 * and to listen for changes to the DataAccessState
 *
 * Used primarily at the framework level to broadcast the current state of data access
 * @see app.stream.ts
 * @see plugin-data-access
 *
 * @usage
 * ```ts
 * const dataAccessSignal = container.resolve(DataAccessSignal);
 * const subscription = dataAccessSignal.$.subscribe((state) => {
 *  console.log('Data access state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(DataAccessSignal) private dataAccessSignal: DataAccessSignal) {
 *  const subscription = this.dataAccessSignal.$.subscribe((state) => {
 *    console.log('Data access state changed', state);
 *  });
 * }
 */
@testScoped
@singleton()
export class DataAccessSignal extends AbstractSignal<DataAccessState> {
  public auditTime: number = 1_000;

  constructor(@inject(ProcessState) processState: ProcessState, auditTime?: number) {
    super(
      processState,
      'dataAccess',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false,
        broadcast: false
      },
      initalState
    );

    this.auditTime = auditTime === undefined || auditTime === null ? this.auditTime : auditTime;

    logger.debug(`Delay for data access is ${this.auditTime}.`);

    this.signal.$ = this.signal.$.pipe(
      audit((value: DataAccessState) => {
        const shouldDelay = value === 'interrupted';
        const auditTime = shouldDelay ? this.auditTime : 0;
        shouldDelay &&
          logger.trace('Delaying data access state', { shouldDelay, auditTime, dataAccessState: value });
        return timer(auditTime);
      }),
      distinctUntilChanged(),
      tap((e) => {
        logger.trace(`Setting data access state to ${e}.`);
        Sentry.setContext('Data Access State', {
          state: e
        });
      })
    );
  }
}
