import { vars } from '@oms/shared-frontend/ui-design-system/css';

export const ClearFilterIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      arial-label="Clear filters"
    >
      <g id="clear_filter">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.52048 1.47979C2.32522 1.28453 2.00864 1.28453 1.81338 1.47979C1.61812 1.67505 1.61812 1.99163 1.81338 2.1869L2.45982 2.83334H1.83331C1.55717 2.83334 1.33331 3.0572 1.33331 3.33334C1.33331 3.60949 1.55717 3.83334 1.83331 3.83334H3.16665C3.25149 3.83334 3.3314 3.81221 3.4014 3.77492L6.95982 7.33334H3.66665C3.3905 7.33334 3.16665 7.5572 3.16665 7.83334C3.16665 8.10949 3.3905 8.33334 3.66665 8.33334H7.95982L13.8134 14.1869C14.0086 14.3822 14.3252 14.3822 14.5205 14.1869C14.7157 13.9916 14.7157 13.6751 14.5205 13.4798L12.095 11.0544C12.0804 11.0345 12.064 11.0155 12.046 10.9975L5.00304 3.95643C4.98749 3.94089 4.97117 3.92659 4.95421 3.91352L2.52048 1.47979ZM6.66665 2.83334C6.3905 2.83334 6.16665 3.0572 6.16665 3.33334C6.16665 3.60949 6.3905 3.83334 6.66665 3.83334H14.6666C14.9428 3.83334 15.1666 3.60949 15.1666 3.33334C15.1666 3.0572 14.9428 2.83334 14.6666 2.83334H6.66665ZM10.6666 7.83334C10.6666 7.5572 10.8905 7.33334 11.1666 7.33334H12.6666C12.9428 7.33334 13.1666 7.5572 13.1666 7.83334C13.1666 8.10949 12.9428 8.33334 12.6666 8.33334H11.1666C10.8905 8.33334 10.6666 8.10949 10.6666 7.83334ZM6.16665 12.8333C6.16665 12.5572 6.3905 12.3333 6.66665 12.3333H9.66665C9.94279 12.3333 10.1666 12.5572 10.1666 12.8333C10.1666 13.1095 9.94279 13.3333 9.66665 13.3333H6.66665C6.3905 13.3333 6.16665 13.1095 6.16665 12.8333Z"
          fill={vars.colors.base.BrandBlue[300]}
        />
      </g>
    </svg>
  );
};
