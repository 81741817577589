import { TableServerAggregatedTradesByPrice } from '@oms/generated/frontend';
import gql from 'graphql-tag';

// This is defined manually because of a rule in the backend for pluralisation/case-changing,
// which results in different query name if the view table name has a suffix of 'rice'.
// This rule is not applied in our codegen.
export const TsTradesGroupedByPriceWithFilterDocument = gql`
  subscription OnTsTradesGroupedByPriceWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsTradesGroupedByPriceWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsTradesGroupedByPriceQueryResult {
        rows {
          id
          price
          quantity
          tradingOrderId
          side
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsTradesGroupedByPricePatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }
`;

export type TsTradesGroupedByPriceWithFilterSubscription = {
  tsTradesGroupedByPriceWithFilter: {
    rows?: TableServerAggregatedTradesByPrice[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};
