import { Lifecycle, scoped, container, inject } from 'tsyringe';
import type { CellValueChangedEvent } from '@ag-grid-community/core';
import type { EventHandler, EventSource, GridEvent, VGridContextInstance } from '@oms/frontend-vgrid';
import { VGridInstance } from '@oms/frontend-vgrid';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { openConfirmation } from '@app/generated/sdk';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { NotificationSettingsType } from './notification-settings.contracts';
import type {
  NotificationSettingsTypeValue,
  MappedUpsertNotificationSettings
} from './notification-settings.contracts';
import { DefaultNotificationSettingsInput } from '@oms/generated/frontend';

@scoped(Lifecycle.ContainerScoped)
export class NotificatioSettingsGridEventHander implements EventHandler {
  public name = 'notification-settings-grid-events';

  constructor(
    @inject(VGridInstance.Context) private context: VGridContextInstance,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace
  ) {}

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    eventSource.add('onCellValueChanged', (e) => {
      this.onCellValueChanged(e).catch(console.error);
    });
  }

  private async onCellValueChanged(e: CellValueChangedEvent) {
    const notificationSettingsService = container.resolve(NotificationSettingsService);
    const gridType = e.context.gridType as NotificationSettingsTypeValue;
    const updatedSettings = this.getSettingsForType(e, gridType);
    let updateResponse;
    if (gridType === NotificationSettingsType.DEFAULT) {
      updateResponse = await notificationSettingsService.updateDefaultNotificationSettings(
        updatedSettings as DefaultNotificationSettingsInput
      );
    } else {
      updateResponse = await notificationSettingsService.upsertCustomSettings(
        updatedSettings as MappedUpsertNotificationSettings
      );
    }

    if (updateResponse.isFailure()) {
      // trigger a refresh of the data
      notificationSettingsService.triggerFetch();

      openConfirmation(this.context.workspace, this.appWorkspace.getLeaderProcessId(), {
        title: 'Edit Notification Setting',
        componentProps: {
          message: 'Notification setting did not save. The latest saved results are being shown.',
          autoClose: true,
          hideCancelButton: true,
          confirmButtonText: 'Ok'
        }
      }).catch(console.error);
    }
  }

  public removeEvents(): void {}

  private getSettingsForType(e: CellValueChangedEvent, gridType: NotificationSettingsTypeValue) {
    if (gridType === NotificationSettingsType.DEFAULT) {
      return {
        notificationName: e.data.notificationName,
        [e.colDef.field as string]: e.newValue
      };
    } else {
      return {
        id: e.data.id,
        matchedAccounts: e.data.matchedAccounts,
        notificationName: e.data.notificationName,
        description: e.data.description,
        isPopup: e.data.isPopup,
        isSound: e.data.isSound,
        isShown: e.data.isShown
      };
    }
  }
}
