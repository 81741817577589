import { OpenableRegistryDefinition } from '@app/app-config/registry.config';

const minSizeMapping: Record<string, { minWidth: number; minHeight: number }> = {
  layout: { minWidth: 900, minHeight: 400 },
  component: { minWidth: 300, minHeight: 200 },
  dialog: { minWidth: 250, minHeight: 165 }
};

export const getWindowMinSize = <TProps>(definition: OpenableRegistryDefinition<TProps>) => {
  const { type, windowOptions = {} } = definition;
  const { width, height, minWidth, minHeight } = windowOptions;

  return {
    minWidth: minWidth ?? (width ? Math.round(width / 2) : (minSizeMapping[type]?.minWidth ?? 0)),
    minHeight: minHeight ?? (height ? Math.round(height / 2) : (minSizeMapping[type]?.minHeight ?? 0))
  };
};
