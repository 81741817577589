import * as Sentry from '@sentry/react';
import { DeploymentStage } from '@fds/frontgate-js-sdk';
import omit from 'lodash/omit';
import {
  FactsetObserverConfig,
  FactsetPricesByTypeRequest,
  FactsetSentryContext,
  PricesByType
} from './factset.types';

const sentryFactsetEndpointTag = 'factset_endpoint';
const sentryFactsetTickerTag = 'factset_ticker';
const sentryFactsetEnvTag = 'factset_env';
const sentryFactsetPayloadTag = 'factset_payload';
const sentryFactsetMissingFieldTag = 'factset_missing_fields';
const sentryFactsetFieldMapTag = 'factset_mappings';
const sentryFactsetContextName = 'Factset Info';

const readableFactsetDeploymentStage = (env: DeploymentStage): string | DeploymentStage => {
  return Object.entries(DeploymentStage).find((e) => e[1] === env)?.[0] || env;
};

export const addFactsetScopeToSentry = (
  scope: Sentry.Scope,
  { client, ticker, requestPayload, endpoint, missingFields, mappings }: FactsetSentryContext
) => {
  const ctx = {
    ...(endpoint ? { [sentryFactsetEndpointTag]: endpoint } : {}),
    ...(ticker ? { [sentryFactsetTickerTag]: ticker } : {}),
    ...(client ? { [sentryFactsetEnvTag]: readableFactsetDeploymentStage(client.env) } : {}),
    ...(requestPayload ? { [sentryFactsetPayloadTag]: requestPayload } : {}),
    ...(missingFields ? { [sentryFactsetMissingFieldTag]: missingFields } : {}),
    ...(mappings ? { [sentryFactsetFieldMapTag]: mappings } : {})
  };

  ticker &&
    endpoint &&
    scope.setFingerprint(
      requestPayload ? [ticker, endpoint, JSON.stringify(requestPayload)] : [ticker, endpoint]
    );

  scope.setContext(sentryFactsetContextName, ctx);
  scope.setTags(omit(ctx, sentryFactsetPayloadTag, sentryFactsetMissingFieldTag, sentryFactsetFieldMapTag));

  return scope;
};

export const captureFactsetSentryException = (
  err: Error,
  ctx: FactsetSentryContext & { message: string }
) => {
  const { message } = ctx;

  Sentry.captureException(err, (scope) => {
    scope.setTransactionName(message);
    addFactsetScopeToSentry(scope, ctx);
    return scope;
  });
};

type TransformedTypes = {
  id: number;
  name: string;
}[];

type TransformFactsetTypes<T> = T extends { data: { types: number[] } }
  ? Omit<T, 'data'> & {
      data: Omit<T['data'], 'types'> & { types: TransformedTypes };
    }
  : never;

type FactsetPricesByTypeRequestTransformed = TransformFactsetTypes<FactsetPricesByTypeRequest>;

/**
 * Converts the factset request payload types property to include its enum name.
 * @param request The request payload sent to factset.
 * @returns The request payload sent to factset where the types array of numbers includes its enum name.
 */
export const transformGetByTypePayload = (
  request: FactsetObserverConfig<FactsetPricesByTypeRequest>
): FactsetObserverConfig<FactsetPricesByTypeRequestTransformed> => {
  const { payload } = request;
  const { data = {} as FactsetPricesByTypeRequest['data'] } = payload;
  const { types = [] } = data;
  const lookup = Object.entries(PricesByType);

  const result = structuredClone(payload as unknown as FactsetPricesByTypeRequestTransformed);

  result.data = result.data || { types: [] };
  result.data.types = types.map((t) => {
    const enumFound = lookup.find(([_, value]) => value === t);
    return { id: t, name: enumFound?.[0] || 'Unknown' };
  });

  return {
    ...request,
    payload: result
  };
};
