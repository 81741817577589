import { OrderType } from '@oms/generated/frontend';
import type { Maybe } from '@oms/generated/frontend';
import { zOrderTitleDetailsSchema, zTradeDescriptionSchema } from '../../zod/trading.zod.schemas';

type OrderTitleDetails = {
  sideType: Maybe<string>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  price: Maybe<number | string>;
  account?: Maybe<string>;
};

export type ViewDialogInfo = {
  id?: string;
  side: Maybe<string>;
  quantity: Maybe<number>;
  symbol: Maybe<string>;
  orderType?: Maybe<string>;
  price: Maybe<string | number>;
};

export const getViewDialogTitle = (
  dialogInfo: ViewDialogInfo,
  type: 'trading-order' | 'investor-order' | 'trade' | 'execution'
) => {
  const id = dialogInfo.id;
  const titleDetails: OrderTitleDetails = {
    sideType: dialogInfo?.side,
    symbol: dialogInfo?.symbol,
    quantity: dialogInfo?.quantity,
    price: dialogInfo?.orderType === OrderType.Market ? OrderType.Market : dialogInfo?.price
  };

  let title = '';
  if (type === 'investor-order') {
    title += 'Investor Order - ';
  } else if (type === 'trading-order') {
    title += 'Trading Order - ';
  } else if (type === 'trade') {
    title += 'Trade - ';
  }

  const result = zOrderTitleDetailsSchema.safeParse(titleDetails);
  if (result.success) {
    const { sideType, quantity, symbol, price } = result.data;
    title += `${sideType} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    title += `${id}`;
  } else {
    title += 'Unknown';
  }
  return title;
};

type TradeDescriptionDetails = {
  side: string;
  quantity: number;
  symbol: string;
  price: number | string;
  account?: string;
};

type TradeDescriptionArgs = {
  id: string;
  side: string;
  instrumentDisplayCode: string;
  quantity: number;
  price: number;
};

export const getTradeDescription = <T extends TradeDescriptionArgs>(trade: T) => {
  const id = trade.id;
  const titleDetails: TradeDescriptionDetails = {
    side: trade?.side,
    symbol: trade?.instrumentDisplayCode,
    quantity: trade?.quantity,
    price: trade?.price
  };

  let description = '';

  const result = zTradeDescriptionSchema.safeParse(titleDetails);
  if (result.success) {
    const { side, quantity, symbol, price } = result.data;
    description += `${side} ${quantity} ${symbol} @ ${price}`;
  } else if (id) {
    description += `${id}`;
  } else {
    description += 'Unknown';
  }
  return description;
};
