import React, { useEffect, useState } from 'react';
import type { FORM_COMPONENT_TYPE } from '../../../../forms/form-builder/common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '@oms/frontend-foundation';
import { TsInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  InvestorOrderInfoFragment,
  InvestorOrderRow,
  TsInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import type { PrincipalFillFormValues } from '../principal-fill.form-contract';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { convertToNumber } from '@oms/shared/util';
import { buildPrincipalFillColumnDefs } from './principal-fill.columns';
import { useEnhancedFormApi, useService } from '@oms/frontend-foundation';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { format } from '@oms/shared-frontend/ui-design-system';

export interface IPrincipalFillGrid<TValidator = Validator>
  extends ICommonTextField<
    typeof FORM_COMPONENT_TYPE.PRINCIPAL_FILL,
    InvestorOrderInfoFragment[],
    TValidator
  > {}

export type PrincipalFillGridRow = Partial<InvestorOrderRow> & { fillQuantity: number };

export const PrincipalFillGrid: React.FC<FieldProps<IPrincipalFillGrid>> = () => {
  const [investorOrderId, setInvestorOrderId] = useState<string | undefined>();
  const [fillQuantity, setFillQuantity] = useState<number | undefined>();

  const datasourceService = useService(TableServerDatasourceService);

  const formApi = useEnhancedFormApi<PrincipalFillFormValues>();

  useEffect(() => {
    formApi.get$({ values: true }).subscribe(({ values }) => {
      setInvestorOrderId(values?.investorOrders?.[0]?.id);
      setFillQuantity(convertToNumber(values.quantity));
    });
  }, [formApi]);

  const columnDefs = buildPrincipalFillColumnDefs();

  columnDefs.columns.push({
    headerName: 'Fill Quantity',
    valueFormatter: () => format('quantity', fillQuantity || '')
  });

  const gridProps = useVGrid<PrincipalFillGridRow>(
    'principal-fill',
    (builder) =>
      builder
        .tableServerColumnLibrary(columnDefs)
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<InvestorOrderRow, TsInvestorOrdersWithFilterSubscription>({
                query: TsInvestorOrdersWithFilterDocument,
                getData: (r) => r.tsInvestorOrdersWithFilter,
                filter: {
                  id: {
                    filterType: 'text',
                    type: 'equals',
                    filter: investorOrderId
                  }
                }
              })
            )
            .rowId((r) => r.data.id!)
            .cacheBlockSize(100)
        )
        .sideBar()
        .reactiveCustomComponents(),
    [investorOrderId, fillQuantity]
  );

  return (
    <>
      <VGrid {...gridProps} />;
    </>
  );
};
