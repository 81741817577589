import type { ActionComponentConfig, ActionDefFactory, GridActionBuilder } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { InvestorOrderBulkEntryService } from '@app/data-access/services/trading/investor-order-bulk-entry/investor-order-bulk-entry.service';
import type { InvestorOrderBulkEntryGridRow } from './investor-order-bulk-entry.grid';
import { InvestorOrderItem } from '../investor-order-bulk-entry.form-common';

export const copyOrderAction: ActionDefFactory<InvestorOrderBulkEntryGridRow> = (
  builder: GridActionBuilder<InvestorOrderBulkEntryGridRow>
) =>
  builder
    .name('copy_order_action')
    .toolbar((toolbar) =>
      toolbar
        .component('action-button')
        .id('copy_order_action_button')
        .location('UserToolbar')
        .props({
          type: 'button',
          content: t('app.common.copy'),
          isDisabled: true,
          spacing: 0
        })
    )
    .lifecycles('change', 'onSelectionChanged')
    .onChange<ActionComponentConfig<InvestorOrderBulkEntryGridRow>>((ctx) => {
      const { lifecycle, api, notify, context } = ctx;
      const formId = context.formId;
      const selecterRows = api.getSelectedRows() as InvestorOrderBulkEntryGridRow[];

      switch (lifecycle) {
        case 'change': {
          const bulkIOEntryService = ctx.appContainer.resolve(InvestorOrderBulkEntryService);
          bulkIOEntryService.copy(
            formId,
            selecterRows.map((order: InvestorOrderItem) => order.id)
          );
          break;
        }
        case 'onSelectionChanged': {
          notify({ isDisabled: selecterRows.length === 0 });
          break;
        }
      }
    });

export const deleteOrderAction: ActionDefFactory<InvestorOrderBulkEntryGridRow> = (
  builder: GridActionBuilder<InvestorOrderBulkEntryGridRow>
) =>
  builder
    .name('delete_order_action')
    .toolbar((toolbar) =>
      toolbar
        .component('action-button')
        .id('delete_order_action_button')
        .location('UserToolbar')
        .props({
          type: 'button',
          content: t('app.common.delete'),
          isDisabled: true,
          spacing: 0
        })
    )
    .onChange<ActionComponentConfig<InvestorOrderBulkEntryGridRow>>((ctx) => {
      const { lifecycle, api, notify, context } = ctx;
      const formId = context.formId;
      const selecterRows = api.getSelectedRows() as InvestorOrderBulkEntryGridRow[];

      switch (lifecycle) {
        case 'change': {
          const bulkIOEntryService = ctx.appContainer.resolve(InvestorOrderBulkEntryService);
          bulkIOEntryService.delete(
            formId,
            selecterRows.map((order: InvestorOrderItem) => order.id)
          );
          break;
        }
        case 'onSelectionChanged': {
          notify({ isDisabled: selecterRows.length === 0 });
          break;
        }
      }
    });
