import React, { useEffect } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { format, type FormatType } from '@oms/shared-frontend/ui-design-system';
import { FlexibleContentRenderer, useFlexibleContent } from './flexible-content.utils';

export const FLEXIBLE_DATETIME_RENDERER = 'FlexibleDatetimeCellRenderer';

const formats: FormatType[][] = [
  ['date', 'time-with-milliseconds'],
  ['datetime'],
  ['monthAndDay', 'time-with-seconds'],
  ['monthAndDay', 'time'],
  ['time']
];

export const FlexibleDatetimeCellRenderer: React.FC<
  Omit<ICellRendererParams, 'value'> & { value: string }
> = ({ value }) => {
  const { containerRef, hiddenRef, containerWidth, displayState, checkTextWidthAndSetContent } =
    useFlexibleContent();

  useEffect(() => {
    formats.some((fmtCombination, index) => {
      const formattedValue = fmtCombination.map((fmt) => format(fmt, value)).join(' ');

      if (index === formats.length - 1) {
        const minimalText = `${format('time', value).slice(0, 2)}:…`;
        const tooltip = format('datetime', value);
        return checkTextWidthAndSetContent(formattedValue, minimalText, tooltip);
      }

      return checkTextWidthAndSetContent(formattedValue);
    });
  }, [value, containerWidth]);

  return (
    <FlexibleContentRenderer
      containerRef={containerRef}
      hiddenElementRef={hiddenRef}
      content={displayState.content}
      tooltip={displayState.tooltip}
    />
  );
};
