import { createClient } from 'graphql-ws';
import { AbstractSignal } from './signal';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { tap } from 'rxjs';
import * as Sentry from '@sentry/react';
import { testScoped } from '@app/workspace.registry';

type SocketStates = keyof NonNullable<Parameters<typeof createClient>['0']['on']>;

export type GraphqlSocketStateTypes = Exclude<SocketStates, 'ping' | 'pong' | 'message'> | 'init';

export type GraphqlSocketState = {
  state: GraphqlSocketStateTypes;
  url?: string;
};

export const DEFAULT_GRAPHQL_SOCKET_STATE: GraphqlSocketState = {
  state: 'closed'
};

@testScoped
@singleton()
export class GraphqlSocketSignal extends AbstractSignal<GraphqlSocketState> {
  constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      'socket',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false,
        broadcast: false
      },
      DEFAULT_GRAPHQL_SOCKET_STATE
    );
    this.signal.$ = this.signal.$.pipe(
      tap((e) => {
        Sentry.setContext('Socket State', e);
      })
    );
  }
}
