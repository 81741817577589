import { getTomorrowMidnight } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { mapRateTypeToCommissionType } from '@app/common/mappers/map-rate-type';
import { TimeInForce, User } from '@oms/generated/frontend';
import { convertStringToNumber } from '@oms/shared/util';
import { InvestorOrderBulkEntryFormValues } from './investor-order-bulk-entry.form-contract';
import {
  InvestorOrderBulkEntryOutput,
  InvestorOrderItem,
  InvestorOrderItemOutput
} from './investor-order-bulk-entry.form-common';

export async function getInitialCommonIOFieldValues() {}

export function sanitizeFormValuesToOutput(
  formValues: InvestorOrderBulkEntryFormValues
): InvestorOrderBulkEntryOutput {
  let extraProperties: {
    gtdTimestamp?: string;
    gtdUseMarketEndTime?: boolean;
    tifDuration?: string;
  } = {};

  if (formValues?.timeInForce?.subValue) {
    if (formValues?.timeInForce?.id === TimeInForce.Gtd) {
      extraProperties = {
        gtdTimestamp: formValues?.timeInForce?.subValue,
        gtdUseMarketEndTime: formValues.timeInForce?.subValue === getTomorrowMidnight()
      };
    }
    if (formValues?.timeInForce?.id === TimeInForce.Duration) {
      extraProperties = { tifDuration: formValues?.timeInForce?.subValue };
    }
  }

  return {
    investorAccount: formValues.investorAccount ? { id: formValues.investorAccount.id } : undefined,
    investorOrders: formValues.investorOrders
      ? formValues.investorOrders.map(
          (order: InvestorOrderItem) =>
            ({
              id: order.id,
              side: order.sideType,
              quantity: order.quantity,
              instrument: order.instrument,
              limitPrice: order.limitPrice
            }) as unknown as InvestorOrderItemOutput
        )
      : ([] as InvestorOrderItemOutput[]),
    primaryBenchmark: formValues.primaryBenchmark,
    settleCurrency: formValues.settleCurrency?.id,
    settleType: formValues.settleType,
    settleDate: formValues.settleDate || undefined,
    timeInForce: formValues.timeInForce?.id || undefined,
    commission: convertStringToNumber(formValues.commission),
    commType: mapRateTypeToCommissionType(formValues.rateType),
    rateType: formValues.rateType,
    locate: formValues.locate,
    // Not exist in InvestorOrderInput
    discretionaryType: formValues.discretionaryType,
    stopPrice: convertStringToNumber(formValues.stopPrice) || undefined,
    customerNotes: formValues.customerNotes ?? null,
    orderTags:
      formValues.orderTags && formValues.orderTags.length
        ? formValues.orderTags.map((tag) => ({ id: tag.id }))
        : undefined,
    // if use enrichment
    // orderTags: formValues.orderTags && formValues.orderTags.length > 0 ? formValues.orderTags : null,
    receivedTimestamp: formValues.receivedTimestamp,
    owner: formValues?.owner?.id ? ({ id: formValues.owner.id } as User) : undefined,
    representativeCode: formValues.representativeCode ? { id: formValues.representativeCode.id } : undefined,
    // if use enrichment
    // representativeCode: formValues.representativeCode,
    // Not exist in InvestorOrderInput
    preAllocationAccount: formValues.preAllocationAccount || '',
    tradeCurrency: formValues.tradeCurrency?.id,
    stoppedPrice: formValues.stoppedPrice,
    stoppedQuantity: formValues.stoppedQuantity,
    stoppedTime: formValues.stoppedTime,
    ...extraProperties
  };
}
