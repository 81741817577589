import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { buildNewOrdersGridColumnDefs } from './new-orders.columns';
import { inlineNewOrdersActions } from './grid-actions/inline.new-orders.action';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import { useScopedProps } from '@app/data-access/hooks/use-scoped-props.hook';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createAlertsEventHandler } from '@app/common/grids/event-handlers/alerts.event-handler';
import { createCloseOnEmptyEventHandler } from '@app/common/grids/event-handlers/close-on-empty.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import type { NewOrdersLayoutProps } from './new-orders.layout.config';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import {
  InvestorOrderStatus,
  TsInvestorOrdersWithFilterDocument,
  TsInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { setupGridActions } from '@app/actions/grids/setup.configurable.actions';
import { createAcceptInvestorOrderAction } from '../investor-order-monitor/commands/accept-investor-order/accept-investor-order.action';
import { createRejectInvestorOrderAction } from '../investor-order-monitor/commands/reject-investor-order/reject-investor-order.action';

export const NEW_ORDERS = 'new-orders';

export const NewOrdersGridWidget = () => {
  const { autoCloseOnEmpty } = useScopedProps<NewOrdersLayoutProps>() ?? {};
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<InvestorOrderRow>(
    NEW_ORDERS,
    (builder) =>
      setupGridActions({
        meta: {
          widgetTypeId: 'NEW_ORDERS_GRID',
          allowedCommands: ['accept_investor_order', 'reject_investor_order']
        },
        grid: builder
          .tableServerColumnLibrary(buildNewOrdersGridColumnDefs())
          .datasource((d) =>
            d
              .source(
                datasourceService.getSource<InvestorOrderRow, TsInvestorOrdersWithFilterSubscription>({
                  query: TsInvestorOrdersWithFilterDocument,
                  getData: (r) => r.tsInvestorOrdersWithFilter,
                  filter: {
                    status: {
                      filterType: 'set',
                      values: [InvestorOrderStatus.Unaccepted]
                    }
                  }
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .rowSelection((c) =>
            c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0])
          )
          .rowStateRules({
            pending: (params) => IOPending(params.data),
            noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
          })
          .sideBar()
          .injectEvents([
            createAlertsEventHandler<InvestorOrderRow>('visibilityReason'),
            createCloseOnEmptyEventHandler(autoCloseOnEmpty)
          ])
          .actions((a) =>
            a.schema((s) =>
              s
                .action(createAcceptInvestorOrderAction('context-menu'))
                .action(createRejectInvestorOrderAction('context-menu'))
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(inlineNewOrdersActions)
                .action(refreshServersideCustomMenuAction)
            )
          )
          .reactiveCustomComponents()
      }),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default NewOrdersGridWidget;
