import { GridApi } from '@ag-grid-community/core';
import type { Observable, OperatorFunction } from 'rxjs';
import { BehaviorSubject, combineLatestWith, distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import type { Optional } from '@oms/shared/util-types';
import type { Logger } from '@oms/shared/util';
import { getGridContext, updateGridContext } from '../grid-api/grid-context.util';
import type { GridTrackingConsumerContext, TrackingSourceType } from './grid-tracking.types';
import { ALL_TRACKING_SOURCES } from './grid-tracking.constants';

interface GridTrackingOptions {
  logger?: Logger;
}

/**
 * Gets or initializes a tracking BehaviorSubject in the grid context.
 * If tracking already exists, returns the existing subject.
 * If not, creates a new tracking subject and stores it in the grid context.
 *
 * @param gridApi - The AG Grid API instance
 * @param options - Optional configuration including logger
 * @returns BehaviorSubject<boolean> indicating tracking state
 *
 * @example
 * ```typescript
 * const isTracking$ = getOrInitTrackingInGridContext(gridApi);
 * ```
 */
export function getOrInitTrackingInGridContext(
  gridApi: GridApi,
  options?: GridTrackingOptions
): BehaviorSubject<boolean> {
  const ctx = getGridContext<GridTrackingConsumerContext>(gridApi);
  if (ctx.isTracking$) {
    return ctx.isTracking$;
  } else {
    const gridId = gridApi.getGridId();
    const isTracking$ = new BehaviorSubject(false);
    updateGridContext<GridTrackingConsumerContext>(gridApi, { isTracking$ });
    options?.logger?.debug(`Grid ID ${gridId}: Initialized tracking 🎯`, {
      gridId,
      isTracking: isTracking$.getValue()
    });
    return isTracking$;
  }
}

interface TrackingFilterOptions<T> {
  onStopTracking?: (data: T) => T;
}

/**
 * RxJS operator that filters a stream based on a tracking state.
 * Only emits values when tracking is active (true).
 *
 * @template TData The type of data being streamed
 * @param isTracking$ Observable that indicates whether tracking is active
 * @param options Configuration options
 * @param options.onStopTracking Optional callback to transform the value when tracking stops. If omitted, value is emitted as-is.
 * @returns An operator that filters values based on tracking state
 *
 * @example
 * ```typescript
 * // Basic usage
 * const data$ = source$.pipe(
 *   trackingFilter(isTracking$)
 * );
 *
 * // With custom handling when tracking stops
 * const data$ = source$.pipe(
 *   trackingFilter(isTracking$, {
 *     onStopTracking: (value) => ({ ...value, selected: false })
 *   })
 * );
 * ```
 */
export function trackingFilter<T>(
  isTracking$: Optional<Observable<boolean>>,
  options?: TrackingFilterOptions<T>
): OperatorFunction<T, T> {
  return (source$: Observable<T>) => {
    let hasStarted = false;
    const tracking$ = (isTracking$ || of(true)).pipe(
      tap((isTracking) => {
        if (hasStarted) return;
        if (isTracking) {
          hasStarted = true;
        }
      }),
      filter((isTracking) => isTracking || hasStarted),
      distinctUntilChanged()
    );
    return source$.pipe(
      combineLatestWith(tracking$),
      map(([value, isTracking]) => ({ value, isTracking })),
      distinctUntilChanged((previous, current) => {
        if (current.isTracking !== previous.isTracking) {
          // If tracking changes, always emit the value
          return false;
        } else if (current.isTracking && previous.isTracking) {
          // While tracking is active, emit as long as value has channged
          return current.value === previous.value;
        } else {
          // While tracking is inactive, never emit the value
          return true;
        }
      }),
      map(({ value, isTracking }) => {
        if (isTracking) {
          return value;
        } else {
          const stopValue = options?.onStopTracking?.(value);
          return typeof stopValue !== 'undefined' ? stopValue : value;
        }
      }),
      distinctUntilChanged()
    );
  };
}

/**
 * Determines if a given source type is a valid tracking source.
 *
 * @param sourceType - The source type to check
 * @returns Whether the source type is a valid tracking source
 */
export function isSourceType(sourceType: string): sourceType is TrackingSourceType {
  return ALL_TRACKING_SOURCES.has(sourceType as TrackingSourceType);
}
