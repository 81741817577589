import {
  ToolPanelVisibleChangedEvent,
  GridReadyEvent,
  RowDataUpdatedEvent,
  ColumnVisibleEvent,
  SelectionChangedEvent,
  ModelUpdatedEvent,
  ColumnValueChangedEvent,
  GridColumnsChangedEvent,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnPivotChangedEvent,
  ColumnRowGroupChangedEvent,
  FirstDataRenderedEvent,
  RowClickedEvent,
  RowDoubleClickedEvent,
  AsyncTransactionsFlushed,
  CellValueChangedEvent,
  PaginationChangedEvent,
  FilterChangedEvent,
  FilterModifiedEvent,
  SortChangedEvent,
  RowDragEndEvent,
  StoreUpdatedEvent,
  GridSizeChangedEvent,
  BodyScrollEndEvent,
  GridPreDestroyedEvent,
  StateUpdatedEvent,
  StoreRefreshedEvent,
  RowEditingStoppedEvent,
  CellClickedEvent,
  CellContextMenuEvent,
  DisplayedColumnsChangedEvent,
  VirtualColumnsChangedEvent,
  AgGridEvent,
  PasteEndEvent,
  PinnedRowDataChangedEvent
} from '@ag-grid-community/core';
import { AnyRecord } from '@oms/frontend-foundation';
import { DependencyContainer } from 'tsyringe';

export type CustomGridEvent<TData extends AnyRecord = AnyRecord> = {
  serverSideRowDataUpdated: (e: RowDataUpdatedEvent<TData>) => void;
  gridConfigReady: (e: AgGridEvent<TData>) => void;
};

export const CUSTOM_EVENT_KEY = {
  SERVER_SIDE_ROW_DATA_UPDATED: 'serverSideRowDataUpdated',
  GRID_CONFIG_READY: 'gridConfigReady'
} as const;

export interface GridEvent<TData extends AnyRecord = AnyRecord> {
  onGridReady: (e: GridReadyEvent<TData>) => void | Promise<void>;
  onRowDataUpdated: (e: RowDataUpdatedEvent<TData>) => void | Promise<void>;
  onPaginationChanged: (e: PaginationChangedEvent<TData>) => void | Promise<void>;
  onFirstDataRendered: (e: FirstDataRenderedEvent<TData>) => void | Promise<void>;
  onColumnVisible: (e: ColumnVisibleEvent<TData>) => void | Promise<void>;
  onToolPanelVisibleChanged: (e: ToolPanelVisibleChangedEvent<TData>) => void | Promise<void>;
  onSelectionChanged: (e: SelectionChangedEvent<TData>) => void | Promise<void>;
  onCellContextMenu: (e: CellContextMenuEvent) => void | Promise<void>;
  onModelUpdated: (e: ModelUpdatedEvent<TData>) => void | Promise<void>;
  onFilterChanged: (e: FilterChangedEvent<TData>) => void | Promise<void>;
  onFilterModifed: (e: FilterModifiedEvent<TData>) => void | Promise<void>;
  onSortChanged: (e: SortChangedEvent<TData>) => void | Promise<void>;
  onColumnValueChanged: (e: ColumnValueChangedEvent<TData>) => void | Promise<void>;
  onGridColumnsChanged: (e: GridColumnsChangedEvent<TData>) => void | Promise<void>;
  onColumnMoved: (e: ColumnMovedEvent<TData>) => void | Promise<void>;
  onColumnPinned: (e: ColumnPinnedEvent<TData>) => void | Promise<void>;
  onColumnResized: (e: ColumnResizedEvent<TData>) => void | Promise<void>;
  onColumnPivotChanged: (e: ColumnPivotChangedEvent<TData>) => void | Promise<void>;
  onColumnRowGroupChanged: (e: ColumnRowGroupChangedEvent<TData>) => void | Promise<void>;
  onRowClicked: (e: RowClickedEvent<TData>) => void | Promise<void>;
  onRowDoubleClicked: (e: RowDoubleClickedEvent<TData>) => void | Promise<void>;
  onAsyncTransactionsFlushed: (e: AsyncTransactionsFlushed<TData>) => void | Promise<void>;
  onCellValueChanged: (e: CellValueChangedEvent<TData>) => void | Promise<void>;
  onCellClicked: (e: CellClickedEvent<TData>) => void | Promise<void>;
  onRowDragEnd: (e: RowDragEndEvent<TData>) => void | Promise<void>;
  // Note: This is a dangerous event to use, as it can be fired multiple times very quickly & cause infinite re-renders.
  onStoreUpdated: (e: StoreUpdatedEvent<TData>) => void | Promise<void>;
  onStoreRefreshed: (e: StoreRefreshedEvent<TData>) => void | Promise<void>;
  onGridSizeChanged: (e: GridSizeChangedEvent<TData>) => void | Promise<void>;
  onBodyScrollEnd: (e: BodyScrollEndEvent<TData>) => void | Promise<void>;
  onGridPreDestroyed: (e: GridPreDestroyedEvent<TData>) => void | Promise<void>;
  onStateUpdated: (e: StateUpdatedEvent<TData>) => void | Promise<void>;
  onRowEditingStopped: (e: RowEditingStoppedEvent<TData>) => void | Promise<void>;
  onDisplayedColumnsChanged: (event: DisplayedColumnsChangedEvent<TData>) => void;
  onVirtualColumnsChanged: (event: VirtualColumnsChangedEvent<TData>) => void;
  onPasteEnd: (e: PasteEndEvent<TData>) => void;
  onPinnedRowDataChanged: (e: PinnedRowDataChangedEvent<TData>) => void;
}

export type GridEventType = keyof GridEvent;

export interface EventStore {
  priority: number;
  cb: (...args: any[]) => void | Promise<void>;
}

export class EventSource<TEvent extends GridEventType, TData extends AnyRecord = AnyRecord> {
  private _eventMap = new Map<TEvent, EventStore[]>();
  public container: DependencyContainer;

  constructor(container: DependencyContainer) {
    this.container = container;
  }

  public add<K extends TEvent>(
    type: K,
    callback: GridEvent<TData>[K],
    priority: number = 0
  ): EventSource<TEvent, TData> {
    const store = this._eventMap.get(type) || [];
    store.push({ priority, cb: callback });

    this._eventMap.set(type, store);

    return this;
  }

  public remove(type: TEvent): void {
    this._eventMap.delete(type);
  }

  public clear(): void {
    this._eventMap.clear();
  }

  public get<K extends TEvent>(type: K): GridEvent<TData>[K] {
    // console.log(`There are ${(this._eventMap.get(type) || []).length} events of type ${type}.`);
    const evts = this._eventMap.get(type) || [];

    const cbs = evts.sort((e) => e.priority).map((e) => e.cb);

    return (...a: any[]) => {
      cbs.forEach((cb) => cb(...a));
    };
  }

  public forEach(cb: <K extends TEvent>(eventName: K, callback: GridEvent<TData>[K]) => void): void {
    this._eventMap.forEach(async (_, evt) => {
      cb(evt, this.get<any>(evt));
    });
  }
}
