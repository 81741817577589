import { TradeAuditTrail } from '@app/common/types/orders/orders.types';

import { useService } from '@oms/frontend-foundation';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { GridBuilder, useVGrid, VGrid } from '@oms/frontend-vgrid';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import {
  inlineViewEventDetailsAction,
  viewEventDetailsAction
} from '@app/common/grids/actions/view-event-details.action';
import { auditTrailColumnLibrary } from './trade-view.audit-trail.columns';

export const TradeViewAuditTrailGrid = ({ tradeId }: { tradeId: string }) => {
  const tradesService = useService(TradesService);

  const props = useVGrid(
    'trade-audit-trail',
    (b: GridBuilder<TradeAuditTrail>) =>
      b
        .columnLibrary(auditTrailColumnLibrary)
        .datasource((d) => d.source(tradesService.getAuditTrail$(tradeId)).rowId((r) => r.data.id))
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .injectEvents([GridConfigEventHandler])
        .sideBar()
        .actions((a) =>
          a.schema((s) => s.action(viewEventDetailsAction).action(inlineViewEventDetailsAction))
        ),
    [tradesService, tradeId]
  );
  return <VGrid {...props} />;
};
