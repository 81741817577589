import { useRef } from 'react';
import type { TradeRepairRequestFragment } from '@oms/generated/frontend';
import {
  useVGrid,
  VGrid,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  toClientsideGridData,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { useSelectTabOnLayoutEffect } from '@app/data-access/hooks/use-select-tab.hook';
import { useScopedProps } from '@app/data-access/hooks/use-scoped-props.hook';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createCloseOnEmptyEventHandler } from '@app/common/grids/event-handlers/close-on-empty.event-handler';
import { groupByTradesAction } from './grid-actions/group-by.repair-queue.trades.action';
import { repairCreateTradeAction } from './grid-actions/repair.trades.action';
import { rejectRepairQueueTradesAction } from './grid-actions/reject.repair-queue.trades.action';
import { retryCreateTradeAction } from './grid-actions/retry.trades.action';
import { tradeRepairRequestColumnLibrary } from './repair-queue.trades.columns';
import type { RepairQueueLayoutProps } from './repair-queue.layout.config';

export const REPAIR_QUEUE_TRADES = 'repair-queue-trades';

export const RepairQueueTradesGridWidget = () => {
  const { tab = '', autoCloseOnEmpty } = useScopedProps<RepairQueueLayoutProps>() ?? {};
  const scopedActorId = useScopedActorId();
  const datasourceService = useService(TradeRepairRequestService);
  const query$ = useRef(datasourceService.watchAll$().pipe(toClientsideGridData()));

  useSelectTabOnLayoutEffect(scopedActorId, tab);

  const gridProps = useVGrid<TradeRepairRequestFragment>(
    REPAIR_QUEUE_TRADES,
    (b) =>
      b
        .columnLibrary(tradeRepairRequestColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data?.id as string))
        .rowSelection((c) => c.multiple())
        .rowStateRules({
          hazard: () => true
        })
        .sideBar()
        .injectEvents([createCloseOnEmptyEventHandler(autoCloseOnEmpty)])
        .actions((a) =>
          a.schema((s) =>
            s
              .action(groupByTradesAction)
              .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(ROW_SELECTION_COUNT_ACTION_TYPE)
              .action(retryCreateTradeAction())
              .action(repairCreateTradeAction())
              .action(rejectRepairQueueTradesAction())
              .action(refreshServersideCustomMenuAction)
          )
        ),
    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default RepairQueueTradesGridWidget;
