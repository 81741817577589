import { inject, Lifecycle, scoped } from 'tsyringe';
import type { AnyRecord, Optional } from '@oms/shared/util-types';
import { Logger } from '@oms/shared/util';
import { EventSource, GridIdService } from '@oms/frontend-vgrid';
import type { EventHandler, GridEventType } from '@oms/frontend-vgrid';
import { GridTrackingSignal } from './grid-tracking.signal';
import type { TrackedGridData } from './grid-tracking.types';

export const GRID_TRACKING_PUBLISH_EVENT_HANDLER_NAME = 'grid-tracking-publisher-events';

/**
 * Function type for extracting tracked properties from grid data
 * @template TData The type of data in the grid
 * @template Key The key of the tracked property
 */
type GetTrackedPropFn<TData extends AnyRecord, Key extends keyof TrackedGridData> = (
  data: TData
) => Optional<TrackedGridData[Key]>;

/**
 * Mapping of tracked property keys to their getter functions
 * @template TData The type of data in the grid
 */
type GetTrackedPropsMap<TData extends AnyRecord> = Partial<{
  [Key in keyof TrackedGridData]: GetTrackedPropFn<TData, Key>;
}>;

/**
 * Mapping of tracked property keys to allowed column IDs
 * @template TData The type of data in the grid
 */
type ColumnFilterMap<TData extends AnyRecord> = Partial<{
  [Key in keyof TrackedGridData]: (keyof TData)[];
}>;

/**
 * Configuration for the grid tracking publisher event handler
 * @template TData The type of data in the grid
 */
interface GridTrackingPublisherEventHandlerSetup<TData extends AnyRecord> {
  /** Map of property getters to extract tracked data */
  getProps: GetTrackedPropsMap<TData>;
  /** Optional column filters to restrict tracking to specific columns */
  columnFilters?: ColumnFilterMap<TData>;
}

/**
 * Creates an event handler for publishing grid tracking events
 * @template TData The type of data in the grid
 * @param setup Configuration for the tracking publisher
 * @returns A class that implements the EventHandler interface
 */
export function createGridTrackingPublisherEventHandler<TData extends AnyRecord>(
  setup: GridTrackingPublisherEventHandlerSetup<TData>
) {
  const logger = Logger.create({
    name: 'grid-tracking.publisher.handler',
    level: 'debug'
  });

  /**
   * Handles grid tracking events and publishes tracked data
   * when cells are clicked in the grid
   */
  @scoped(Lifecycle.ContainerScoped)
  class GridTrackingPublisherEventHandler implements EventHandler<TData> {
    public name = GRID_TRACKING_PUBLISH_EVENT_HANDLER_NAME;

    public constructor(
      @inject(GridTrackingSignal) private signal: GridTrackingSignal,
      @inject(GridIdService) private gridIdService: GridIdService
    ) {}

    /**
     * Sets up cell click event listener for the grid
     * @param eventSource The event source to attach listeners to
     */
    public addEvents(eventSource: EventSource<GridEventType, TData>): void {
      eventSource.add('onCellClicked', ({ api }) => {
        const selectedRows = api.getSelectedRows();
        const colId = api.getFocusedCell()?.column.getColId();
        const rows = selectedRows.map((row) => {
          const trackedData: Partial<TrackedGridData> = {};
          for (const [prop, getter] of Object.entries(setup.getProps) as [
            keyof TrackedGridData,
            GetTrackedPropFn<TData, keyof TrackedGridData>
          ][]) {
            const value = getter(row);
            if (value !== undefined) {
              const colIdsAllowed = setup.columnFilters?.[prop];
              if (!colIdsAllowed || colIdsAllowed.includes(colId as keyof TData)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                trackedData[prop] = value as any;
              }
            }
          }
          return trackedData;
        });
        this.signal.publishFromGrid(api, this.gridIdService, rows).mapSync(
          (event) => {
            logger.debug(`${event.sourceType}.${event.sourceId}: Publishing grid tracking data 📢`, event);
          },
          ({ message, ...context }) => {
            logger.error(
              `${context.sourceType}.${context.sourceId}: Failed to publish grid tracking data: ${message} 🚨`,
              context
            );
          }
        );
      });
    }

    /**
     * Cleanup method (no subscriptions to clean up in this handler)
     */
    public removeEvents(): void {}
  }

  return GridTrackingPublisherEventHandler;
}
