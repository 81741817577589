import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

const advancedSelectValue = z.object({
  id: z.string(),
  value: z.unknown().optional(),
  label: z.string().nullable().optional()
});

export default createAppVersionedSchema('BULK_ROUTE_ORDER_FORM_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      investorOrderIds: z.array(z.string()),
      venue: advancedSelectValue.optional(),
      strategy: z
        .object({
          id: z.string(),
          value: z.object({
            strategyParams: z.array(z.any()),
            strategyControls: z.array(z.any()),
            strategyName: z.string(),
            venueId: z.string(),
            orderFormValues: z.any().optional(),
            isLayoutsPopulated: z.boolean().optional()
          }),
          label: z.string().nullable().optional()
        })
        .optional()
    })
    .describe('Bulk route order form input schema')
});
