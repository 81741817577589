import { FeedbackWrapper } from '@oms/generated/frontend';
import { BulkRouteOrderRow, BulkRouteOrderRowWithValidation } from './bulk-route-order-grid.columns';
import { TableServerRow } from '@app/data-access/services/system/table-server/table-server.datasource.contracts';

/**
 * Joins the row data with additional feedback data based on the ID field.
 *
 * @param rows - An array of BulkRouteOrderRow objects, each containing an order ID field.
 * @param feedbackWrappersMap - A Map where the keys are order IDs and the values are arrays of FeedbackWrapper objects.
 * @returns An array of BulkRouteOrderRowWithValidation objects, where each row is merged with the corresponding feedback data.
 */
export function joinFeedbackWrapperWithRowData(
  rows: BulkRouteOrderRow[],
  feedbackWrappersMap: Map<string, FeedbackWrapper[]>
): BulkRouteOrderRowWithValidation[] {
  return rows.map((row) => {
    const feedback = feedbackWrappersMap.get(row.id) || [];
    return { ...row, feedback };
  });
}

export function reduceDataToBulkRouteOrderRow(data: TableServerRow[]): BulkRouteOrderRow[] {
  return data.map((row) => {
    return {
      id: row.id,
      investorAccountName: row.investorAccountName as string,
      instrumentDisplayCode: row.instrumentDisplayCode as string,
      side: row.side as string,
      openQuantity: row.openQuantity as number,
      limitPrice: row.limitPrice as string,
      locate: row.locate as string,
      orderType: row.orderType as string
    };
  });
}
