import {
  InvestorOrderStatus,
  TimeInForce,
  TradeStatus,
  TradingOrderCategory,
  TradingOrderStatus,
  VisibilityReason
} from '@oms/generated/frontend';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';
import type { ValueFormatterFunc, ValueFormatterParams } from '@ag-grid-community/core';
import { mapOrderStatus } from '@app/common/mappers/map-order-status';
import { format } from '@oms/shared-frontend/ui-design-system';
import { SharedNotification } from '@oms/shared/oms-common';
import { mapPrimaryAlertType } from '@app/common/mappers/map-primary-alert-type';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import { mapTradeStatus } from '@app/common/mappers/map-trade-status';
import { mapBooleanToStatus } from '@app/common/mappers/map-boolean-to-status';
import { translateEnum } from '@app/common/mappers/translate-enum';
import { EnumKey } from '@oms/frontend-foundation';

const customFormatters = [
  'orderType',
  'orderEntryType',
  'timeInForce',
  'orderSettleType',
  'tradingOrderStatus',
  'investorOrderStatus',
  'investorOrderIds',
  'tradeStatus',
  'visibilityReason',
  'tradingOrderCategory',
  'primaryAlertType',
  'side',
  'sideType',
  'capacity',
  'criteriaStatus'
] as const;

export type CustomFormatters = (typeof customFormatters)[number];

export const isCustomFormatter = (value: string): value is CustomFormatters => {
  return customFormatters.includes(value as CustomFormatters);
};

const createEnumFormatter = (key: EnumKey) => {
  return ({ value }: ValueFormatterParams) => translateEnum(key, value as string);
};

export const timeInForce = ({
  value,
  data
}: {
  value: TimeInForce;
  data: { tifDuration: number; expiryDateTime: string };
}) => {
  switch (value) {
    case TimeInForce.Duration:
      return getDurationInMMSS(data.tifDuration) || '';
    case TimeInForce.Gtd:
      return format('datetime', data.expiryDateTime) || '';
    default:
      return value;
  }
};

const tradingOrderStatus = ({ value }: ValueFormatterParams) =>
  mapTradingOrderStatus(value as TradingOrderStatus);

const tradeStatus = ({ value }: ValueFormatterParams) => mapTradeStatus(value as TradeStatus);

const investorOrderStatus = ({ value }: ValueFormatterParams) => mapOrderStatus(value as InvestorOrderStatus);

const criteriaStatus = ({ value }: ValueFormatterParams) => mapBooleanToStatus(value as boolean);

const visibilityReason = ({ value }: ValueFormatterParams) =>
  mapOrderVisibilityReason(value as VisibilityReason);

const tradingOrderCategory = ({ value }: { value: TradingOrderCategory }) =>
  mapTradingOrderCategory(value as TradingOrderCategory);

const primaryAlertType = ({ value }: { value: SharedNotification.PrimaryAlertType }) =>
  mapPrimaryAlertType(value as SharedNotification.PrimaryAlertType);

const side = createEnumFormatter('side');
const sideType = createEnumFormatter('sideType');
const capacity = createEnumFormatter('capacity');
const orderType = createEnumFormatter('orderType');
const orderEntryType = createEnumFormatter('orderEntryType');
const orderSettleType = createEnumFormatter('settleType');

const investorOrderIds = ({ value }: ValueFormatterParams<any, string[]>) =>
  value?.map((id) => id).join(', ') || '';

export const CUSTOM_FORMATTER_REGISTRY: Record<CustomFormatters, ValueFormatterFunc> = {
  timeInForce,
  orderType,
  orderEntryType,
  orderSettleType,
  tradingOrderStatus,
  investorOrderStatus,
  investorOrderIds,
  tradeStatus,
  visibilityReason,
  tradingOrderCategory,
  primaryAlertType,
  side,
  sideType,
  capacity,
  criteriaStatus
};
