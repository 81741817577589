import type { ColumnBuilder, ColumnLibrary, ColumnBuilderField } from '@oms/frontend-vgrid';
import type { MappedCommonNotificationSettings } from './notification-settings.contracts';
import {
  NotificationSettingsType,
  NotificationSettingsTypeValue
} from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';
import { sharedDefaultCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';
import { MultiItemCellRenderer } from '@app/common/grids/cell-renderers/milti-item/multi-item.cell-renderer';

const defaultCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  sharedDefaultCol(c).flex(1).colDef({ editable: true });

const idCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  c.field('id').colDef({ editable: false }).hide();

const notificationNameCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  sharedTextCol<T>(c, 'notificationName').header('Event').flex(1.5).colDef({
    editable: false
  });

const account = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  sharedTextCol<T>(c, 'matchedAccountsData' as ColumnBuilderField<T>)
    .header('Account')
    .flex(1.5)
    .colDef({
      editable: false
    })
    .cell((cell) =>
      cell.renderer(MultiItemCellRenderer, {
        getDisplayName: (item: { id: string; name: string }) => item.name
      })
    );

const description = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  sharedTextCol<T>(c, 'description' as ColumnBuilderField<T>)
    .header('Description')
    .flex(1.5)
    .colDef({
      editable: false
    });

const platformPopupCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  c
    .field('isPopup')
    .colDef({
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) =>
        !!(params.data && 'isPopupEnabled' in params.data ? params.data.isPopupEnabled : false) // disable all pop-ups for all custom notification types
    })
    .header('Platform popup');

const soundCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  c
    .field('isSound')
    .colDef({
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) =>
        !!(params.data && 'isSoundEnabled' in params.data ? params.data.isSoundEnabled : true)
    })
    .header('Sound');

const showCol = <T extends MappedCommonNotificationSettings>(c: ColumnBuilder<T>) =>
  c
    .field('isShown')
    .colDef({
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      editable: (params) =>
        !!(params.data && 'isShownEnabled' in params.data ? params.data.isShownEnabled : true)
    })
    .flex(2)
    .header('Show in notification center');

const notificationsDefauldSettingsColumnLibrary = {
  defaultColumn: defaultCol,
  columns: [idCol, notificationNameCol, platformPopupCol, soundCol, showCol]
};

const notificationsCustomSettingsColumnLibrary = {
  defaultColumn: defaultCol,
  columns: [idCol, notificationNameCol, account, description, platformPopupCol, soundCol, showCol]
};

export const getColumnLibraryByType = (
  type: NotificationSettingsTypeValue
): ColumnLibrary<MappedCommonNotificationSettings> => {
  switch (type) {
    case NotificationSettingsType.DEFAULT:
      return notificationsDefauldSettingsColumnLibrary;
    case NotificationSettingsType.CUSTOM:
      return notificationsCustomSettingsColumnLibrary;
    default:
      console.error('Unknown notification settings type', type);
      return notificationsDefauldSettingsColumnLibrary;
  }
};
