import { useMemo } from 'react';
import type { ExecutionRow } from '@oms/generated/frontend';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { Box, format, Text, DisplayGrid } from '@oms/shared-frontend/ui-design-system';

import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { withUpdatedExecution } from '@app/data-access/hoc/with-updated-execution';
import { ExecutionViewUtilityButtonGroup } from './execution-view.utility-button-group';

const getDisplayGridItems = (execution: ExecutionRow): DisplayGridProps['items'] => {
  if (!execution) {
    return [];
  }

  return [
    {
      label: 'Side',
      component: {
        type: 'Badge',
        value: execution.side
      }
    },
    {
      label: 'Instrument',
      component: {
        type: 'Text',
        value: execution.instrumentDisplayCode
      }
    },
    {
      label: 'Account',
      component: {
        type: 'Text',
        value: execution.investorAccountName
      }
    },
    {
      label: 'Quantity',
      component: {
        type: 'Numeric',
        value: execution.executedQuantity,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: {
        type: 'Numeric',
        value: execution.executedPrice,
        format: 'price'
      }
    },
    {
      label: 'Trade Status',
      component: {
        type: 'Badge',
        value: execution.status
      }
    },
    {
      label: 'Trade Counterparty',
      component: {
        type: 'Text',
        value: execution.tradeCounterParty
      }
    },
    {
      label: 'Trade datetime',
      component: {
        type: 'Text',
        value: format('datetime', execution.lastTradeTime)
      }
    }
  ];
};

const gridProps: DisplayGridProps['gridProps'] = {
  columns: 3,
  columnGap: 10,
  rowGap: 3
};

export const ExecutionViewInformationWidget = ({ data: execution }: { data: ExecutionRow }) => {
  const displayGridItems = useMemo(() => {
    return getDisplayGridItems(execution);
  }, [execution]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Execution Information</Text>
        <ExecutionViewUtilityButtonGroup
          executionId={execution.id}
          investorOrderId={execution.investorOrderId}
          executionStatus={execution.status}
        />
      </Box>
      <DisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedExecution(ExecutionViewInformationWidget, { sx });
