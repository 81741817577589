import { FC } from 'react';
import { BadgeProps as BadgePropsBase, Badge as BadgeComp } from '../../../badge/badge';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../../display-field.contracts';
import { Flex } from '../../../../layout/flex/flex';
import { Sprinkles } from '../../../../system/sprinkles.css';

export type ValueProps = string | { text: string; badgeProps?: BadgePropsBase };

export type BadgeProps = {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.Badge;
  value: ValueProps | ValueProps[];
  badgeProps?: BadgePropsBase;
  sx?: Sprinkles;
};

export const Badge: FC<BadgeProps> = ({ value, badgeProps, sx }) => {
  const values = Array.isArray(value) ? value : [value];
  return (
    <Flex style={{ whiteSpace: 'nowrap' }} sx={sx}>
      {values.map((v, index) => {
        const val = typeof v === 'object' ? v?.text : v;
        return (
          <BadgeComp
            {...(typeof v === 'object' ? v?.badgeProps : badgeProps)}
            key={val}
            data-raw-value={val}
            sx={index !== 0 ? { marginTop: 1 } : undefined}
          >
            {val}
            {index < values.length - 1 ? <>&nbsp;</> : null}
          </BadgeComp>
        );
      })}
    </Flex>
  );
};
