import type { GetVersionedSchemaType } from '@oms/frontend-schemas';
import type { LATEST_VERSIONED_SCHEMA } from './schema';

// If you need to change the shape of this type, you should create a new versioned schema.
// Because this schema depends on the route order form schema, you should also update the route order form schema.
// To accomplish this, you can start by running the following command & updating the schema / migrations as needed:
// pnpm exec nx generate @oms/ui-gen:sub-schema-migration --key=MODIFY_INVESTOR_ORDER --no-interactive
export type ModifyInvestorOrderAction = GetVersionedSchemaType<typeof LATEST_VERSIONED_SCHEMA>['data'];

export const MODIFY_INVESTOR_ORDER_ACTION_NAME = 'modify_investor_order';
