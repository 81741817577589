import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import type { ExecutionRow } from '@oms/generated/frontend';
import { ExecutionsSubscriptionService } from '@app/data-access/services/trading/executions/executions.subscriptions.service';

export type UpdatedExecution = UpdatedData<ExecutionRow>;

export const withUpdatedExecution = (
  Component: ComponentType<UpdatedExecution>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<ExecutionRow>(Component, ExecutionsSubscriptionService, props);
};
