import { OrderVwapForm } from '@app/generated/sdk';
import { useCallback, useState } from 'react';
import type { OrderVwapFormValues } from './form/order-vwap.form-contract';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import type { OnValuesChanging } from '@oms/frontend-foundation';
import { Box, Button, DisplayGrid, Flex } from '@oms/shared-frontend/ui-design-system';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import type { OrderVwapProps } from './order-vwap.widget.config';
import type { OrderVwapFormOutput } from './form/order-vwap.form-common';
import { orderVwapFormValuesToOutput } from './form/order-vwap.form-builder.sanitizer';
import { getDisplayGridItems } from './order-vwap.widget.utils';

function OrderVwapWidgetComponent() {
  const [{ investorOrder, vwap }, onValuesChanging] = useOrderVwapFormState();
  const [key, setKey] = useState(0);

  const investorOrderId = investorOrder?.id;

  return (
    <Flex sx={{ flexGrow: 1, width: 'full', flexDirection: 'column' }}>
      <Box sx={{ position: 'relative' }} style={{ height: 50 }}>
        <OrderVwapForm
          onValuesChanging={onValuesChanging}
          input={{ investorOrderId }}
          key={`${investorOrderId || ''}-${key}`}
        />
      </Box>
      <Box sx={{ backgroundColor: 'layout.level2', flexGrow: 1, px: 3, py: 3 }}>
        <DisplayGrid
          items={getDisplayGridItems({
            vwap: vwap,
            side: investorOrder?.side,
            averagePrice: investorOrder?.averagePrice
          })}
          gridProps={{ columns: 2, rows: 2, columnGap: 10, rowGap: 3 }}
        />
      </Box>
      <Flex sx={{ justifyContent: 'flex-end', marginTop: 'auto', backgroundColor: 'layout.level1', p: 3 }}>
        <Button
          aria-label="refresh order vwap"
          type="submit"
          // Force the form to re-render (the key state variable is used as part of the key prop on the form component)
          onClick={() => setKey((prevKey) => prevKey + 1)}
        >
          Refresh
        </Button>
      </Flex>
    </Flex>
  );
}

function useOrderVwapFormState() {
  const [props, setProps] = useCurrentAppWidgetActorProps<OrderVwapProps>();
  const { trackingEnabled } = props || {};
  const [state, setState] = useState<Partial<OrderVwapFormOutput>>({});
  const container = useWorkspaceContainer();

  const onValuesChanging: OnValuesChanging<OrderVwapFormValues> = useCallback(
    ({ formValues }) => {
      orderVwapFormValuesToOutput(formValues, container)
        .then((output) => {
          setProps({
            trackingEnabled,
            investorOrderId: output?.investorOrder?.id
          }).catch((err) => console.error(err));

          if (output) {
            setState(output);
          }
        })
        .catch((error) => {
          console.error('Failed to convert form values to output', error);
        });
    },
    [container, trackingEnabled]
  );

  return [state, onValuesChanging] as const;
}

export default OrderVwapWidgetComponent;
