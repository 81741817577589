import type { AnyRecord } from '@oms/frontend-foundation';
import type {
  CustomContextMenuItemPredicate,
  CustomContextMenuItemNumberPredicate,
  CustomContextMenuItemLabelPredicate,
  CustomContextMenuItemDef
} from '../models/actions.model';

export class CustomContextMenuBuilder<TData extends AnyRecord> {
  private _menu: Partial<CustomContextMenuItemDef<TData>> = {};

  public name(name: string): CustomContextMenuBuilder<TData> {
    this._menu.id = name;
    this._menu.label = name;
    return this;
  }

  public primary(
    primary: boolean | CustomContextMenuItemPredicate<TData> = true
  ): CustomContextMenuBuilder<TData> {
    this._menu.primary = primary;
    return this;
  }

  public priority(
    priority: number | CustomContextMenuItemNumberPredicate<TData>
  ): CustomContextMenuBuilder<TData> {
    this._menu.priority = priority;
    return this;
  }

  public disabled(
    disabled: boolean | CustomContextMenuItemPredicate<TData>
  ): CustomContextMenuBuilder<TData> {
    this._menu.disabled = disabled;
    return this;
  }

  public label(label: string | CustomContextMenuItemLabelPredicate<TData>): CustomContextMenuBuilder<TData> {
    this._menu.label = label;
    return this;
  }

  public action(fn: (() => void) | (() => Promise<void>)): CustomContextMenuBuilder<TData> {
    this._menu.onClick = fn;
    return this;
  }

  public visible(
    visible: boolean | CustomContextMenuItemPredicate<TData> = true
  ): CustomContextMenuBuilder<TData> {
    this._menu.visible = visible;
    return this;
  }

  public tabName(tab: string): CustomContextMenuBuilder<TData> {
    this._menu.tabName = tab;
    return this;
  }

  public build(): CustomContextMenuItemDef<TData> {
    if (!this._menu.id || !this._menu.label) {
      throw new Error('Menu name is required.');
    }

    if (this._menu.priority === undefined) {
      this._menu.priority = 500;
    }

    return this._menu as CustomContextMenuItemDef<TData>;
  }
}
