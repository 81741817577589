import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import * as field from './custom-notification-entry.form-fields';
import type { CustomNotificationEntryOutput } from './custom-notification-entry.form-common';

export const customNotificationEntryContract = FormContract.create<CustomNotificationEntryOutput>()
  .fields({
    hiddenFormInfo: field.hiddenFormInfo,
    id: field.id,
    notificationName: field.notificationName,
    description: field.description,
    matchedAccounts: field.matchedAccounts,
    isPopup: field.isPopup,
    isSound: field.isSound,
    isShown: field.isShown
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'wrapper',
        [
          f.id,
          FieldDefinition.heading('Notification Settings', {
            name: 'notification-settings-heading',
            sx: { marginBottom: 3 }
          }),
          FieldDefinition.simpleGrid('grid', 1, [f.notificationName, f.description, f.matchedAccounts]),
          FieldDefinition.box('flags', [f.isPopup, f.isSound, f.isShown], {
            sx: { display: 'flex', justifyContent: 'space-between' }
          })
        ],
        {
          sx: {
            margin: 5
          }
        }
      )
    ]
  }))
  .template('simple', {
    showSubmitButton: true,
    submitLabel: 'Save',
    showCancelButton: true
  });

export type CustomNotificationEntryContractType = typeof customNotificationEntryContract;

export type CustomNotificationEntryValues =
  InferFormValuesFromFormContract<CustomNotificationEntryContractType>;
