import type { ExecutionAuditTrail } from '@app/common/types/orders/orders.types';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';

const auditTrailDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};
const auditTrailColumns: ColDefMap<ExecutionAuditTrail> = {
  eventTimestamp: { extends: 'generic.dateTime' },
  event: { extends: 'generic.text' },
  bidPrice: { extends: 'orders.price' },
  askPrice: { extends: 'orders.price' },
  lastTradePrice: { extends: 'orders.price' },
  cumulativeVolume: { extends: 'orders.quantity' },
  user: { extends: 'orders.user' }
};

const auditTrailColumnLibrary: TableServerColumnLibrary<ExecutionAuditTrail> = {
  defaultColumn: auditTrailDefaultColumn,
  columns: auditTrailColumns
};

export const buildAuditTrailColumnDefs = () =>
  buildColumnDefs(auditTrailColumnLibrary, 'executionViewAuditTrail');
