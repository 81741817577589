import { Fragment, useMemo } from 'react';
import type { FC } from 'react';
import type { SemVer } from 'semver';
import { Box } from '@oms/shared-frontend/ui-design-system';
import { t } from '@oms/codegen/translations';
import { ValstroInfoContainer } from '@app/containers/valstro-info/valstro-info.container';
import { aboutInfoStyle, copyrightStyle, linkContainerStyle } from './about-valstro.css';
import { AppEnv, formatVersion } from '@oms/shared/util';
import { useAppVersion } from '@app/common/env/use-app-version.hook';
import { getAppEnv } from '@oms/shared/util';

export interface AboutValstroProps {
  env: AppEnv;
  version?: string | SemVer;
  copyrightYear?: number;
}

export const AboutValstroContent: FC<Pick<AboutValstroProps, 'env' | 'version'>> = ({ env, version }) => {
  const versionInfo = useMemo(() => {
    const envString = env.toFullEnv();
    const versionString = formatVersion(version, { prependV: true });
    return versionString ? `${envString} ${versionString}` : envString;
  }, [env, version]);
  return (
    <Box className={aboutInfoStyle}>
      <Box>{versionInfo}</Box>
      <Box className={linkContainerStyle}>{/* TODO: Links go here */}</Box>
    </Box>
  );
};

export const AboutValstroFooter: FC<Pick<AboutValstroProps, 'copyrightYear'>> = ({ copyrightYear }) => {
  const copyright = useMemo(() => t('app.system.about.copyright', { copyrightYear }), [copyrightYear]);
  return (
    <Fragment>
      <Box className={copyrightStyle}>{copyright}</Box>
    </Fragment>
  );
};

export const AboutValstro: FC<AboutValstroProps> = ({ env, version, copyrightYear }) => {
  const footer = <AboutValstroFooter copyrightYear={copyrightYear} />;
  return (
    <ValstroInfoContainer footer={footer}>
      <AboutValstroContent env={env} version={version} />
    </ValstroInfoContainer>
  );
};

export const AboutValstroWidget: FC = () => {
  const copyrightYear = useMemo(() => new Date().getFullYear(), []);
  const env = getAppEnv();
  const version = useAppVersion();

  return <AboutValstro env={env} version={version} copyrightYear={copyrightYear} />;
};

export default AboutValstroWidget;
