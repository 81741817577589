import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import * as field from './trade-modify.form-fields';
import type { TradeModifyFieldContract } from './trade-modify.form-common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';

export const tradeModifyContract = FormContract.create<TradeModifyFieldContract>()
  .fields({
    id: field.id,
    hiddenFormInfo: field.hiddenFormInfo,
    side: field.side,
    instrument: field.instrument,
    tradeQuantity: field.tradeQuantity,
    tradePrice: field.tradePrice,
    tradeCounterpartyId: field.tradeCounterpartyId,
    settleType: field.settlement.settlementType,
    settleDate: field.settlement.settlementDate,
    tradeContraAccountId: field.tradeContraAccountId,
    tradeDateTime: field.tradeDateTime,
    tradeTags: field.tradeTags
  })
  .schema((f) => {
    const settlementContainer = field.settlement.settlementContainer.build();
    const sharedRunwayFieldStyle = { minWidth: 100 };
    return {
      fields: [
        f.id,
        f.hiddenFormInfo,
        createMarketDataField('market-data', {
          instrumentFieldName: 'instrument',
          orderQuantityField: 'quantity',
          trackableFieldMap: {
            bid: 'price',
            ask: 'price'
          }
        }),
        FieldDefinition.runway('runway', [
          { ...f.side, isPrimaryField: true, style: sharedRunwayFieldStyle },
          { ...f.instrument, isPrimaryField: true, style: sharedRunwayFieldStyle },
          { ...f.tradeQuantity, isPrimaryField: true, style: sharedRunwayFieldStyle },
          { ...f.tradePrice, isPrimaryField: true, style: sharedRunwayFieldStyle },
          { ...f.tradeCounterpartyId, isPrimaryField: true, style: sharedRunwayFieldStyle }
        ]),
        createAdditionalAllFields({
          groups: ['Order'],
          fixedFields: ['tradeContraAccountId', settlementContainer.name, 'tradeDateTime', 'tradeTags'],
          fields: [
            [createEnrichedField(f.tradeContraAccountId, { groupName: 'Order' })],
            [createEnrichedField(settlementContainer, { groupName: 'Order' })],
            [createEnrichedField(f.tradeDateTime, { groupName: 'Order' })],
            [createEnrichedField(f.tradeTags, { groupName: 'Order', colSpan: 3 })]
          ]
        })
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type TradeModifyContractType = typeof tradeModifyContract;

export type TradeModifyValues = InferFormValuesFromFormContract<TradeModifyContractType>;
