import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import {
  JoinedInstrumentRestrictionRow,
  RefDataRestrictionTypeEnum,
  ValstroEntitlements
} from '@oms/generated/frontend';
import { openInstrumentRestrictionEntry } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';

function isVisible(data: JoinedInstrumentRestrictionRow[]) {
  return data.length <= 1 && !!data[0];
}

function stringToBool(str: string) {
  return str.toLowerCase() === 'true';
}

export const openModifyInstrumentRestriction: ActionDefFactory<JoinedInstrumentRestrictionRow> = (builder) =>
  builder
    .name('open_instrument_restriction_modify')
    .toolbar((toolbar) =>
      toolbar
        .id('open_instrument_restriction_modify_button')
        .component('action-button')
        .location('UserToolbar')
        .props({
          variant: 'primary',
          content: t('app.referenceData.instrumentRestriction.openIRModify'),
          isDisabled: true
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) =>
      m
        .name('Edit')
        .visible(({ rowData }) => isVisible(rowData))
        .tabName(t('app.common.grids.contextMenuTabs.trade'))
    )
    .onChange((ctx) => {
      const { lifecycle, data } = ctx;
      ctx.notify({ isDisabled: !isVisible(data) });
      if (lifecycle !== 'change') return;
      const {
        instrumentId,
        instrumentDisplayCode,
        applyToFungible,
        applyToRelated,
        id,
        account,
        restrictionType,
        restrictionEffectiveDate,
        restrictionExpiryDate
      } = data[0];
      openInstrumentRestrictionEntry(ctx.windowActor.id, {
        title: 'Edit instrument restriction',
        form: {
          input: {
            id,
            symbol: {
              id: instrumentId,
              label: instrumentDisplayCode,
              value: instrumentId
            },
            fungible: stringToBool(String(applyToFungible)),
            related: stringToBool(String(applyToRelated)),
            account: { id: account },
            restrictionType: restrictionType as RefDataRestrictionTypeEnum,
            restrictionEffectiveDate,
            restrictionExpiryDate
          }
        }
      }).catch((e) => console.error(e));
    });
