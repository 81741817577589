import type {
  ActorSchema,
  AnyRecord,
  CommonWindowActorSchemaOptions,
  WindowOperations
} from '@valstro/workspace';
import type { AuthClientState, ParsedIdentityToken } from './keycloak.types';
import type { AppWindowContext } from '@app/app-config/workspace.config';
import type { DependencyContainer } from 'tsyringe';

/**
 * Common Auth Window Contracts
 */
export const COMMON_AUTH_WINDOW = {
  ID: 'auth-window', // Keep same ID to prevent multiple windows
  TYPE: 'auth-window'
} as const;

export type AuthWindowContext = AppWindowContext;

export type AuthWindowOperations<TMeta extends AnyRecord = AnyRecord> = WindowOperations<TMeta> & {
  initialize: (forceAuthState?: AuthClientState) => Promise<void>;
  startLogin: () => Promise<void>;
  finishLogin: () => Promise<void>;
  logout: () => Promise<void>;
};

export type CommonAuthWindowActorSchemaOptions = CommonWindowActorSchemaOptions & {
  forceAuthState?: AuthClientState;
  container?: DependencyContainer;
};

export type CommonAuthWindowActorSchema = ActorSchema<
  AuthWindowContext,
  AuthWindowOperations<AnyRecord>,
  React.FC<{}>,
  CommonAuthWindowActorSchemaOptions
>;

const NA = 'N/A';

/**
 * Default parsed Keycloak Identity token (if not set)
 */
export const DEFAULT_KC_TOKEN_PARSED: ParsedIdentityToken = {
  id: NA,
  sub: NA,
  name: NA,
  email: NA,
  realm_access: {
    roles: []
  },
  group: []
};

export const PERSISTED_AUTH_TOKEN_KEY = 'auth-token';

export const UNAUTHORIZED_TYPE = 'client/unauthorized';

export const DEFAULT_AUTH_STATE: AuthClientState = {
  lastAuthClientEvent: 'idle',
  isReady: false,
  isAuthenticated: false,
  sessionId: null,
  token: null,
  tokenParsed: null,
  refreshToken: null,
  hasError: false,
  error: '',
  roles: [],
  expiry: null
};

export const AUTH_EVENT_ACTION = {
  LOGIN: 'login',
  LOGOUT: 'logout'
} as const;

export type AuthEventAction = (typeof AUTH_EVENT_ACTION)[keyof typeof AUTH_EVENT_ACTION];

// This ensures updateToken(MIN_TOKEN_VALIDITY) always tries to refresh the token when it's within 30 seconds of expiration.
export const MIN_TOKEN_VALIDITY = 30;
