import { isEmpty } from 'lodash';
import type { AnyRecord } from '@oms/shared/util-types';
import type { ActionContext } from '@oms/frontend-vgrid';
import type { ActionCommandConfig } from '../commands/command.registry.types';

/**
 * Checks if a button has configuration settings
 * @param ctx - The action context
 * @returns True if the button has configuration, false otherwise
 */
export function isConfiguredButton<TRowData extends AnyRecord>(
  ctx: ActionContext<TRowData, AnyRecord>
): boolean {
  if (typeof ctx.config === undefined) return false;
  return !isEmpty(ctx.config);
}

/**
 * Checks if an action requires user confirmation based on its configuration
 * @param ctx - The action context
 * @returns True if confirmation is required, false otherwise
 * @remarks This is a type guard that narrows the context type to include confirmation config
 */
export function requiresConfirmation<TRowData extends AnyRecord>(
  ctx: ActionContext<TRowData, AnyRecord>
): ctx is ActionContext<TRowData, Pick<ActionCommandConfig<AnyRecord>, 'confirmation'>> {
  if (!isConfiguredButton(ctx)) return false;
  return (ctx.config as Partial<ActionCommandConfig<AnyRecord>>)?.confirmation === true;
}

/**
 * Checks if a button is configured for "hot" (immediate) execution without confirmation
 * @param ctx - The action context
 * @returns True if the button is configured for hot execution, false otherwise
 * @remarks This is a type guard that narrows the context type to include hot button config properties
 */
export function isHotButton<TRowData extends AnyRecord>(
  ctx: ActionContext<TRowData, AnyRecord>
): ctx is ActionContext<
  TRowData,
  Pick<ActionCommandConfig<AnyRecord>, 'allowsConfirmation' | 'confirmation'>
> {
  if (!isConfiguredButton(ctx)) return false;
  const { allowsConfirmation = false, confirmation = true } = (ctx.config || {}) as Partial<
    ActionCommandConfig<AnyRecord>
  >;
  return allowsConfirmation === true && !confirmation;
}
