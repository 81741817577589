import { useEffect, useState } from 'react';
import { retry, takeWhile } from 'rxjs';
import { FactsetClient } from '@app/data-access/services/marketdata/factset/factset.client';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import {
  FactsetPricesByTypeRequest,
  FactsetPricesByTypeResponse,
  PricesByType
} from '../factset/factset.types';
import { parseTicker } from '../factset/factset.operators';

export const useInstrumentVwap = ({
  ticker,
  investorOrderId,
  isToday
}: {
  ticker: string;
  investorOrderId: string;
  isToday: boolean;
}) => {
  const factsetClient = useWorkspaceContainer().resolve(FactsetClient);
  const [instrumentVwap, setInstrumentVwap] = useState<number>(0);

  useEffect(() => {
    if (!ticker) return;

    const endpoint = '/prices/getByType';
    const request: Parameters<
      typeof factsetClient.observeEndpoint<FactsetPricesByTypeRequest, FactsetPricesByTypeResponse>
    >[0] = {
      method: 'POST',
      endpoint,
      payload: {
        data: {
          identifier: parseTicker(ticker),
          identifierType: 'tickerRegion',
          types: [PricesByType.VWAP],
          quality: 'BST',
          sameQuality: true
        },
        meta: {
          subscription: {
            minimumInterval: 1000
          }
        }
      }
    };

    const endpoint$ = factsetClient
      .observeEndpoint<FactsetPricesByTypeRequest, FactsetPricesByTypeResponse>(request)
      .pipe(
        retry({ delay: 1000 }),
        takeWhile(() => isToday)
      );

    const subscription = endpoint$.subscribe((data) => {
      setInstrumentVwap(data?.data?.prices?.[0]?.latest?.price ?? 0);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [ticker, factsetClient, investorOrderId, isToday]);

  return { instrumentVwap };
};
