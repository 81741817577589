import { convertToNumber } from '@oms/shared/util';
import { t } from '@oms/codegen/translations';
import { FORM_VALIDATOR_TYPE } from '../contracts';

// TODO: How to property type validators?
export const FORM_VALIDATOR_MAPPER = {
  [FORM_VALIDATOR_TYPE.largeNumericInputMinValue]:
    ({ threshold = 0, message }: any) =>
    (value: any) => {
      return value && convertToNumber(value) < threshold
        ? message || `Value is less than ${threshold}.`
        : undefined;
    },
  [FORM_VALIDATOR_TYPE.largeNumericInputMaxValue]:
    ({ threshold = 0, message }: any) =>
    (value: any) => {
      return value && convertToNumber(value) > threshold
        ? message || `Value is greater than ${threshold}.`
        : undefined;
    },
  [FORM_VALIDATOR_TYPE.maxLength]:
    ({ length = 100, message }: any) =>
    (value: any) => {
      return value && value.length > length
        ? message || `Value is more than ${length} characters.`
        : undefined;
    },
  [FORM_VALIDATOR_TYPE.validateParentAccount]: () => (value: number, allValues: any) => {
    if ((value && allValues.parentAccountType === 'group') || allValues.parentAccountType === 'entity') {
      return null;
    }

    return t('app.common.requiredField');
  },
  [FORM_VALIDATOR_TYPE.email]: () => (value: any) => {
    return value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Invalid email address' : undefined;
  }
};
