/**
 * Defines the available grid types in the application
 * Used for identifying different grid instances and their purposes
 * @constant
 */
export const GRID_TYPE = {
  /** Grid for monitoring investor orders */
  INVESTOR_ORDER_MONITOR: 'investor-order-monitor',
  /** Grid for monitoring trading orders */
  TRADING_ORDER_MONITOR: 'trading-order-monitor'
  // Add more grid types here as needed
} as const;
