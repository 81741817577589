import { YesNo, yesNoMapping } from '@app/common/mappers/ref-data-enums';
import type { ComboBoxItem } from '@oms/shared-frontend/ui-design-system';
import { convert } from '@oms/shared/util';

export const yesNoComboBoxMapper = (input: YesNo | boolean): ComboBoxItem => {
  const yesNo =
    typeof input === 'string'
      ? input
      : convert.boolean.to.string(input, { type: 'yes-no', short: true, capitalize: true, strict: true });
  return {
    type: 'item',
    id: yesNo,
    label: yesNoMapping[yesNo as keyof typeof yesNoMapping],
    value: convert.string.to.boolean(yesNo) ?? false
  };
};

export const yesNoOptions = Object.values(YesNo).map(yesNoComboBoxMapper);
