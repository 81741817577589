import { openCancelExecutions, openConfirmation } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import {
  convertAlert,
  FeedbackWrapper,
  FoundationWorkspace,
  useWorkspaceContainer
} from '@oms/frontend-foundation';
import { useCurrentWindow } from '@valstro/workspace-react';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { useCallback } from 'react';
import { InvestorOrderExecutionsService } from '@app/data-access/services/trading/investor-orders/investor-order-executions.service';
import { DependencyContainer } from 'tsyringe';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openMessageDialog } from '@app/common/dialog/dialog.common';

export interface IOpenCancelExecutionDialogOptions {
  workspace: FoundationWorkspace;
  container: DependencyContainer;
  windowId: string;
  orderId: string;
  executionIds: string[];
}
interface ICancelExecutionInput {
  orderId: string;
  executionIds: string[];
  tradingOrderIntermediaryAccountId: string;
  investorOrderAccountId: string;
}
async function openConfirmationAndListen(
  input: ICancelExecutionInput,
  errors: FeedbackWrapper[],
  executionsService: InvestorOrderExecutionsService,
  workspace: FoundationWorkspace,
  numRows: number
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, workspace.getLeaderProcessId(), {
    title: `Cancel ${numRows} execution${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} execution${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const resp = await executionsService.cancelExecutions(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(input, feedbackWrappers, executionsService, workspace, numRows);
      }

      break;
    }
  }
}
export const openCancelExecutionDialog = async ({
  workspace,
  container,
  windowId,
  orderId,
  executionIds
}: IOpenCancelExecutionDialogOptions) => {
  const rowsCount = executionIds.length;
  const [_, api] = await openConfirmation(workspace, windowId, {
    title: `Cancel ${rowsCount} execution${rowsCount > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      message: `Are you sure you want to cancel ${rowsCount} execution${rowsCount > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.yes'),
      cancelButtonText: t('app.common.no')
    }
  });
  const event = await api.awaitFirstEvent;
  if (event.type === DIALOG_EVENT_TYPE.OK) {
    const ioService = container.resolve(InvestorOrdersService);
    const service = container.resolve(InvestorOrderExecutionsService);
    const authService = container.resolve(AuthService);
    const userId = authService.getUserId();

    if (!orderId || !userId) {
      throw new Error('Could not determine order ID');
    }

    const orderInfo = await ioService.getById(orderId);

    const instrumentId = orderInfo.isSuccess()
      ? orderInfo.value?.data?.visibleInvestorOrder?.instrument?.id
      : undefined;

    const defaultAccounts =
      userId || instrumentId ? await service.getDefaultAccounts(userId, instrumentId) : undefined;

    if (!defaultAccounts?.intermediaryAccount?.id || !defaultAccounts?.firmAccount?.id) {
      throw new Error('Could not determine intermediaryAccountId or investorOrderAccountId');
    }

    const input = {
      orderId,
      executionIds,
      tradingOrderIntermediaryAccountId: defaultAccounts.intermediaryAccount.id,
      investorOrderAccountId: defaultAccounts.firmAccount.id
    };

    const resp = await service.cancelExecutions(input);

    if (resp.isSuccess() === false) {
      const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

      await openConfirmationAndListen(input, feedbackWrappers, service, workspace, executionIds.length);
    }
  }
};

export const openCancelExecutionOnChange = (
  workspace: FoundationWorkspace,
  executionId: string,
  orderId: string
) => {
  openCancelExecutions(workspace.getLeaderProcessId(), {
    windowId: `cancel-execution-${executionId}`,
    form: {
      formId: `cancel-execution-${executionId}`,
      input: {
        orderId,
        executionIds: [executionId]
      }
    },
    title: `Cancel Execution: ${executionId}`
  }).catch((e) => {
    openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
    console.error(e);
  });
};

export const useOpenCancelExecutionDialog = (executionId: string, orderId: string) => {
  const workspace = useAppWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentWindow();
  return useCallback(() => {
    openCancelExecutionOnChange(workspace, executionId, orderId);
  }, [executionId, orderId, windowActor.id, workspace, container]);
};
