import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { Text, Tooltip } from '@oms/shared-frontend/ui-design-system';
import { AnyRecord } from '@oms/frontend-foundation';
export const MULTI_ITEM_CELL_RENDERER = 'multiItemCellRenderer';

interface RenderItemProps {
  item: string;
  textColor: 'semiminor' | 'active' | 'level1';
}

const RenderItem = ({ item, textColor }: RenderItemProps) => {
  const textStyle = { color: `var(--colors-text-${textColor})` };
  return (
    <Text type={'gridBase'} style={textStyle}>
      {item}
    </Text>
  );
};

interface RenderItemsProps<T> {
  items: T[];
  getDisplayName: (item: T) => string;
}

const RenderItems = <T extends AnyRecord>({ items, getDisplayName }: RenderItemsProps<T>) => {
  return (
    <ul style={{ padding: 0 }}>
      {items.map((item, index) => (
        <li key={index} style={{ listStyleType: 'none' }}>
          <RenderItem item={getDisplayName(item)} textColor="semiminor" />
        </li>
      ))}
    </ul>
  );
};

interface MultiItemCellRendererProps<T> extends ICellRendererParams {
  value: T[];
  getDisplayName: (item: T) => string;
}

export const MultiItemCellRenderer = <T extends AnyRecord>({
  value,
  getDisplayName
}: MultiItemCellRendererProps<T>) => {
  if (!value || value.length === 0) return null;

  if (value.length === 1) {
    return <RenderItem item={getDisplayName(value[0])} textColor="level1" />;
  } else {
    const tooltipContent = `${getDisplayName(value[0])} and ${value.length - 1} more`;
    return (
      <Tooltip label={<RenderItems items={value} getDisplayName={getDisplayName} />}>
        <RenderItem item={tooltipContent} textColor="active" />
      </Tooltip>
    );
  }
};
