import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  groupByAction,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TradeStatus, TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type {
  TradeRow,
  TradesGroupedByPriceRow,
  TsTradesWithFilterSubscription
} from '@oms/generated/frontend';
import { TsTradesGroupedByPriceWithFilterDocument } from './trade-monitor.contracts';
import type { TsTradesGroupedByPriceWithFilterSubscription } from './trade-monitor.contracts';
import { openModifyTradeAction } from './grid-actions/open-modify.trade.action';
import { cancelTradeAction } from './grid-actions/cancel.trade.action';
import { buildTradesMonitorColumnDefs } from './trade-monitor.columns';
import { TableServerQueryFilter } from '@app/data-access/services/system/table-server/table-server.service';
import { viewTradeAction } from './grid-actions/view.trade.action';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { t } from '@oms/codegen/translations';
import { SortModelItem } from '@app/data-access/services/system/table-server/filters/ag-grid.filters.schema';
import { VisibilityEventHander } from '@app/widgets/trading/investor-order-monitor/grid-services/visibility.event-handler';

type TradeMonitorWidgetProps = {
  id?: string;
};

const createDatasource = (datasourceService: TableServerDatasourceService, id?: string) => {
  const commonFilter = id
    ? ({
        tradingOrderId: {
          filterType: 'text',
          type: 'equals',
          filter: id
        }
      } as TableServerQueryFilter<TradeRow>)
    : undefined;

  const timestampSort: SortModelItem<TradeRow>[] = [
    {
      colId: 'updatedTime',
      sort: 'desc'
    }
  ];

  const regularDatasource = datasourceService.getSource<TradeRow, TsTradesWithFilterSubscription>({
    query: TsTradesWithFilterDocument,
    getData: (r) => r.tsTradesWithFilter,
    filter: commonFilter,
    sort: timestampSort
  });

  const groupByPriceDatasource = datasourceService.getGroupSource<
    TradesGroupedByPriceRow,
    TsTradesGroupedByPriceWithFilterSubscription,
    TradeRow,
    TsTradesWithFilterSubscription
  >({
    aggregateQuery: {
      query: TsTradesGroupedByPriceWithFilterDocument,
      getData: (r) => r.tsTradesGroupedByPriceWithFilter,
      filter: commonFilter,
      sort: [
        {
          colId: 'price',
          sort: 'asc'
        }
      ]
    },
    detailQuery: {
      query: TsTradesWithFilterDocument,
      getData: (r) => r.tsTradesWithFilter,
      filter: {
        ...commonFilter,
        price: {
          filterType: 'number',
          type: 'equals'
        }
      },
      sort: timestampSort
    }
  });

  return { regularDatasource, groupByPriceDatasource };
};

export const TradeMonitorWidget = ({ id: tradingOrderId }: TradeMonitorWidgetProps) => {
  const [props] = useCurrentAppWidgetActorProps<TradeMonitorWidgetProps>();
  const id = tradingOrderId || props.id;
  const datasourceService = useService(TableServerDatasourceService);
  const { regularDatasource, groupByPriceDatasource } = createDatasource(datasourceService, id);

  const gridProps = useVGrid<TradeRow>(
    'trade-monitor',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildTradesMonitorColumnDefs())
        .datasource((d) =>
          d
            .source(regularDatasource)
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .injectEvents([GridConfigEventHandler, VisibilityEventHander])
        .rowStateRules({
          noExecutedQuantity: (params) => params.data?.status === TradeStatus.Inactive
        })
        .sideBar()
        .rowSelection((c) => c.multiple())
        .actions((a) => {
          const actions = a.schema((s) => {
            if (id) {
              s.action(
                groupByAction<TradeRow>({
                  aggregateDatasource: groupByPriceDatasource,
                  detailDatasource: regularDatasource,
                  groupFieldName: 'price',
                  label: t('app.actions.groupByPrice')
                })
              );
            }
            return s
              .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
              .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
              .action(viewTradeAction())
              .action(openModifyTradeAction())
              .action(cancelTradeAction())
              .action(ROW_SELECTION_COUNT_ACTION_TYPE);
          });
          return actions;
        })
        .reactiveCustomComponents(),
    [datasourceService, id]
  );

  return <VGrid {...gridProps} />;
};

export default TradeMonitorWidget;
