import { SHORTCUT } from '@app/app-config/shortcut.config';
import { AppWorkspace } from '@app/app-config/workspace.config';
import type {
  CommandPaletteItem,
  CommandPaletteMemoryState,
  CommandPaletteSubmitContext,
  CommandPaletteUnregisterContextItems
} from '@app/common/command-palette/command-palette.contracts';
import {
  COMMAND_PALETTE,
  CommandPaletteOpenOptions,
  CommandPaletteSubmitEvent
} from '@app/common/command-palette/command-palette.contracts';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { CommandPaletteStore } from '@app/data-access/memory/command-palette.state';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { getGlobalOrWindowShortcut$ } from '@app/data-access/memory/shortcuts.subject';
import type { ComboBoxItem, ComboBoxItemUnion } from '@oms/shared-frontend/ui-design-system';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import { isPromiseLike, parseJSON, createLogger } from '@oms/shared/util';
import type { RemoteProxy, Unsubscribe } from '@valstro/remote-link';
import { createProxy, expose } from '@valstro/remote-link';
import { isTauri } from '@valstro/workspace';
import omit from 'lodash/omit';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, debounceTime, map, merge, Subject } from 'rxjs';
import type { Disposable } from 'tsyringe';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';
import { isDevEnv } from '@oms/shared/util';

const logger = createLogger({
  name: 'CommandPaletteService'
});

const timeout = isDevEnv() ? 1_000 : 30_000;

/**
 * Command Palette Service
 *
 * @example
 * ```ts
 * import { container, inject } from 'tsyringe';
 * import { CommandPaletteService } from '@app/data-access/services/command-palette/command-palette.service';
 *
 * const commandPaletteService = container.resolve(CommandPaletteService);
 * await commandPaletteService.register([...]);
 * await commandPaletteService.unregister([...]);
 *
 * // Temporarily register items with context
 * const unregisterContextItems = await commandPaletteService.registerContext(`${order.symbol} Commands`, [...]);
 * await unregisterContextItems();
 *
 * // Or, inject inside class constructor
 * constructor(
 *  @inject(CommandPaletteService) private _commandPaletteService: CommandPaletteService
 * ) {
 * //...
 * }
 * ```
 *
 */
@testScoped
@singleton()
export class CommandPaletteService implements Disposable {
  /**
   * Command Palette Subjects that control the command palette (for the Command Palette Plugin)
   */
  public cmdPaletteOpen$: Subject<CommandPaletteOpenOptions>;

  public cmdPaletteUpdate$: BroadcastSubject<CommandPaletteOpenOptions>;
  public cmdPaletteClose$: BroadcastSubject<ComboBoxItem[]>;
  public cmdPaletteSelectedItemsChange$: BroadcastSubject<ComboBoxItem[]>;
  /**
   * Returns a subject for the command palette submit events
   */
  public cmdPaletteSubmit$: BroadcastSubject<CommandPaletteSubmitEvent>;
  private _itemSelectFnMap = new Map<
    string,
    (item: ComboBoxItem, context: CommandPaletteSubmitContext) => void | Promise<void>
  >();
  private _isInitializing = false;
  private _isLeader: boolean | undefined = undefined;
  private _isReady = new BehaviorSubject<boolean>(false);
  private _store: RemoteProxy<CommandPaletteStore> | undefined;
  private _state: CommandPaletteMemoryState = {
    categories: [],
    categoryGroups: [],
    categoriesOrder: [],
    items: []
  };
  private _subscriptions: Subscription[] = [];
  private _unsubscribes: Unsubscribe[] = [];
  private _unexposeCmdPaletteStore?: ReturnType<
    typeof expose<CommandPaletteStore, 'categories' | 'categoryGroups' | 'items'>
  >[0];

  constructor(
    @inject(AppWorkspace) private _workspace: AppWorkspace,
    @inject(ProcessState) private processService: ProcessState
  ) {
    this.cmdPaletteOpen$ = isTauri()
      ? new BroadcastSubject<CommandPaletteOpenOptions>(
          `cmd-palette-open-${this.processService.LEADER_PROCESS_ID}`
        )
      : new Subject<CommandPaletteOpenOptions>();

    this.cmdPaletteUpdate$ = new BroadcastSubject<CommandPaletteOpenOptions>(
      `cmd-palette-update-${this.processService.LEADER_PROCESS_ID}`
    );
    this.cmdPaletteClose$ = new BroadcastSubject<ComboBoxItem[]>(
      `cmd-palette-close-${this.processService.LEADER_PROCESS_ID}`
    );
    this.cmdPaletteSelectedItemsChange$ = new BroadcastSubject<ComboBoxItem[]>(
      `cmd-palette-selected-items-change-${this.processService.LEADER_PROCESS_ID}`
    );
    this.cmdPaletteSubmit$ = new BroadcastSubject<CommandPaletteSubmitEvent>(
      `cmd-palette-submit-${this.processService.LEADER_PROCESS_ID}`
    );
  }

  /**
   * Initializes the command palette service on the leader process
   */
  public async initialize() {
    try {
      this._isInitializing = true;
      this._isLeader = await this.processService.isLeaderProcess();
      const cmdpStore = `cmdp-store-${this.processService.LEADER_PROCESS_ID}`;

      if (this._isLeader) {
        const realStore = new CommandPaletteStore();

        if (this._unexposeCmdPaletteStore) {
          this._unexposeCmdPaletteStore();
        }

        this._unexposeCmdPaletteStore = expose(realStore, cmdpStore, {
          awaitConfirmation: false,
          handshakeTimeout: timeout,
          ignoreProperties: ['categories', 'categoryGroups', 'items']
        })[0];
      }

      this._store = await createProxy<CommandPaletteStore>(cmdpStore, {
        awaitConfirmation: true,
        handshakeTimeout: timeout,
        responseTimeout: timeout
      });

      // Initialize the command palette initial state (from the store in the main process)
      await this._ensureStateSynced({ shouldRender: true });

      this._listenForStateUpdates();
      this._listenForSubmit();
      this._isInitializing = false;
    } catch (e) {
      logger.error('Error initializing command palette service', e);
      this._isInitializing = false;
      throw e;
    }
  }

  /**
   * Disposes of the command palette service
   * - Unsubscribes from all subscriptions
   * - Runs when container is disposed
   */
  public dispose() {
    this._isReady.next(false);
    this._subscriptions.forEach((sub) => sub.unsubscribe());
    this._unsubscribes.forEach((unsub) => unsub());
    this._itemSelectFnMap.clear();
    if (this._unexposeCmdPaletteStore) {
      this._unexposeCmdPaletteStore();
    }
    this._store = undefined;
  }

  /**
   * Returns a stream of the command palette ready state
   */
  public get isReady$() {
    return this._isReady.asObservable();
  }

  /**
   * Sets the command palette ready state
   */
  public setIsReady(isReady: boolean) {
    this._isReady.next(isReady);
  }

  /**
   * Opens the command palette
   */
  public open() {
    if (!this._store || this._isInitializing) {
      logger.error('Command palette service not initialized yet');
      openMessageDialog(
        'Command palette service not initialized yet, try again in a few moments.',
        this._workspace
      ).catch(logger.error);
    }
    this.cmdPaletteOpen$.next({});
  }

  /**
   * Returns a stream of command palette open events (general or shortcut)
   * - If the app is running in Tauri, the global command palette shortcut open subject will be used
   * - If the app is running in a browser, the window command palette shortcut open subject will be used
   */
  public get open$() {
    return merge(
      this.cmdPaletteOpen$.asObservable(),
      getGlobalOrWindowShortcut$(SHORTCUT.OPEN_COMMAND_PALETTE).pipe(map(() => ({})))
    );
  }

  /**
   * Returns a stream of command palette update events
   */
  public get update$() {
    return this.cmdPaletteUpdate$;
  }

  /**
   * Returns a stream of command palette close events (general or shortcut)
   * - If the app is running in Tauri, the global command palette shortcut close subject will be used
   * - If the app is running in a browser, the window command palette shortcut close subject will be used
   */
  public get close$() {
    return merge(this.cmdPaletteClose$.asObservable(), getGlobalOrWindowShortcut$(SHORTCUT.ESCAPE));
  }

  /**
   * Registers command palette items and adds categories & category groups (if applicable)
   * @param item - The item to register
   */
  public async register(items: CommandPaletteItem[]) {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }

    const serializedItems = items.map((item) => omit({ ...item }, 'onSelect'));

    await this._store.register.call(serializedItems);

    // Add the items onSelect callback to the map (this is stored in the same process)
    items.forEach((item) => {
      if (!item.onSelect) {
        return;
      }
      this._itemSelectFnMap.set(item.id, item.onSelect);
    });

    // Update the command p actor via the update$ subject/event
    await this._ensureStateSynced({ shouldRender: true });
  }

  /**
   * Unregisters a command palette item and removes categories & category groups (if applicable)
   * @param item - The item to register
   */
  public async unregister(itemsOrIds: CommandPaletteItem[] | string[]) {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }

    const ids = itemsOrIds.map((itemOrId) =>
      typeof itemOrId === 'string' ? itemOrId : (itemOrId as CommandPaletteItem).id
    );

    await this._store.unregister.call(ids);

    // Remove the items onSelect callback from the map (this is stored in the same process)
    itemsOrIds.forEach((itemOrId) => {
      const id = typeof itemOrId === 'string' ? itemOrId : (itemOrId as CommandPaletteItem).id;
      this._itemSelectFnMap.delete(id);
    });

    // Update the command p actor via the update$ subject/event
    await this._ensureStateSynced({ shouldRender: true });
  }

  /**
   * Registers contextual command palette items and adds categories & category groups (if applicable)
   * - Contextual items are temporary and will be unregistered when the returned function is called
   * - These are useful for registering items that are only available in certain contexts
   *
   * @param items - The contextual items to register
   * @returns - A function to unregister the items
   */
  public async registerContext(
    contextName: string,
    items: Omit<CommandPaletteItem, 'category'>[]
  ): Promise<CommandPaletteUnregisterContextItems> {
    const contextItems = items.map((item) => ({ ...item, category: contextName, isContextual: true }));
    await this.register(contextItems);

    return async () => {
      await this.unregister(items.map((i) => i.id));
    };
  }

  /**
   * Unregisters all command palette items and removes categories & category groups (if applicable)
   */
  public async unregisterAll() {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }

    await this._store.unregisterAll.call();

    // Remove the items onSelect callback from the map (this is stored in the same process)
    this._itemSelectFnMap.clear();

    await this._ensureStateSynced({ shouldRender: true });
  }

  /**
   * Sets the order of the category groups inside a tab (category)
   */
  public async setCategoriesOrder(order: string[]) {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }

    await this._store.setCategoriesOrder.call(order);

    // Update the command p actor via the update$ subject/event
    this.update$.next({
      items: this._renderComboxItems()
    });
  }

  /**
   * Listens for the command palette state updates from the store (single source of truth)
   */
  private _listenForStateUpdates() {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }
    this._unsubscribes.push(
      this._store.stringifiedState.subscribe((state) => {
        const parsed = parseJSON<CommandPaletteMemoryState>(state);
        if (parsed) {
          // Need to run a diff here to determine if any items were removed.
          // So we can clean up the onSelect callbacks in this process
          const removedItems = this._state.items.filter(
            (item) => !parsed.items.find((i) => i.id === item.id)
          );

          removedItems.forEach((item) => {
            this._itemSelectFnMap.delete(item.id);
          });

          this._state = parsed;
        }
      })
    );
  }

  private async _ensureStateSynced({ shouldRender }: { shouldRender?: boolean } = { shouldRender: false }) {
    if (!this._store) {
      throw new Error('Command palette service not initialized');
    }
    const stateStr = await this._store.stringifiedState.get();
    const parsed = parseJSON<CommandPaletteMemoryState>(stateStr);
    if (parsed) {
      this._state = parsed;
      if (shouldRender) {
        this.update$.next({
          items: this._renderComboxItems()
        });
      }
    }
  }

  /**
   * Listens for the command palette submit event and runs the onSelect callback for each item
   */
  private _listenForSubmit() {
    this._subscriptions.push(
      this.cmdPaletteSubmit$.pipe(debounceTime(50)).subscribe(({ items, context }) => {
        items.forEach((item) => {
          const commandPaletteItemFn = this._itemSelectFnMap.get(item.id);
          async function runOnSelect() {
            if (commandPaletteItemFn) {
              const result = commandPaletteItemFn(item, context);
              if (isPromiseLike(result)) {
                await result;
              }
            }
          }
          runOnSelect().catch(console.error);
        });
      })
    );
  }

  /**
   * Returns the command palette items to render
   * - Combines the categories, category groups, and items
   *
   * @returns ComboBoxItemUnion[] - The command palette items
   */
  private _renderComboxItems(): ComboBoxItemUnion[] {
    const sortedCats = this._state.categories.sort((a, b) => {
      if (a.isContextual || b.isContextual) {
        return -1;
      }

      const aIndex = this._state.categoriesOrder.indexOf(a.label);
      const bIndex = this._state.categoriesOrder.indexOf(b.label);

      if (aIndex > -1 && bIndex > -1) {
        return aIndex - bIndex;
      }

      return 1;
    });

    return sortedCats.flatMap((cat) => {
      const tab: ComboBoxItemUnion = {
        type: 'tab',
        label: cat.label,
        items: []
      };

      const itemsInCat = this._state.categoryGroups.filter((catGroup) => catGroup.category === cat.label);

      if (!itemsInCat.length) {
        return [] as ComboBoxItemUnion[];
      }

      tab.items = this._state.categoryGroups
        .filter((catGroup) => catGroup.category === cat.label)
        .flatMap((catGroup) => {
          const items = this._state.items
            .filter((item) => item.category === catGroup.category && item.group === catGroup.label)
            .map((item) => {
              const nextItem: ComboBoxItem = {
                type: 'item',
                id: item.id,
                label: item.label,
                sublabel: item.sublabel,
                searchValues: item.searchValues,
                shortcut: item.shortcut,
                iconId: item.iconId,
                value: item.value || item.id
              };
              return nextItem;
            });

          if (catGroup.label === COMMAND_PALETTE.CATEGORY_GROUP_UNKNOWN) {
            return items;
          }

          return {
            type: 'group',
            label: catGroup.label,
            items
          } as ComboBoxItemUnion;
        });

      return tab;
    });
  }
}
