import { AnyRecord } from '@oms/frontend-foundation';
import { type ActionComponentConfig, type ActionDefFactory } from '../models/actions.model';

export const ROW_SELECTION_COUNT_ACTION_TYPE = 'row-selection-count';

export function createRowSelectionCountAction<TData extends AnyRecord>(): ActionDefFactory<TData> {
  return (builder) =>
    builder
      .name('row_selection_count')
      .toolbar((t) =>
        t
          .id('row_selection')
          .component('action-text')
          .location('InfoToolbar')
          .props({
            style: { whiteSpace: 'nowrap' }
          })
      )
      .onChange<ActionComponentConfig<TData>>((e) => {
        const rows = e.api.getSelectedRows();

        if (!rows.length) {
          e.notify({ content: '', isVisible: false });
        } else if (rows.length === 1) {
          e.notify({ content: '1 row', isVisible: true });
        } else {
          e.notify({ content: `${rows.length} rows`, isVisible: true });
        }
      });
}
