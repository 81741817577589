import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { TradeViewLayoutComponentProps } from './trade-view.layout.config';
import { ViewTabsList } from '@app/widgets/common/components/view.tabs-list';
import type { TabsComponentProps } from '@app/widgets/common/components/view.tabs-list';
import TradeViewExecutionsGrid from './trade-view.executions-grid.widget';
import { TradeViewAuditTrailGrid } from './trade-view.audit-trail.grid';

const getTabsListProps = (id: string): TabsComponentProps['tabs'] => [
  { name: 'Executions', value: 'executions', tabContent: <TradeViewExecutionsGrid id={id} /> },
  {
    name: 'Audit trail',
    value: 'auditTrail',
    tabContent: <TradeViewAuditTrailGrid tradeId={id} />
  }
];

export const TradeViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<TradeViewLayoutComponentProps>();

  if (!props) {
    throw new Error('TradeViewTabbedGridsWidget requires a trading order ID');
  }
  const { id } = props;
  return <ViewTabsList defaultValue="executions" tabs={getTabsListProps(id)} />;
};

export default TradeViewTabbedGridsWidget;
