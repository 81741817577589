import { inject, singleton } from 'tsyringe';
import { TsDailyExecutionsWithFilterDocument } from '@oms/generated/frontend';
import type { DailyExecutionRow, TsDailyExecutionsWithFilterSubscription } from '@oms/generated/frontend';
import type { Dispatch, SetStateAction } from 'react';
import { TableServerService } from '../../system/table-server/table-server.service';
import { TableServerRowSubscriptionVariables } from '../../system/table-server/table-server.datasource.contracts';
import { agFilterModelToTableServerFilterStr } from '../../system/table-server/filters/ag-grid.table-server.transformer';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class InvestorOrderDailyExecutionsSubscriptionService {
  constructor(@inject(TableServerService) private tableServerService: TableServerService) {}

  public queryDailyExecutions(
    orderId: string,
    setDailyExecutions: Dispatch<SetStateAction<DailyExecutionRow[]>>,
    setError: Dispatch<SetStateAction<Error | undefined>>,
    isToday?: boolean
  ) {
    const variables: TableServerRowSubscriptionVariables = {
      filterBy: agFilterModelToTableServerFilterStr<keyof DailyExecutionRow>({
        investorOrderId: {
          filterType: 'text',
          type: 'contains',
          filter: orderId
        },
        executionDate: {
          filterType: 'customDate',
          type: 'equals',
          period: isToday ? 'today' : 'no-filter'
        }
      }),
      limit: 1000,
      offset: 0,
      sortBy: ''
    };

    const subscription = this.tableServerService
      .query$<DailyExecutionRow, TsDailyExecutionsWithFilterSubscription>({
        query: TsDailyExecutionsWithFilterDocument,
        getData: (r) => r.tsDailyExecutionsWithFilter,
        variables
      })
      .subscribe(({ errors, rows }) => {
        if (errors?.length) {
          setError(new Error(`Error getting daily executions for the investor order with id ${orderId}`));
          console.error(errors);
        }

        setDailyExecutions(rows || []);
      });

    return () => {
      subscription.unsubscribe();
    };
  }

  public dispose() {}
}
