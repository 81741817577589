import React, { FC } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type {
  TooltipContentProps as RadixTooltipContentProps,
  TooltipTriggerProps as RadixTooltipTriggerProps
} from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import * as styles from './tooltip.css';

export const TooltipProvider = TooltipPrimitive.Provider;
export const TooltipRoot = TooltipPrimitive.Root;
export const TooltipArrow = TooltipPrimitive.Arrow;

export const TooltipTrigger = React.forwardRef<HTMLButtonElement, RadixTooltipTriggerProps>(
  ({ asChild = true, children, ...rest }, ref) => (
    <TooltipPrimitive.Trigger ref={ref} asChild={asChild} {...rest}>
      {children}
    </TooltipPrimitive.Trigger>
  )
);

export type TooltipContentProps = {} & RadixTooltipContentProps;

export const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ children, sideOffset = 4, side = 'bottom', className }, ref) => {
    return (
      <TooltipPrimitive.Content
        sideOffset={sideOffset}
        side={side}
        className={clsx(styles.tooltipContent, className)}
        ref={ref}
        asChild={false}
      >
        <span>
          {children}
          <TooltipArrow className={styles.tooltipArrow} />
        </span>
      </TooltipPrimitive.Content>
    );
  }
);

export interface TooltipProps extends TooltipContentProps {
  label: string | React.ReactElement;
  maxWidth?: string | number;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  /**
   * The duration from when the mouse enters the trigger until the tooltip gets opened. This will
   * override the prop with the same name passed to Provider.
   * @defaultValue 700
   */
  delayDuration?: number;
  children?: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({
  defaultOpen,
  open,
  onOpenChange,
  delayDuration,
  children,
  label,
  maxWidth,
  side,
  sideOffset,
  align,
  alignOffset,
  avoidCollisions,
  className,
  style
}) => {
  return (
    <TooltipRoot
      delayDuration={delayDuration}
      onOpenChange={onOpenChange}
      open={open}
      defaultOpen={defaultOpen}
    >
      <TooltipTrigger asChild={true}>
        <span>{children}</span>
      </TooltipTrigger>
      <TooltipPrimitive.Portal>
        <TooltipContent
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          avoidCollisions={avoidCollisions}
          className={className}
          style={{ maxWidth, ...style }}
        >
          {label}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipRoot>
  );
};
