import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { DependencyContainer } from 'tsyringe';
import { UpdaterSignal } from '@app/data-access/memory/updater.signal';
import { check } from '@tauri-apps/plugin-updater';
import type { Update } from '@tauri-apps/plugin-updater';
import { relaunch } from '@tauri-apps/plugin-process';
import { createLogger } from '@oms/shared/util';
import { isBrowser } from '@app/common/workspace/workspace.constants';

export const updaterPluginLogger = createLogger({ name: 'Updater Plugin' });

export interface UpdaterPluginOptions {
  container: DependencyContainer;
}

export const updaterPlugin = ({ container }: UpdaterPluginOptions) => {
  return Plugin.create<AppWorkspace>({
    name: 'valstro-updater-plugin',
    pluginFn: ({ workspace }) => {
      const updaterSignal = container.resolve(UpdaterSignal);

      workspace.addHook('leaderWindowReady', () => {
        updateApp(updaterSignal).catch((e) => {
          updaterPluginLogger.error(e);
          updaterSignal.signal.set((curr) => ({
            ...curr,
            updateErrorMessage: (e as Error)?.message ?? 'Something went wrong updating the app'
          }));
        });
      });

      return () => {};
    }
  });
};

/**
 * Updates the app
 *
 * @param updaterSignal - The updater signal
 * @returns void
 */
async function updateApp(updaterSignal: UpdaterSignal) {
  if (isBrowser()) {
    updaterPluginLogger.log('browser does not require checking for updates - skipping step');
    updaterSignal.signal.set({
      hasUpdateAvailable: false,
      isCheckingForUpdates: false,
      isUpdating: false,
      updateErrorMessage: null
    });
    return;
  }

  let update: Update | null = null;

  try {
    update = await check();
  } catch (e) {
    const errMessage =
      e instanceof Error
        ? e.message
        : typeof e === 'string'
          ? e
          : 'Something went wrong checking for updates';
    const isEndpointError = errMessage.includes('Updater does not have any endpoints set');
    const errMsg = (e as Error)?.message ?? 'Something went wrong checking for updates';

    if (isEndpointError) {
      updaterPluginLogger.warn(errMsg);
    } else {
      updaterPluginLogger.error(errMsg);
    }

    updaterSignal.signal.set({
      hasUpdateAvailable: isEndpointError ? false : true, // Allow the app to continue running if we don't have any endpoints set (dev mode)
      isCheckingForUpdates: false,
      isUpdating: false,
      updateErrorMessage: errMessage
    });

    return;
  }

  if (!update || update.available === false) {
    updaterSignal.signal.set({
      hasUpdateAvailable: false,
      isCheckingForUpdates: false,
      isUpdating: false,
      updateErrorMessage: null
    });

    return;
  }

  updaterSignal.signal.set({
    hasUpdateAvailable: true,
    isCheckingForUpdates: false,
    isUpdating: true,
    updateErrorMessage: null
  });

  updaterPluginLogger.log(`found update ${update.version} from ${update.date ?? 'now'}`);

  let downloaded: number = 0;
  let contentLength: number = 0;

  try {
    await update.downloadAndInstall((event) => {
      switch (event.event) {
        case 'Started':
          contentLength = event.data.contentLength ?? 0;
          updaterPluginLogger.log(`started downloading ${contentLength} bytes`);
          break;
        case 'Progress':
          downloaded += event.data.chunkLength;
          updaterPluginLogger.log(`downloaded ${downloaded} from ${contentLength}`);
          break;
        case 'Finished':
          updaterPluginLogger.log('download finished');
          break;
      }
    });

    updaterPluginLogger.log('update installed, relaunching app.');
    await relaunch();
  } catch (e) {
    const errMessage =
      e instanceof Error
        ? e.message
        : typeof e === 'string'
          ? e
          : 'Something went wrong checking for updates';
    updaterPluginLogger.error(errMessage);
    updaterSignal.signal.set({
      hasUpdateAvailable: false,
      isCheckingForUpdates: false,
      isUpdating: false,
      updateErrorMessage: errMessage
    });

    relaunch().catch((e) => {
      updaterPluginLogger.error(e);
    });

    return;
  }
}
