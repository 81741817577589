import { openExecutionView } from '@app/generated/sdk';
import { getViewDialogTitle } from '@app/common/types/orders/orders.utils';
import type { ViewDialogInfo } from '@app/common/types/orders/orders.utils';
import { ExecutionViewLayoutComponentProps } from '@app/widgets/trading/execution-view/execution-view.layout.config';

export interface OpenViewExecutionArgs {
  windowId: string;
  dialogInfo: ViewDialogInfo;
  componentProps: ExecutionViewLayoutComponentProps;
}

export const openViewExecution = async ({ windowId, dialogInfo, componentProps }: OpenViewExecutionArgs) => {
  await openExecutionView(windowId, {
    componentProps,
    title: getViewDialogTitle(dialogInfo, 'execution')
  });
};
