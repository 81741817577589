import { Plugin } from '@valstro/workspace';
import {
  excludeDocumentsWithInvalidSchemaVersion,
  lastUpdatedAtHooks,
  migrateDocumentsWithOutdatedSchemaVersion,
  pushCompletedMigrations,
  RxdbReplicationDecorator
} from '@app/data-access/offline/replicationMigrations/middleware';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { DependencyContainer } from 'tsyringe';
import { RxdbReplicationService } from '@app/data-access/offline/rxdb-replication.service';
import {
  createWorkspaceWindowReady$,
  createWorkspaceLeaderWindowReady$
} from '@app/common/workspace/workspace.rxjs';
import { filter, switchMap, map } from 'rxjs';
import { AppState } from '@app/data-access/memory/app.stream';
import { AppDatabase } from '@app/data-access/offline/app-database';

export interface SyncronisationPluginOptions {
  container: DependencyContainer;
}

export const syncronisationPlugin = ({ container }: SyncronisationPluginOptions) => {
  return Plugin.create<AppWorkspace>({
    name: 'valstro-syncronisation-plugin',
    pluginFn: ({ workspace }) => {
      const windowReady$ = createWorkspaceWindowReady$(workspace);
      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const appState = container.resolve(AppState);
      let replicationService: RxdbReplicationService | undefined;
      let rxdbReplicationDecorator: RxdbReplicationDecorator | undefined;
      let completedMigrationsSubscription: ReturnType<typeof pushCompletedMigrations> | undefined;
      const subscription = windowReady$
        .pipe(
          switchMap(({ isLeader }) =>
            appState.$.pipe(
              filter((state) => state.state === 'Connected' || state.state === 'Ready'),
              map((v) => ({ ...v, isLeader }))
            )
          )
        )
        .subscribe(({ isLeader }) => {
          replicationService = container.resolve(RxdbReplicationService);
          replicationService.registerRxDBHooks([lastUpdatedAtHooks]);

          if (isLeader) {
            replicationService.addMiddleware(excludeDocumentsWithInvalidSchemaVersion);
            replicationService.addMiddleware(migrateDocumentsWithOutdatedSchemaVersion);
            rxdbReplicationDecorator = container.resolve(RxdbReplicationDecorator);
            replicationService.addMiddleware(rxdbReplicationDecorator.setSharerName);
            completedMigrationsSubscription = pushCompletedMigrations(container.resolve(AppDatabase));
            replicationService.init().catch(console.error);
          }

          workspace.updateMeta({
            replication: replicationService
          });
        });
      const disconnectSubscription = leaderWindowReady$
        .pipe(
          switchMap(() =>
            appState.$.pipe(
              filter((state) => state.state === 'Disconnected' || state.state === 'Unauthorized')
            )
          )
        )
        .subscribe(() => {
          if (replicationService) {
            replicationService.stopReplication();
          }
        });
      return () => {
        subscription.unsubscribe();
        disconnectSubscription.unsubscribe();
        if (replicationService) {
          replicationService.stopReplication();
        }
        if (completedMigrationsSubscription) {
          completedMigrationsSubscription.unsubscribe();
        }
      };
    }
  });
};
