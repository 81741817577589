import { fetch as tauriFetch } from '@tauri-apps/plugin-http';
import type { ClientOptions } from '@tauri-apps/plugin-http';

declare const window: Window & { __TAURI__: any };
function isTauri(): boolean {
  return !!window.__TAURI__;
}

async function fetch(input: RequestInfo, init?: RequestInit & ClientOptions): Promise<Response> {
  if (isTauri()) {
    const response = await tauriFetch(input.toString(), init);
    return response as unknown as Response;
  } else {
    return window.fetch(input, init);
  }
}

export default fetch;
