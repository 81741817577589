import { useVGrid, VGrid, CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { inlineTradingOrderActions } from '../trading-order-monitor/grid-actions/inline.trading-order.actions';
import { openEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { memo } from 'react';
import { buildInvestorOrderTradingOrderMonitorColumnDefs } from '../trading-order-monitor/trading-order-monitor.columns';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TsMappedTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedTradingOrderRow,
  TradingOrderRow,
  TsMappedTradingOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import {
  TOHasExecutedQuantity,
  TOHazard,
  TONoExecutedQuantity,
  TOPending
} from '../utils/row-state-rule-utils';

export type TradingOrderDetailGridProps = {
  investorOrderId: string;
};

export const TradingOrdersDetailGrid = memo(
  ({ investorOrderId }: TradingOrderDetailGridProps) => {
    const datasourceService = useService(TableServerDatasourceService);
    const gridProps = useVGrid<TradingOrderRow>(
      `investor-order-${investorOrderId || ''}-trading-orders`,
      (builder) =>
        builder
          .tableServerColumnLibrary(buildInvestorOrderTradingOrderMonitorColumnDefs())
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(
                datasourceService.getSource<
                  MappedTradingOrderRow,
                  TsMappedTradingOrdersWithFilterSubscription
                >({
                  query: TsMappedTradingOrdersWithFilterDocument,
                  getData: (r) => r.tsMappedTradingOrdersWithFilter,
                  filter: {
                    investorOrderId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: investorOrderId
                    }
                  }
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .rowStateRules({
            pending: (params) => TOPending(params.data),
            noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data),
            hazard: (params) => TOHazard(params.data)
          })
          .toolbarStrategies('hidden')
          .actions((a) =>
            a.schema((s) =>
              s
                .action(openEntryTradingOrderAction())
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction())
                .action(modifyTradingOrderAction())
                .action(cancelTradingOrderAction())
                .action(inlineTradingOrderActions())
                .action(refreshServersideCustomMenuAction)
            )
          ),
      [datasourceService]
    );

    return <VGrid {...gridProps} />;
  },
  (prev, next) => prev.investorOrderId === next.investorOrderId
);

export default TradingOrdersDetailGrid;
