import { useService } from '@oms/frontend-foundation';
import { ROW_SELECTION_COUNT_ACTION_TYPE, useVGrid, VGrid } from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { buildInvestorOrderViewTradingOrdersColumnDefs } from './execution-view.investor-orders.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TsMappedInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedInvestorOrderRow,
  TsMappedInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import { createViewInvestorOrderAction } from '../investor-order-view/commands/view-investor-order/view-investor-order.action';
import { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';

export const ExecutionViewInvestorOrderGrid = ({
  executionId,
  allocationMapId
}: ExecutionViewLayoutComponentProps) => {
  const datasourceService = useService(TableServerDatasourceService);

  const props = useVGrid(
    'execution-view-investor-orders',
    (b: GridBuilder<MappedInvestorOrderRow>) =>
      b
        .tableServerColumnLibrary(buildInvestorOrderViewTradingOrdersColumnDefs())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<
                MappedInvestorOrderRow,
                TsMappedInvestorOrdersWithFilterSubscription
              >({
                query: TsMappedInvestorOrdersWithFilterDocument,
                getData: (r) => {
                  return r.tsMappedInvestorOrdersWithFilter;
                },
                filter: {
                  allocationMapId: {
                    filterType: 'text',
                    type: 'equals',
                    filter: allocationMapId
                  }
                }
              })
            )
            .rowId((r) => r.data.allocationMapId)
            .cacheBlockSize(100)
        )
        .injectEvents([GridConfigEventHandler])
        .rowStateRules({
          pending: (params) => IOPending(params.data),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a.schema((s) =>
            s
              .action(createViewInvestorOrderAction('context-menu'))
              .action(createViewInvestorOrderAction('configurable'))
              .action(ROW_SELECTION_COUNT_ACTION_TYPE)
              .action(refreshServersideCustomMenuAction)
          )
        )
        .reactiveCustomComponents(),
    [datasourceService, executionId, allocationMapId]
  );
  return <VGrid {...props} />;
};

export default ExecutionViewInvestorOrderGrid;
