import type { ColumnBuilder, ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { sharedDefaultCol, sharedIdCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';
import { sharedSideCol, createQuantityCol, sharedLimitPriceCol } from '@app/common/grids/columns/order-cols';
import type { InvestorOrderBulkEntryGridRow } from './investor-order-bulk-entry.grid';
import { OrderSideType } from '@oms/generated/frontend';
import { SideTypeCellRenderer } from '@app/common/grids/cell-renderers/side-type-cell/side-type-cell.renderer';
import { ErrorsCellRenderer } from '@app/common/grids/cell-renderers/errors/errors.cell-renderer';
import type { ValueFormatterParams } from '@ag-grid-community/core';

const isEmptyPinnedCell = (params: ValueFormatterParams) =>
  params.node && params.node.rowPinned === 'top' && (params.value == null || params.value === '');

const emptyCellFormatter = (params: ValueFormatterParams) => {
  return isEmptyPinnedCell(params) ? params.colDef.headerName : params.value;
};

const defaultColumn = (col: ColumnBuilder<InvestorOrderBulkEntryGridRow>) =>
  sharedDefaultCol<InvestorOrderBulkEntryGridRow>(col)
    .flex(1)
    .colDef({
      editable: true,
      useValueFormatterForExport: false // TODO: check if formatting needed for exported data
    })
    .cell((cell) => cell.valueFormatter(emptyCellFormatter));

const idCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedIdCol<InvestorOrderBulkEntryGridRow>(col, 'id').colDef({ editable: false }).hide();

const SHOW_PLACEHOLDER = true;
const sideTypeCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedSideCol<InvestorOrderBulkEntryGridRow>(col, 'sideType', SHOW_PLACEHOLDER).colDef({
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [
        OrderSideType.Buy,
        OrderSideType.Btc,
        OrderSideType.Sell,
        OrderSideType.Short,
        OrderSideType.Exempt
      ],
      cellRenderer: SideTypeCellRenderer
    }
  });

const openQuantityCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  createQuantityCol<InvestorOrderBulkEntryGridRow>(
    col,
    t('app.orders.orderMonitor.quantityGroup'),
    t('app.orders.orderMonitor.quantityGroup', { ns: 'short' }),
    'quantity'
  )
    .colDef({
      editable: true,
      cellEditor: 'agNumberCellEditor'
    })
    .cell((cell) => cell.valueFormatter(emptyCellFormatter));

const instrumentCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedTextCol(col, 'instrument')
    .header('Symbol')
    .width(110)
    .maxWidth(120)
    .minWidth(100)
    .filter('agTextColumnFilter')
    .colDef({
      editable: true,
      cellEditor: 'agTextCellEditor'
    })
    .cell((cell) =>
      cell.valueFormatter((params: ValueFormatterParams) =>
        params.value ? params.value.toUpperCase() : emptyCellFormatter(params)
      )
    );

const limitPriceCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedLimitPriceCol(col).colDef({
    editable: true,
    cellEditor: 'agNumberCellEditor'
  });

const fillQuantityCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedTextCol(col, 'preAllocationAcc')
    .header(t('app.orders.orderMonitor.preAllocationAcc'))
    .shortHeader(t('app.orders.orderMonitor.preAllocationAcc', { ns: 'short' }))
    .format('decimal')
    .minWidth(120)
    .width(120)
    .colDef({
      editable: true,
      cellEditor: 'agTextCellEditor'
    })
    .cell((cell) => cell.valueFormatter(emptyCellFormatter));

const errorsCol: ColumnBuilderCallback<InvestorOrderBulkEntryGridRow> = (col) =>
  sharedTextCol(col, 'validation')
    .colDef({
      editable: false,
      minWidth: 200
    })
    .cell((cell) => cell.renderer(ErrorsCellRenderer));

export const InvestorOrderBulkEntryColumnLibrary: ColumnLibrary<InvestorOrderBulkEntryGridRow> = {
  defaultColumn,
  columns: [idCol, sideTypeCol, openQuantityCol, instrumentCol, limitPriceCol, fillQuantityCol, errorsCol]
};
