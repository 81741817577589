// TODO: Note, using multiple packages across both apps seems to cause some type issues in rxdb hooks

import type { ReactWindowActorWrapperComp } from '@valstro/workspace-react';
import { ToolbarProvider } from '@app/common/toolbar/toolbar-context';
import { ErrorBoundary } from '@app/containers/error-boundary/error-boundary.container';
import { ConditionalWrapper } from '@oms/shared-frontend/ui-design-system';
import { LauncherFrameContainer } from '@app/containers/launcher-frame/launcher-frame.container';
import { isBrowser } from '@app/common/workspace/workspace.constants';
import { AppStateWrapper } from './app-state.wrapper';

export const RootWindowWrapper: ReactWindowActorWrapperComp = ({ children }) => {
  return (
    <ConditionalWrapper
      condition={isBrowser()}
      wrapper={(c) => (
        <AppStateWrapper>
          <LauncherFrameContainer showShortcut={true}>{c}</LauncherFrameContainer>
        </AppStateWrapper>
      )}
    >
      <ErrorBoundary>
        <ToolbarProvider>{children}</ToolbarProvider>
      </ErrorBoundary>
    </ConditionalWrapper>
  );
};
