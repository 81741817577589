import { omit } from 'lodash';
import { GridApi } from '@ag-grid-community/core';
import type { AnyRecord } from '@oms/shared/util-types';
import type { GridContext, GridContextUpdateFn } from './grid-context.types';

/**
 * Retrieves the current context from the grid in type-safe manner
 * @param api - The AG Grid API instance
 * @returns The current grid context
 */
export function getGridContext<TData extends AnyRecord>(api: GridApi): GridContext<Partial<TData>> {
  return (api.getGridOption('context') || {}) as GridContext<TData>;
}

/**
 * Updates the grid's context in a type-safe manner
 * @param api - The AG Grid API instance
 * @param data - New context data to merge into the existing context
 * @returns The updated context
 */
export function updateGridContext<TData extends AnyRecord>(
  api: GridApi,
  data: TData
): GridContext<Partial<TData>>;

/**
 * Updates the grid's context in a type-safe manner
 * @param api - The AG Grid API instance
 * @param getData - A function to update the existing context
 * @returns The updated context
 */
export function updateGridContext<TData extends AnyRecord>(
  api: GridApi,
  getData: GridContextUpdateFn<TData>
): GridContext<Partial<TData>>;

// Implementation only ---------------------------------------------------- /
export function updateGridContext<TData extends AnyRecord>(
  api: GridApi,
  dataOrDataFn: TData | GridContextUpdateFn<TData>
): GridContext<Partial<TData>> {
  const current = getGridContext<TData>(api);
  const data = typeof dataOrDataFn === 'function' ? dataOrDataFn(current) : dataOrDataFn;
  const updatedContext: GridContext<Partial<TData>> = {
    ...omit(current, ...Object.keys(data)),
    ...data
  };
  api.setGridOption('context', updatedContext);
  return updatedContext;
}
