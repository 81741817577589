import { useEffect, useState } from 'react';
import type { Optional } from '@oms/shared/util-types';
import { getVersion } from '@tauri-apps/api/app';
import { formatVersion } from '@oms/shared/util';
import type { VersionFormatOptions } from '@oms/shared/util';

const errorHandler = (e: any) => {
  console.error(`Unable to get app version: ${(e as Error).message}`);
};

export const useAppVersion = (options?: VersionFormatOptions): Optional<string> => {
  const [version, setVersion] = useState<Optional<string>>(undefined);

  useEffect(() => {
    getVersion()
      .then((versionString) => {
        setVersion(formatVersion(versionString, { prependV: options?.prependV }));
      })
      .catch((e) => {
        errorHandler(e);
        setVersion(undefined);
      });
  }, [options?.prependV]);

  return version;
};
