/**
 * Get parsed payload from sourceEventPayload.
 * Recursively apply JSON.parse to sourceEventPayload until it is parsed and we get Object from string.
 */
export function getParsedPayload<T>(sourceEventPayload: string | T): T {
  if (typeof sourceEventPayload === 'string') {
    try {
      return getParsedPayload<T>(JSON.parse(sourceEventPayload) as string | T);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to parse sourceEventPayload');
    }
  } else {
    return sourceEventPayload;
  }
}
