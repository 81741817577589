import type { VersionedSchemaExport } from '@oms/frontend-schemas';
import type { ActionCommands } from './command.registry.types';
import { MONTAGE_BUMP_PRICE_ACTION } from '@app/widgets/trading/montage/commands/bump-price/bump-price.action';
import { MONTAGE_TARGET_TRADING_ORDER_ACTION } from '@app/widgets/trading/montage/commands/target-trading-order/target-trading-order.action';
import { CREATE_UNBOUND_TO_ACTION } from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/create-unbound-trading-order.action';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { LATEST_VERSIONED_SCHEMA as acceptInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-monitor/commands/accept-investor-order/schema';
import { LATEST_VERSIONED_SCHEMA as cancelInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-monitor/commands/cancel-investor-order/schema';
import { LATEST_VERSIONED_SCHEMA as createInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-entry/commands/create-investor-order/schema';
import { LATEST_VERSIONED_SCHEMA as createUnboundToVersionedSchema } from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/schema';
import { LATEST_VERSIONED_SCHEMA as doneForDayVersionedSchema } from '@app/widgets/trading/investor-order-monitor/commands/done-for-day/schema';
import { LATEST_VERSIONED_SCHEMA as modifyInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-entry/commands/modify-investor-order/schema';
import { LATEST_VERSIONED_SCHEMA as rejectInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-monitor/commands/reject-investor-order/schema';
import { LATEST_VERSIONED_SCHEMA as routeOrderVersionedSchema } from '@app/widgets/trading/route-order/commands/route-order/schema';
import { LATEST_VERSIONED_SCHEMA as montageBumpPriceVersionedSchema } from '@app/widgets/trading/montage/commands/bump-price/schema';
import { LATEST_VERSIONED_SCHEMA as viewInvestorOrderVersionedSchema } from '@app/widgets/trading/investor-order-view/commands/view-investor-order/schema';
import { createRouteOrderAction } from '@app/widgets/trading/route-order/commands/route-order/route-order.action';
import { createCreateInvestorOrderAction } from '@app/widgets/trading/investor-order-entry/commands/create-investor-order/create-investor-order.action';
import { createViewInvestorOrderAction } from '@app/widgets/trading/investor-order-view/commands/view-investor-order/view-investor-order.action';
import { createAcceptInvestorOrderAction } from '@app/widgets/trading/investor-order-monitor/commands/accept-investor-order/accept-investor-order.action';
import { createDoneForDayAction } from '@app/widgets/trading/investor-order-monitor/commands/done-for-day/done-for-day.action';
import { createRejectInvestorOrderAction } from '@app/widgets/trading/investor-order-monitor/commands/reject-investor-order/reject-investor-order.action';
import { createCancelInvestorOrderAction } from '@app/widgets/trading/investor-order-monitor/commands/cancel-investor-order/cancel-investor-order.action';
import { createModifyInvestorOrderAction } from '@app/widgets/trading/investor-order-entry/commands/modify-investor-order/modify-investor-order.action';

/**
 * Register all commands here to be used by actions.
 */
export const ACTION_COMMAND_REGISTRY: Record<ActionCommands, ActionDefFactory> = {
  accept_investor_order: createAcceptInvestorOrderAction('configurable'),
  cancel_investor_order: createCancelInvestorOrderAction('configurable'),
  create_investor_order: createCreateInvestorOrderAction('configurable'),
  create_unbound_to: CREATE_UNBOUND_TO_ACTION,
  done_for_day: createDoneForDayAction('configurable'),
  modify_investor_order: createModifyInvestorOrderAction('configurable'),
  montage_bump_price: MONTAGE_BUMP_PRICE_ACTION,
  montage_target_trading_order: MONTAGE_TARGET_TRADING_ORDER_ACTION,
  reject_investor_order: createRejectInvestorOrderAction('configurable'),
  route_order: createRouteOrderAction('configurable'),
  view_investor_order: createViewInvestorOrderAction('configurable')
};

export type ActionCommandsWithSchemas = Exclude<ActionCommands, 'montage_target_trading_order'>;

export const ACTION_COMMAND_SCHEMA_REGISTRY: Record<ActionCommandsWithSchemas, VersionedSchemaExport<any>> = {
  accept_investor_order: acceptInvestorOrderVersionedSchema,
  cancel_investor_order: cancelInvestorOrderVersionedSchema,
  create_investor_order: createInvestorOrderVersionedSchema,
  create_unbound_to: createUnboundToVersionedSchema,
  done_for_day: doneForDayVersionedSchema,
  modify_investor_order: modifyInvestorOrderVersionedSchema,
  montage_bump_price: montageBumpPriceVersionedSchema,
  reject_investor_order: rejectInvestorOrderVersionedSchema,
  route_order: routeOrderVersionedSchema,
  view_investor_order: viewInvestorOrderVersionedSchema
};
