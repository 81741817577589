import { type ChangeEventHandler, type FC, useCallback, useMemo, useRef, useState } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';
import { Select } from '@oms/shared-frontend/ui-design-system';
import isObject from 'lodash/isObject';
import { AnyRecord } from '@oms/frontend-foundation';

export interface ActionDropdownOption<TValue = string, TLabel = string> {
  value: TValue;
  label: TLabel;
}

export interface ActionDropdownProps<TData extends AnyRecord> extends ActionComponentProps<TData> {
  selected: any;
  options: (any | ActionDropdownOption)[];
}

export const ActionDropdown: FC<ActionDropdownProps<any>> = (props) => {
  const actionProps = useActionProps<any, ActionDropdownProps<any>>(props);
  const [selected, setSelected] = useState<string>(actionProps?.selected ?? undefined);
  const initialized = useRef(false);
  const options: ActionDropdownOption[] = useMemo(() => {
    return (actionProps.options || []).map((op) =>
      isObject(op) ? (op as ActionDropdownOption) : { value: op, label: op }
    );
  }, [actionProps.options]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSelected(() => {
        actionProps.onChange({
          state: actionProps,
          actionId: actionProps.id,
          lifecycle: 'change',
          value: e.target.value
        });
        return e.target.value;
      });
    },
    [actionProps]
  );

  if (actionProps.isVisible === false) {
    return null;
  }

  if (!initialized.current && options?.length && !selected) {
    setSelected(() => {
      actionProps.onChange({
        state: actionProps,
        actionId: actionProps.id,
        lifecycle: 'change',
        value: options[0].value
      });
      initialized.current = true;
      return options[0].value;
    });
  }

  return (
    <Select
      value={selected}
      onChange={onChange}
      size={actionProps?.size ?? 'sm'}
      style={props.style}
      sx={props.sx}
    >
      {options.map((option, index) => (
        <option key={`${option.value}-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
