import { InvestorOrderExecutionsService } from '@app/data-access/services/trading/investor-orders/investor-order-executions.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { applySettleFxRateFormContract } from './apply-settlement-fx-rate.form-contract';
import type {
  ApplySettleFxRateFormInput,
  ApplySettleFxRateFormContractType,
  ApplySettleFxRateFormValues,
  ApplySettleFxRateFormOutput
} from './apply-settlement-fx-rate.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import { t } from '@oms/codegen/translations';

export const applySettleFxRateFormBuilder = FormBuilder.create<
  ApplySettleFxRateFormInput,
  ApplySettleFxRateFormOutput
>('apply-settle-fx-rate-form')
  .contract<ApplySettleFxRateFormContractType>(applySettleFxRateFormContract)
  .type('applySettleFxRate')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, { container }) {
        if (!input.orderId) {
          throw new Error('Cannot apply settlement FX rate without investor order ID');
        }
        if (!input.executionId) {
          throw new Error('Cannot apply settlement FX rate without execution ID');
        }

        const ioService = container.resolve(InvestorOrdersService);
        const service = container.resolve(InvestorOrderExecutionsService);
        const orderInfo = await ioService.getById(input.orderId);
        if (!orderInfo.isSuccess()) {
          throw new Error('Failed to retrieve investor order');
        }
        const order = orderInfo.value.data.visibleInvestorOrder;
        if (!order) {
          throw new Error('Investor order not found');
        }
        const executionInfo = await service.getById(input.executionId);
        if (!executionInfo.isSuccess() || !executionInfo.value.data.execution) {
          throw new Error('Execution not found');
        }

        const settleFxRate = executionInfo.value.data.execution.settleFxRate;

        const formValues: ApplySettleFxRateFormValues = {
          orderId: input.orderId,
          executionId: input.executionId,
          currenciesData: {
            gridProps: {
              rows: 1,
              columns: 2,
              gap: 4,
              width: '100%'
            },
            items: [
              {
                label: `${t('app.orders.settlementFXRateDialog.tradeCurrency')}:`,
                component: {
                  type: 'Text',
                  value: order.tradeCurrency ?? ''
                }
              },
              {
                label: `${t('app.orders.settlementFXRateDialog.settlementCurrency')}:`,
                component: {
                  type: 'Text',
                  value: order.settleCurrency ?? ''
                }
              }
            ],
            labelMargin: 6,
            sx: {
              paddingTop: 4,
              paddingBottom: 4,
              marginBottom: 4,
              backgroundColor: 'layout.level2',
              padding: 4
            }
          },
          settleFxRate: settleFxRate ?? undefined
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.orderId || !formValues.executionId) {
          return;
        }

        const settleFxRate = Number(formValues.settleFxRate);

        const output: ApplySettleFxRateFormOutput = {
          executionId: formValues.executionId,
          settleFxRate: isNaN(settleFxRate) ? null : settleFxRate
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(InvestorOrderExecutionsService);

        return await service.applySettleFxRate(event.payload.output);
      }
    }
  });

export type ApplySettleFxRateFormBuilderType = typeof applySettleFxRateFormBuilder;

export default applySettleFxRateFormBuilder;
