import type { Column, GridApi } from '@ag-grid-community/core';
import { inject, Lifecycle, scoped, Disposable } from 'tsyringe';
import { GridIdService } from './grid-id.service';
import { getCanvasFont, getTextWidth } from '@oms/frontend-foundation';

@scoped(Lifecycle.ContainerScoped)
export class GridColumnHeadersService implements Disposable {
  private _gridIdService: GridIdService;
  private _displayedColumns: Set<string>;
  private _resizeObserver: ResizeObserver;
  private _gridApi: GridApi | undefined;

  constructor(@inject(GridIdService) gridIdService: GridIdService) {
    this._gridIdService = gridIdService;
    this._displayedColumns = new Set();
    this._resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(({ target }) => {
        if (!this._gridApi) {
          return;
        }

        const colId = target.getAttribute('col-id');
        if (!colId) return;

        const colTextEl = target.querySelector<HTMLSpanElement>('.ag-header-cell-text');
        if (!colTextEl) return;

        /**
         * Get the long and short text for the column header from the attributes
         */
        const longText = this.getColHeaderText(colId, this._gridApi);
        const shortText = this.getColHeaderShortText(colId, this._gridApi);

        if (!longText) {
          return;
        }
        /**
         * Calculate the default width of the header with long text
         */
        const font = getCanvasFont(colTextEl);
        const textWidth = getTextWidth(longText, font);
        /**
         * Using the default text widget, calculate if it is ellipsised
         */
        const isShort = this.isEllipsisActive(colTextEl, textWidth);
        const textToUse = shortText && isShort ? shortText : longText;
        const currentText = colTextEl.innerText;

        if (currentText !== textToUse) {
          colTextEl.innerText = textToUse;
        }
      });
    });
  }

  private getColHeaderEl(colId: string | undefined): HTMLSpanElement | null {
    if (!colId) {
      return null;
    }

    return this._gridIdService
      .getGridEl()
      ?.querySelector(`.ag-header-cell[col-id="${colId}"]`) as HTMLSpanElement | null;
  }

  private getColHeaderText(colId: string, api: GridApi): string | null {
    const colDef = api.getColumnDef(colId);
    return colDef?.headerName ?? null;
  }

  private getColHeaderShortText(colId: string, api: GridApi): string | null {
    const colDef = api.getColumnDef(colId);
    return colDef?.headerComponentParams?.shortName ?? null;
  }

  private isEllipsisActive(e: HTMLElement | null, originalW?: number) {
    if (!e) {
      return false;
    }

    const containerWidget = (e.parentElement?.offsetWidth ?? e.offsetWidth) - 24; // 24 represents the sort icon width + padding
    const breakpoint = originalW ?? e.scrollWidth;
    return containerWidget < breakpoint;
  }

  public init(api: GridApi) {
    this._gridApi = api;
  }

  public observeColumns(columns: Column[]) {
    const colsToRemove = Array.from(this._displayedColumns.keys()).reduce(
      (set, curr) => {
        set[curr] = true;
        return set;
      },
      {} as Record<string, true>
    );

    columns.forEach((c) => {
      const colId = c.getColId();
      const colEl = this.getColHeaderEl(colId);

      if (colEl) {
        this._resizeObserver.observe(colEl);
        this._displayedColumns.add(colId);
      }

      delete colsToRemove[colId];
    });

    Object.keys(colsToRemove).forEach((col) => {
      const colEl = this.getColHeaderEl(col);
      colEl && this._resizeObserver.unobserve(colEl);
      this._displayedColumns.delete(col);
    });
  }

  public dispose() {
    this._resizeObserver.disconnect();
  }
}
