import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type {
  AnyRecord,
  FieldContract,
  IAdvancedSelectField,
  IAdvancedMultiSelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';

export const createAccountField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField>
) => {
  return fc.field(key, 'advanced-select').advancedSelectOptions({
    autoSizeWidthToTrigger: false,
    width: 200,
    label: 'Account',
    query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_ACCOUNTS_WATCH_QUERY,
    ...overrides
  });
};

export const createMultiAccountField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedMultiSelectField>
) => {
  return fc.field(key, 'advanced-multi-select').advancedSelectMultiOptions({
    autoSizeWidthToTrigger: false,
    width: 200,
    label: 'Account',
    query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_ACCOUNTS_WATCH_QUERY,
    ...overrides
  });
};
