import type { InstrumentDetailsFragment, InstrumentDetailsSimpleFragment } from '@oms/generated/frontend';
import { comboBoxItemFrom } from '@oms/shared-frontend/ui-design-system';
import type { AnyInstrumentInput, SimpleInstrument } from './instrument.types';

export const isInstrumentDetailsSimpleFragment = (
  instrument: AnyInstrumentInput
): instrument is InstrumentDetailsSimpleFragment =>
  (instrument as InstrumentDetailsFragment).__typename === 'InstrumentDetails';

export const isInstrumentDetailsFragment = (
  instrument: AnyInstrumentInput
): instrument is InstrumentDetailsFragment => {
  if (!isInstrumentDetailsSimpleFragment(instrument)) return false;
  const { active, isCustomInstrument, firmMarketMaker } = instrument as InstrumentDetailsFragment;
  return (
    typeof active === 'boolean' &&
    typeof isCustomInstrument === 'boolean' &&
    typeof firmMarketMaker === 'boolean'
  );
};

export const makeInstrumentsCombobox = <T extends InstrumentDetailsSimpleFragment>(instrumentDetails: T) =>
  comboBoxItemFrom.record(instrumentDetails, {
    id: ({ id }) => id,
    label: ({ mappings, longName }) => mappings?.displayCode ?? longName,
    sublabel: ({ longName }) => longName
  });

export const convertInstrument = {
  simple: ({
    id,
    active = true,
    name: longName,
    displayCode,
    firmMarketMaker = false,
    isCustomInstrument = false
  }: SimpleInstrument) => ({
    toDetails: (): InstrumentDetailsFragment => ({
      __typename: 'InstrumentDetails',
      id,
      active,
      longName,
      mappings: {
        displayCode
      },
      firmMarketMaker,
      isCustomInstrument
    }),
    toSimpleDetails: (): InstrumentDetailsSimpleFragment => ({
      __typename: 'InstrumentDetails',
      id,
      active,
      longName,
      firmMarketMaker,
      mappings: {
        displayCode
      }
    })
  }),
  details: ({
    id,
    active,
    longName,
    mappings: { displayCode },
    firmMarketMaker,
    isCustomInstrument
  }: InstrumentDetailsFragment) => ({
    toSimpleDetails: (): InstrumentDetailsSimpleFragment => ({
      __typename: 'InstrumentDetails',
      id,
      active,
      longName,
      firmMarketMaker,
      mappings: {
        displayCode
      }
    }),
    toSimple: (): SimpleInstrument => ({
      id,
      active,
      name: longName,
      displayCode: displayCode || '',
      firmMarketMaker,
      isCustomInstrument
    })
  }),
  simpleDetails: ({ id, longName, mappings: { displayCode } }: InstrumentDetailsSimpleFragment) => ({
    toDetails: (): InstrumentDetailsFragment => ({
      __typename: 'InstrumentDetails',
      id,
      active: true,
      longName,
      mappings: {
        displayCode
      },
      firmMarketMaker: false,
      isCustomInstrument: false
    }),
    toSimple: (): SimpleInstrument => ({
      id,
      name: longName,
      displayCode: displayCode || ''
    })
  })
};
