import {
  ACTOR_STATE,
  Actor,
  CreateActorDefinition,
  ActorSchema,
  Unsubscribe,
  isActorTypeWindowRoot,
  UnwrapContext,
  ActorStateType,
  AnyActorSchema,
  isActorNameWindowRoot
} from '@valstro/workspace';
import { useEffect, useMemo, useState } from 'react';
import { useWorkspace } from './react-workspace.context';
import { useCurrentActor, useCurrentActorHierachy } from './react-actor.context';

type QueryState<TData> = {
  data?: TData | null;
  isFetching: boolean;
  error?: Error;
};

/**
 * Fetches an actor by ID or simply returns the actor if it is already available
 * Note: This is a helper hook to make it easier to fetch actors (and their state) from the workspace
 * Because the actors live in a different thread, they are not available immediately
 *
 * @param actorIdOrActor - Actor ID or Actor
 * @returns QueryState<Actor<T>>
 */
export function useActorQuery<T extends AnyActorSchema>(actorIdOrActor: string | Actor<T>) {
  const [state, setState] = useState<QueryState<Actor<T>>>({
    isFetching: true
  });

  useEffect(() => {
    async function getActor() {
      return typeof actorIdOrActor === 'string' ? Actor.get<T>(actorIdOrActor) : actorIdOrActor;
    }
    setState((prev) => ({
      ...prev,
      isFetching: true
    }));
    getActor()
      .then((proxy) => {
        setState(() => ({
          error: undefined,
          data: proxy,
          isFetching: false
        }));
      })
      .catch((e: Error) => {
        console.error(e);
        setState((prev) => ({
          ...prev,
          data: null,
          isFetching: false,
          error: e
        }));
      });
  }, [actorIdOrActor]);

  return state;
}

/**
 * Use an actor by ID using a lazy proxy
 * Note: This is more performant than useActorQuery because it assumes the actor is already available
 * But it will throw unresponsive errors if actor methods are run after a certain amount of time
 *
 * @param actorId - Actor ID
 * @returns Actor<T>
 */
export function useActorLazy<T extends AnyActorSchema>(actorId: string) {
  return useMemo(() => Actor.getSyncLazy<T>(actorId), [actorId]);
}

/**
 * Use the actor context by subscribing to context changes
 *
 * @param actor - Actor<T>
 * @returns UnwrapContext<T>
 */
export function useActorContext<T extends AnyActorSchema>(actor: Actor<T>) {
  const [context, setContext] = useState<UnwrapContext<T>>(
    (actor.initialDefinition?.context as UnwrapContext<T>) || ({} as UnwrapContext<T>)
  );

  useEffect(() => actor.listen('context', setContext, { emitInitial: true }), [actor]);

  return context;
}

/**
 * Use the actor context (if actor exists) by subscribing to context changes
 *
 * @param actor - Actor<T> | null
 * @returns UnwrapContext<T> | null
 */
export function useMaybeActorContext<T extends AnyActorSchema>(actor?: Actor<T> | null) {
  const [context, setContext] = useState<UnwrapContext<T> | null>(
    (actor?.initialDefinition?.context as UnwrapContext<T>) || null
  );

  useEffect(() => {
    if (!actor) {
      return;
    }
    return actor.listen('context', setContext, { emitInitial: true });
  }, [actor]);

  return context;
}

/**
 * Use the current actor context by subscribing to context changes
 *
 * @returns UnwrapContext<T>
 */
export function useCurrentActorContext<T extends AnyActorSchema>() {
  const actor = useCurrentActor<T>();
  return useActorContext(actor);
}

/**
 * Use the actor state by subscribing to state changes
 *
 * @param actor - Actor<T>
 * @returns [ActorStateType, string | null | undefined] - [state, failedMessage]
 */
export function useActorState<T extends AnyActorSchema>(actor: Actor<T>) {
  const workspace = useWorkspace();
  const [state, setState] = useState<ActorStateType>(actor.initialDefinition.state);
  const [failedMessage, setFailedMessage] = useState<string | null | undefined>(null);
  useEffect(() => {
    let unsubState: Unsubscribe | undefined = undefined;
    let unsubFailedMessage: Unsubscribe | undefined = undefined;
    let unsubDestroyed: Unsubscribe | undefined = undefined;

    unsubState = actor.listen(
      'state',
      (state) => {
        if (state === ACTOR_STATE.DESTROYED) {
          unsubState?.();
          unsubDestroyed?.();
          unsubFailedMessage?.();
        }
        setState(state);
      },
      {
        emitInitial: true
      }
    );

    unsubFailedMessage = actor.listen(
      'failedMessage',
      (failedMessage) => {
        setFailedMessage(failedMessage);
      },
      {
        emitInitial: true
      }
    );

    unsubDestroyed = workspace.listen('actor/destroyed', (actorDef) => {
      if (actorDef.id === actor.id) {
        unsubState?.();
        unsubDestroyed?.();
        unsubFailedMessage?.();
        setState(ACTOR_STATE.DESTROYED);
      }
    });
    return () => {
      unsubState?.();
      unsubFailedMessage?.();
      unsubDestroyed?.();
    };
  }, [actor, workspace]);

  return [state, failedMessage] as const;
}

/**
 * Use the current actor state by subscribing to state changes
 *
 * @returns [ActorStateType, string | null | undefined] - [state, failedMessage]
 */
export function useCurrentActorState() {
  const actor = useCurrentActor();
  return useActorState(actor);
}

export type UseActorChildrenOptions = {
  includeWindowRoots?: boolean;
};

/**
 * Use the actor children by subscribing to children changes
 * Note: This will not include window roots by default
 * Therefore, it's usually used to render the actor children (that are not rootable) in the UI
 *
 * @param actor - Actor<T>
 * @param options - { includeWindowRoots: false }
 * @returns CreateActorDefinition[]
 */
export function useActorChildren<T extends AnyActorSchema>(
  actor: Actor<T>,
  options: UseActorChildrenOptions = { includeWindowRoots: false }
) {
  const workspace = useWorkspace();
  const [children, setChildren] = useState<CreateActorDefinition[]>([]);
  useEffect(
    () =>
      actor.listen(
        'children',
        (children) => {
          if (options.includeWindowRoots) {
            setChildren(children);
            return;
          }

          setChildren(
            children.filter((child) =>
              child.name
                ? !isActorNameWindowRoot(workspace, child.name)
                : !isActorTypeWindowRoot(workspace, child.type, actor.initialDefinition)
            )
          );
        },
        {
          emitInitial: true
        }
      ),
    [actor, workspace, options.includeWindowRoots]
  );

  return children;
}

/**
 * Use the current actor children by subscribing to children changes
 * Note: This will not include window roots by default
 * Therefore, it's usually used to render the actor children (that are not rootable) in the UI
 *
 * @param actor - Actor<T>
 * @param options - { includeWindowRoots: false }
 * @returns CreateActorDefinition[]
 */
export function useCurrentActorChildren<T extends ActorSchema = ActorSchema>(
  options: UseActorChildrenOptions = { includeWindowRoots: false }
) {
  const actor = useCurrentActor<T>();
  return useActorChildren<T>(actor, options);
}

/**
 * Use the actor schema by name
 * Note: This is a helper hook to make it easier to find actor schemas by name
 * from the workspace
 *
 * @param name - Actor name
 * @returns T | null
 */
export function useActorSchemaByName<T extends AnyActorSchema>(name: string): T | null {
  const workspace = useWorkspace();
  return useMemo(() => workspace?.getActorRegistry()?.getActorByName<T>?.(name) || null, [workspace, name]);
}

/**
 * Use the actor schema meta
 * Note: This is a helper hook to get the actor schema meta
 * from the workspace which is a key-value object of meta data
 * that can be used to store additional information about the actor
 *
 * @param actor - Actor<T>
 * @returns T['meta']
 */
export function useActorSchemaMeta<T extends AnyActorSchema>(actor: Actor<T>): T['meta'] {
  const workspace = useWorkspace();
  return useMemo(
    () => workspace?.getActorRegistry()?.getActorByName<T>?.(actor.name)?.meta,
    [workspace, actor]
  );
}

/**
 * Use the actor class names
 * Note: This is a helper hook to get universal actor class names for consistent styling
 *
 * @param actor - Actor<T>
 * @param state - ActorStateType
 * @returns object - { wrapper, draggableContainer, box, title, message, toolbar, toolbarTitlebar, toolbarTitlebarTitle, toolbarActions, getTitlebarAction, content }
 */
export function useActorClassNames<T extends AnyActorSchema>(actor: Actor<T>, state: ActorStateType) {
  const actorId = actor.id;
  const actorName = actor.name;
  const actorType = actor.type;
  const isDiff = actorName !== actorType;
  const actorWrapperClass = `actor actor--${state}`;
  const nameWrapperClass = `${actorName} ${actorName}--${state}`;
  const typeWrapperClass = `${actorType} ${actorType}--${state}`;

  return {
    wrapper: `${actorWrapperClass} ${typeWrapperClass}${isDiff ? ` ${nameWrapperClass}` : ''}`,
    draggableContainer: `draggable-container`,
    box: `actor__box ${actorType}__box${isDiff ? ` ${actorName}__box` : ``}`,
    title: `actor__title ${actorType}__title${isDiff ? ` ${actorName}__title` : ``}`,
    message: `actor__message ${actorType}__message${isDiff ? ` ${actorName}__message` : ``}`,
    toolbar: `actor__toolbar ${actorType}__toolbar${isDiff ? ` ${actorName}__toolbar` : ``}`,
    toolbarTitlebar: `actor__toolbar__titlebar ${actorType}__toolbar__titlebar${
      isDiff ? ` ${actorName}__toolbar__titlebar` : ``
    } drag-handle actor-drag-handle-${actorId}`,
    toolbarTitlebarTitle: `actor__toolbar__titlebar__title ${actorType}__toolbar__titlebar__title${
      isDiff ? ` ${actorName}__toolbar__titlebar__title` : ``
    }`,
    toolbarActions: `actor__toolbar__actions ${actorType}__toolbar__actions${
      isDiff ? ` ${actorName}__toolbar__actions` : ``
    }`,
    getTitlebarAction: (actionName: string) =>
      `actor__toolbar__action actor__toolbar__action--${actionName} ${actorType}__toolbar__action ${actorType}__toolbar__action--${actionName}${
        isDiff ? ` ${actorName}__toolbar__action ${actorName}__toolbar__action--${actionName}` : ``
      }`,
    content: `actor__content ${actorType}__content${isDiff ? ` ${actorName}__content` : ``}`,
    isFocused: `actor--focused`
  };
}

/**
 * Use the current actor class names
 * Note: This is a helper hook to get universal actor class names for consistent styling
 *
 * @param actor - Actor<T>
 * @param state - ActorStateType
 * @returns object - { wrapper, draggableContainer, box, title, message, toolbar, toolbarTitlebar, toolbarTitlebarTitle, toolbarActions, getTitlebarAction, content }
 */
export function useCurrentActorClassNames<T extends AnyActorSchema = AnyActorSchema>(state: ActorStateType) {
  const actor = useCurrentActor<T>();
  return useActorClassNames<T>(actor, state);
}

/**
 * Use the leader actor of the application (the first actor in the hierarchy)
 * Useful for running application-wide operations
 *
 * @returns Actor<T>
 */
export function useLeaderActor<T extends AnyActorSchema = AnyActorSchema>() {
  const workspace = useWorkspace();
  return workspace.getLeaderActor() as Actor<T>;
}

/**
 * Use the root actor of the current window (the first actor in the windows hierarchy)
 * Useful for running window operations for the current window/process
 *
 * @returns Actor<T>
 */
export function useRootActor<T extends AnyActorSchema = AnyActorSchema>() {
  const hierarchy = useCurrentActorHierachy();
  return hierarchy[hierarchy.length - 1] as Actor<T>;
}

/**
 * Use the nearest actor by type
 * Useful for finding the nearest actor by type in the hierarchy of actors
 *
 * @param type - Actor type
 * @returns Actor<T> | null
 */
export function useClosestActorByType<T extends AnyActorSchema = AnyActorSchema>(
  type: string | Array<string>
): Actor<T> | null {
  const hierarchy = useCurrentActorHierachy();
  const actor = useMemo(
    () =>
      hierarchy.find((actor) =>
        Array.isArray(type) ? type.includes(actor.type) : actor.type === type
      ) as Actor<T> | null,
    [hierarchy, type]
  );
  return actor;
}
