import { filter, Observable, Subject } from 'rxjs';
import { Lifecycle, scoped } from 'tsyringe';
import uniqBy from 'lodash/uniqBy';
import type { AnyRecord } from '@oms/frontend-foundation';
import { ComponentEvent, ComponentId, ComponentLocation, VComponent } from '../models/v.component.model';

export type Components<TProps extends AnyRecord = AnyRecord> = Record<
  ComponentLocation,
  VComponent<TProps>[]
>;

@scoped(Lifecycle.ContainerScoped)
export class ComponentService {
  private subject$ = new Subject<ComponentEvent>();

  private _components: Components = {
    [ComponentLocation.StaticToolbar]: [],
    [ComponentLocation.InfoToolbar]: [],
    [ComponentLocation.UserToolbar]: [],
    [ComponentLocation.Selection]: [],
    [ComponentLocation.LeftVerticalToolbar]: [],
    [ComponentLocation.RightVerticalToolbar]: [],
    [ComponentLocation.SuggestionToolbar]: []
  };

  public observe(location: ComponentLocation): Observable<ComponentEvent> {
    return this.subject$.pipe(filter((e) => e.location === location));
  }

  public getComponents(location: ComponentLocation): VComponent[] {
    return this._components[location];
  }

  public prependTo<TProps extends AnyRecord = AnyRecord>(
    location: ComponentLocation,
    components: VComponent<TProps>[]
  ) {
    this._components[location] = uniqBy([...components, ...this._components[location]], 'id');
    this._renderComponents(location);
  }

  public appendTo<TProps extends AnyRecord = AnyRecord>(
    location: ComponentLocation,
    components: VComponent<TProps>[]
  ) {
    this._components[location] = uniqBy([...this._components[location], ...components], 'id');
    this._renderComponents(location);
  }

  public updateIn<TProps extends AnyRecord = AnyRecord>(
    location: ComponentLocation,
    updatedComponents: VComponent<TProps>[]
  ) {
    this._components[location] = this._components[location].map((oldComp) => {
      const newComp = updatedComponents.find((updatedComp) => updatedComp.id === oldComp.id);
      return newComp || oldComp;
    });
    this._renderComponents(location);
  }

  public upsertIn<TProps extends AnyRecord = AnyRecord>(
    container: ComponentLocation,
    upsertComponents: VComponent<TProps>[]
  ) {
    this.appendTo(container, upsertComponents);
    this.updateIn(container, upsertComponents);
  }

  public removeFrom(location: ComponentLocation, componentIds: ComponentId[]) {
    this._components[location] = this._components[location].filter(
      (compItem) => compItem.id && !componentIds.includes(compItem.id)
    );
    this._renderComponents(location);
  }

  public has(location: ComponentLocation, id: string): boolean {
    return this._components[location].some((c: VComponent<AnyRecord>) => id === String(c.id));
  }

  // This method exists only to support the old way of updating disabled/visible
  // on CRUD actions. Currently they are not updated via subscription with notify
  // so we need to rerender them when the grid selection changes.
  public rerenderLocation(location: ComponentLocation) {
    this._renderComponents(location);
  }

  private _renderComponents(location: ComponentLocation): void {
    this.subject$.next({ components: this._components[location], location });
  }
}
