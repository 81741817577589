import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('CUSTOM_NOTIFICATION_ENTRY_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      entryType: z.string().optional(),
      notificationId: z.string().optional()
    })
    .describe('Custom notification entry form input schema')
});
