import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { openViewTradingOrder } from '@app/common/types/orders/trading-order/open.view.trading-order';
import { t } from '@oms/codegen/translations';

type ViewTradingOrderActionArgs = {
  id: string;
  side: string;
  orderType: string;
  limitPrice: string;
  totalQuantity: number;
  instrumentDisplayCode: string;
};

function isVisible(data: ViewTradingOrderActionArgs[]) {
  return data.length === 1;
}

export const viewTradingOrderOnChange = async <T extends ViewTradingOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewTradingOrder({
      windowId: getLeaderOrTabId(ctx.appContainer),
      dialogInfo: {
        id: data[0].id,
        side: data[0].side,
        orderType: data[0].orderType,
        price: data[0].limitPrice,
        quantity: data[0].totalQuantity,
        symbol: data[0].instrumentDisplayCode
      }
    });
  }
};

export const viewTradingOrderAction =
  <T extends ViewTradingOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('view_trading_order')
      .toolbar((builder) =>
        builder
          .component('action-button')
          .id('view_trading_order_button')
          .location('UserToolbar')
          .props({
            isDisabled: true,
            content: t('app.commands.common.viewOrder.button')
          })
      )
      .customMenu((m) =>
        m
          .name(t('app.commands.common.viewOrder.contextMenu'))
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
          .priority(30)
      )
      .onChange<ActionComponentConfig<T>>(viewTradingOrderOnChange);
