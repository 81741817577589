import { BehaviorSubject } from 'rxjs';
import type { AllActionContextEventsRecord, AllActionContextEvents } from './types';
import type { ActionCommands } from '../commands/command.registry.types';

export class ButtonConfigSubject extends BehaviorSubject<AllActionContextEventsRecord> {
  constructor(startingState?: AllActionContextEventsRecord) {
    const defaultState: AllActionContextEventsRecord = {
      accept_investor_order: undefined,
      cancel_investor_order: undefined,
      create_investor_order: undefined,
      create_unbound_to: undefined,
      done_for_day: undefined,
      modify_investor_order: undefined,
      montage_bump_price: undefined,
      montage_target_trading_order: undefined,
      reject_investor_order: undefined,
      route_order: undefined,
      view_investor_order: undefined
    };
    super(startingState || defaultState);
  }

  public forCommand(event: AllActionContextEvents): void {
    const currentState = this.getValue();
    const eventState = currentState[event.commandId];

    this.next({
      ...currentState,
      [event.commandId]: {
        ...eventState,
        ...event.payload
      }
    });
  }

  public forCommands(...events: AllActionContextEvents[]): void {
    events.forEach((e) => this.forCommand(e));
  }

  public getCommand<TCommand extends ActionCommands>(
    commandId: TCommand
  ): AllActionContextEventsRecord[TCommand] {
    const currentState = this.getValue();
    return currentState[commandId];
  }
}
