import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import type { MappedInvestorOrderRow } from '@oms/generated/frontend';

const executionViewInvestorOrdersDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const executionViewInvestorOrdersColumns: ColDefMap<MappedInvestorOrderRow> = {
  id: { hide: true },
  investorOrderId: { extends: 'generic.text' },
  investorAccountName: {
    extends: 'generic.text'
  },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  limitPrice: { extends: 'orders.limitPrice' },
  mappedQuantity: { extends: 'generic.numeric' },
  executedQuantity: {
    extends: 'generic.numeric'
  },
  leavesQuantity: { extends: 'generic.numeric' },
  averagePrice: {
    extends: 'generic.numeric'
  }
};

const executionViewInvestorOrdersColumnLibrary: TableServerColumnLibrary<MappedInvestorOrderRow> = {
  defaultColumn: executionViewInvestorOrdersDefaultColumn,
  columns: executionViewInvestorOrdersColumns
};

export const buildInvestorOrderViewTradingOrdersColumnDefs = () =>
  buildColumnDefs(executionViewInvestorOrdersColumnLibrary, 'executionViewInvestorOrders');
