import type { TableServerJoinedTradingOrder } from '@oms/generated/frontend';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { GridTrackingToggleButton } from '@app/common/grids/grid-tracking/grid-tracking-toggle-button.component';
import { updateGridContext } from '@app/common/grids/grid-api/grid-context.util';
import { GridTrackingConsumerContext } from '@app/common/grids/grid-tracking/grid-tracking.types';

export const ORDER_TRACKING_ACTION_NAME = 'order_follow_target';

export const ORDER_TRACKING_TOOLBAR_ID = 'order_follow_target_button';

// Action --------------------------------------------------------------------- /

/**
 * Factory function that creates the Order Tracking action definition.
 * This action adds a button to the horizontal toolbar that allows users to toggle
 * order following functionality.
 *
 * @param builder - The action definition builder
 * @returns A configured action definition for order following
 */
export const orderTrackingAction: ActionDefFactory<TableServerJoinedTradingOrder> = (builder) =>
  builder
    .name(ORDER_TRACKING_ACTION_NAME)
    .toolbar((toolbar) =>
      toolbar
        .id(ORDER_TRACKING_TOOLBAR_ID)
        .location('StaticToolbar')
        .customComponent(GridTrackingToggleButton)
        .props({
          showSwitch: false,
          turnOnTooltip: t('app.commands.follow.targetButton.investorOrder.turnOn'),
          turnOffTooltip: t('app.commands.follow.targetButton.investorOrder.turnOff')
        })
    )
    .onChange(({ api, lifecycle, notify }) => {
      if (lifecycle !== 'change') return;
      notify({ isLoading: true });
      updateGridContext<GridTrackingConsumerContext>(api, (ctx) => {
        if (ctx.isTracking$) {
          const isTracking = ctx.isTracking$.getValue();
          ctx.isTracking$.next(!isTracking);
        }
        return ctx;
      });
      notify({ isLoading: false });
    });
