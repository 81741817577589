import { IconButton } from '@oms/shared-frontend/ui-design-system';
import type { FC, MouseEventHandler } from 'react';
import React, { useCallback } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';
import type { AnyRecord } from '@oms/frontend-foundation';
import { omit } from 'lodash';
import { IconButtonProps } from '@oms/shared-frontend/ui-design-system';
import { vars } from '@oms/shared-frontend/ui-design-system/css';

export const ActionIconButton: FC<ActionComponentProps<AnyRecord>> = (props) => {
  const actionProps = useActionProps<any, ActionComponentProps<AnyRecord>>(props);

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    actionProps.onChange({
      state: actionProps,
      actionId: actionProps.id,
      lifecycle: 'change'
    });
  }, [actionProps]);

  if (actionProps.isVisible === false) {
    return null;
  }

  const isDisabled = actionProps.isDisabled || actionProps.isLoading;

  const styles = {
    background: 'transparent',
    color: vars.colors.icons.active,
    marginRight: vars.space[2],
    ...actionProps.style
  };

  const style: React.CSSProperties | undefined = isDisabled
    ? omit(styles, 'backgroundColor', 'color')
    : styles;

  return (
    <IconButton
      {...(omit(
        actionProps,
        'onChange',
        'content',
        'actionType',
        'isVisible',
        'instanceId',
        'notify',
        'rowData',
        'config'
      ) as unknown as IconButtonProps)}
      style={style}
      onClick={onClick}
      width={16}
      height={16}
    />
  );
};
