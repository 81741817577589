import type { DependencyContainer } from 'tsyringe';
import { isTauri } from './workspace.constants';
import type { PlatformInfo } from '@valstro/workspace';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { ProcessState } from '@app/data-access/memory/process-id.subject';

export const getLeaderOrTabId = (containerOrWorkspace: DependencyContainer | AppWorkspace): string => {
  const container =
    containerOrWorkspace instanceof AppWorkspace
      ? containerOrWorkspace.getMeta()?.container
      : containerOrWorkspace;
  if (!container) {
    throw new Error('Container not found');
  }
  const workspace = container.resolve(AppWorkspace);
  const processService = container.resolve(ProcessState);
  const processId = isTauri()
    ? workspace.getLeaderProcessId()
    : processService.processId || workspace.getLeaderProcessId();
  return processId;
};

export async function getPlatformInfo(workspace: AppWorkspace): Promise<PlatformInfo | null> {
  try {
    const info = await workspace.getPlatformInfo();
    return info ?? null;
  } catch (error) {
    console.error('Error getting platform info', error);
    return null;
  }
}
