import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { TradesSubscriptionService } from '@app/data-access/services/trading/trades/trades.subscriptions.service';
import type { TradeRow } from '@oms/generated/frontend';

export type UpdatedTrade = UpdatedData<TradeRow>;

export const withUpdatedTrade = (Component: ComponentType<UpdatedTrade>, props: UpdatedDataProps = {}) => {
  return withUpdatedData<TradeRow>(Component, TradesSubscriptionService, props);
};
