import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import type { MappedTradingOrderRow, TradingOrderRow } from '@oms/generated/frontend';

const tradeViewTradingOrdersDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const tradeViewTradingOrdersColumns: ColDefMap<MappedTradingOrderRow> = {
  id: { extends: 'orders.id', hide: true },
  limitPrice: { extends: 'orders.limitPrice' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  totalQuantity: { extends: 'orders.quantity' },
  capacity: { extends: 'orders.tradingOrderCapacity' },
  mappedQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity' },
  venue: { extends: 'generic.text' },
  averagePrice: { extends: 'orders.price' },
  ownerName: { extends: 'orders.owner' },
  createdTimestamp: { extends: 'generic.dateTime', sort: 'desc' }
};

const tradeViewTradingOrdersColumnLibrary: TableServerColumnLibrary<TradingOrderRow> = {
  defaultColumn: tradeViewTradingOrdersDefaultColumn,
  columns: tradeViewTradingOrdersColumns
};

export const buildtradeViewTradingOrdersColumnDefs = () =>
  buildColumnDefs(tradeViewTradingOrdersColumnLibrary, 'tradeViewTradingOrders');
