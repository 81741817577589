import { useMemo, useCallback, FC } from 'react';
import type { IContextMenu } from '../in-window.context-menu.model';
import type { ContextMenuService } from '../in-window.context-menu.service';
import { PrimaryButton } from './primary-button.component';
import type { SimpleContextMenuItem } from '../in-window.context-menu.model';
import { PrimaryButtonGroup, PrimaryButtonRowWrapper } from './primary-button-row.components.internal';

// ===============================================================================
// 📝 Interfaces 📝
// ===============================================================================

/**
 * Props for the PrimaryButtonStack component
 * @interface PrimaryButtonStackProps
 * @property {SimpleContextMenuItem[]} items - Array of menu items to display as primary buttons
 * @property {IContextMenu | ContextMenuService} state - Context menu state or service
 * @property {number} [maxLength=3] - Maximum number of primary buttons to display
 * @property {Function} [shouldRender] - Optional callback to determine if buttons should render
 */
interface PrimaryButtonStackProps {
  items: SimpleContextMenuItem[];
  state: IContextMenu | ContextMenuService;
  maxLength?: number;
  shouldRender?: (items: SimpleContextMenuItem[]) => boolean;
}

// ===============================================================================
// 🎨 Component 🎨
// ===============================================================================

/**
 * Renders a stack of primary buttons for a context menu
 * @component
 * @param {PrimaryButtonStackProps} props - Component props
 * @returns {JSX.Element | null} Primary button stack or null if shouldn't render
 */
export const PrimaryButtonStack: FC<PrimaryButtonStackProps> = ({
  items,
  state,
  maxLength = 3,
  shouldRender: customShouldRender
}) => {
  // ===============================================================================
  // 🎯 Callbacks 🎯
  // ===============================================================================

  const shouldRender = useCallback((primary: SimpleContextMenuItem[]) => {
    if (customShouldRender) {
      return customShouldRender(primary);
    }
    return primary.length > 0;
  }, [customShouldRender]);

  if (!shouldRender(items)) {
    return null;
  }

  // ===============================================================================
  // 💾 Memoized Values 💾
  // ===============================================================================

  const [activeButtons, fillerButtons] = useMemo(() => {
    // Create active buttons from items
    const active = items.slice(0, maxLength).map((item) => (
      <PrimaryButton
        key={item.id}
        item={item}
        state={state}
      />
    ));

    // Create empty buttons to fill the row to max length
    const filler = Array(Math.max(0, maxLength - active.length)).fill(null).map((_, index) => (
      <PrimaryButton
        key={`empty-${index + 1}`}
        state={state}
        index={index}
      />
    ));

    return [active, filler];
  }, [items, maxLength, state]);

  // ===============================================================================
  // 🎨 Render 🎨
  // ===============================================================================

  return (
    <PrimaryButtonRowWrapper>
      <PrimaryButtonGroup type='active'>
        {activeButtons}
      </PrimaryButtonGroup>
      <PrimaryButtonGroup type='filler'>
        {fillerButtons}
      </PrimaryButtonGroup>
    </PrimaryButtonRowWrapper>
  );
};
