import { firstValueFrom, defer, of, retry, delayWhen, catchError, timer } from 'rxjs';
import * as Sentry from '@sentry/react';
import { Logger } from '@oms/shared/util';

const getRetryIntervalMs = (attempts: number) => Math.min(Math.pow(2, attempts - 1) * 1000, 30_000);

const logger = Logger.named('WS Retry');

export const retrySocketConnection = (attempts: number) => {
  let wasRetried = false;

  logger.debug('Retrying socket connection', { attempts });

  return firstValueFrom(
    defer(() => of(undefined as void)).pipe(
      retry({
        count: 4,
        delay: (err, count) => {
          const interval = getRetryIntervalMs(count);
          logger.debug('Retrying deferred observable', { count, err, interval });
          wasRetried = true;
          return timer(interval);
        },
        resetOnSuccess: true
      }),
      catchError((err, _caught) => {
        logger.error('Defer observable failed', { err });
        Sentry.captureException(err, (scope) => {
          scope.setTransactionName('Socket Connection Error');
          return scope;
        });
        return of(undefined as void);
      }),
      delayWhen(() => {
        const interval = wasRetried ? 0 : getRetryIntervalMs(attempts);
        logger.debug('Delaying WS connect?', { wasRetried, interval });
        return timer(wasRetried ? 0 : interval);
      })
    )
  );
};
