//@ts-nocheck
import type { INumericInputOptions } from '../contracts/config-types';
import { isFinite, isString, isNumber } from 'lodash';
import { editString } from './shared-utils';
import {
  type FormatterFunctionParams,
  type NumericFormatType,
  formatNumeric,
  getNumberOfDecimalPlaces
} from '@oms/shared/util';
import { NumericInputRange } from '../contracts/enum-types';

const getDecimalIndex = (val: string, decimal: string): number =>
  val.indexOf(decimal) > -1 ? val.indexOf(decimal) : val.length;

export const formatThousands = (val: string, options: INumericInputOptions): string => {
  const startIndex =
    options.decimal && val.indexOf(options.decimal) > -1 ? val.indexOf(options.decimal) - 1 : val.length - 1;
  const endIndex = val[0] === '-' ? 1 : 0;

  // i must be greater than zero because number cannot start with comma
  let i = startIndex;
  let j = 1;
  while (i > endIndex) {
    // Every 3 characters, add a comma
    if (j % 3 === 0) {
      val = editString(val, options.thousands || '', i);
    }
    i--;
    j++;
  }

  return val;
};

export const partialFormat = (
  val: string,
  options: INumericInputOptions,
  trimTrailingZeros = true
): string => {
  val = val.replace(new RegExp(`[${options.thousands}]`, 'g'), '');
  if (trimTrailingZeros) {
    val = removeLeadingZeros(val, options);
  }
  val = removeExtraDecimals(val, options);
  val = formatThousands(val, options);

  return val;
};

export const fullFormat = (val: string, options: INumericInputOptions, trimTrailingZeros = true): string => {
  // If value matches a shortcut, don't add delimiter
  const matchingStrShorcut = Object.values(options.shortcuts || {}).find(
    (shortcut) => typeof shortcut === 'string' && shortcut === val
  ) as string;

  if (matchingStrShorcut) {
    return matchingStrShorcut;
  }

  val = partialFormat(val, options, trimTrailingZeros);

  const isRealNumber = formattedToRaw(val, options);
  if (isNaN(isRealNumber)) {
    return '';
  }

  if (val === '') {
    return '';
  }

  // Fully format decimal places
  const decimalIndex = getDecimalIndex(val, options.decimal);
  const sign = val[0] === '-' ? val[0] : '';
  let integerPart = val.slice(sign ? 1 : 0, decimalIndex);
  let decimalPart = val.slice(decimalIndex + 1);

  if (options.fixed) {
    // If there should be some decimals
    if (options.scale > 0) {
      decimalPart =
        decimalPart.length >= options.scale
          ? decimalPart.slice(0, options.scale)
          : decimalPart + Array(options.scale - decimalPart.length + 1).join('0');

      if (!integerPart.length) {
        integerPart = '0';
      }

      return `${sign}${integerPart}${options.decimal}${decimalPart}`;
    } else {
      return `${sign}${integerPart}`;
    }
  } else {
    return val;
  }
};

const removeLeadingZeros = (val: string, options: INumericInputOptions): string => {
  const decimalIndex = getDecimalIndex(val, options.decimal);
  const sign = val[0] === '-' ? val[0] : '';
  let integerPart = val.slice(sign ? 1 : 0, decimalIndex + 1);
  const decimalPart = val.slice(decimalIndex + 1);

  const i = 0;

  while (integerPart[i] === '0' && integerPart[i + 1] !== options.decimal && integerPart.length > 1) {
    integerPart = integerPart.slice(0, i) + integerPart.slice(i + 1);
  }

  return `${sign}${integerPart}${decimalPart}`;
};

const removeExtraDecimals = (val: string, options: INumericInputOptions): string => {
  const decimalIndex = getDecimalIndex(val, options.decimal);
  const integerPart = val.slice(0, decimalIndex + 1);
  const decimalPart = val.slice(decimalIndex + 1).slice(0, options.scale);

  return `${integerPart}${decimalPart}`;
};

export const allowedDecimal = (val: string, options: INumericInputOptions): boolean => {
  const decimalPart = val.slice(getDecimalIndex(val, options.decimal) + 1);
  return decimalPart.length <= options.scale;
};

export const calculateOffset = (
  prev: string,
  curr: string,
  pos: number,
  options: INumericInputOptions
): number => {
  let i;
  let prevSymbols = 0;
  let currentSymbols = 0;

  for (i = 0; i < pos; i++) {
    if (prev[i] === options.thousands) {
      prevSymbols++;
    }
  }
  for (i = 0; i < pos; i++) {
    if (curr[i] === options.thousands) {
      currentSymbols++;
    }
  }
  return currentSymbols - prevSymbols;
};

export const allowedZero = (
  val: string,
  char: string,
  caretPos: number,
  options: INumericInputOptions
): boolean => {
  if (char !== '0') {
    return true;
  }

  const isNegative = val[0] === '-';
  const integerPart = val.slice(isNegative ? 1 : 0, getDecimalIndex(val, options.decimal));
  caretPos = isNegative ? caretPos - 1 : caretPos;

  // If there is some integer part and the caret is to the left of
  // the decimal point
  if (integerPart.length > 0 && caretPos < integerPart.length + 1) {
    // IF integer part is just a zero then no zeros can be added
    // ELSE the zero can not be added at the front of the value
    return integerPart === '0' ? false : caretPos > 0;
  } else {
    return true;
  }
};

/**
 * Parse a string-formatted value to a number
 *
 * @param formattedValue string to attempt to parse
 * @param options number formatting option
 * @returns number or undefined
 */
export const formattedToRaw = (formattedValue: string, options: INumericInputOptions): number | undefined => {
  if (!isString(formattedValue)) {
    return NaN;
  }

  if (!formattedValue.length) {
    return undefined;
  }

  const { range, allowableNaNInputMapper = new Map<string, number>() } = options;
  // If number is allowed to be negative, we myst allow "-" as a temporary value while a negative number is typed.
  if (range !== NumericInputRange.POSITIVE && !allowableNaNInputMapper.has('-')) {
    allowableNaNInputMapper.set('-', -0);
  }

  // Number(...) accepts thousands ',' or '' and decimal '.' so we must:

  // 1. Remove thousands delimiter to cover case it is not ','
  // Cannot replace with ',' in case decimal uses this
  formattedValue = formattedValue.replace(new RegExp(`[${options.thousands}]`, 'g'), '');

  // 2. Replace decimal with '.' to cover case it is not '.'
  // Ok to replace as thousands delimiter removed above
  formattedValue = formattedValue.replace(new RegExp(`[${options.decimal}]`, 'g'), '.');
  return allowableNaNInputMapper.has(formattedValue)
    ? allowableNaNInputMapper.get(formattedValue)
    : Number(formattedValue);
};

export const rawToFormatted = (rawValue: number, options: INumericInputOptions): string => {
  if (!isNumber(rawValue) || !isFinite(rawValue)) {
    return '';
  }

  let stringValue = String(rawValue);

  // String(...) has normalised formatting of:
  const rawThousands = ',';
  const rawDecimal = '.';

  // ensure string we are returning adheres to options
  stringValue = stringValue.replace(new RegExp(`[${rawThousands}]`, 'g'), options.thousands);
  stringValue = stringValue.replace(new RegExp(`[${rawDecimal}]`, 'g'), options.decimal);

  return stringValue;
};

export const extractNumberFromString = (value: string) => {
  const numb = value.match(/\d/g);
  return numb ? numb.join('') : '';
};

/** @deprecated */
export const getNumberOfDecimals = ({
  format,
  value,
  thousandDelimiter,
  decimalDelimiter,
  trimTrailingZeros = true
}: {
  format: NumericFormatType;
  trimTrailingZeros?: boolean;
} & FormatterFunctionParams) => {
  return getNumberOfDecimalPlaces(
    formatNumeric({
      type: format,
      value,
      thousandDelimiter,
      decimalDelimiter,
      hasDelimiters: true,
      trimTrailingZeros
    }),
    trimTrailingZeros,
    decimalDelimiter
  );
};
