import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('ACTION_BUTTON_LAYOUT_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      locationId: z.string(),
      objectId: z.string(),
      widgetTypeId: z.string(),
      gridStateId: z.string(),
      allowedCommands: z.array(z.string()).nullable()
    })
    .describe('Action button layout schema')
});
