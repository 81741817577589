import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { Scope } from '@sentry/react';
import { OperationDefinitionNode } from 'graphql';

const sentryGqlOpNameTag = 'graphql.operation.name';
const sentryGqlOpVariables = 'graphql.operation.variables';
const sentryGqlOpExtensions = 'graphql.operation.extensions';
const sentryGqlOpTypeTag = 'graphql.operation.type';
const sentryGraphqlContextName = 'Graphql Operation Info';

export type GraphQLContextForSentry = {
  operation: Operation;
  scope: Scope;
  /* default: true */
  setTags?: boolean;
  context?: Record<string, any>;
};

export const graphqlContextForSentry = ({
  operation,
  scope,
  context,
  setTags = true
}: GraphQLContextForSentry) => {
  const definition = getMainDefinition(operation.query);
  const queryOperationType = (definition as OperationDefinitionNode).operation;

  const queryName = `graphql.${queryOperationType}.${operation.operationName}`;
  const queryContext = {
    [sentryGqlOpNameTag]: operation.operationName,
    [sentryGqlOpTypeTag]: queryOperationType
  };

  scope.setContext(sentryGraphqlContextName, {
    ...queryContext,
    [sentryGqlOpVariables]: operation.variables,
    [sentryGqlOpExtensions]: operation.extensions,
    ...(context || {})
  });

  if (setTags) {
    scope.setTags(queryContext);
  }

  return {
    queryContext,
    queryName,
    queryOperationType
  };
};
