import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { testScoped } from '@app/workspace.registry';
import {
  AddInstrumentRestrictionDocument,
  AddInstrumentRestrictionMutation,
  AddInstrumentRestrictionMutationVariables,
  ModifyInstrumentRestrictionDocument,
  ModifyInstrumentRestrictionMutation,
  ModifyInstrumentRestrictionMutationVariables
} from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';

@testScoped
@singleton()
export class ReferenceDataService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public createInstrumentRestriction(input: AddInstrumentRestrictionMutationVariables) {
    const gqlResponse = this.gqlResponse.wrapMutate<
      AddInstrumentRestrictionMutation,
      AddInstrumentRestrictionMutationVariables
    >({
      mutation: AddInstrumentRestrictionDocument,
      variables: {
        ...input
      }
    });
    return gqlResponse.exec();
  }

  public modifyInstrumentRestriction(input: ModifyInstrumentRestrictionMutationVariables) {
    const gqlResponse = this.gqlResponse.wrapMutate<
      ModifyInstrumentRestrictionMutation,
      ModifyInstrumentRestrictionMutationVariables
    >({
      mutation: ModifyInstrumentRestrictionDocument,
      variables: {
        ...input
      }
    });
    return gqlResponse.exec();
  }
}
