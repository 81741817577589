import {
  registerForm,
  COMMON_OBJECT_CATEGORY,
  type InferRegistryFormVersionedSchema
} from '@app/app-config/registry.config';
import { LATEST_VERSIONED_SCHEMA } from './schema.bug-reporter.form-builder.input';

export const BUG_REPORTER_WINDOW_ID = 'BUG_REPORTER_WINDOW';

const config = registerForm({
  key: 'BUG_REPORTER',
  isUnique: true,
  windowId: BUG_REPORTER_WINDOW_ID,
  title: 'Report a bug',
  objectCategory: COMMON_OBJECT_CATEGORY.SYSTEM,
  versionedSchema: LATEST_VERSIONED_SCHEMA,
  form: {
    input: {}
  },
  isPublic: true,
  showInCommandPalette: false,
  hasEntitlement: [],
  windowOptions: {
    width: 380,
    height: 448,
    minWidth: 300,
    minHeight: 200,
    maxWidth: 800,
    maxHeight: 800
  }
});

export default config;
export type BugReporterFormSchemaInput = InferRegistryFormVersionedSchema<typeof config>;
