import { RulesDefinitionRow } from '@oms/generated/frontend';
import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';

const routingRulesDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

export const routingRulesColumns: ColDefMap<RulesDefinitionRow> = {
  id: { extends: 'orders.id', hide: true },
  name: { extends: 'generic.text', width: 140 },
  active: {
    headerName: 'Status',
    extends: 'generic.text',
    valueFormatter: 'criteriaStatus',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      valueFormatter: 'criteriaStatus'
    }
  },
  priority: { extends: 'generic.numeric' },
  description: { extends: 'generic.text', width: 260 },
  numberRules: { headerName: 'Criteria', extends: 'generic.numeric', cellRenderer: 'rule_count', width: 140 }
};

const routingRulesColumnLibrary: TableServerColumnLibrary<RulesDefinitionRow> = {
  defaultColumn: routingRulesDefaultColumn,
  columns: routingRulesColumns
};

export const buildRoutingRulesColumnDefs = () => buildColumnDefs(routingRulesColumnLibrary, 'routingRules');
