import { TradingOrderAuditTrailService } from '@app/data-access/services/trading/trading-orders/trading-order-audit-trail.service';
import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { auditTrailColumnLibrary } from './trading-order-view.audit-trail.columns';
import { broadcastGridSelection } from '@app/data-access/memory/grid.events';
import {
  viewEventDetailsAction,
  inlineViewEventDetailsAction
} from '@app/common/grids/actions/view-event-details.action';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';

export const TradingOrderAuditTrailGrid = ({
  tradingOrderId
}: {
  tradingOrderId: VisibleTradingOrderInfoWithAllocationsFragment['id'];
}) => {
  const auditTrailService = useService(TradingOrderAuditTrailService);

  const props = useVGrid(
    'trading-order-audit-trail',
    (b: GridBuilder<OrderAuditTrail>) =>
      b
        .columnLibrary(auditTrailColumnLibrary)
        .datasource((d) => d.source(auditTrailService.getAuditTrail$(tradingOrderId)).rowId((r) => r.data.id))
        .rowSelection((c) => c.single().broadcast(broadcastGridSelection()).selectRowsOnFirstDataRender([0]))
        .injectEvents([GridConfigEventHandler])
        .sideBar()
        .actions((a) =>
          a.schema((s) => s.action(viewEventDetailsAction).action(inlineViewEventDetailsAction))
        ),
    [auditTrailService, tradingOrderId]
  );
  return <VGrid {...props} />;
};

export default TradingOrderAuditTrailGrid;
