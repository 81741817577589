import { inject, injectable } from 'tsyringe';
import type { IServerSideGetRowsParams, RowDataUpdatedEvent } from '@ag-grid-community/core';
import type { Subscription } from 'rxjs';
import type { Prettify } from '@oms/shared/util-types';
import {
  agFilterModelToTableServerFilterStr,
  agSortModelToTableServerSortStr
} from '@app/data-access/services/system/table-server/filters/ag-grid.table-server.transformer';
import { ServerSideDatasource } from '@oms/frontend-vgrid';
import type {
  TableServerRow,
  TableServerRowSubscriptionVariables,
  TableServerSubscriptionShape
} from './table-server.datasource.contracts';
import { TableServerService } from './table-server.service';
import type { TableServerQueryFilter, TableServerQueryOptions } from './table-server.service';
import { createLogger } from '@oms/shared/util';
import type { OperationDefinitionNode } from 'graphql';
import type { EnrichedColumnDef } from '@app/common/grids/table-server/table-server.types';

export type TableServerDatasourceServiceOptions<
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
> = Prettify<Omit<TableServerQueryOptions<TData, TSubscription>, 'variables'>>;

export type TableServerGroupDatasourceServiceOptions<
  TAggregateData extends TableServerRow,
  TAggregateSubscription extends TableServerSubscriptionShape<TAggregateData>,
  TDetailData extends TableServerRow,
  TDetailSubscription extends TableServerSubscriptionShape<TDetailData>
> = {
  aggregateQuery: Omit<TableServerQueryOptions<TAggregateData, TAggregateSubscription>, 'variables'>;
  detailQuery: Omit<TableServerQueryOptions<TDetailData, TDetailSubscription>, 'variables'>;
};

const logger = createLogger({ name: 'Grid: TableServerDatasourceService', level: 'trace' });

@injectable()
export class TableServerDatasourceService {
  private querySubscriptions: Map<string, Subscription> = new Map();

  constructor(@inject(TableServerService) private tableServerService: TableServerService) {}

  public getSource<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>(
    options: TableServerDatasourceServiceOptions<TData, TSubscription>
  ): ServerSideDatasource<TData> {
    const definition = options.query?.definitions?.[0] as OperationDefinitionNode | undefined;
    const queryName = definition?.name?.value || 'unknown';
    return {
      getRows: (params: IServerSideGetRowsParams<TData>) => {
        const variables = this.createVariables(options, params);
        const subscriptionKey = this.getSubscriptionKey(variables);
        const didResetSubscription = this.maybeResetSubscription(subscriptionKey);
        const loggingParams = {
          query: options.query,
          variables,
          subscriptionKey,
          didResetSubscription
        };

        if (didResetSubscription) {
          logger.debug(`getSource: ${queryName}: reset subscription`, loggingParams);
        }

        logger.debug(`getSource: ${queryName}: subscribing to query. Waiting for updates...`, loggingParams);

        const subscription = this.tableServerService
          .query$<TData, TSubscription>({
            ...options,
            variables
          })
          .subscribe(({ totalCount, errors, rows }) => {
            if (errors?.length) {
              params.fail();
              console.error('Error fetching data', errors);
              logger.error(`getSource: ${queryName}: Error fetching data`, {
                ...loggingParams,
                errors
              });
              return;
            }

            const payload = {
              rowData: rows || [],
              rowCount: totalCount
            };

            params.success(payload);

            // Dispatch a serverSideRowDataUpdated event to notify the grid action of the changes
            const updatedEvent: RowDataUpdatedEvent = {
              type: 'serverSideRowDataUpdated',
              api: params.api,
              context: {},
              columnApi: params.columnApi
            };

            params.api.dispatchEvent(updatedEvent);

            logger.debug(
              `getSource: ${queryName}: received data success, dispatching serverSideRowDataUpdated`,
              { ...loggingParams, agGridSuccessPayload: payload }
            );
          });

        this.querySubscriptions.set(subscriptionKey, subscription);
      },
      destroy: () => {
        logger.debug(`getSource: ${queryName}: destroy`);
        return this.dispose();
      }
    };
  }

  public getGroupSource<
    TAggregateData extends TableServerRow,
    TAggregateSubscription extends TableServerSubscriptionShape<TAggregateData>,
    TDetailData extends TableServerRow,
    TDetailSubscription extends TableServerSubscriptionShape<TDetailData>
  >(
    options: TableServerGroupDatasourceServiceOptions<
      TAggregateData,
      TAggregateSubscription,
      TDetailData,
      TDetailSubscription
    >
  ): ServerSideDatasource<TAggregateData | TDetailData> {
    const isAggregateQuery = (
      query: (typeof options)['aggregateQuery'] | (typeof options)['detailQuery']
    ): query is (typeof options)['aggregateQuery'] => query === options.aggregateQuery;
    const aggDefinition = options.aggregateQuery.query.definitions[0] as OperationDefinitionNode | undefined;
    const aggQueryName = aggDefinition?.name?.value || 'unknown';
    const detailDefinition = options.detailQuery.query.definitions[0] as OperationDefinitionNode | undefined;
    const detailQueryName = detailDefinition?.name?.value || 'unknown';
    const queryName = `${aggQueryName} + ${detailQueryName}`;

    return {
      getRows: (params: IServerSideGetRowsParams<TAggregateData | TDetailData>) => {
        const { groupKeys } = params.request;
        const isExpanded = groupKeys.length > 0;
        const datasourceOptions = isExpanded ? options.detailQuery : options.aggregateQuery;

        const variables = this.createVariables(datasourceOptions, params, groupKeys);
        const subscriptionKey = this.getSubscriptionKey(variables);
        const didResetSubscription = this.maybeResetSubscription(subscriptionKey);
        const loggingParams = {
          queryName,
          aggQueryName,
          detailQueryName,
          variables,
          subscriptionKey,
          didResetSubscription
        };

        if (didResetSubscription) {
          logger.debug(`getGroupSource: ${queryName}: reset subscription`, loggingParams);
        }

        logger.debug(
          `getGroupSource: ${queryName}: subscribing to query. Waiting for updates...`,
          loggingParams
        );

        const subscription = this.tableServerService
          .query$<TAggregateData | TDetailData, TAggregateSubscription | TDetailSubscription>({
            ...datasourceOptions,
            variables,
            getData: (result) => {
              if (isAggregateQuery(datasourceOptions)) {
                return datasourceOptions.getData(result as TAggregateSubscription);
              } else {
                return datasourceOptions.getData(result as TDetailSubscription);
              }
            }
          })
          .subscribe(({ totalCount, errors, rows }) => {
            if (errors?.length) {
              logger.error(`getGroupSource: ${queryName}: Error fetching data`, {
                ...loggingParams,
                errors
              });
              return params.fail();
            }

            const processedRows = !isExpanded
              ? rows?.map((row) => ({ ...row, id: `group-${row.id}` }))
              : rows;

            const payload = {
              rowData: processedRows || [],
              rowCount: totalCount
            };

            params.success(payload);

            // Dispatch a serverSideRowDataUpdated event to notify the grid action of the changes
            const updatedEvent: RowDataUpdatedEvent = {
              type: 'serverSideRowDataUpdated',
              api: params.api,
              context: {},
              columnApi: params.columnApi
            };
            params.api.dispatchEvent(updatedEvent);

            logger.debug(
              `getGroupSource: ${queryName}: received data success, dispatching serverSideRowDataUpdated`,
              { ...loggingParams, agGridSuccessPayload: payload }
            );
          });

        this.querySubscriptions.set(subscriptionKey, subscription);
      },
      destroy: () => {
        this.dispose();
      }
    };
  }

  private createVariables<TData extends TableServerRow>(
    options: Omit<TableServerQueryOptions<TData, any>, 'variables'>,
    params: IServerSideGetRowsParams<TData>,
    groupKeys?: string[]
  ): TableServerRowSubscriptionVariables {
    const { startRow = 0, endRow = 100, sortModel, filterModel } = params.request;
    const limit = endRow - startRow;

    const groupColId = params.request.rowGroupCols?.[0]?.id;
    const filterValue = groupKeys?.[0];

    const queryFilter: TableServerQueryFilter<TData> = {
      ...options.filter,
      ...((groupColId && filterValue
        ? {
            [groupColId]: {
              ...(options.filter?.[groupColId] || {}),
              filter: filterValue
            }
          }
        : {}) as Partial<TableServerQueryFilter<TData>>),
      ...filterModel
    } as TableServerQueryFilter<TData>;

    // This allows a column to filter on a different column if it specifies useColumn in filterParams.
    const modifiedQueryFilter: TableServerQueryFilter<TData> = {};
    for (const key in queryFilter) {
      const colDef = params.api.getColumn(key)?.getColDef() as EnrichedColumnDef | undefined;
      const filterOnCol = colDef?.filterParams?.useColumn;
      modifiedQueryFilter[filterOnCol ? (filterOnCol as any) : (key as any)] = { ...queryFilter[key] };
    }

    // Using map to remove duplicates
    const mergedSort = [
      ...new Map([...(options.sort || []), ...sortModel].map((item) => [item.colId, item])).values()
    ];

    return {
      filterBy: agFilterModelToTableServerFilterStr(modifiedQueryFilter),
      sortBy: agSortModelToTableServerSortStr(mergedSort),
      limit,
      offset: startRow
    };
  }

  private getSubscriptionKey(vars: TableServerRowSubscriptionVariables): string {
    return `${vars.filterBy}-${vars.sortBy}-${vars.limit}-${vars.offset}`;
  }

  private maybeResetSubscription(key: string): boolean {
    const subscription = this.querySubscriptions.get(key);
    if (subscription) {
      subscription.unsubscribe();
      this.querySubscriptions.delete(key);
      return true;
    }

    return false;
  }

  private dispose(): void {
    this.querySubscriptions.forEach((sub) => sub.unsubscribe());
    this.querySubscriptions.clear();
  }
}
